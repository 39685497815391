<!--
 * @Author: pengyu
 * @Date: 2020-06-18 18:21:41
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-15 11:52:40
 * @Description: file content
-->
<template>
  <div class="date-panel" :class="{ show: showPanel }">
    <div class="panel-head" v-if="dataList.length >= 2 && type === 1">
      <div class="left-arrow" @click="handleArrowClick(1)">
        <en-icon name="xiayiye1" size="12px" color="#A9B5C6"></en-icon>
      </div>
      <div class="date-maintitle">
        <div v-for="(item, index) in dataList" v-show="item.isShow" :key="index" class="date-title" :class="{'is-border': item.id === isShowFirstItem.id}">
          <span class="txts">{{ item.name }}</span>
        </div>
      </div>
      <div class="right-arrow" @click="handleArrowClick(2)">
        <en-icon name="xiayiye" size="12px" color="#A9B5C6"></en-icon>
      </div>
    </div>
    <div class="panel-main">
      <div class="panel-type-two" v-if="type === 1">
        <div v-for="(item, index) in dataList" v-show="item.isShow" :key="index" class="date-box">
          <div v-for="(month, ind) in item.nodes" :key="ind" class="date-cell" :class="{ marcur: month.isDefault === 0, cur: month.isSelect }" @click="itemClick(month)">
            <span class="txts">{{ month.num | $padStart(2) }}</span>
          </div>
        </div>
      </div>
      <div class="panel-type-three" v-else>
        <div class="date-box">
          <div v-for="(year, index) in dataList" :key="index" class="date-cell" :class="{ marcur: year.isDefault === 0, cur: year.isSelect }" @click="itemClick(year)">
            <span class="txts">{{ year.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-btn">
      <en-button :disabled="hasButton" @click="submit" class="sure-btn">确定</en-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatePanel",
  props: {
    type: {
      type: Number,
      default: 2 // 2多级数据带头部，3单级数据带头部
    },
    ftype: {
      type: Number,
      default: 94 // 字段类型
    },
    startDate: {
      type: String,
      default: ""
    },
    endDate: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => []
    },
    hasButton: {
      type: Boolean,
      default: false // 确定按钮
    }
  },
  data() {
    return {
      showPanel: false,
      dataList: [], // 会计期间数据
      startItem: this.startDate, // 开始期间
      endItem: this.endDate // 结束期间
    };
  },
  computed: {
    isShowFirstItem() {
      const res = this.dataList.filter((itm) => itm.isShow);
      return res[0] || {};
    }
  },
  mounted() {
    this.initTheData(JSON.parse(JSON.stringify(this.list)));
  },
  watch: {
    showPanel() {
      this.propsToData();
    },
    list(nVal) {
      this.propsToData(nVal);
    }
  },
  methods: {
    /**
     * @description: 将属性赋值转换给data
     * @param data   会计期间数据
     */
    propsToData(data) {
      this.startItem = this.startDate;
      this.endItem = this.endDate;
      this.initTheData(JSON.parse(JSON.stringify(data || this.list)));
    },
    /**
     * @description: 初始化数据
     * @param data   会计期间数据
     */
    initTheData(data = []) {
      const sdAry = this.startItem.split(".");
      const edAry = this.endItem.split(".");
      if (this.type === 1) {
        data.forEach((item) => {
          item.isShow = false;
        });
        data.forEach((item, index) => {
          if (sdAry[0] === edAry[0]) {
            // 同一年的期间
            if (item.id === sdAry[0]) {
              item.isShow = true;
              if (index === 0) {
                data[index + 1] ? data[index + 1].isShow = true : "";
              } else {
                data[index - 1] ? data[index - 1].isShow = true : "";
              }
            }
          } else if (item.id === edAry[0]) {
            // 跨年的会计期间区间
            item.isShow = true;
            data[index - 1] ? data[index - 1].isShow = true : ""; // 在面板中默认展示最后两个区间
          }
          (item.nodes || []).forEach((citem) => {
            if (citem.yearNum === sdAry[0]) {
              if (citem.num === sdAry[1] || citem.num === edAry[1]) {
                citem.isDefault = 0;
              } else if (citem.num > sdAry[1] && citem.num < edAry[1]) {
                citem.isSelect = true;
              }
            }
          });
        });
      } else if (this.type === 2) {
        // 一级数据  仅年份
        data.forEach((item) => {
          if (item.id === sdAry[0]) {
            item.isDefault = 0;
          } else if (Number(item.id) > Number(sdAry[0]) && Number(item.id) < Number(edAry[0])) {
            item.isSelect = true;
          }
        });
      }
      this.dataList = data;
      this.$forceUpdate();
    },
    /**
     * @description: 左右箭头事件
     * @param dir   方向
     */
    handleArrowClick(dir) {
      const sIndex = this.dataList.findIndex((v) => v.isShow);
      if (dir === 1) {
        // 左
        if (sIndex > 0) {
          this.dataList[sIndex - 1].isShow = true;
          this.dataList[sIndex + 1].isShow = false;
        }
      } else if (dir === 2) {
        // 右
        if (this.dataList.length - 1 > sIndex + 1) {
          this.dataList[sIndex].isShow = false;
          this.dataList[sIndex + 2].isShow = true;
        }
      }
      this.$forceUpdate();
    },
    /**
     * @description: 面板会计期间点击
     * @param node   点击项data
     */
    itemClick(node) {
      const _this = this;
      if (this.type === 1) {
        // 层级数据
        const { yearNum, num } = node;
        // const index = this.dataList.findIndex((item) => item.id === yearNum);
        const sdAry = this.startItem.split(".");
        if (this.startItem === this.endItem) {
          if (`${yearNum}.${num}` === this.startItem) {
            // 点击本身
            return;
          }
          if (yearNum === sdAry[0]) {
            // 同一年
            if (Number(num) < sdAry[1]) {
              this.setTheData(`${yearNum}.${num}`, this.startItem);
            } else {
              this.setTheData(this.startItem, `${yearNum}.${num}`);
            }
          } else if (Number(yearNum) < Number(sdAry[0])) {
            // 跨年 点击项在前
            this.setTheData(`${yearNum}.${num}`, this.startItem);
          } else {
            // 跨年 点击项在后
            this.setTheData(this.startItem, `${yearNum}.${num}`);
          }
        } else {
          this.setTheData(`${yearNum}.${num}`, null);
        }
      } else {
        // 非层级数据
        const { id } = node;
        if (this.startItem === this.endItem) {
          const mx = Math.max(Number(id), Number(this.startItem));
          const mi = Math.min(Number(id), Number(this.startItem));

          if (id !== this.startItem) {
            this.dataList.forEach((v) => {
              if (v.id === id || v.id === _this.startItem) {
                v.isDefault = 0;
              } else if (Number(v.id) > mi && Number(v.id) < mx) {
                v.isSelect = true;
              } else {
                v.isDefault = 1;
                v.isSelect = false;
              }
            });
          }
          _this.startItem = String(mi);
          _this.endItem = String(mx);
        } else {
          this.dataList.forEach((v) => {
            v.isSelect = false;
            if (v.id === id) {
              v.isDefault = 0;
            } else {
              v.isDefault = 1;
            }
          });
          _this.startItem = id;
          _this.endItem = id;
        }
      }
    },
    /**
     * @description: 设置期间选中
     * @param sd  选中开始节点
     * @param ed  选中结束节点
     * @return:
     */
    setTheData(sd, ed) {
      const sdAry = sd.split(".");
      const edAry = ed?.split(".") || "";
      if (!ed) {
        this.startItem = sd;
        this.endItem = sd;
        this.dataList.forEach((item) => {
          (item.nodes || []).forEach((citem) => {
            citem.isSelect = false;
            if (Number(citem.yearNum) === Number(sdAry[0]) && Number(citem.num) === Number(sdAry[1])) {
              citem.isDefault = 0;
            } else {
              citem.isDefault = 1;
            }
          });
        });
      } else {
        this.startItem = sd;
        this.endItem = ed;
        this.dataList.forEach((item) => {
          (item.nodes || []).forEach((citem) => {
            if (sdAry[0] === edAry[0] && citem.yearNum === sdAry[0]) {
              // 同年
              if (Number(citem.num) === Number(sdAry[1]) || Number(citem.num) === Number(edAry[1])) {
                citem.isDefault = 0;
              } else if (Number(citem.num) > Number(sdAry[1]) && Number(citem.num) < Number(edAry[1])) {
                citem.isSelect = true;
              } else {
                citem.isDefault = 1;
                citem.isSelect = false;
              }
            } else if (
              (Number(citem.yearNum) === Number(sdAry[0]) && Number(citem.num) === Number(sdAry[1]))
             || (Number(citem.yearNum) === Number(edAry[0]) && Number(citem.num) === Number(edAry[1]))
            ) {
              citem.isDefault = 0;
            } else if ((Number(citem.yearNum) === Number(sdAry[0]) && Number(citem.num) > Number(sdAry[1])) || (Number(citem.yearNum) === Number(edAry[0]) && Number(citem.num) < Number(edAry[1]))) {
              citem.isSelect = true;
            } else if (Number(citem.yearNum) > Number(sdAry[0]) && Number(citem.yearNum) < Number(edAry[0])) {
              citem.isSelect = true;
            } else {
              citem.isDefault = 1;
              citem.isSelect = false;
            }
          });
        });
      }

      this.$forceUpdate();
    },
    /**
     * @description: 确定
     */
    submit() {
      this.$emit("panelConfirm", this.dataList, this.ftype);
      this.showPanel = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.date-panel {
  width: 600px;
  position: absolute;
  top: 4px;
  right: 71px;
  background: #fff;
  z-index: 9;
  border: 1px solid #e8ecf2;
  border-radius: 5px;
  display: none;
  .panel-head {
    display: flex;
    height: 60px;
    line-height: 60px;
    padding: 0 22px;
    // background: #f6fafd;
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #f6fafd;
      position: absolute;
      right: -8px;
      top: 10px;
    }
    .date-maintitle {
      flex: 1;
      display: flex;
    }
    .date-title {
      flex: 1;
      text-align: center;
      .txts {
        font-size: 12px;
        font-weight: bold;
      }
      &.is-border{
        border-right: 1px solid #e8ecf2;
      }
    }
    .left-arrow,
    .right-arrow {
      cursor: pointer;
    }
  }
  .panel-main {
    max-height: 120px;
    > div {
      display: flex;
    }
  }
  .date-box {
    flex: 1;
    max-height: 120px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid #e8ecf2;
    border-top: none;
    padding: 0 20px 10px 20px;
    border-left: 0;
    &:last-child {
      border-right: none;
    }
  }
  .date-cell {
    float: left;
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    margin: 10px 8px 0 0;
    border-radius: 5px;
    cursor: pointer;
    .txts {
      font-size: 12px;
    }
    &:nth-child(7n) {
      margin-right: 0;
    }
    &.cur {
      background: #e4effe;
    }
    &.marcur {
      background: #3e90fe;
      color: #fff;
    }
  }
  .panel-btn {
    height: 60px;
    line-height: 60px;
    text-align: right;
    .sure-btn {
      width: 72px;
      height: 32px;
      margin-right: 20px;
    }
  }
}
.show {
  display: block;
}
</style>
