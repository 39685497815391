<!--
 * @Author: pengyu
 * @Date: 2020-03-17 12:22:15
 * @LastEditTime: 2021-07-20 15:57:37
 * @LastEditors: pengyu
 * @Description: 会计期间控件
 * @FilePath: \user-intelligent-accounting\src\views\accountBook\dateInterval\index.vue
 -->
<template>
  <div class="date-interval-container" :class="{active:isActive}" v-click-outside="handleClickOutSide">
    <!-- head+body -->
    <div class="date-bar">
      <!-- head -->
      <div
        class="date-choose"
        v-if="hideHead===false"
        @click.stop="openPanel"
      >
        <div class="date-sel">{{startDate}}</div>
        <div class="date-sel">{{endDate}}</div>
      </div>
      <!-- body -->
      <div class="line">
        <div class="top-arrow" @click="topArrow">
          <en-icon name="zhankai" size="small" color="#A9B5C6"></en-icon>
        </div>
        <div class="line-list" v-if="type===1">
          <div
            v-for="(item, index) in dateRangeList"
            :key="index"
            class="date-item"
          >
            <div class="date-year">{{ item.name }}</div>
            <div
              v-for="(month, cindex) in item.nodes"
              :key="cindex"
              :class="{'date-month': true, 'cur' : (month.isDefault===0 || month.isSelect===true)}"
              :style="!index && cindex === 0 ? styles : ''"
              @click="itemClick(month)"
            >
              {{ month.num }}
              <en-icon
                name="yixuan"
                size="mini"
                color="#3e90fe"
                v-if="selected && Object.keys(selected).length && month[Object.keys(selected)[0]]===Object.values(selected)[0]"
              ></en-icon>
            </div>
          </div>
        </div>
        <div class="line-list" v-if="type===2">
          <div class="date-item">
            <div
              v-for="(item) in dateRangeList"
              :key="item.id"
              :class="{'date-month': true, 'cur' : (item.isDefault===0 || item.isSelect===true)}"
              @click="itemClick(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="bottom-arrow" @click="bottomArrow">
          <en-icon name="zhankai" size="small" color="#A9B5C6"></en-icon>
        </div>
      </div>
    </div>
    <!-- 切换按钮 -->
    <div class="open-icon" @click="collapseBtn">
      <en-icon name="shouqi-huijiqijian" size="12px" color="#fff"></en-icon>
    </div>
    <!-- 会计期间弹出框 -->
    <datePanel
      ref="panelMod"
      :list="dateRangeList"
      :type="type"
      :startDate="startDate"
      :endDate="endDate"
      @panelConfirm="resetDefaultAcc"
    ></datePanel>
  </div>
</template>

<script>
import datePanel from "./components/datePanel";

export default {
  name: "DateInterval",
  components: { datePanel },
  props: {
    // 类型： 1年月期间 2年期间
    type: {
      type: Number,
      default: 2
    },
    // 初始数据
    list: {
      type: Array,
      default: () => []
    },
    // 是否隐藏头部
    hideHead: {
      type: Boolean,
      default: false
    },
    // 已经勾选的节点，按节点的属性显示，结账与反结账的业务用到
    selected: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    list: {
      deep: true,
      handler(nVal) {
        this.dateRangeList = nVal;
        this.resetHeadDate();
      }
    }
  },
  data() {
    return {
      dateRangeList: this.data || [],
      styles: {},
      rangeStep: 0,
      startDate: "", // 开始日期
      endDate: "", // 结束日期
      isActive: false
    };
  },
  mounted() {
    this.resetHeadDate();
  },
  methods: {
    /**
     * @description: 初查找默认期间,  初始化startDate和endDate
     */
    resetHeadDate() {
      let defIds = [];
      if (this.type === 2) { // 无层级
        defIds = this.dateRangeList.filter((v) => v.isDefault === 0);
        if (defIds.length) {
          this.startDate = defIds[0]?.name;
          this.endDate = defIds[defIds.length - 1]?.name;
        } else {
          this.startDate = this.dateRangeList[0]?.name;
          this.endDate = this.startDate;
        }
      } else { // 数据有层级
        for (let i = 0; i < this.dateRangeList.length; i++) {
          (this.dateRangeList[i].nodes || []).forEach((v) => {
            if (v.isDefault === 0 || v.isSelect) {
              defIds.push(v);
            }
          });
        }
        if (defIds.length) {
          this.startDate = `${defIds[0].yearNum}.${defIds[0].num}`;
          this.endDate = `${defIds[defIds.length - 1].yearNum}.${defIds[defIds.length - 1].num}`;
        } else {
          this.startDate = this.dateRangeList[0]?.name || "";
          this.endDate = this.startDate;
        }
      }
    },
    /**
     * @description: 展开隐藏日期区间
     */
    collapseBtn() {
      this.isActive = !this.isActive;
      this.$emit("collapseBtn");
    },
    /**
     * @description: 展开日期选择面板
     */
    openPanel() {
      this.$refs.panelMod.showPanel = !this.$refs.panelMod.showPanel;
    },
    /**
     * @description: 会计区间向上滑动
     */
    topArrow() {
      if (this.rangeStep > 0) {
        this.rangeStep -= (this.rangeStep >= 5 ? 5 : this.rangeStep);
        this.styles = {
          "margin-top": `${this.rangeStep * -32}px`,
          transition: "1s"
        };
      }
    },
    /**
     * @description: 会计区间向下滑动
     */
    bottomArrow() {
      let allItem = 0;
      this.dateRangeList.forEach((v) => {
        allItem += v.nodes?.length || 0;
      });
      const allItemLength = allItem - 13;
      if (this.rangeStep < allItemLength) {
        this.rangeStep += (allItemLength - this.rangeStep >= 5 ? 5 : allItemLength - this.rangeStep);
        this.styles = {
          "margin-top": `${this.rangeStep * -32}px`,
          transition: "1s"
        };
      }
    },
    /**
     * @description: 会计期间点击
     */
    itemClick(data) {
      if (this.type === 1) {
        this.dateRangeList.forEach((itm) => {
          (itm.nodes || []).forEach((citm) => {
            citm.isDefault = 1;
            citm.isSelect = false;
            if (citm.id === data.id) {
              citm.isDefault = 0;
            }
          });
        });
      } else {
        this.dateRangeList.forEach((itm) => {
          itm.isDefault = 1;
          itm.isSelect = false;
          if (itm.id === data.id) {
            itm.isDefault = 0;
          }
        });
      }
      this.$forceUpdate();
      this.$refs.panelMod.showPanel = false;
      this.$emit("handleAccChange", {
        ids: data.id, mids: data.mId, names: data.name, list: this.dateRangeList, yearNum: data.yearNum
      });
    },
    /**
   * @description: 自定义查询会计期间区间
   * @param list
   */
    resetDefaultAcc(list) {
      const ids = [];
      const mids = [];
      const names = [];
      const yearNum = [];
      if (this.type === 1) { // 层级
        list.forEach((itm) => {
          (itm.nodes || []).forEach((citm) => {
            if (citm.isDefault === 0 || citm.isSelect) {
              ids.push(citm.id);
              mids.push(citm.mId);
              names.push(citm.name);
              yearNum.push(citm.yearNum);
            }
          });
        });
      } else { // 非层级
        list.forEach((itm) => {
          if (itm.isDefault === 0 || itm.isSelect) {
            ids.push(itm.id);
            mids.push(itm.mId);
            names.push(itm.name);
            yearNum.push(itm.id);
          }
        });
      }
      this.$emit("handleAccChange", {
        ids: ids.join(","), mids: mids.join(","), list, names: names.join(","), yearNum: yearNum.join(",")
      });
      this.dateRangeList = list;
      this.resetHeadDate();
      this.$forceUpdate();
      // 滚动位置
      if (this.type === 1) {
        let dms = [];
        list.forEach((item) => {
          dms = dms.concat([...item.nodes]);
        });

        const index = dms.findIndex((item) => item.isDefault === 0);
        const pyl = index >= 5 ? index - 5 : index;
        const deviation = `${-(pyl * 32)}px`;
        this.rangeStep = pyl;
        this.styles = {
          marginTop: deviation,
          transition: "1s"
        };
      }
    },
    /**
   * @description: 获取当前 会计期间
   */
    getCurDate() {
      let arr = [];
      if (this.type === 1) {
        this.dateRangeList.forEach((itm) => {
          itm.nodes.forEach((citm) => {
            if (citm.isDefault === 0) {
              arr.push(citm);
            }
          });
        });
      } else {
        arr = this.dateRangeList.filter((itm) => itm.isDefault === 0);
      }
      return [...arr];
    },
    handleClickOutSide() {
      this.$refs.panelMod.showPanel = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.date-interval-container {
  position: relative;
  .date-bar {
    width: 62px;
    background: #fff;
    border-radius: 5px;
    font-size: 12px;
    .date-choose {
      position: relative;
      text-align: center;
      cursor: pointer;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 50px;
      border-bottom: 1px solid #f3f3f3;
      &::before {
        position: absolute;
        left: 31px;
        top: 22px;
        content: "";
        height: 6px;
        width: 0;
        border-left: 1px solid #ababab;
        line-height: 0;
      }
    }
    .date-sel {
      line-height: 25px;
      cursor: pointer;
    }
    .top-arrow {
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      background: #f2f2f6;
      border-radius: 50%;
      margin: 0 auto 10px auto;
      cursor: pointer;
      .en-icon {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        margin-top: 2px;
      }
    }
    .bottom-arrow {
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      background: #f2f2f6;
      border-radius: 50%;
      margin: 10px auto;
      cursor: pointer;
      .en-icon {
        margin-top: 4px;
      }
    }
    .line-list {
      height: 420px;
      padding-top: 10px;
      overflow: hidden;
    }
    .line {
      height: 510px;
      padding-top: 10px;
      text-align: center;
      .date-item {
        position: relative;
      }
      .date-year {
        height: 12px;
        line-height: 12px;
        background: #edf4ff;
        color: #3e90fe;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        z-index: 9;
      }
      .date-month {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        position: relative;
        .en-icon{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
        }
      }
      .date-month.cur {
        background: #edf4ff;
        color: #3e90fe;
        font-weight: 600;
      }
      .date-month.disabled {
        color: #b9c4d2;
      }
    }
  }
  .open-icon {
    position: absolute;
    top: 225px;
    right: -4px;
    width: 16px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: rgba(25, 48, 70, 0.5);
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    vertical-align: middle;
    z-index: 9;
  }
}
.active {
  .date-bar,
  .date-panel {
    display: none;
  }
  .open-icon {
    .en-icon {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      margin-top: 2px;
    }
  }
}
</style>

<!--
 * 使用案例
      <dateInterval
        :type="1" //期间类型 1数据有层级 2数据无层级
        :hideHead="false" //是否隐藏头部
        :list="dateLineList"  //会计期间数据
        @collapseBtn="isActive=!isActive"  //此回调用于设置外部 样式 展开|隐藏
        @handleAccChange="accChange"   //回调 返回所选会计期间id 多个逗号隔开
      ></dateInterval>
-->
