<!--
 * @Author: Zhouql
 * @Date: 2020-03-04 16:08:01
 * @LastEditTime: 2021-07-12 10:54:14
 * @LastEditors: pengyu
 * @Description: 科目辅助余额表
 * @FilePath: \user\src\views\intelligentAccounting\accountBook\alert\balanceAlert.vue
 -->
<template>
  <en-dialog
    :visible.sync="visible"
    class="balanceAlert"
    width="1100px"
    @close="visible=false"
  >
    <div slot="title" class="dialog-title">
      <div>{{alertName}}</div>
      <subjectSel :subjectList="subjectList" @subjectVal="subjectValue"></subjectSel>
      <div class="btn-group">
        <div class="btn-export" @click="handleExport">
          <en-icon
            name="bus-btn5_1"
            size="small"
            style="color:#26c393"
          ></en-icon>
          <div class="label">
            导出
          </div>
        </div>
        <div class="btn-print" @click="handlePrint">
          <en-icon
            name="bus-btn6_1"
            size="small"
            style="color:#3e90fe"
          ></en-icon>
          <div class="label">
            打印
          </div>
        </div>
      </div>
    </div>
    <en-tabs
      v-model="activeTab"
      :list="tabList"
      type="white"
      @change="changeTabs"
    >
    </en-tabs>
    <!--表格部分-->
    <div class="subject-table" v-en-loading="enLoading">
        <!--表格部分-->
        <en-table :tree-config="{ children: 'nodes', hasChildren: 'hasNode', trigger: 'row' }" :data="subjectTable"  height="100%">
          <en-table-column type="index" width="60" align="center"></en-table-column>
          <en-table-column prop="name" label="辅助核算" ></en-table-column>
          <en-table-column title="期初余额" header-align="center">
            <en-table-column title="借方金额" prop="balanceBor" header-align="center" align="right">
               <template slot-scope="scope">
                {{scope.row.balanceBor|thousand(2)}}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="balanceLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{scope.row.balanceLoan|thousand(2)}}
              </template>
            </en-table-column>
          </en-table-column>
          <en-table-column title="本期发生" header-align="center">
            <en-table-column title="借方金额" prop="curBor" header-align="center" align="right">
              <template slot-scope="scope">
                {{scope.row.curBor|thousand(2)}}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="curLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{scope.row.curLoan|thousand(2)}}
              </template>
            </en-table-column>
          </en-table-column>
          <en-table-column title="期末余额" header-align="center">
            <en-table-column title="借方金额" prop="endBor" width="140" header-align="center" align="right">
              <template slot-scope="scope">
                {{scope.row.endBor|thousand(2)}}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="endLoan" width="140" header-align="center" align="right">
               <template slot-scope="scope">
                {{scope.row.endLoan|thousand(2)}}
              </template>
            </en-table-column>
          </en-table-column>
        </en-table>
    </div>
    <div slot="footer"></div>
  </en-dialog>
</template>

<script>
import { thousand, request } from "en-js";
import { Message } from "element-ui";
import { balanceService, commonService } from "@/api/intelligentAccounting";
import subjectSel from "../../components/subjectSel";

export default {
  props: {
    afferentData: {}// 查询的一些参数
  },
  components: {
    subjectSel
  },
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  mixins: [],
  data() {
    return {
      visible: false,
      enLoading: false,
      alertName: "", // 弹窗名称
      subjectList: [], // 科目列表
      subjectCode: undefined, // 科目的code
      tabList: [],
      activeTab: "", // 默认tab选中
      subjectTable: []

    };
  },
  watch: {
    visible(data) {
      if (data) {
        if (this.afferentData) {
          this.alertName = `科目辅助余额表（${this.afferentData.startDate}—${this.afferentData.endDate}）`;
          this.init();
        }
      }
    }
  },
  methods: {
    async init() {
      await this.querySubjectHasAssit();
    },
    // 请求科目数据
    @request(true, "enLoading")
    async querySubjectHasAssit() {
      const res = await commonService.querySubjectHasAssit({
        accountId: this.afferentData.accountId,
        accountingEntity: this.afferentData.accountingEntity,
        yearNum: this.afferentData.yearNum,
        subjectCategory: this.afferentData.subjectCategory
      });
      this.subjectList = res.filter((item) => !(item.parentCode === "0" && item.hasChildren === "000"));
      // TODO 数组解构
      this.subjectCode = this.subjectList[0].code;
      this.queryTab();
    },
    // 请求tab数据
    @request(true, "enLoading")
    async queryTab() {
      const res = await commonService.querySubjectAllAssit({
        accountId: this.afferentData.accountId,
        yearNum: this.afferentData.yearNum,
        subjectCategory: this.afferentData.subjectCategory,
        subjectCode: this.subjectCode
      });
      this.tabList = res;
      // TODO 数组解构
      this.activeTab = this.tabList[0].id;
      this.querySubjectAss();
      console.log("tab", this.tabList);
    },
    /**
     * @description: 取科目下拉选中数据
     */
    subjectValue(param) {
      this.subjectCode = param.code;
      this.activeTab = param.mainAssistAcc;
      if (this.activeTab && this.subjectCode) {
        this.querySubjectAss();
      }
    },
    // 请求科目辅助余额表
    @request(true, "enLoading")
    async querySubjectAss() {
      const res = await balanceService.queryAbSubjectAss({
        accountId: this.afferentData.accountId,
        accountingEntity: this.afferentData.accountingEntity,
        subjectCode: this.subjectCode,
        accountingPeriod: this.afferentData.accountingPeriod,
        status: this.afferentData.status,
        assitAccId: this.activeTab
      });
      this.subjectTable = res;
    },

    /**
     * @description: tab切换
     */
    changeTabs(res) {
      this.activeTab = res.id;
      this.querySubjectAss();
    },
    /**
     * @description: 导出科目辅助余额表
     */
    handlePrint() {
      this.$emit("printSubject", this.subjectTable);
    },
    async handleExport() {
      const _this = this;
      const {
        accountId, accountName, accountingEntity, accountingEntityName, accountingPeriod, accountingPeriodName
      } = this.afferentData;
      const pa = {
        typeId: "km",
        accountId,
        accountName,
        accountingEntity,
        accountingEntityName,
        accountingPeriod, // 日期区间id
        accountingPeriodName,
        subjectCode: this.subjectCode,
        subjectName: (() => {
          const o = this.subjectList.filter((itm) => itm.code === _this.subjectCode);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        assitAccId: this.activeTab
      };
      if (!pa.subjectCode) {
        Message("请选择科目");
        return;
      }
      this.$parent.handleExportExcel(pa);
    }
  }
};
</script>

<style lang="scss" scoped>
.balanceAlert {
  text-align: left;
  min-height: 300px;
  .dialog-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-group{
      margin-right: 20px;
      display: flex;
    }
    .btn-export,
    .btn-print {
      cursor: pointer;
      margin-right: 20px;
      display: flex;
      align-items: center;
      .en-icon {
        margin-right: 5px;
      }
      .label {
        color: #636c78;
        font-weight: 500;
      }
    }
    .choice-subject{
      flex:1;
      text-align: left;
    }
  }
  .subject-table{
    min-height: 480px;
  }
}
</style>
