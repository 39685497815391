/*
 * @Author: 周晴龙
 * @Date: 2020-07-30 15:05:53
 * @LastEditTime: 2021-06-21 16:53:22
 * @LastEditors: pengyu
 * @Description: 账簿公用方法
 * @FilePath: \user\src\mixins\intelligentAccounting\accountBookComm.js
 */
import { thousand } from "en-js";
import printJS from "print-js";
import { commonService } from "@/api/intelligentAccounting";

export default {
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  watch: {
    isActive() {
      this.$nextTick(() => {
        this.$refs.enTable?.recalculate();
      });
    }
  },
  methods: {
    /**
     * @description: 打印
     */
    printTable(printable = "print-template-assist") {
      printJS({
        printable,
        type: "html",
        css: "../../../css/print-table.css",
        // header: "打印测试",
        // documentTitle: "水印|保存名称",
        maxWidth: 900,
        scanStyles: false,
        // honorColor: true, // 彩打
        targetStyles: ["*"],
        onPrintDialogClose: (err) => {
          console.log("取消打印", err);
        },
        error: (err) => {
          console.log("打印errrrr", err);
        },
        onLoadingStart: (err) => {
          console.log("onLoadingStart", err);
        },
        onLoadingEnd: (err) => {
          console.log("onLoadingEnd", err);
        }
      });
    },
    // 请求账套接口
    // @request(true, "enLoading")
    async queryAccList() {
      const _this = this;
      const res = await commonService.queryAccList({});
      _this.accountList = res;
      res.forEach((item) => {
        if (item.isDefault === 0) {
          _this.accountDefault = item.id;
          return false;
        }
        return true;
      });
      if (!_this.accountDefault) { // 无默认值 取第一个
        _this.accountDefault = res[0].id;
      }
    },
    /**
     * @description: 请求记账主体接口
     * @param single  是否单选
     * @return:
     */
    // @request(true, "enLoading")
    async queryAccountEntity(single) {
      const _this = this;
      const res = await commonService.queryObjectTreeExt({
        objectType: "020" // 记账主体请求基础数据固定参数
      });
      _this.accountMainList = res;
      _this.defaultCondition(single);
    },
    /**
     * @description: 默认选中搜索条件
     * @param single 是否单选
     * @return:
     */
    defaultCondition(single) {
      const _this = this;
      // 默认记账主体
      function findDefault(arr) {
        const nodesCur = [];
        arr.forEach((v) => {
          // nodesCur = v;
          if (v.isDefault === 0) {
            nodesCur.push(v.id);
          }
        });
        return nodesCur;
      }
      this.accountMainList.forEach((item) => {
        if (item.isDefault === 0) {
          _this.accountMainDefault.push(item.id);
        }
        if (item.hasChildren === "000") {
          _this.accountMainDefault = [...this.accountMainDefault, ...findDefault(item.nodes)];
        }
      });
      if (single) {
        this.accountMainDefault = this.accountMainDefault.splice(0, 1);
      }
      _this.defaultSearchData = {
        accountDefault: this.accountDefault,
        vocrangeDefault: this.vocrangeDefault,
        accountMainDefault: this.accountMainDefault
      };
      // 搜素组件下拉选项
      _this.searchDataList = {
        accountList: this.accountList,
        vocrangeList: this.vocrangeList,
        accountMainList: this.accountMainList
      };
    },
    // 请求科目数据
    // @request(true, "enLoading")
    async queryAllSubject() {
      const _this = this;
      const res = await commonService.queryAllSubject({
        accountId: this.accountDefault,
        yearNum: _this.defaultYearNum
      });
      if (!res) return;
      _this.subjectList = res;

      // 找默认值 保留上一次的记录 没有则取第一个
      const pa = _this.getCommParam();
      if (pa.subjectCode) {
        const df = res.filter((itm) => itm.code === pa.subjectCode);
        if (df.length) {
          _this.subjectCode = df[0].code;
        } else {
          _this.subjectCode = res[0].code;
        }
      } else {
        _this.subjectCode = res[0].code;
      }
    },
    /**
   * @description: 账簿公共导出导出excel
   * @param params
   * @return:
   */
    async handleExportExcel(params) {
      const res = await commonService.exportExcelData(params);
      const { headers } = res;
      const cd = headers["content-disposition"];
      const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/vnd.ms-excel" }));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = decodeURIComponent(cd.split("=")[1]);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
