var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "screen-model",
      attrs: { title: `${_vm.name}：${_vm.item.valueText}` },
    },
    [
      _c("div", { staticClass: "key" }, [_vm._v(_vm._s(_vm.name) + "：")]),
      _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.item.valueText))]),
      _c("en-icon", {
        attrs: { name: "guanbi-danchuang", size: "mini" },
        nativeOn: {
          click: function ($event) {
            return _vm.close.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }