<!--
 * @Author: pengyu
 * @Date: 2020-03-13 17:52:50
 * @LastEditTime: 2021-01-21 17:10:32
 * @LastEditors: pengyu
 * @Description: 凭证处理-分录视图-筛选块块
 * @FilePath: \user-intelligent-accounting\src\components\screenModel\index.vue
 -->
<template>
  <div class="screen-model" :title="`${name}：${item.valueText}`">
    <div class="key">{{ name }}：</div>
    <div class="value">{{ item.valueText }}</div>
    <en-icon
      name="guanbi-danchuang"
      size="mini"
      @click.native="close"
    ></en-icon>
  </div>
</template>

<script>
export default {
  name: "ScreenModel",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    name() {
      const obj = {
        subjectId: "科目",
        baseDataTableType: "辅助核算",
        remark: "摘要",
        voucherWay: "凭证类型",
        voucherNo: "凭证号码",
        amount: "金额",
        status: "凭证状态",
        makeId: "制单人",
        interfaceStatus: "是否传输",
        voucherDataType: "凭证类别",
        busTemplateId: "业务模板",
        refCode: "业务编号"
      };
      return obj[this.item.nameVariable];
    }
  },
  methods: {
    /**
     * @description:  更新请求参数
     * @param {type}
     * @return:
     */
    close() {
      this.$emit("deleteCondition", this.index);
    }
  }
};
</script>

<style lang="scss" scoped>
.screen-model {
  // width: 120px;
  height: 25px;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #f5f8fd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  margin-left: 20px;
  border: 1px solid #dcdfe6;
  cursor: pointer;
  >div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .en-icon{
    margin-left: 10px;
    &:hover{
      color: #409eff;
    }
  }
}
</style>
