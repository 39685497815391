<!--
 * @Author: pengyu
 * @Date: 2020-11-17 11:28:17
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-12 11:04:23
 * @Description: 辅助核算余额表|多栏帐--穿透-辅助核算明细
-->
<template>
  <en-dialog
    :visible="visible"
    class="assit"
    width="1100px"
    @close="visible=false"
  >
    <div slot="title" class="dialog-title">
      <div>{{alertName}}</div>
      <div class="btn-group">
        <div class="btn-export" @click="handleExport">
          <en-icon
            name="bus-btn5_1"
            size="small"
            style="color:#26c393"
          ></en-icon>
          <div class="label">
            导出
          </div>
        </div>
        <div class="btn-print" @click="handlePrint">
          <en-icon
            name="bus-btn6_1"
            size="small"
            style="color:#3e90fe"
          ></en-icon>
          <div class="label">
            打印
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <en-table :data="list" height="400px" rowId="onlyId" @row-click="handleRowClick">
        <en-table-column type="index" width="60" align="center"></en-table-column>
        <en-table-column title="日期" prop="produceDate" header-align="center"></en-table-column>
        <en-table-column title="凭证号" prop="voucherNo" header-align="center"></en-table-column>
        <en-table-column title="摘要" prop="remark"  header-align="center" width="250"></en-table-column>
        <en-table-column title="借方金额" prop="bor" header-align="center" align="right">
          <template slot-scope="scope">
            {{scope.row.bor|thousand(2)}}
          </template>
        </en-table-column>
        <en-table-column title="贷方金额" prop="loan" header-align="center" align="right">
          <template slot-scope="scope">
            {{scope.row.loan|thousand(2)}}
          </template>
        </en-table-column>
        <en-table-column title="方向" prop="direction" width="50" align="center"></en-table-column>
        <en-table-column title="余额" prop="balance" header-align="center" align="right">
          <template slot-scope="scope">
            {{scope.row.balance|thousand(2)}}
          </template>
        </en-table-column>
      </en-table>
    </div>
    <div slot="footer"></div>
  </en-dialog>
</template>

<script>
import { thousand, request } from "en-js";
import { commonService } from "@/api/intelligentAccounting";

export default {
  props: {
    afferentData: {}// 查询的一些参数
  },
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  data() {
    return {
      enLoading: false,
      visible: false,
      list: [],
      alertName: ""
    };
  },
  computed: {
  },
  watch: {
    visible(data) {
      const { assitAccName, subjectCode, subjectName } = this.afferentData;
      this.alertName = `${assitAccName || ""}-${subjectCode || ""}${subjectName || ""}明细表`;
      if (data === true) {
        this.queryDetail();
      } else {
        Object.assign(this.$data, this.$options.data());
      }
    }
  },
  methods: {
    // 详情
    @request(true, "enLoading")
    async queryDetail() {
      const res = await commonService.queryAbAssitAccDetail({ ...this.afferentData });
      res.dataList.forEach((itm) => {
        itm.dataId = itm.id;
      });
      this.list = res.dataList || [];
    },
    /**
     * @description: 打印
     */
    handlePrint() {
      this.$emit("printDetail", { list: this.list, title: this.alertName });
    },
    // 导出
    handleExport() {
      const pa = { typeId: "fzmx", ...this.afferentData };
      this.$parent.handleExportExcel(pa);
    },
    changeRowId(row) {
      console.log(row);
      return "id";
    },
    // 行点击
    handleRowClick({ row }) {
      const { dataId } = row;
      // 穿透凭证详情
      if (dataId) {
        this.$parent.voucherId = dataId;
        this.$parent.$refs.vocDialog.visible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.assit {
  text-align: left;
  min-height: 300px;
  .dialog-title{
    display: flex;
    justify-content: space-between;
    .btn-group{
      margin-right: 20px;
      display: flex;
    }
    .btn-export,
    .btn-print {
      cursor: pointer;
      margin-right: 20px;
      display: flex;
      align-items: center;
      .en-icon {
        margin-right: 5px;
      }
      .label {
        color: #636c78;
        font-weight: 500;
      }
    }
  }
}
</style>
