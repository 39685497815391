<!--
 * @Author: Zhouql
 * @Date: 2020-03-10 17:43:11
 * @LastEditTime: 2021-08-05 11:50:49
 * @LastEditors: pengyu
 * @Description: 账簿-辅助核算余额表
 * @FilePath: \user\src\views\intelligentAccounting\accountBook\assistBalanceList\index.vue
 -->
<template>
  <div class="assistBalanceList">
    <div class="content" :class="isActive ? 'left-container expand' : 'left-container'">
      <en-title-card name="辅助核算余额表">
        <div slot="right">
          <searchList @clickData="getVal" :searchDataList="searchDataList" :defaultSearchData="defaultSearchData"></searchList>
          <div class="btn-export" @click="handleExport">
            <en-icon name="bus-btn5_1" size="small" style="color:#26c393"></en-icon>
            <div class="label">导出</div>
          </div>
          <div class="btn-print" @click="printTable('print-template-assist')">
            <en-icon name="bus-btn6_1" size="small" style="color:#3e90fe"></en-icon>
            <div class="label">打印</div>
          </div>
        </div>
      </en-title-card>
      <en-tabs v-model="activeTab" :list="tabList" type="white" :fixedWidth="120" @change="changeTabs">
        <div slot="right">
          <en-select-data v-model="assistValue" :config="assistConfig" ref="clearValue"></en-select-data>
          <subjectSel :subjectList="subjectList" @subjectVal="subjectValue"></subjectSel>
        </div>
      </en-tabs>
      <!--表格部分-->
      <div class="table-main" v-en-loading="enLoading">
        <en-table
          ref="dataTable"
          :tree-config="{ children: 'nodes', hasChildren: 'hasNode', trigger: 'default' }"
          :data="list"
          height="100%"
          @row-click="handleRowClick"
        >
          <en-table-column type="index" width="60"></en-table-column>
          <en-table-column prop="name" label="辅助核算" width="430"></en-table-column>
          <en-table-column title="期初余额" header-align="center">
            <en-table-column title="借方金额" prop="balanceBor" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.balanceBor | thousand(2) }}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="balanceLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.balanceLoan | thousand(2) }}
              </template>
            </en-table-column>
          </en-table-column>
          <en-table-column title="本期发生" header-align="center">
            <en-table-column title="借方金额" prop="curBor" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.curBor | thousand(2) }}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="curLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.curLoan | thousand(2) }}
              </template>
            </en-table-column>
          </en-table-column>
          <en-table-column title="期末余额" header-align="center">
            <en-table-column title="借方金额" prop="endBor" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.endBor | thousand(2) }}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="endLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.endLoan | thousand(2) }}
              </template>
            </en-table-column>
          </en-table-column>
        </en-table>
      </div>
    </div>
    <!-- '会计期间' -->
    <dateInterval
      :type="1"
      :hideHead="false"
      :list="dateLineList"
      @collapseBtn="isActive = !isActive"
      @handleAccChange="accChange"
      class="date-interval"
    ></dateInterval>
    <!-- 打印模板 -->
    <print-template-assist :data-list="flatList" :search-data="printSearchData"></print-template-assist>
    <print-template-assist-detail :data-list="detailList" :search-data="printDetailData"></print-template-assist-detail>
    <!-- 穿透辅助明细 -->
    <assitAccDetail ref="assitModel" @printDetail="printDetail" :afferentData="afferentItem"></assitAccDetail>
    <!-- 凭证详情 -->
    <vocDetailAlert
      ref="vocDialog"
      :voucherId="voucherId"
      :actionType="2"
      :formal="{}"
      openFlag="quotes"
    ></vocDetailAlert>
  </div>
</template>

<script>
import { request } from "en-js";
import { cloneDeep } from "lodash";
import { Message } from "element-ui";
import { commonService, balanceService } from "@/api/intelligentAccounting";
import accountBookComm from "@/mixins/intelligentAccounting/accountBookComm.js"; // 公用的JS方法
import enSelectData from "@/components/en-select-data";
import searchList from "../../components/searchList"; // 顶部搜索
import dateInterval from "../../components/dateInterval"; // 会计期间控件
import subjectSel from "../../components/subjectSel";
import PrintTemplateAssist from "./components/PrintTemplateAssist";
import PrintTemplateAssistDetail from "./components/PrintTemplateAssistDetail";
import assitAccDetail from "../alert/assitAccDetail.vue";
import vocDetailAlert from "../../components/vocDetailAlert";

// 科目
export default {
  name: "AssistBalanceList",
  components: {
    searchList, dateInterval, subjectSel, enSelectData, assitAccDetail, PrintTemplateAssist, vocDetailAlert, PrintTemplateAssistDetail
  },
  mixins: [accountBookComm],
  data() {
    return {
      enLoading: null,
      isActive: false,
      list: [], // 表格
      flatList: [], // 扁平化的表格数据
      detailList: [], // 详情列表
      tabList: [], // tab数据
      activeTab: undefined, // tab选中id
      dateLineList: [], // 会计期间数据
      defaultYearNum: "", // 会计区间选中年份
      accountMainId: [], // 记账主体
      // 凭证范围下拉数据
      vocrangeList: [
        {
          id: 1,
          name: "全部凭证"
        },
        {
          id: 2,
          name: "仅已审核"
        }
      ],
      accountList: [], // 账套下拉数据
      accountMainList: [], // 记账主体下拉数据
      accountDefault: {}, // 默认账套
      // 默认范围
      vocrangeDefault: 1,
      accountMainDefault: [], // 默认记账主体
      subjectList: [], // 科目下拉数据
      subjectCode: "",
      searchDataList: {}, // 搜索的下拉数据
      defaultSearchData: {}, // 搜索默认数据
      accountingPeriod: [], // 会计期间的id
      accountingPeriodName: [],
      assistTreeList: [], // 辅助核算选择数据
      assistValue: {}, // 辅助核算内容
      // 人员数 配置数据
      assistConfig: {
        isMulti: false, // 是否多选
        lazy: true, // 获取静态数据  请设置false
        selectMode: "tree",
        placeholder: "请选择辅助核算",
        defaultProps: {
          value: "id",
          label: "name",
          isLeaf: (data) => data.hasChildren === "001"
        },
        dependData: {}
      },
      endDate: "",
      startDate: "",
      afferentItem: {}, // 辅助核算明细表穿透
      voucherId: "",
      printDetailData: {}
    };
  },
  computed: {
    printSearchData() {
      let unit = "";
      const findNode = (list, id) => {
        if (!id) return;
        list.forEach((item) => {
          if (item.id === id) {
            unit = item.code + item.text;
          } else if (item.nodes) {
            findNode(item.nodes, id);
          }
        });
      };
      const title = `${this.tabList.find((item) => item.id === this.activeTab)?.name + (this.assistValue?.name || "")}余额表`;
      findNode(this.accountMainList, this.accountMainDefault[0]);
      const { startDate, endDate } = this;
      const period = startDate && endDate && startDate !== endDate ? `${startDate}-${endDate}` : endDate || startDate;
      return {
        unit,
        title,
        period
      };
    }
  },
  watch: {
    assistValue() {
      this.queryAssitAcc();
    },
    isActive() {
      this.$refs.dataTable.resetResizable();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    printDetail({ list, title }) {
      this.detailList = list;
      this.printDetailData = {
        title,
        period: this.printSearchData.period
      };
      setTimeout(() => {
        this.printTable("print-template-assist-detail");
      }, 300);
    },
    /**
     * @description: 初始化
     */
    async init() {
      await this.queryAccList(); // 查询账套
      await this.queryAccTime(); // 查询会计期间
      await this.queryAccountEntity(); // 请求记账主体
      await this.queryAllAssit(); // 查询辅助核算项
      await this.querySubject(); // 查询最末级科目
      await this.queryAssitAcc(); // 查数据
    },
    // 请求日期区间数据接口
    async queryAccTime() {
      const res = await commonService.queryAccTime({
        accountId: this.accountDefault,
        hasChildren: "000"
      });
      this.dateLineList = res;
      this.accPeriod();
    },
    /**
     * @deprecate
     */
    accPeriod() {
      // 默认会计区间获取会计区间选中ID
      function findDefaultPeriod(arr) {
        const nodesCur = [];
        const nodesCurName = [];
        const yearNum = [];
        const dateNameList = [];
        arr.forEach((v) => {
          if (v.isDefault === 0) {
            nodesCur.push(v.id);
            nodesCurName.push(v.name);
            yearNum.push(v.yearNum);
            dateNameList.push(v.name);
          }
        });
        return {
          nodesCur, nodesCurName, yearNum, dateNameList
        };
      }

      this.dateLineList.forEach((item) => {
        if (item.isDefault === 0) {
          if (item.nodes) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            this.accountingPeriod = findDefaultData.nodesCur;
            this.accountingPeriodName = findDefaultData.nodesCurName;
            this.defaultYearNum = findDefaultData.yearNum.toString();
            this.startDate = findDefaultData.dateNameList[0];
            this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          }
        }
      });
    },
    /**
     * @description: 取搜索组件带出数据
     */
    async getVal(data) {
      // 根据账套ID 查不同会计期间
      const originAccount = this.accountDefault;
      if (data.accountMain.length === 0) {
        Message("请选择记账主体");
        return;
      }
      this.accountDefault = data.account;
      this.accountMainDefault = data.accountMain;
      this.vocrangeDefault = data.vocrange;

      if (data.account !== originAccount) {
        // 账套发生改变 重新查询会计期间  辅助核算项
        await this.queryAccTime();
        await this.queryAllAssit();
        await this.querySubject();
      }
      this.queryAssitAcc();
    },
    /**
     * @description: 查询辅助核算  tab数据
     */
    async queryAllAssit() {
      const res = await commonService.querySubjectAllAssit({
        accountId: this.accountDefault,
        yearNum: this.defaultYearNum
      });
      this.tabList = res;
      // 如果选过辅助核算则保留
      if (this.activeTab) {
        const as = res.filter((itm) => itm.id === this.activeTab)[0];
        if (!as) {
          this.activeTab = res[0].id;
        }
      } else {
        this.activeTab = res[0].id;
      }
      const type = res[0].type;
      const url = type === "002" ? "getDeptUserTree" : "queryObjectTreeExt";
      const parmas = type === "002" ? { id: 0 } : {
        objectTypeId: res.id,
        objectType: type,
        dataSource: 1
      };
      this.assistConfig.dependData = {
        requestService: commonService, // 引入服务名称
        requestFn: url, // 服务中方法
        requestParams: parmas
      };
      if (type === "002") {
        this.$set(this.assistConfig.defaultProps, "disabled", (data) => data.type !== "003");
      } else {
        this.$set(this.assistConfig.defaultProps, "disabled", null);
      }
    },
    // 请求科目
    /**
     * @description: 查询最末级科目
     */
    async querySubject() {
      const res = await commonService.queryAllSubject({
        accountId: this.accountDefault,
        yearNum: this.defaultYearNum,
        assitId: this.activeTab
      });
      this.subjectList = res;
      this.subjectList.unshift({ id: "0", name: "全部", code: "" });
    },
    // 请求辅助余额表数据
    @request(true, "enLoading")
    async queryAssitAcc() {
      const pa = this.getCommParam();
      const res = await balanceService.queryAssitAcc(pa);
      const copyList = cloneDeep(res?.dataList || []);
      // 处理一下数据
      res?.dataList.forEach((itm) => {
        itm.nodes?.forEach((citm) => {
          citm.isKemu = true;
          citm.name = `${citm.id} ${citm.name}`;
          citm.dataId = itm.id;
        });
      });
      this.list = res?.dataList || [];
      this.flatList = [];
      this.getFlatList(copyList);
    },
    /**
     * @description:扁平化list
     */
    getFlatList(list) {
      list.forEach((item) => {
        this.flatList.push(item);
        if (item.nodes) {
          this.getFlatList(item.nodes);
        }
      });
    },
    /**
     * @description: tab切换
     */
    async changeTabs(res) {
      this.activeTab = res.id;
      // 切换辅助核算tab  清除辅助核算下拉
      this.$refs.clearValue.clearMultiSelect();
      const type = res.type;
      const url = type === "002" ? "getDeptuserTree" : "queryObjectTreeExt";
      const parmas = type === "002" ? { id: 0 } : {
        objectTypeId: res.id,
        objectType: type,
        dataSource: 1
      };
      this.assistValue.id = "";
      this.assistValue.name = "";
      this.assistConfig.dependData = {
        requestService: commonService, // 引入服务名称
        requestFn: url, // 服务中方法
        requestParams: parmas // 请求参数
      };
      if (type === "002") {
        this.$set(this.assistConfig.defaultProps, "isLeaf", (data) => data.hasChildren === "000");
        this.$set(this.assistConfig.defaultProps, "disabled", (data) => data.type !== "003");
      } else {
        this.$set(this.assistConfig.defaultProps, "disabled", null);
      }
      await this.querySubject();
      this.queryAssitAcc();
    },
    /**
     * @description: 取科目下拉选中数据
     */
    subjectValue(param) {
      this.subjectCode = param.code;
      if (this.subjectCode) {
        this.queryAssitAcc();
      }
    },
    /**
     * @description: 日期区间选中数据
     */
    async accChange(data) {
      this.accountingPeriod = data.ids;
      this.accountingPeriodName = data.names;
      this.defaultYearNum = data.yearNum;
      let dateNames = [];
      dateNames = data.names.split(",");
      this.startDate = dateNames[0];
      this.endDate = dateNames[dateNames.length - 1];
      await this.queryAllAssit();
      await this.querySubject();
      this.queryAssitAcc();
    },
    /**
     * @description: 获取公共传参
     */
    getCommParam() {
      const _this = this;
      const params = {
        accountId: this.accountDefault,
        accountName: (() => {
          const o = this.accountList.filter((itm) => itm.id === _this.accountDefault);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        accountingEntity: this.accountMainDefault.toString(),
        accountingEntityName: (() => {
          const re = [];
          _this.accountMainDefault.forEach((itm) => {
            _this.accountMainList.forEach((itm2) => {
              if (itm2.id === itm) {
                re.push(`${itm2.code} ${itm2.name}`);
              }
              itm2.nodes?.forEach((itm3) => {
                if (itm3.id === itm) {
                  re.push(`${itm3.code} ${itm3.name}`);
                }
              });
            });
          });
          return re.join(",");
        })(),
        status: this.vocrangeDefault,
        accountingPeriod: this.accountingPeriod.toString(),
        accountingPeriodName: this.accountingPeriodName.toString(),
        yearNum: this.defaultYearNum,
        subjectCode: this.subjectCode,
        assitAccId: this.activeTab,
        assitAccName: (() => {
          const o = this.tabList.filter((itm) => itm.id === _this.activeTab);
          return o.length ? o[0].name : "";
        })(),
        dataId: this.assistValue.id || "",
        dataName: this.assistValue.name || ""
      };

      return params;
    },
    /**
     * @description: 导出
     */
    handleExport() {
      if (!this.accountMainDefault.length) {
        Message("请选择记账主体");
        return;
      }
      const pa = { typeId: "fzye", ...this.getCommParam() };
      // 传辅助核算+辅助核算子项
      pa.subjectName = pa.dataId ? `${pa.assitAccName}-${pa.dataName}` : pa.assitAccName;
      this.handleExportExcel(pa);
    },
    handleRowClick(data) {
      const { row } = data;
      if (!row.id || !row.isKemu) return;
      const pa = this.getCommParam();
      pa.subjectCode = row.id;
      pa.subjectName = row.name ? row.name.split(" ")[1] : "";
      pa.dataId = row.dataId;
      this.afferentItem = { ...pa };
      this.$refs.assitModel.visible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.assistBalanceList {
  position: relative;
  background-color: #1d2532;
  .en-title-card {
    overflow: hidden;
  }

  .choice-subject {
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  /deep/ .en-tabs-item {
    justify-content: center;
    font-weight: 600;
  }
  .btn-export,
  .btn-print {
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      color: #636c78;
    }
  }
  .btn-print {
    margin-right: 0;
  }
  .table-main {
    height: calc(100% - 100px);
  }
  /deep/ .en-tabs .en-tabs-list .en-tabs-item {
    width: 120px;
    text-align: center;
  }
}
.left-container {
  height: 100%;
  width: calc(100% - 72px);
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.expand {
    width: 100%;
  }
}
.model-select{
  display: inline-block;
  vertical-align: middle;
}
.date-interval {
  position: absolute;
  right: 4px;
  top: 0;
}
</style>
