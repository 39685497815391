<!--
 * @Author: zhouql
 * @Date: 2020-03-06 18:04:56
 * @LastEditTime: 2021-08-05 11:50:25
 * @LastEditors: pengyu
 * @Description: 账簿-明细账
 * @FilePath: \user\src\views\intelligentAccounting\accountBook\specification\index.vue
 -->
<template>
  <div class="specification">
    <div class="content" :class="isActive ? 'left-container expand' : 'left-container'">
      <en-title-card name="明细账">
        <div slot="right">
          <searchList
            @clickData="getVal"
            :searchDataList="searchDataList"
            :defaultSearchData="defaultSearchData"
            :isDisabled="disableSearch"
          ></searchList>
          <div class="btn-export" @click="handleExport">
            <en-icon name="bus-btn5_1" size="small" style="color:#26c393"></en-icon>
            <div class="label">导出</div>
          </div>
          <div class="btn-print" @click="printTable('print-template-specification')">
            <en-icon name="bus-btn6_1" size="small" style="color:#3e90fe"></en-icon>
            <div class="label">打印</div>
          </div>
        </div>
      </en-title-card>
      <div class="top-head">
        <subjectSel :subjectList="subjectList" @subjectVal="subjectValue" :defaultId="defaultSubId"></subjectSel>
      </div>
      <!--表格部分-->
      <div class="table-main" v-en-loading="enLoading">
        <en-table :data="list" height="100%" ref="dataTable" @row-click="handleRowClick">
          <en-table-column type="index" width="60" align="center"></en-table-column>
          <en-table-column title="日期" header-align="center" prop="produceDate" width="90"></en-table-column>
          <en-table-column title="凭证号" header-align="center" prop="voucherNo" width="80"></en-table-column>
          <en-table-column title="摘要" header-align="center" prop="remark" width="330"></en-table-column>
          <en-table-column title="科目" header-align="center" prop="subjectName" width="304">
            <template v-slot="{ row }">
              {{ `${row.subjectCode} ${row.subjectName}` }}
            </template>
          </en-table-column>
          <en-table-column title="借方金额" prop="bor" header-align="center" align="right">
            <template v-slot="{ row }">
              {{ row.bor | thousand(2) }}
            </template>
          </en-table-column>
          <en-table-column title="贷方金额" prop="loan" header-align="center" align="right">
            <template v-slot="{ row }">
              {{ row.loan | thousand(2) }}
            </template>
          </en-table-column>
          <en-table-column title="方向" prop="direction" align="center" width="50"></en-table-column>
          <en-table-column title="余额" prop="balance" header-align="center" align="right">
            <template v-slot="{ row }">
              {{ row.balance | thousand(2) }}
            </template>
          </en-table-column>
        </en-table>
      </div>
    </div>
    <!-- '会计期间' -->
    <dateInterval :type="1" :hideHead="false" ref="dateInterval" :list="dateLineList" @collapseBtn="isActive = !isActive" @handleAccChange="accChange" class="date-interval"></dateInterval>
    <!-- 明细帐打印模板 -->
    <print-template-specification :dataList="list" :search-data="printSearchData"></print-template-specification>
    <!-- 凭证详情 -->
    <vocDetailAlert
      ref="vocDialog"
      :voucherId="voucherId"
      :actionType="2"
      :formal="{}"
      openFlag="quotes"
    ></vocDetailAlert>
  </div>
</template>

<script>
import { request } from "en-js";
import { Message } from "element-ui";
import { mapGetters } from "vuex";
import { commonService } from "@/api/intelligentAccounting";
import accountBookComm from "@/mixins/intelligentAccounting/accountBookComm.js";
import searchList from "../../components/searchList";
import dateInterval from "../../components/dateInterval"; // 会计期间控件
import subjectSel from "../../components/subjectSel";
import PrintTemplateSpecification from "./components/PrintTemplateSpecification";
import vocDetailAlert from "../../components/vocDetailAlert";

export default {
  name: "Specification",
  mixins: [accountBookComm],
  components: {
    searchList,
    dateInterval,
    subjectSel,
    PrintTemplateSpecification,
    vocDetailAlert
  },
  computed: {
    ...mapGetters("intelligentAccounting", [
      "getSaMxSubjectlist"
    ]),
    printSearchData() {
      let unit = "";
      const findNode = (list, id) => {
        if (!id) return;
        list.forEach((item) => {
          if (item.id === id) {
            unit = item.code + item.text;
          } else if (item.nodes) {
            findNode(item.nodes, id);
          }
        });
      };
      const legerObj = this.subjectList?.find((item) => item.code === this.subjectCode);
      findNode(this.accountMainList, this.accountMainDefault[0]);
      const { startDate, endDate } = this;
      const period = startDate && endDate && startDate !== endDate ? `${startDate}-${endDate}` : endDate || startDate;
      return {
        unit,
        period,
        title: `${legerObj?.code + legerObj?.name}明细账`
      };
    },
    defaultSubId() {
      if (this.subjectCode) {
        const sub = this.subjectList?.filter((itm) => itm.code === this.subjectCode)[0];
        return sub ? sub.id : "";
      }
      return "";
    }
  },
  data() {
    return {
      enLoading: null,
      dateLineList: [], // 会计期间数据
      isActive: false, // 会计区间显示
      searchDataList: {}, // 搜索的下拉数据
      defaultSearchData: {}, // 搜索默认数据
      accountingPeriod: [], // 会计期间的id
      defaultYearNum: "", // 会计区间选中年份
      // 凭证范围下拉数据
      vocrangeList: [
        {
          id: 1,
          name: "全部凭证"
        },
        {
          id: 2,
          name: "仅已审核"
        }
      ],
      accountList: [], // 账套下拉数据
      accountMainList: [], // 记账主体下拉数据
      accountDefault: {}, // 默认账套
      // 默认范围
      vocrangeDefault: 1,
      accountMainDefault: [], // 默认记账主体
      subjectList: [], // 科目列表
      subjectCode: undefined, // 科目的code
      list: [],
      endDate: "",
      startDate: "",
      disableSearch: false,
      voucherId: ""
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * @description: 初始化
     */
    async init() {
      await this.queryAccList();
      await this.queryAccTime();
      // 获取记账主体数据列表
      await this.queryAccountEntity();
      await this.queryAllSubject();
      this.getRouterParam();
      this.queryDetail();
    },
    /**
     * @description 获取路由参数
     */
    getRouterParam() {
      const { query } = this.$route;
      if (query && query.flg === "1") {
        // 从 结账 穿透过来的  初始参数 需要取带过来的值
        this.vocrangeDefault = query.status || 1;
        this.accountDefault = query.accountId;
        this.accountMainDefault = [query.accountingEntity];
        this.accountingPeriod = query.accountingPeriod;
        this.defaultYearNum = query.yearNum;
        this.subjectCode = query.subjectCode;
        this.defaultSearchData = {
          accountDefault: this.accountDefault,
          vocrangeDefault: this.vocrangeDefault,
          accountMainDefault: this.accountMainDefault
        };
        this.disableSearch = true;
        // 科目 是结账页面存储的
        const sub = this.getSaMxSubjectlist || [];
        this.subjectList = typeof (sub) === "string" ? JSON.parse(sub) : sub;
        if (!query.subjectCode && this.subjectList.length) {
          this.subjectCode = this.subjectList[0].code;
        }
      }
    },
    // 请求日期区间数据接口
    // @request(true, "enLoading")
    async queryAccTime() {
      const _this = this;
      const res = await commonService.queryAccTime({
        accountId: _this.accountDefault,
        hasChildren: "000"
      });
      this.dateLineList = res;
      this.accPeriod();
    },
    /**
     * @deprecate
     */
    accPeriod() {
      // 默认会计区间获取会计区间选中ID
      const { flg, accountingPeriod } = this.$route.query;
      function findDefaultPeriod(arr) {
        const nodesCur = [];
        const nodesCurName = [];
        const yearNum = [];
        const dateNameList = [];
        const def = arr.filter((itm) => itm.isDefault === 0)[0];
        if (def) {
          nodesCur.push(def.id);
          nodesCurName.push(def.name);
          yearNum.push(def.yearNum);
          dateNameList.push(def.name);
          arr.forEach((itm) => {
            itm.isSelect = def.id === itm.id;
          });
        }
        return {
          nodesCur, nodesCurName, yearNum, dateNameList
        };
      }

      const _this = this;
      if (flg === "1" && accountingPeriod) {
        this.dateLineList.forEach((item) => {
          const defNode = item.nodes?.filter((nd) => nd.id === accountingPeriod)[0];
          if (defNode) {
            item.isDefault = 0;
            item.nodes.forEach((nd) => { nd.isDefault = nd.id === defNode.id ? 0 : 1; });
          } else {
            item.isDefault = 1;
            item.nodes.forEach((nd) => { nd.isDefault = 1; });
          }
          if (defNode) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            _this.accountingPeriod = findDefaultData.nodesCur;
            _this.accountingPeriodName = findDefaultData.nodesCurName;
            _this.defaultYearNum = findDefaultData.yearNum.toString();
            _this.startDate = findDefaultData.dateNameList[0];
            _this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          }
        });
        return;
      }

      this.dateLineList.forEach((item) => {
        if (item.isDefault === 0) {
          if (item.nodes) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            _this.accountingPeriod = findDefaultData.nodesCur;
            _this.accountingPeriodName = findDefaultData.nodesCurName;
            _this.defaultYearNum = findDefaultData.yearNum.toString();
            _this.startDate = findDefaultData.dateNameList[0];
            _this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          }
        }
      });
    },
    /**
     * @description: 取科目下拉选中数据
     */
    subjectValue(param) {
      this.subjectCode = param.code;
      this.queryDetail();
    },
    /**
     * @description: 取搜索组件带出数据
     */
    async getVal(data) {
      const originAccount = this.accountDefault;

      if (data.accountMain.length === 0) {
        Message("请选择记账主体");
        return;
      }

      this.accountDefault = data.account;
      this.accountMainDefault = data.accountMain;
      this.vocrangeDefault = data.vocrange;
      if (data.account !== originAccount) {
        // 账套发生改变 重新查询会计期间 跟 科目
        await this.queryAccTime();
        await this.queryAllSubject();
      }

      this.queryDetail();
    },
    /**
     * @description: 日期区间选中数据
     */
    async accChange(data) {
      this.accountingPeriod = data.ids;
      this.accountingPeriodName = data.names;
      this.defaultYearNum = data.yearNum;
      this.$nextTick(() => {
        this.startDate = this.$refs.dateInterval.startDate;
        this.endDate = this.$refs.dateInterval.endDate;
      });
      await this.queryAllSubject();
      if (this.accountMainDefault.length === 0) {
        Message("请选择记账主体");
        return;
      }
      this.queryDetail();
    },
    // 详情
    @request(true, "enLoading")
    async queryDetail() {
      const pa = this.getCommParam();
      const res = await commonService.queryAbDetail(pa);
      // 处理id  id值会被表格控件重新赋值
      res.dataList.forEach((itm, idx) => {
        itm.dataId = itm.id;
        itm.id = `${itm.id}-${idx}`;
      });
      this.list = res.dataList || [];
    },
    /**
     * @description: 日期区间
     */
    collapseBtn(isActive) {
      isActive = !isActive;
      this.$refs.show.isActive = isActive;
      if (isActive === true) {
        this.rightPx = "0px";
      } else {
        this.rightPx = "75px";
      }
    },
    /**
     * @description: 获取公共传参
     */
    getCommParam() {
      const _this = this;
      const params = {
        accountId: this.accountDefault,
        accountName: (() => {
          const o = _this.accountList.filter((itm) => itm.id === _this.accountDefault);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        accountingEntity: this.accountMainDefault.toString(),
        accountingEntityName: (() => {
          const re = [];
          _this.accountMainDefault.forEach((itm) => {
            _this.accountMainList.forEach((itm2) => {
              if (itm2.id === itm) {
                re.push(`${itm2.code} ${itm2.name}`);
              }
              itm2.nodes?.forEach((itm3) => {
                if (itm3.id === itm) {
                  re.push(`${itm3.code} ${itm3.name}`);
                }
              });
            });
          });
          return re.join(",");
        })(),
        status: this.vocrangeDefault,
        accountingPeriod: this.accountingPeriod.toString(),
        accountingPeriodName: this.accountingPeriodName.toString(),
        yearNum: this.defaultYearNum,
        subjectCode: this.subjectCode,
        subjectName: (() => {
          const o = this.subjectList?.filter((itm) => itm.code === this.subjectCode) || [];
          if (o.length) {
            return `${o[0].code} ${o[0].name}`;
          }
          return "";
        })()
      };

      return params;
    },
    /**
   * @description:导出
   */
    handleExport() {
      if (!this.accountMainDefault.length) {
        Message("请选择记账主体");
        return;
      }
      const pa = { typeId: "mx", ...this.getCommParam() };
      this.handleExportExcel(pa);
    },
    /**
   * @description:行点击 穿透
   */
    handleRowClick({ row }) {
      const { dataId } = row;
      if (dataId) {
        this.voucherId = dataId;
        this.$refs.vocDialog.visible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.specification {
  position: relative;
  background-color: #1d2532;
  .en-title-card {
    overflow: hidden;
  }
  .top-head {
    height: 45px;
    line-height: 45px;
    text-align: right;
    overflow: hidden;
    .choice-subject {
      float: right;
      margin-right: 20px;
    }
  }
  .btn-export,
  .btn-print {
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      color: #636c78;
    }
  }
  .btn-print {
    margin-right: 0;
  }
  .table-main {
    height: calc(100% - 100px);
  }
}
.left-container {
  height: 100%;
  width: calc(100% - 72px);
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.expand {
    width: 100%;
  }
}
.date-interval {
  position: absolute;
  right: 4px;
  top: 0;
}
</style>
