<!--
 * @Author: pengyu
 * @Date: 2020-11-19 15:39:14
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-16 16:06:15
 * @Description: 结转日志
-->
<template>
  <en-dialog
    :visible="visible"
    title="结转日志"
    class="log-alert"
    width="1100px"
    @close="visible = false"
  >
    <div class="table-wrap" v-en-loading="enLoading">
      <en-table
        :data="tableData"
        :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }"
        @filter-sort-change="handleFilterSortChange"
      >
        <en-table-column type="index" width="60"></en-table-column>
        <table-column
          v-for="(item,index) in columnData"
          :data="item"
          :key="index"
          :width="item.width"
        >
        </table-column>
      </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { request } from "en-js";
import { carryOverService } from "@/api";
import tableColumn from "@/components/en-table-extra/tableColumn";

export default {
  name: "CarryOverLog",
  props: {
    refId: {
      type: String,
      default: ""
    }
  },
  components: { tableColumn },
  data() {
    return {
      enLoading: null,
      visible: false,
      tableData: [],
      columnData: [
        {
          name: "操作类型", field: "btnName", fieldType: 1, isShow: 0
        },
        {
          name: "操作对象", field: "businessName", fieldType: 1
        },
        {
          name: "操作人", field: "operatorName", fieldType: 1
        },
        {
          name: "操作时间", field: "operateTime", fieldType: 7, timeFormat: 9
        },
        {
          name: "备注", field: "content", width: 240, fieldType: 1
        },
        {
          name: "操作结果", field: "btnKey", fieldType: 1
        }
      ],
      pageModel: {
        pageSize: 10,
        pageNo: 1,
        total: "",
        totalPages: ""
      }
    };
  },
  watch: {
    visible(newval) {
      if (newval) {
        this.tableData = [];
        this.queryLogList();
      }
    }
  },
  methods: {
    /**
     * @description: 查询列表数据
     * @param {type}
     */
    @request(true, "enLoading")
    async queryLogList() {
      const rsp = await carryOverService.queryOperateLog({ refId: this.refId, refType: 3, ...this.pageModel });
      this.pageModel.total = rsp.total;
      this.pageModel.totalPages = rsp.totalPages;
      this.tableData = rsp.records || [];
    },
    /**
     * @description: 分页器 事件
     */
    handlePageChanged({ pageNo, pageSize }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.queryLogList();
    },
    /**
     * @description: 表格筛选排序
     */
    handleFilterSortChange(params) {
      const { type } = params;
      if (type === "sort") { // 排序
        this.pageModel.sortField = params.sortData;
      } else if (type === "filter") {
        this.pageModel.filterFields = params.filterData;
      } else {
        this.pageModel.sortFields = "{}";
        this.pageModel.filterFields = "[]";
      }
      this.queryLogList();
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
