<!--
 * @Author: pengyu
 * @Date: 2020-03-16 14:43:06
 * @LastEditTime: 2021-07-14 18:05:15
 * @LastEditors: pengyu
 * @Description: 凭证处理--筛选弹窗
 * @FilePath: \user-intelligent-accounting\src\components\screenAlert\index.vue
 -->
<template>
  <en-dialog
    :visible="visible"
    class="screen-alert"
    :close-on-click-modal="false"
    width="900px"
    @close="visible=false"
  >
    <div slot="title">
      <div class="title">
        筛选
      </div>
      <en-button
        type="text"
        icon="bus-btn7_1"
        icon-color="#4ECBA1"
        :icon-size="16"
        @click.native="reset"
      >
        重置
      </en-button>
    </div>
    <div slot="footer">
      <en-button @click="submit">
        确定
      </en-button>
    </div>
    <el-form ref="screenForm" :model="form" label-width="80px">
      <el-form-item label="科目" prop="subjectId">
        <en-select
          v-model="form.subjectId"
          :filterable="true"
          placeholder="请选择"
          :data="subjectList"
          :props="{value:'id',label:'text'}"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="辅助核算" prop="baseDataType">
        <en-select
          v-model="form.baseDataType"
          placeholder="请选择"
          :data="assitList"
          :props="{value:'id',label:'name'}"
          @change="handleAssitChange"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <en-select
          v-model="baseDataValue"
          mode="tree"
          check-mod="siblings"
          placeholder="请选择"
          :data="baseDataList"
          multiple
          :lazy="true"
          :props="fzhsProps"
          :load="loadTree"
          data-mode="data"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="摘要" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="凭证类型" prop="voucherWay">
        <en-select
          v-model="form.voucherWay"
          placeholder="请选择"
          :data="voucherList"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="业务模板" prop="busTemplateId">
        <en-select
          v-model="form.busTemplateId"
          check-mode="siblings"
          mode="tree"
          data-mode="data"
          :filterable="true"
          placeholder="请选择"
          :data="busTempList"
          :props="busProps"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="业务编号" prop="refCode">
        <el-input v-model="form.refCode" placeholder="请输入" maxlength="100"></el-input>
      </el-form-item>

      <el-form-item v-show="form.voucherWay==='1'" label="">
        <en-select
          v-model="voucherModel"
          placeholder="请选择"
          :data="voucherModelList"
          data-mode="data"
          :props="{value:'id',label:'name',children:'nodes'}"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="凭证字号" prop="voucherNo">
        <rangeInput
          :inputType="1"
          :value="form.voucherNo"
          :first-holder="`请输入`"
          :second-holder="`请输入`"
          @rangeInputChange="(val)=>changeInputRange(val,'voucherNo')"
        ></rangeInput>
      </el-form-item>
      <el-form-item label="金额" prop="amount">
        <rangeInput
          :inputType="3"
          :value="form.amount"
          @rangeInputChange="(val)=>changeInputRange(val,'amount')"
        ></rangeInput>
      </el-form-item>
      <el-form-item label="凭证状态" prop="status">
        <en-select
          v-model="form.status"
          placeholder="请选择"
          :multiple="true"
          :data="statusList"
          :props="{value: 'id', label: 'name'}"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="凭证类别" prop="voucherDataType">
        <en-select
          v-model="form.voucherDataType"
          placeholder="请选择"
          :multiple="true"
          :props="{value: 'id', label: 'name'}"
          :data="voucherDataTypeList"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="制单人" prop="makeId">
        <en-select
          v-model="form.makeId"
          placeholder="请选择"
          mode="tree"
          :data="userData"
          :props="defaultProps"
          :multiple="true"
          :lazy="true"
          :load="loadTree"
          data-mode="data"
          :loading="loading"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="是否传输" prop="interfaceStatus">
        <en-select
          v-model="form.interfaceStatus"
          placeholder="请选择"
          :data="interfaceStatusList"
        >
        </en-select>
      </el-form-item>
    </el-form>
  </en-dialog>
</template>

<script>
import { intelligentService } from "@/api";
import rangeInput from "../rangeInput";

export default {
  name: "ScreenAlert",
  components: { rangeInput },
  props: {
    accountId: { // 账套id
      type: String,
      default: ""
    },
    dateList: { // 会计期间数据
      type: Array,
      default: () => []
    },
    initData: { // 回填数据
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      params: {}, // 查询参数
      voucherModel: "", // 系统凭证模板id
      baseDataValue: [], // 辅助核算具体基础数据id
      form: {
        subjectId: "",
        baseDataType: "",
        remark: "",
        voucherWay: "",
        voucherNo: "",
        amount: "",
        status: [],
        makeId: [],
        voucherDataType: [],
        interfaceStatus: "",
        busTemplateId: "",
        refCode: ""
      },
      subjectList: [], // 科目
      assitList: [], // 辅助核算
      baseDataList: [], // 基础数据
      voucherList: [{ value: "1", label: "系统凭证" }, { value: "2", label: "手工凭证" }],
      voucherModelList: [], // 系统凭证模板
      statusList: [
        { id: "000", name: "待审核" },
        { id: "001", name: "已审核" },
        // { value: "002", label: "生成异常" },
        { id: "004", name: "未提交" }
      ],
      voucherDataTypeList: [], // 凭证类别下拉项
      interfaceStatusList: [{ value: "0", label: "是" }, { value: "1", label: "否" }],
      userData: [],
      defaultProps: {
        value: "id",
        label: "name",
        disabled: (data) => data.type !== "003",
        isLeaf: (data) => data.hasChildren === "001"
      },
      busProps: {
        value: "id",
        label: "text",
        children: "nodes",
        isLeaf: (data) => data.selectable
      },
      fzhsProps: {
        value: "id",
        label: "name"
      },
      loading: false,
      busTempList: [] // 业务建模模板
    };
  },
  watch: {
    visible(nVal) {
      if (nVal) {
        Object.assign(this.$data, this.$options.data(), { visible: this.visible });
        this.queryOptions();
      }
    },
    "form.voucherWay": {
      handler(nVal) {
        if (nVal === "1") {
          this.queryVoucherModel();
        }
      }
    }
  },
  methods: {
    /**
     * @description: 回填数据
     */
    initReturnData() {
      const _this = this;
      if (this.initData.length) {
        this.initData.forEach((v) => {
          const { nameVariable } = v;
          if (_this.form[nameVariable] !== undefined || nameVariable === "baseDataTableType") {
            if (nameVariable === "baseDataTableType") {
              _this.form.baseDataType = v.baseDataType;
              _this.baseDataValue = _this.getSplitData(v);
              _this.queryBaseDataList(v.baseDataType);
            } else if (nameVariable === "makeId") {
              this.form[nameVariable] = _this.getSplitData(v);
            } else if (["voucherDataType", "status"].includes(nameVariable)) {
              this.form[nameVariable] = v.value.split(",");
            } else {
              _this.form[nameVariable] = v.value;
            }
          }
        });
      }
    },
    /**
     * @description: 拼接数据 {value: "1,2", valueText: "a,b"} ==> [{id:1,name:a},{id:2,name:b}]
     */
    getSplitData(v) {
      if (!v || (v && !v.value)) return "";
      const ids = v.value.split(",");
      const names = v.valueText.split(",");
      const arr = [];
      ids.forEach((a, b) => {
        names.forEach((a2, b2) => {
          if (b === b2) {
            arr.push({
              id: a,
              name: b
            });
          }
        });
      });
      return arr;
    },
    /**
     * @description: 查询下拉选项
     */
    async queryOptions() {
      const _this = this;
      const pa = { accountId: this.accountId };
      pa.yearNum = (() => {
        const yn = [];
        _this.dateList.forEach((itm) => {
          (itm.nodes || []).forEach((citm) => {
            if (citm.isDefault === 0 || citm.isSelect) {
              yn.push(citm.yearNum);
            }
          });
        });
        return yn.join(",");
      })();
      this.params = pa;
      // 查询账套下所有科目
      const rsp = await intelligentService.queryAllSubject(pa);
      rsp.forEach((itm) => {
        itm.text = `${itm.code} ${itm.name}`;
      });
      this.subjectList = rsp;
      pa.screen = 1;
      // 查询账套下所有辅助核算
      this.assitList = await intelligentService.queryAllAssit(pa) || [];
      // 查询制单人下拉数据
      const userRsp = await intelligentService.getDeptUserTree({ id: 0 });
      userRsp.forEach((item) => {
        item.isLeaf = item.hasChildren === "001";
      });
      this.userData = userRsp;
      // 查询凭证类别
      this.voucherDataTypeList = await intelligentService.queryEntity({ objectType: "026" });
      // 查询业务建模模板
      const busRsp = await intelligentService.queryCommClassifyTemplate({ busType: 1, type: 3 });
      busRsp.forEach((item) => {
        item.hasChildren = item.nodes ? "000" : "001";
        item.nodes.forEach((citm) => {
          citm.isLeaf = true;
        });
      });
      this.busTempList = busRsp;
      this.initReturnData();
    },
    /**
     * @description: 重置表单
     */
    reset() {
      this.$refs.screenForm && this.$refs.screenForm.resetFields();
      this.baseDataValue = [];
      this.baseDataList = [];
      this.form.amount = "";
      this.form.voucherNo = "";
    },
    /**
     * @description: 凭证号 绑定 金额 绑定
     * @param rng 返回值
     */
    changeInputRange(rng, key) {
      if (rng && rng.length) {
        this.$set(this.form, key, rng.join(","));
      }
    },
    /**
     * @description: 切换辅助核算 查询基础数据下拉项
     * @param val
     */
    async handleAssitChange(val) {
      this.form.value = "";
      this.baseDataValue = [];
      this.queryBaseDataList(val);
    },
    /**
     * @description: 查询辅助核算对应的 基础数据
     * @param val
     */
    async queryBaseDataList(val) {
      const dd = this.assitList.filter((v) => v.id === val);
      const { type } = dd[0];
      if (type === "002") {
        this.$set(this.fzhsProps, "isLeaf", (data) => data.hasChildren === "001");
        this.$set(this.fzhsProps, "disabled", (data) => data.type !== "003");
        this.baseDataList = this.userData;
      } else {
        this.$set(this.fzhsProps, "isLeaf", (data) => !data.hasChildren);
        this.$set(this.fzhsProps, "disabled", null);
        this.baseDataList = await intelligentService.queryObjectTreeExtAccess({ objectTypeId: val, objectDataType: type }) || [];
      }
    },
    /**
     * @description: 查询系统凭证模板
     */
    async queryVoucherModel() {
      if (!this.voucherModelList.length) {
        this.voucherModelList = await intelligentService.queryBusTemplateByAccount(this.params) || [];
      }
    },
    /**
     * @description: 确定
     */
    submit() {
      const list = [];
      Object.keys(this.form).forEach((key) => {
        if ((["makeId", "status", "voucherDataType"].includes(key) && this.form[key].length) || (!["makeId", "status", "voucherDataType"].includes(key) && this.form[key])) {
          list.push(this.getFormatData(key));
        }
      });
      this.$emit("submitScreen", list);
      this.visible = false;
    },
    /**
     * @description: 获取字段参数
     * @param key
     */
    getFormatData(key) {
      const _this = this;
      const obj = {
        nameVariable: key === "baseDataType" ? "baseDataTableType" : key,
        fieldType: this.getTypeByKey(key),
        value: this.form[key],
        valueText: this.form[key],
        baseDataType: "",
        baseDataTableType: ""
      };
      if (key === "baseDataType") {
        obj.baseDataType = this.form[key];
        obj.baseDataTableType = (() => {
          const bd = _this.assitList.filter((v) => v.id === _this.form[key]);
          return bd[0]?.type || "";
        })();
        obj.value = (() => {
          const ids = [];
          _this.baseDataValue.forEach((itm) => {
            ids.push(itm.id);
          });
          return ids.join(",");
        })();
        obj.valueText = (() => {
          const names = [];
          _this.baseDataValue.forEach((itm) => {
            names.push(itm.name);
          });
          return names.join(",");
        })();
      } else if (key === "voucherWay") {
        obj.valueText = (() => {
          const dd = _this.voucherList.filter((v) => v.value === this.form[key]);
          return dd[0]?.label || "";
        })();
        if (this.form.voucherWay === 1) { // 选择系统凭证
          obj.value = this.voucherModel;
          obj.valueText = "";
        }
      } else if (key === "makeId") {
        obj.value = (() => {
          const ids = [];
          obj.value.forEach(((itm) => {
            ids.push(itm.id);
          }));
          return ids.join(",");
        })();
        obj.valueText = (() => {
          const names = [];
          obj.valueText.forEach(((itm) => {
            names.push(itm.name);
          }));
          return names.join(",");
        })();
        obj.baseDataTableType = "002";
      } else if (key === "subjectId") {
        obj.valueText = (() => {
          const dd = _this.subjectList.filter((v) => v.id === this.form[key])[0];
          if (dd) {
            return `${dd.code} ${dd.name}`;
          }
          return "";
        })();
      } else if (key === "status") {
        obj.valueText = (() => {
          const txts = [];
          obj.value.forEach((itm) => {
            const dd = _this.statusList.filter((v) => v.id === itm)[0];
            txts.push(dd.name);
          });
          return txts.join(",");
        })();
        obj.value = obj.value.toString();
      } else if (key === "voucherDataType") {
        obj.valueText = (() => {
          const txts = [];
          obj.value.forEach((itm) => {
            const dd = _this.voucherDataTypeList.filter((v) => v.id === itm)[0];
            txts.push(dd.name);
          });
          return txts.join(",");
        })();
        obj.value = obj.value.toString();
      } else if (key === "interfaceStatus") {
        obj.valueText = obj.value === "0" ? "是" : "否";
      } else if (key === "busTemplateId") {
        obj.value = this.form[key].id;
        obj.valueText = this.form[key].text;
      }

      return obj;
    },
    /**
     * @description: 获取字段类型
     * @param key
     */
    getTypeByKey(key) {
      switch (key) {
        case "remark":
          return "1";
        case "voucherNo":
          return "3";
        case "amount":
          return "4";
        case "subjectId":
        case "baseDataType":
        case "voucherWay":
        case "status":
        case "makeId":
        case "voucherDataType":
          return "5";
        default:
          return "";
      }
    },
    /**
     * @description: 异步加载人员数节点
     * @param node
     * @param resolve
     */
    async loadTree(node, resolve) {
      this.loading = true;
      const { id = 0 } = node.data;
      const rsp = await intelligentService.getDeptUserTree({ id });
      resolve(rsp);
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.screen-alert{
  .el-dialog__header{
    padding: 20px;
    >div{
      display: flex;
      justify-content: space-between;
      line-height: 32px;
      .en-button{
        margin-right: 40px;
      }
    }
  }
  .el-dialog__body{
    clear: both;
    .en-select{
      width: 100%;
    }
  }
  & /deep/ .el-form-item{
    margin-bottom: 16px;
  }
  & /deep/ .el-input__inner:hover{
    border-color: $field-border-color-hover;
  }
  & /deep/ .el-input__inner:focus{
    border-color: $field-border-color-focus;
  }
}
</style>
