/*
 * @Author: pengyu
 * @Date: 2020-03-17 14:39:16
 * @LastEditTime: 2021-07-28 17:37:17
 * @LastEditors: pengyu
 * @Description: 凭证处理-查询账套记账主体等公共部分
 * @FilePath: \user-intelligent-accounting\src\mixins\queryVoucherComm.js
 */
// import { Message } from "element-ui";
import { request } from "en-js";
import { Message } from "element-ui";
import { intelligentService } from "@/api";

export default {
  methods: {
    /**
     * @description: 查询账套列表
     * @param params 查询参数
     * @return:
     */
    async queryAccountList(params = {}) {
      const rsp1 = await intelligentService.queryAccList(params);
      const rsp2 = await intelligentService.queryEntity({ objectType: "020", ...params }); // 查询记账主体
      this.accList = rsp1;
      this.entityList = rsp2;
      // 查找账套和记账主体默认值
      this.entityList.forEach((v) => {
        v.text = `${v.code || v} ${v.name || ""}`;
        v.nodes?.forEach((vn) => {
          vn.text = `${vn.code || v} ${vn.name || ""}`;
        });
      });
      const def1 = this.accList.filter((v) => v.isDefault === 0);
      const def2 = this.entityList;
      this.params.accountId = def1[0]?.id;
      this.params.accountingEntity = def2[0]?.id || "";
    },
    /**
     * @description:  查询凭证数据
     */
    @request(true, "enLoading")
    async queryVoucherList() {
      this.checkedAll = false;
      this.voucherIdAry = [];
      const pa = { ...this.params };
      const obj = {
        subjectId: "科目",
        baseDataTableType: "辅助核算",
        remark: "摘要",
        voucherWay: "凭证类型",
        voucherNo: "凭证号码",
        amount: "金额",
        status: "凭证状态",
        makeId: "制单人",
        interfaceStatus: "是否传输",
        voucherDataType: "凭证类别"
      };
      pa.conditions.forEach((itm) => {
        itm.column = {
          field: itm.field,
          prop: itm.field,
          property: itm.field,
          title: obj[itm.field],
          fieldType: itm.fieldType
        };
      });
      pa.conditions = typeof pa.conditions === "object" ? JSON.stringify(pa.conditions) : pa.conditions;
      const _this = this;

      const rsp = await intelligentService.queryVoucherList(pa); // 查询凭证数据
      (rsp.records || []).forEach((a) => {
        a.checked = false;
        if (_this.voucherIdAry) {
          _this.voucherIdAry.push(a.id);
        }
      });
      this.voucherData = rsp.records || []; // 凭证数据
      this.commParams = rsp.commParams || {}; // 管理端配置项
      this.pageModel.total = rsp.total;
      this.pageModel.totalPages = rsp.totalPages;
      this.batchBtn = false;
    },
    /**
     * @description:  查询会计期间 (该方法只用于凭证处理，凭证审核)
     * @param params  查询参数
     */
    async queryDateLine(params) {
      const rsp = await intelligentService.queryAccTime(params);
      // 查找默认值
      const def = [];
      rsp.forEach((itm) => {
        (itm.nodes || []).forEach((citm) => {
          citm.mId = itm.mId;
          if (citm.isDefault === 0) {
            def.push(citm);
          }
        });
      });

      this.dateLineList = rsp;
      this.params.accountingPeriod = def[0]?.id || "";
      this.dateLineParam.accountingPeriodMainId = def[0]?.mId || "";
      this.dateLineParam.accountingPeriodName = def[0]?.name || "";
      this.dateLineParam.yearNum = def[0]?.yearNum || "";
    },
    /**
     * @description:  改变批量按钮 显示状态
     * @param obj
     * @return:
     */
    changeBatchBtn(obj) {
      const { id, value } = obj;
      if (id === "all") {
        // 全部
        this.voucherData.forEach((a) => {
          a.checked = value;
        });
      } else {
        this.voucherData.forEach((a) => {
          if (a.id === id) {
            a.checked = value;
          }
        });
      }
      const ary = this.voucherData.filter((item) => item.checked === true);
      this.batchBtn = !!ary.length;
      this.checkedAll = ary.length === this.voucherData.length;
    },
    /**
     * @description:  更新请求参数
     * @param obj  账套和记账主体
     */
    async handleChangeParmas(obj) {
      const isAccChange = this.params.accountId !== obj.accountId;
      this.params = Object.assign(this.params, obj);
      this.saveAccHabit();
      if (isAccChange) {
        // 改变了账套 需要重新查询会计期间
        this.params = Object.assign(this.params, obj);
        await this.queryDateLine({ accountId: obj.accountId, hasChildren: "000" });
        await this.queryVoucherList();
      } else {
        await this.queryVoucherList();
      }
    },
    /**
     * @description:  分页控件事件
     * @param pageNo
     * @param pageSize
     */
    handlePageChanged({ pageNo, pageSize }) {
      this.params.pageNo = pageNo;
      this.params.pageSize = pageSize;
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.queryVoucherList();
    },
    /**
     * @description:  会计期间改变
     * @param ids
     */
    accChange({
      ids, mids, list, names, yearNum
    }) {
      this.params.accountingPeriod = ids;
      this.dateLineParam.yearNum = yearNum;
      this.dateLineParam.accountingPeriod = ids;
      this.dateLineParam.accountingPeriodMainId = mids;
      this.dateLineParam.accountingPeriodName = names;
      this.dateLineList = list;
      this.queryVoucherList();
    },
    /**
     * @description:  保存账套或记账主体的选择习惯
     */
    async saveAccHabit() {
      const { accountId, accountingEntity } = this.params;
      await intelligentService.saveAccHabit({ accountId, accountingEntity });
    },
    /**
     * @description:  提交凭证
     * @param rowData
     */
    async submitVoucher(rowData) {
      const ids = [];
      let sts = false;
      rowData.forEach((itm) => {
        if (["000", "001", "002"].includes(itm.status)) {
          sts = true;
        }
        ids.push(itm.id);
      });

      if (sts) {
        Message("勾选的凭证状态须为“未提交”");
        return;
      }
      const pa = { voucherIds: ids.join(",") };
      const rsp = await intelligentService.submitVoucher(pa);
      Message(`成功提交${rsp.successNum || 0}条，失败${rsp.failNum}条`);
      if (rsp.successNum > 0) {
        this.queryVoucherList();
      }
    }
  }
};
