var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "batch-check-dialog",
      attrs: {
        title: "结账区间",
        visible: _vm.dialogVisible,
        width: "50%",
        "append-to-body": "",
      },
      on: { close: _vm.close },
    },
    [
      _c("el-input", {
        staticClass: "start-time",
        attrs: { readonly: "", disabled: "" },
        model: {
          value: _vm.startDate.name,
          callback: function ($$v) {
            _vm.$set(_vm.startDate, "name", $$v)
          },
          expression: "startDate.name",
        },
      }),
      _vm._v(" - "),
      _c("en-select", {
        staticClass: "end-time",
        attrs: {
          data: _vm.intervalList,
          props: _vm.defaultProps,
          placeholder: "请选择",
        },
        model: {
          value: _vm.endDate,
          callback: function ($$v) {
            _vm.endDate = $$v
          },
          expression: "endDate",
        },
      }),
      _c(
        "div",
        {
          staticStyle: { height: "32px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.complete.apply(null, arguments)
                },
              },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }