<!--
 * @Author: pengyu
 * @Date: 2020-11-17 16:29:19
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-12 11:00:58
 * @Description: 现金流量明细
-->
<template>
 <div class="cash-detail">
   <div class="content" :class="isActive ? 'left-container expand' : 'left-container'">
      <en-title-card name="现金流量明细">
        <div slot="right">
          <searchList @clickData="getVal" :searchDataList="searchDataList" :defaultSearchData="defaultSearchData"></searchList>
          <div class="btn-export" @click="handleExport">
            <en-icon name="bus-btn5_1" size="small" style="color:#26c393"></en-icon>
            <div class="label">导出</div>
          </div>
          <div class="btn-print" @click="printTable('print-template-cash')">
            <en-icon name="bus-btn6_1" size="small" style="color:#3e90fe"></en-icon>
            <div class="label">打印</div>
          </div>
        </div>
      </en-title-card>
      <div class="top-head">
        <subjectSel :subjectList="subjectList" @subjectVal="subjectValue"></subjectSel>
      </div>
      <div class="balance-table" v-en-loading="enLoading">
        <!--表格部分-->
        <en-table
          ref="dataTable"
          :data="list"
          height="100%"
        >
          <en-table-column type="index" width="60" align="center"></en-table-column>
          <en-table-column prop="voucherNo" label="凭证号" header-align="center"></en-table-column>
          <en-table-column prop="remark" label="摘要" header-align="center"></en-table-column>
          <en-table-column prop="subject" label="科目" header-align="center">
            <template v-slot="{row}">
              {{ row | getFullSubjectName }}
            </template>
          </en-table-column>
          <en-table-column prop="bor" label="借方金额" header-align="center" align="right">
            <template v-slot="{row}">
              {{ row.bor | thousand(2)}}
            </template>
          </en-table-column>
          <en-table-column prop="loan" label="贷方金额" header-align="center" align="right">
            <template v-slot="{row}">
              {{ row.loan | thousand(2)}}
            </template>
          </en-table-column>
          <template #hoverRow="{row}">
            <en-icon
              v-if="row.isEdit!==1"
              class="edit-btn"
              name="bianji-liebiao"
              size="16px"
              color="#49bef2"
              title="修改"
              @click.native.stop="editCashFlow(row)"
            ></en-icon>
          </template>
        </en-table>
      </div>
    </div>

    <!-- '会计期间' -->
    <dateInterval :type="1" :hideHead="false" :list="dateLineList" @collapseBtn="isActive = !isActive" @handleAccChange="accChange" class="date-interval"></dateInterval>
    <!-- 修改现金流量明细 -->
    <en-dialog
      :visible="visible"
      width="500px"
      title="修改流量项目"
      @close="visible=false"
      class="edit-cashflow-alert"
    >
      <div class="col-wrap">
        <div class="col-mod">
          <div class="col-tit">流量项目</div>
          <div class="col-cent">
            <en-select
              v-model="newCashObjId"
              :data="cashObjList"
              :props="{value:'id',label: labelFormat}"
              data-mode="id-name"
              placeholder="aaaaaaaaaaa"
            ></en-select>
          </div>
        </div>
      </div>
      <div slot="footer">
        <en-button @click="handleEditCashFlow">
          确定
        </en-button>
      </div>
    </en-dialog>
        <!-- 打印模板数量金额 -->
    <print-template-cash :dataList="list" :afferentData="afferentItem" :search-data="printSearchData"></print-template-cash>
 </div>
</template>

<script>
import { request } from "en-js";
import { Message } from "element-ui";
import { commonService, voucherDetail, cashFlowService } from "@/api/intelligentAccounting";
import accountBookComm from "@/mixins/intelligentAccounting/accountBookComm.js";
import searchList from "../../components/searchList"; // 顶部搜索
import dateInterval from "../../components/dateInterval";
import subjectSel from "../../components/subjectSel";
import PrintTemplateCash from "./components/PrintTemplateCash";

// 会计期间控件
export default {
  name: "CashFlowDetail",
  mixins: [accountBookComm],
  components: {
    searchList, dateInterval, subjectSel, PrintTemplateCash
  },
  computed: {
    cashObjList() { // 现金流量项目
      const arr = [];
      const _this = this;
      this.subjectList.forEach((itm) => {
        if (itm.id !== _this.cashObjId) {
          arr.push(itm);
        }
      });
      return arr;
    },
    printSearchData() {
      let unit = "";
      const findNode = (list, id) => {
        if (!id) return;
        list.forEach((item) => {
          if (item.id === id) {
            unit = item.code + item.text;
          } else if (item.nodes) {
            findNode(item.nodes, id);
          }
        });
      };
      const { startDate, endDate } = this;
      const period = startDate && endDate && startDate !== endDate ? `${startDate}-${endDate}` : endDate || startDate;
      findNode(this.accountMainList, this.accountMainDefault[0]);
      return {
        unit,
        period
      };
    }
  },
  data() {
    return {
      enLoading: null,
      visible: false,
      endDate: "",
      startDate: "",
      dateLineList: [], // 会计期间数据
      // 凭证范围下拉数据
      vocrangeList: [
        {
          id: 1,
          name: "全部凭证"
        },
        {
          id: 2,
          name: "仅已审核"
        }
      ],
      accountList: [], // 账套下拉数据
      accountMainList: [], // 记账主体下拉数据
      accountDefault: {}, // 默认账套
      // 默认范围
      vocrangeDefault: 1,
      accountMainDefault: [], // 默认记账主体
      searchDataList: {}, // 搜索的下拉数据
      defaultSearchData: {}, // 搜索默认数据
      subjectList: [], // 科目列表
      isActive: false, // 会计区间显示
      defaultYearNum: "", // 会计区间选中年份
      subjectCode: "", // 科目的code
      list: [], // 表格数据
      afferentItem: {},
      cashObjId: "", // 编辑现金流量项目（被编辑现金流量项目id）
      newCashObjId: {}
    };
  },
  watch: {
    isActive() {
      this.$refs.dataTable.resetResizable();
    }
  },
  filters: {
    getFullSubjectName(row) {
      return `${row.subjectCode || ""} ${row.subjectName || ""}
              ${row.assitAccName || ""}`;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.queryAccList(); // 请求账套和日期区间
      await this.queryAccountEntity(); // 请求记账主体
      await this.queryAccTime(); // 会计期间
      await this.queryCashObject(); // 查询现金流量项目
      this.queryData();
    },
    /**
 * @description 查询会计期间数据
 */
    // @request(true, "enLoading")
    async queryAccTime() {
      const _this = this;
      const res = await commonService.queryAccTime({
        accountId: _this.accountDefault,
        hasChildren: "000"
      });
      this.dateLineList = res;
      this.accPeriod();
    },
    /**
   * @description 查询会计期间默认值
   */
    accPeriod() {
    // 默认会计区间获取会计区间选中ID
      const _this = this;
      function findDefaultPeriod(arr) {
        const nodesCur = [];
        const nodesCurName = [];
        const yearNum = [];
        const dateNameList = [];
        arr.forEach((v) => {
          if (v.isDefault === 0) {
            nodesCur.push(v.id);
            nodesCurName.push(v.name);
            yearNum.push(v.yearNum);
            dateNameList.push(v.name);
          }
        });
        return {
          nodesCur, nodesCurName, yearNum, dateNameList
        };
      }

      this.dateLineList.forEach((item) => {
        if (item.isDefault === 0) {
          if (item.nodes) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            _this.accountingPeriod = findDefaultData.nodesCur;
            _this.accountingPeriodName = findDefaultData.nodesCurName;
            _this.defaultYearNum = findDefaultData.yearNum.toString();
            _this.startDate = findDefaultData.dateNameList[0];
            _this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          }
        }
      });
    },
    /**
   * @description: 会计期间选中回调
   */
    accChange(data) {
      this.accountingPeriod = data.ids;
      this.accountingPeriodName = data.names;
      this.defaultYearNum = data.yearNum;
      let dateNames = [];
      dateNames = data.names.split(",");
      this.startDate = dateNames[0];
      this.endDate = dateNames[dateNames.length - 1];
      this.queryData();
    },
    /**
   * @description: 取科目下拉选中数据
   */
    subjectValue(param) {
      this.subjectCode = param.id;
      this.queryData();
    },
    /**
     * @description: 取搜索组件带出数据
     */
    async getVal(data) {
      const originAccount = this.accountDefault;
      if (data.accountMain.length === 0) {
        Message("请选择记账主体");
        return;
      }
      this.accountDefault = data.account;
      this.accountMainDefault = data.accountMain;
      this.vocrangeDefault = data.vocrange;
      if (data.account !== originAccount) {
      // 账套发生变化 重查会计期间
        await this.queryAccTime();
        this.queryData();
      } else {
        this.queryData();
      }
    },
    /**
   * @description: 查询现金流量科目
   */
    async queryCashObject() {
      const rsp = await voucherDetail.queryObjectTree({
        objType: "017",
        pageNo: 1,
        pageSize: 9999
      });

      // 先按照code排顺序
      rsp.sort((a, b) => {
        const c1 = Number(a.code);
        const c2 = Number(b.code);
        return c1 - c2;
      });

      this.subjectList = rsp;
      this.subjectCode = rsp[0]?.id || "";
    },
  /**
   * @description: 查询现金流量明细表数据
   */
  @request(true, "enLoading")
    async queryData() {
      const pa = this.getCommParam();
      delete pa.accountName;
      delete pa.accountingEntityName;
      delete pa.accountingPeriodName;
      delete pa.cashFlowItemName;
      if (!pa.accountingEntity) {
        Message("请选择记账主题");
        return;
      }

      const rsp = await cashFlowService.queryCashFlowDetail(pa);
      this.list = rsp;
    },
    /**
   * @description: 获取公共传参
   */
    getCommParam() {
      const _this = this;
      const param = {
        accountId: this.accountDefault,
        accountName: (() => {
          const o = this.accountList.filter((itm) => itm.id === _this.accountDefault);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        accountingEntity: this.accountMainDefault.toString(),
        accountingEntityName: (() => {
          const re = [];
          _this.accountMainDefault.forEach((itm) => {
            _this.accountMainList.forEach((itm2) => {
              if (itm2.id === itm) {
                re.push(`${itm2.code} ${itm2.name}`);
              }
              itm2.nodes?.forEach((itm3) => {
                if (itm3.id === itm) {
                  re.push(`${itm3.code} ${itm3.name}`);
                }
              });
            });
          });
          return re.join(",");
        })(),
        accountingPeriod: this.accountingPeriod.toString(),
        accountingPeriodName: this.accountingPeriodName.toString(),
        status: this.vocrangeDefault,
        cashFlowItemCode: this.subjectCode,
        cashFlowItemName: (() => {
          const o = _this.subjectList.filter((itm) => itm.id === _this.subjectCode);
          if (o.length) {
            return `${o[0].code} ${o[0].name}`;
          }
          return "";
        })()
      };

      return param;
    },
    /**
   * @description: 导出
   */
    async handleExport() {
      const pa = this.getCommParam();
      if (!pa.accountingEntity) {
        Message("请选择记账主体");
      }
      const param = { typeId: "xj", ...pa };
      this.handleExportExcel(param);
    },
    /**
   * @description: 修改现金流量
   */
    editCashFlow(data) {
      this.cashObjId = data.id;
      this.newCashObjId = {};
      this.visible = true;
    },
    /**
   * @description: 修改现金流量下拉 自定义label
   */
    labelFormat(data) {
      if (data) {
        return `${data.code || ""} ${data.name || ""}`;
      }
      return "";
    },
    /**
   * @description: 修改现金流量项目  提交
   */
    async handleEditCashFlow() {
      if (Object.keys(this.newCashObjId).length === 0) {
        Message("请选择现金流量项目");
        return;
      }
      const param = {
        id: this.cashObjId,
        cashFlowItemCode: this.newCashObjId.id,
        cashFlowItem: this.newCashObjId.name
      };
      await cashFlowService.modifyCashFlowDetail(param);
      this.visible = false;
      this.queryData();
    }
  }
};
</script>

<style lang='scss' scoped>
.cash-detail {
  position: relative;
  background-color: #1d2532;
  .en-title-card {
    overflow: hidden;
  }
  .top-head {
    height: 45px;
    line-height: 45px;
    text-align: right;
    overflow: hidden;
    .choice-subject {
      float: right;
      margin-right: 20px;
    }
  }
  .btn-export,
  .btn-print {
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      color: #636c78;
    }
  }
  .btn-print {
    margin-right: 0;
  }
  .table-main {
    height: calc(100% - 100px);
  }
  .edit-btn{
    margin-top: 15px;
    margin-right: 10px;
  }
}
.left-container {
  height: 100%;
  width: calc(100% - 72px);
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.expand {
    width: 100%;
  }
}
.date-interval {
  position: absolute;
  right: 4px;
  top: 0;
}
.edit-cashflow-alert{
  .col-mod{
    display: flex;
    align-items: center;
    .col-cent{
      flex: 1;
      margin-left: 10px;
      .en-select{
        width: 100%;
      }
    }
  }
}
</style>
