<!--
 * @Author: Zhouql
 * @Date: 2020-03-05 10:44:52
 * @LastEditTime: 2021-07-12 10:58:57
 * @LastEditors: pengyu
 * @Description: 数量金额余额表
 * @FilePath: \user-intelligent-accounting\src\views\accountBook\alert\numBalanceAlert.vue
 -->
<template>
  <en-dialog
    :visible="visible"
    :title="alertName"
    class="numBalance"
    en-fullscreen
    en-body-scroll
    @close="visible=false"
  >
    <div slot="title" class="dialog-title">
      <div>{{alertName}}</div>
      <div class="btn-group">
        <div class="btn-export" @click="handleExport">
          <en-icon
            name="bus-btn5_1"
            size="small"
            style="color:#26c393"
          ></en-icon>
          <div class="label">
            导出
          </div>
        </div>
        <div class="btn-print" @click="handlePrint">
          <en-icon
            name="bus-btn6_1"
            size="small"
            style="color:#3e90fe"
          ></en-icon>
          <div class="label">
            打印
          </div>
        </div>
      </div>
    </div>
    <div class="main" v-en-loading="enLoading">
      <!--表格部分-->
      <en-table :tree-config="{ children: 'nodes', hasChildren: 'hasNode', trigger: 'row' }" :data="list"  height="100%">
        <en-table-column type="index" width="60" align="center"></en-table-column>
        <en-table-column prop="name" label="科目" width="220"></en-table-column>
        <en-table-column prop="quantityUnitName" label="单位" width="130" ></en-table-column>
        <en-table-column title="期初余额" header-align="center">
          <en-table-column title="数量" prop="balanceNum" header-align="center" width="130">
              <template slot-scope="scope">
                {{scope.row.balanceNum|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="单价" prop="balanceAmount" header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.balanceAmount|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="借方金额" prop="balanceBor" header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.balanceBor|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="贷方金额" prop="balanceLoan" header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.balanceLoan|thousand(2)}}
              </template>
          </en-table-column>
        </en-table-column>
        <en-table-column title="本期发生" header-align="center">
          <en-table-column title="借方数量" prop="curBorNum" align="center" width="130">
              <template slot-scope="scope">
                {{scope.row.curBorNum|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="借方单价" prop="curBorAmount" header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.curBorAmount|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="借方金额" prop="curBor" header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.curBor|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="贷方数量" prop="curLoanNum" width="130">
              <template slot-scope="scope">
                {{scope.row.curLoanNum|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="贷方单价" prop="curLoanAmount" header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.curLoanAmount|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="贷方金额" prop="curLoan" header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.curLoan|thousand(2)}}
              </template>
          </en-table-column>
        </en-table-column>
        <en-table-column title="期末余额" header-align="center">
          <en-table-column title="数量" prop="endNum" header-align="center" width="130">
              <template slot-scope="scope">
                {{scope.row.endNum|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="单价" prop="endAmount"  header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.endAmount|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="借方金额" prop="endBor" header-align="center"  align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.endBor|thousand(2)}}
              </template>
          </en-table-column>
          <en-table-column title="贷方金额" prop="endLoan" header-align="center" align="right" width="130">
              <template slot-scope="scope">
                {{scope.row.endLoan|thousand(2)}}
              </template>
          </en-table-column>
        </en-table-column>
      </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { thousand, request } from "en-js";
import { balanceService } from "@/api/intelligentAccounting";

export default {
  props: {
    afferentData: {}// 查询的一些参数
  },
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  data() {
    return {
      enLoading: false,
      visible: false,
      list: [], // 表格数据
      alertName: "" // 弹窗名
    };
  },
  watch: {
    visible(data) {
      if (this.afferentData) {
        this.alertName = `数量金额余额表（${this.afferentData.startDate}—${this.afferentData.endDate}）`;
      }
      if (data) {
        this.queryAbNumAmount();
      }
    }
  },
  mounted() {
  },
  methods: {
    // 请求数量金额余额
    @request(true, "enLoading")
    async queryAbNumAmount() {
      const res = await balanceService.queryAbNumAmount({
        accountId: this.afferentData.accountId,
        accountingEntity: this.afferentData.accountingEntity,
        status: this.afferentData.status,
        accountingPeriod: this.afferentData.accountingPeriod
      });
      this.list = res;
    },
    // 导出
    handleExport() {
      const pa = { typeId: "sl", ...this.afferentData };
      this.$parent.handleExportExcel(pa);
    },
    // 打印
    handlePrint() {
      this.$emit("printNum", this.list);
    }
  }
};
</script>

<style lang="scss" scoped>
.numBalance {
  .dialog-title{
    display: flex;
    justify-content: space-between;
    .btn-group{
      margin-right: 20px;
      display: flex;
    }
    .btn-export,
    .btn-print {
      cursor: pointer;
      margin-right: 20px;
      display: flex;
      align-items: center;
      .en-icon {
        margin-right: 5px;
      }
      .label {
        color: #636c78;
        font-weight: 500;
      }
    }
  }
  .main{
    min-height:100%;
  }
}
</style>
