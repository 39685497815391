<template>
  <div class="print-template" id="print-template-specification">
    <div class="title">{{searchData.title}}</div>
    <div class="table-info">
      <span>编制单位:{{searchData.unit}}</span> &nbsp; &nbsp;
      <span>会计期间:{{searchData.period}}</span> &nbsp; &nbsp;
      <span>单位:元</span>
    </div>
    <table cellspacing="0" cellpadding="0" border="0">
      <thead>
        <tr>
          <th colspan="1" rowspan="1" style="text-align:center;width:80px">日期</th>
          <th colspan="1" rowspan="1" style="text-align:center;width:80px">凭证号</th>
          <th colspan="1" rowspan="1" style="text-align:center;width:80px">摘要</th>
          <th colspan="1" rowspan="1" style="text-align:center;width:100px">科目</th>
          <th colspan="1" rowspan="1" style="text-align:center;width:80px">借方金额</th>
          <th colspan="1" rowspan="1" style="text-align:center;width:80px">贷方金额</th>
          <th colspan="1" rowspan="1" style="text-align:center;width:60px">方向</th>
          <th colspan="1" rowspan="1" style="text-align:center;width:80px">余额</th></tr>
      </thead>
      <tbody>
        <tr v-for="(rowData, index) in dataList" :key="index">
          <td colspan="1" rowspan="1">{{rowData.produceDate}}</td>
          <td colspan="1" rowspan="1">{{rowData.voucherNo}}</td>
          <td colspan="1" rowspan="1">{{rowData.remark}}</td>
          <td colspan="1" rowspan="1">{{rowData.subjectCode + rowData.subjectName}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.bor|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.loan|thousand(2)}}</td>
          <td colspan="1" rowspan="1">{{rowData.direction}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.balance|thousand(2)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { thousand } from "en-js";

// 总账
export default {
  name: "PrintTemplateSpecification",
  components: {
  },
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  props: {
    isYear: {
      type: [Number, Boolean]
    },
    dataList: {
      type: Array,
      default() {
        return [];
      }
    },
    searchData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  }
};
</script>

<style lang="scss">
.print-template {
  position: fixed;
  z-index: -12;
  width: 1000px;
  height: auto;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: #fff;
  .title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  .table-info {
    font-size: 12px;
    margin: 10px 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    color: #000;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    th {
      text-align: left;
      padding: 6px 10px;
      line-height: 20px;
      border: 1px solid #333;
      word-break: break-all;
      font-weight: normal;
    }
  }
  th{text-align:left;padding:6px 10px;line-height:20px;border:1px solid #333;word-break:break-all;font-weight:normal;}
  td{padding:2px 10px;line-height:20px;border:1px solid #333;word-break:break-all;height:24px !important;}
}
</style>
