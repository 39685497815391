<!--
 * @Author: Zhouql
 * @Date: 2020-03-10 17:43:11
 * @LastEditTime: 2021-08-05 11:51:33
 * @LastEditors: pengyu
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\intelligentAccounting\accountBook\multiColAccount\index.vue
 -->
<template>
  <div class="assistBalanceList">
    <div class="content" :class="isActive ? 'left-container expand' : 'left-container'">
      <en-title-card name="多栏账">
        <div slot="right">
          <searchList @clickData="getVal" :searchDataList="searchDataList" :defaultSearchData="defaultSearchData"></searchList>
          <div class="btn-export" @click="handleExport">
            <en-icon name="bus-btn5_1" size="small" style="color:#26c393"></en-icon>
            <div class="label">导出</div>
          </div>
          <!-- <div class="btn-print">
            <en-icon name="bus-btn6_1" size="small" style="color:#3e90fe"></en-icon>
            <div class="label">打印</div>
          </div> -->
        </div>
      </en-title-card>
      <en-tabs v-model="activeTab" :list="tabList" type="white" :fixedWidth="120" @change="changeTabs">
        <div slot="right">
          <en-select :data="multiColList" v-model="multiColDefault" @change="handleMultiColChange" :props="{value:'id',label:'name'}"  placeholder="请选择"></en-select>
          <subjectSel :subjectList="subjectList" @subjectVal="subjectValue"></subjectSel>
        </div>
      </en-tabs>
      <!--表格部分-->
      <div class="table-main" v-en-loading="enLoading">
        <en-table
          ref="multitable"
          :tree-config="{ children: 'nodes', hasChildren: 'hasNode', trigger: 'default' }"
          :data="list.dataList"
          height="100%"
          :optimization="{scrollX: {rSize: 1000}}"
          @row-click="handleRowClick"
        >
          <en-table-column type="index" width="60" align="center" key="index"></en-table-column>
          <en-table-column prop="text" label="科目" width="400" key="text">
            <template v-slot="{row}">
              {{(row.code || "") + " " + row.text}}
            </template>
          </en-table-column>
          <en-table-column v-if="multiColDefault===2" width="120" title="借" header-align="center" align="right" prop="1_0" :key="Math.random()">
            <template v-slot="{row}">
              {{ row["1_0"] | thousand(2) }}
            </template>
          </en-table-column>
          <en-table-column v-if="multiColDefault===1||multiColDefault===3" title="借" header-align="center" :key="Math.random()">
            <en-table-column
              v-for="(item) in list.assAccListB"
              :key="item.id"
              :title="item.name"
              :prop="item.id"
              min-width="70"
              header-align="center"
              align="right">
                <template v-slot="{row}">
                {{ row[item.id] | thousand(2) }}
                </template>
            </en-table-column>
          </en-table-column>
          <en-table-column v-if="multiColDefault===2||multiColDefault===3" title="贷" header-align="center" :key="Math.random()">
            <en-table-column
              v-for="(item) in list.assAccListL"
              :key="item.id"
              :title="item.name"
              :prop="item.id"
              min-width="70"
              header-align="center"
              align="right">
                <template v-slot="{row}">
                {{ row[item.id] | thousand(2) }}
              </template>
            </en-table-column>
          </en-table-column>
          <en-table-column v-if="multiColDefault===1" width="120" title="贷" header-align="center" align="right" prop="2_0" :key="Math.random()">
            <template v-slot="{row}">
              {{ row["2_0"] | thousand(2) }}
            </template>
          </en-table-column>
          <en-table-column title="方向" prop="direction" width="120" header-align="center" key="direction"></en-table-column>
            <en-table-column title="余额" prop="balance" width="120" header-align="center" align="right" key="balance">
              <template v-slot="{row}">
                {{ row.balance | thousand(2) }}
              </template>
            </en-table-column>
        </en-table>
      </div>
    </div>
    <!-- '会计期间' -->
    <dateInterval :type="1" :hideHead="false" :list="dateLineList" @collapseBtn="isActive = !isActive" @handleAccChange="accChange" class="date-interval"></dateInterval>
    <!-- 穿透辅助明细 -->
    <assitAccDetail ref="assitModel" :afferentData="afferentItem"  @printDetail="printDetail"></assitAccDetail>
    <!-- 打印模板详情 -->
    <print-template-detail :dataList="detailList" :afferentData="afferentItem" :search-data="printSearchData"></print-template-detail>
     <!-- 凭证详情 -->
    <vocDetailAlert
      ref="vocDialog"
      :voucherId="voucherId"
      :actionType="2"
      :formal="{}"
      openFlag="quotes"
    ></vocDetailAlert>
  </div>
</template>

<script>
import { request } from "en-js";
import { Message } from "element-ui";
import { commonService, balanceService } from "@/api/intelligentAccounting";
import accountBookComm from "@/mixins/intelligentAccounting/accountBookComm.js"; // 公用的JS方法
import PrintTemplateDetail from "../printTemplate/PrintTemplateDetail";
import searchList from "../../components/searchList"; // 顶部搜索
import dateInterval from "../../components/dateInterval"; // 会计期间控件
import subjectSel from "../../components/subjectSel"; // 科目
import assitAccDetail from "../alert/assitAccDetail.vue";
import vocDetailAlert from "../../components/vocDetailAlert";

export default {
  name: "MultiColAccount",
  components: {
    searchList, dateInterval, subjectSel, assitAccDetail, PrintTemplateDetail, vocDetailAlert
  },
  mixins: [accountBookComm],
  data() {
    return {
      enLoading: null,
      isActive: false,
      multiColtype: true,
      searchDataList: {}, // 搜索的下拉数据
      list: {
        dataList: [], // 数据
        assAccListB: [], // 借方多栏
        assAccListL: [] // 贷方多栏
      },
      tabList: [],
      activeTab: undefined,
      subjectCode: "",
      dateLineList: [], // 会计期间数据
      defaultYearNum: "", // 会计区间选中年份
      accountId: "", // 所属账套id
      // 凭证范围下拉数据
      vocrangeList: [
        {
          id: 1,
          name: "全部凭证"
        },
        {
          id: 2,
          name: "仅已审核"
        }
      ],
      accountList: [], // 账套下拉数据
      accountMainList: [], // 记账主体下拉数据
      multiColList: [
        { id: 1, name: "借方多栏" },
        { id: 2, name: "贷方多栏" },
        { id: 3, name: "借贷均多栏" }
      ], // 多栏下拉
      accountDefault: {}, // 默认账套
      // 默认范围
      vocrangeDefault: 1,
      subjectList: [],
      accountMainDefault: [], // 默认记账主体
      defaultSearchData: {}, // 搜索默认数据
      accountingPeriod: [], // 会计期间的id
      detailList: [], // 打印详情列表
      multiColDefault: 1,
      afferentItem: {}, // 辅助核算明细表穿透
      voucherId: "" // 穿透凭证详情id
    };
  },
  watch: {
    isActive() {
      this.$refs.multitable.resetResizable();
    }
  },
  computed: {
    printSearchData() {
      let unit = "";
      const findNode = (list, id) => {
        if (!id) return;
        list.forEach((item) => {
          if (item.id === id) {
            unit = item.code + item.text;
          } else if (item.nodes) {
            findNode(item.nodes, id);
          }
        });
      };
      const { startDate, endDate } = this;
      const period = startDate && endDate && startDate !== endDate ? `${startDate}-${endDate}` : endDate || startDate;
      findNode(this.accountMainList, this.accountMainDefault[0]);
      return {
        unit,
        period
      };
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * @description: 初始化
     */
    async init() {
      await this.queryAccList(); // 请求账套和日期区间
      await this.queryAccTime();
      await this.queryAccountEntity(); // 请求记账主体
      await this.queryAllAssit(); // tab切换
      await this.querySubject();
      this.querymultiCol();
    },
    // 请求日期区间数据接口
    async queryAccTime() {
      const res = await commonService.queryAccTime({
        accountId: this.accountDefault,
        hasChildren: "000"
      });
      this.dateLineList = res;
      this.accPeriod();
    },
    /**
     * @description: 打印详情
     */
    printDetail(list) {
      console.log(list);
      this.detailList = list;
      this.$nextTick(() => {
        this.printTable("print-template-detail");
      });
    },
    /**
     * @deprecate
     */
    accPeriod() {
      // 默认会计区间获取会计区间选中ID
      function findDefaultPeriod(arr) {
        const nodesCur = [];
        const nodesCurName = [];
        const yearNum = [];
        const dateNameList = [];
        arr.forEach((v) => {
          if (v.isDefault === 0) {
            nodesCur.push(v.id);
            nodesCurName.push(v.name);
            yearNum.push(v.yearNum);
            dateNameList.push(v.name);
          }
        });
        return {
          nodesCur, nodesCurName, yearNum, dateNameList
        };
      }

      this.dateLineList.forEach((item) => {
        if (item.isDefault === 0) {
          if (item.nodes) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            this.accountingPeriod = findDefaultData.nodesCur;
            this.accountingPeriodName = findDefaultData.nodesCurName;
            this.defaultYearNum = findDefaultData.yearNum.toString();
            this.startDate = findDefaultData.dateNameList[0];
            this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          } else {
            this.defaultYearNum = item.name;
          }
        }
      });
    },
    // 请求tab数据
    async queryAllAssit() {
      const res = await commonService.querySubjectAllAssit({
        accountId: this.accountDefault,
        yearNum: this.defaultYearNum
      });
      this.tabList = res;
      // 如果选过辅助核算则保留
      if (this.activeTab) {
        const as = res.filter((itm) => itm.id === this.activeTab)[0];
        if (!as) {
          this.activeTab = res[0].id;
        }
      } else {
        this.activeTab = res[0].id;
      }
    },
    // 请求科目
    async querySubject() {
      const res = await commonService.queryAllSubject({
        accountId: this.accountDefault,
        yearNum: this.defaultYearNum,
        assitId: this.activeTab
      });
      this.subjectList = res;
      this.subjectList.unshift({ id: "0", name: "全部", code: "" });
    },
    // 请求多栏数据
    @request(true, "enLoading")
    async querymultiCol() {
      const _this = this;
      const pa = this.getCommParam();
      const res = await balanceService.querymultiCol(pa);
      if (this.multiColDefault === 1 || this.multiColDefault === 2) { // 借方多栏 贷方多栏
        const arr = [];
        res.assAccList.forEach((item) => {
          arr.push({
            id: `${_this.multiColDefault}_${item.id}`,
            name: item.name
          });
        });
        if (this.multiColDefault === 1) {
          this.$set(this.list, "assAccListB", arr);
        } else {
          this.$set(this.list, "assAccListL", arr);
        }
      } else {
        const arr1 = [];
        const arr2 = [];
        res.assAccList.forEach((item) => {
          arr1.push({
            id: `1_${item.id}`,
            name: item.name
          });
          arr2.push({
            id: `2_${item.id}`,
            name: item.name
          });
        });
        this.$set(this.list, "assAccListB", arr1);
        this.$set(this.list, "assAccListL", arr2);
      }

      this.$set(this.list, "dataList", res.dataList);
      this.$refs.multitable && this.$refs.multitable.refreshColumn();
    },
    /**
     * @description: 取搜索组件带出数据
     */
    async getVal(data) {
      const originAccount = this.accountDefault;
      if (data.accountMain.length === 0) {
        Message("请选择记账主体");
        return;
      }

      this.accountDefault = data.account;
      this.accountMainDefault = data.accountMain;
      this.vocrangeDefault = data.vocrange;
      if (data.account !== originAccount) {
        // 账套发生改变 重新查询会计期间  辅助核算项
        this.resetQueryData();
        await this.queryAccTime();
        await this.queryAllAssit();
        await this.querySubject();
      }
      this.querymultiCol();
    },
    /**
     * @description: 清空查询后的表格数据
     */
    resetQueryData() {
      this.tabList = [];
      this.activeTab = "";
      this.subjectList = [];
      this.list.dataList = [];
      this.list.assAccListB = [];
      this.list.assAccListL = [];
    },
    /**
     * tab切换
     */
    async changeTabs() {
      await this.querySubject();
      this.querymultiCol();
    },
    /**
     * @description: 日期区间选中数据
     */
    async accChange(data) {
      this.accountingPeriod = data.ids;
      this.accountingPeriodName = data.names;
      this.defaultYearNum = data.yearNum;
      let dateNames = [];
      dateNames = data.names.split(",");
      this.startDate = dateNames[0];
      this.endDate = dateNames[dateNames.length - 1];
      await this.queryAllAssit();
      await this.querySubject();
      this.querymultiCol();
    },
    /**
     * @description: 取科目下拉选中数据
     */
    subjectValue(param) {
      this.subjectCode = param.code;
      this.assitAccId = param.mainAssistAcc;
      this.querymultiCol();
    },
    /**
     * @description: 获取公共传参
     */
    getCommParam() {
      const _this = this;
      const params = {
        accountId: this.accountDefault,
        accountName: (() => {
          const o = this.accountList.filter((itm) => itm.id === _this.accountDefault);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        accountingEntity: this.accountMainDefault.toString(),
        accountingEntityName: (() => {
          const re = [];
          _this.accountMainDefault.forEach((itm) => {
            _this.accountMainList.forEach((itm2) => {
              if (itm2.id === itm) {
                re.push(`${itm2.code} ${itm2.name}`);
              }
              itm2.nodes?.forEach((itm3) => {
                if (itm3.id === itm) {
                  re.push(`${itm3.code} ${itm3.name}`);
                }
              });
            });
          });
          return re.join(",");
        })(),
        status: this.vocrangeDefault,
        accountingPeriod: this.accountingPeriod.toString(),
        accountingPeriodName: this.accountingPeriodName.toString(),
        yearNum: this.defaultYearNum,
        startDate: this.startDate,
        endDate: this.endDate,
        tooType: this.multiColDefault,
        assitAccId: this.activeTab,
        assitAccName: (() => {
          const o = this.tabList.filter((itm) => itm.id === _this.activeTab);
          return o.length ? o[0].name : "";
        })(),
        subjectCode: this.subjectCode,
        subjectName: (() => {
          const o = _this.subjectList.filter((item) => item.code === _this.subjectCode);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })()
      };
      return params;
    },
    /**
     * @description: 导出
     */
    handleExport() {
      if (!this.accountMainDefault.length) {
        Message("请选择记账主体");
        return;
      }

      const pa = { typeId: "dlz", ...this.getCommParam() };
      // 传辅助核算+辅助核算子项
      pa.subjectName = pa.assitAccName;
      pa.accountingPeriodName = `${pa.startDate}-${pa.endDate}`;
      this.handleExportExcel(pa);
    },
    /**
     * @description: 表格行点击穿透
     */
    handleRowClick(data) {
      const { row, column } = data;
      const { field } = column;
      if (!row.code) {
        return;
      }
      const fs = field.split("_");
      if (!fs[1]) return;
      if (!/^[0-9]+$/.test(fs[1]) || fs[1].length < 18) return;
      const pa = this.getCommParam();
      pa.subjectCode = row.code;
      pa.subjectName = row.text;
      pa.dataId = fs[1];
      this.afferentItem = { ...pa };
      this.$refs.assitModel.visible = true;
    },
    /**
     * @description: 表格行点击穿透
     */
    handleMultiColChange() {
      this.querymultiCol();
    }
  }
};
</script>

<style lang="scss" scoped>
.assistBalanceList {
  position: relative;
  background-color: #1d2532;
  .en-title-card {
    overflow: hidden;
  }

  .choice-subject {
    // float: right;
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  /deep/ .en-tabs-item {
    justify-content: center;
    font-weight: 600;
  }
  .btn-export,
  .btn-print {
    position: relative;
    cursor: pointer;
    // margin-right: 15px;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      color: #636c78;
    }
  }
  .table-main {
    height: calc(100% - 100px);
  }
  /deep/ .en-tabs .en-tabs-list .en-tabs-item {
    width: 120px;
    text-align: center;
  }
  /deep/ .search .search-box {
    max-width: 950px;
    overflow-x: auto;
  }
  /deep/ .search .search-box {
    line-height: 38px;
  }
  /deep/ .search .search-box .search-condition {
    min-width: 260px;
  }
  /deep/ .search .search-box .query-btn {
    height: 28px;
    line-height: 28px;
    min-height: 28px;
  }
}
.left-container {
  height: 100%;
  width: calc(100% - 72px);
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.expand {
    width: 100%;
  }
}
.date-interval {
  position: absolute;
  right: 4px;
  top: 0;
}
</style>
