var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "print-template", attrs: { id: "print-template-assist" } },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.searchData.title)),
      ]),
      _c("div", { staticClass: "table-info" }, [
        _c("span", [_vm._v("编制单位: " + _vm._s(_vm.searchData.unit))]),
        _vm._v("     "),
        _c("span", [_vm._v("会计期间: " + _vm._s(_vm.searchData.period))]),
        _vm._v("     "),
        _c("span", [_vm._v("单位: 元")]),
      ]),
      _c(
        "table",
        { attrs: { cellspacing: "0", cellpadding: "0", border: "0" } },
        [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(_vm.dataList, function (rowData, index) {
              return _c("tr", { key: index }, [
                _c("td", { attrs: { colspan: "1", rowspan: "1" } }, [
                  _vm._v(_vm._s(rowData.name)),
                ]),
                _c(
                  "td",
                  { attrs: { colspan: "1", rowspan: "1", align: "right" } },
                  [_vm._v(_vm._s(_vm._f("thousand")(rowData.balanceBor, 2)))]
                ),
                _c(
                  "td",
                  { attrs: { colspan: "1", rowspan: "1", align: "right" } },
                  [_vm._v(_vm._s(_vm._f("thousand")(rowData.balanceLoan, 2)))]
                ),
                _c(
                  "td",
                  { attrs: { colspan: "1", rowspan: "1", align: "right" } },
                  [_vm._v(_vm._s(_vm._f("thousand")(rowData.curBor, 2)))]
                ),
                _c(
                  "td",
                  { attrs: { colspan: "1", rowspan: "1", align: "right" } },
                  [_vm._v(_vm._s(_vm._f("thousand")(rowData.curLoan, 2)))]
                ),
                _c(
                  "td",
                  { attrs: { colspan: "1", rowspan: "1", align: "right" } },
                  [_vm._v(_vm._s(_vm._f("thousand")(rowData.endBor, 2)))]
                ),
                _c(
                  "td",
                  { attrs: { colspan: "1", rowspan: "1", align: "right" } },
                  [_vm._v(_vm._s(_vm._f("thousand")(rowData.endLoan, 2)))]
                ),
              ])
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "1", rowspan: "2" },
          },
          [_vm._v("辅助核算")]
        ),
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "2", rowspan: "1" },
          },
          [_vm._v("期初余额")]
        ),
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "2", rowspan: "1" },
          },
          [_vm._v("本期发生")]
        ),
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "2", rowspan: "1" },
          },
          [_vm._v("期末余额")]
        ),
      ]),
      _c("tr", [
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [_vm._v("借方金额")]
        ),
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [_vm._v("贷方金额")]
        ),
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [_vm._v("借方金额")]
        ),
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [_vm._v("贷方金额")]
        ),
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [_vm._v("借方金额")]
        ),
        _c(
          "th",
          {
            staticStyle: { "text-align": "center" },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [_vm._v("贷方金额")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }