<!--
 * @Author: wuqi
 * @Date: 2021-09-26 16:58:43
 * @LastEditors: wuqi
 * @LastEditTime: 2021-10-11 15:33:30
 * @Description:
-->
<template>
  <en-dialog
    title="结账区间"
    :visible="dialogVisible"
    class="batch-check-dialog"
    width="50%"
    append-to-body
    @close="close"
  >
    <el-input readonly v-model="startDate.name" disabled class='start-time'></el-input>
    -
    <en-select v-model="endDate" :data="intervalList" :props='defaultProps' placeholder="请选择" class='end-time' >
    </en-select>
    <div slot="footer" style="height: 32px">
      <en-button  @click.native="complete">
        保存
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
export default {
  name: "batchCheck",
  data() {
    return {

      endDate: "",
      dialogVisible: false,
      defaultProps: {
        label: "name",
        value: "value"
      }
    };
  },
  props: {
    interval: {
      type: Array,
      default() {
        return [];
      }
    },
    prezjrq: { // 上次结账日期，有值表示有过结账记录
      type: String,
      default: ""
    },
    params: Object
  },
  computed: {
    intervalList() {
      const res = [];
      this.interval.forEach((item) => {
        item.nodes.forEach((node) => {
          if (node.isCloseAccount !== 0) {
            res.push({
              value: node.name,
              name: `${node.yearNum}年${node.num}月`
            });
          }
        });
      });
      return res;
    },
    startDate() {
      return this.intervalList[0] || {};
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    complete() {
      if (!this.startDate) {
          this.$message.error("开始时间不能为空");
          return false;
      }
      if (!this.endDate) {
          this.$message.error("结束时间不能为空");
          return false;
      }
      if (Number(this.startDate?.value) > Number(this.endDate)) {
          this.$message.error("开始时间不能大于结束时间");
          return false;
      }
      // 循环结账
      this.$emit("batchCheck", this.startDate?.value, this.endDate);
      return undefined;
    },

    getMD(dd) {
      const d = dd || new Date();
      const m = (`00${ d.getMonth() + 1}`);
      const r = (`00${ d.getDate()}`);
      return `${m.substr(m.length - 2) }-${ r.substr(r.length - 2)}`;
    },
    getYM(dd) {
      const d = dd || new Date();
      const y = d.getFullYear();
      const m = (`00${ d.getMonth() + 1}`);
      return `${y }-${ m.substr(m.length - 2)}`;
    },
    getPreNextMonthDate(date, n) {
        // 加减月数
        if (!date) {
            date = new Date();
        }
        const re = new Date(date.setMonth(date.getMonth() + n));
        return re;
    }
  },
  watch: {
    intervalList: {
      handler(newVal) {
        const lastKz = newVal[newVal.length - 1]?.value;
        const preYm = this.getYM(this.getPreNextMonthDate(null, -1)).replace("-", "");// 当前时间前一个月
        const lastKzNum = Number(lastKz);
        const preYmNum = Number(preYm);
        if (this.prezjrq) { // 有结过账
            const curYm = this.getYM().replace("-", ""); // 当前时间年月
            if (Number(curYm) > lastKzNum) { // 当前时间大于开账截止时间
                this.endDate = lastKz;
            } else if (preYmNum < lastKzNum) { // 当前时间的前一个月小于企业所有已开账-开账截止时间
                this.endDate = preYm;
                if (preYmNum < Number(this.startDate.value)) { // 当前时间的前一个月小于企业所有已开账-开账截止时间且小于开始时间
                    this.endDate = lastKz;
                }
            }
        } else if (preYmNum > lastKzNum) { // 当前时间的前一个月大于开账截止时间
                this.endDate = lastKz;
        } else if (preYmNum < lastKzNum && preYmNum > Number(this.startDate.value)) { // 当前时间的前一个月小于企业所有已开账-开账截止时间且大于开始时间
            this.endDate = preYm;
        } else if (preYmNum < lastKzNum && preYmNum < Number(this.startDate.value)) { // 当前时间的前一个月小于企业所有已开账-开账截止时间且小于开始时间
            this.endDate = lastKz;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang='scss' scoped>
  .start-time,.end-time{
    display: inline-block;
    width: 250px;
  }
</style>
