<!--
 * @Author: pengyu
 * @Date: 2020-03-10 14:15:46
 * @LastEditTime: 2021-07-15 17:11:30
 * @LastEditors: pengyu
 * @Description: 凭证处理-分录视图列表视图 顶部公共导航
 * @FilePath: \user-intelligent-accounting\src\components\topNav\index.vue
 -->
<template>
  <div class="top-nav">
    <en-title-card :name="isCheckPage ? '凭证审核' : '凭证处理'">
      <div
        slot="left"
        class="left-area"
      >
        <!-- 账套 -->
        <!-- a9b5c6 -->
        <en-select
          v-model="value.accountId"
          :data="accAry"
          :props="{ value: 'id', label: 'name'}"
          @change="selectChange"
          :disabled="selectDisable"
        ></en-select>
        <!-- 记账主体 -->
        <en-select
          mode="tree"
          v-model="value.accountingEntity"
          :data="entityAry"
          :props="{ value: 'id', label: 'text', children: 'nodes' }"
          @change="selectChange"
          :disabled="selectDisable"
        ></en-select>
        <!-- 选择视图 -->
        <en-select
          v-model="curItem"
          :data="viewList"
          @change="viewClick"
        >
        </en-select>
      </div>
      <div slot="right" class="right-area">
        <!-- 批量按钮 -->
        <div v-show="batchBtn">
          <en-button
            v-if="!isCheckPage"
            type="text"
            icon="fabu"
            icon-color="#69AADE"
            :icon-size="16"
            @click="clickBtn('submit')"
          >
            提交
          </en-button>
          <!-- 凭证处理无审核取消审核按钮 -->
          <en-button
            v-if="isCheckPage"
            type="text"
            icon="shenhe"
            icon-color="#26c393"
            :icon-size="16"
            @click="clickBtn('check')"
          >
            审核
          </en-button>
          <en-button
            v-if="isCheckPage"
            type="text"
            icon="shenhe"
            icon-color="#a9b5c6"
            :icon-size="16"
            @click="clickBtn('cancelCheck')"
          >
            取消审核
          </en-button>
          <en-button
            type="text"
            icon="bus-btn6_1"
            icon-color="#5097DE"
            :icon-size="16"
            @click="clickBtn('print')"
          >
            打印
          </en-button>
          <en-button
            v-if="!isCheckPage"
            type="text"
            icon="bus-btn10_1"
            icon-color="#F77981"
            :icon-size="16"
            @click="clickBtn('delete')"
          >
            删除
          </en-button>
          <!-- 自定义按钮 -->
          <en-button
            v-for="(itm, idx) in selfBtn"
            :key="idx"
            type="text"
            :icon="getIconName(itm.icon)"
            :icon-color="itm.color"
            :icon-size="16"
            @click="clickBtn('self', itm)">
            {{itm.btnName}}
          </en-button>
        </div>
        <!-- 凭证按钮 -->
        <div v-show="!batchBtn">
          <en-button
            type="text"
            icon="bus-btn5_1"
            icon-color="#66D2AF"
            :icon-size="16"
            @click="clickBtn('export')"
          >
            导出
          </en-button>
           <en-button
            type="text"
            icon="pingzhengrizhi"
            icon-color="#a9b5c6"
            :icon-size="16"
            @click="clickBtn('voucherLog')"
          >
            日志
          </en-button>
          <en-button
            type="text"
            icon="shaixuan"
            icon-color="#987DE6"
            :icon-size="16"
            @click="clickBtn('screen')"
          >
            筛选
          </en-button>
          <en-button
            v-if="!isCheckPage"
            type="text"
            icon="zhengliduanhao"
            icon-color="#DEAF90"
            :icon-size="16"
            @click="clickBtn('break')"
          >
            整理断号
          </en-button>
          <en-button
            v-if="!isCheckPage"
            icon="add"
            type="blue"
            @click="clickBtn('add')"
          >
            添加
          </en-button>
        </div>
      </div>
    </en-title-card>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    isCheckPage: {
      type: Boolean,
      default: false
    },
    curView: {
      type: String,
      default: "1"
    },
    entityList: { // 记账主体
      type: Array,
      default() {
        return [];
      }
    },
    accList: { // 账套列表
      type: Array,
      default() {
        return [];
      }
    },
    batchBtn: { // 选中数据 显示批量按钮
      type: Boolean,
      default: false
    },
    selectDisable: { // 禁用 账套 和 记账主体下拉
      type: Boolean,
      default: false
    },
    selfBtn: Array
  },
  watch: {
    $route(to) {
      if (to.path.includes("/voucherEntry") || to.path.includes("/voucherExamine")) {
        this.curItem = "1";
      } else {
        this.curItem = "2";
      }
    },
    accList: {
      immediate: true,
      handler(nVal) {
        this.accAry = nVal;
      }
    },
    entityList: {
      immediate: true,
      handler(nVal) {
        this.entityAry = nVal;
      }
    },
    params: {
      deep: true,
      handler(nval) {
        this.value = JSON.parse(JSON.stringify(nval));
      }
    }
  },
  data() {
    return {
      value: {},
      viewList: [{ value: "1", label: "分录视图" }, { value: "2", label: "列表视图" }], // 视图选择
      voucherData: [], // 凭证数据
      entityAry: [], // 记账主体数据
      accAry: [], // 账套数据
      curItem: this.curView
    };
  },
  methods: {
    /**
     * @description: 视图选择
     * @param {val}
     */
    viewClick(val) {
      const { query } = this.$parent.$route;
      let pa = {};
      if (query && query.flg === "1") {
        pa = { ...query };
      }

      if (val === "1") {
        if (this.isCheckPage) {
          this.$router.push({ path: "/intelligent-accounting/voucherExamine", query: pa });
        } else {
          this.$router.push({ path: "/intelligent-accounting/voucherEntry", query: pa });
        }
      } else {
        this.$router.push({ path: "/intelligent-accounting/voucherList", query: { isCheckPage: this.isCheckPage, ...pa } });
      }
    },
    /**
     * @description: 顶层 按钮点击
     * @param {id}
     */
    clickBtn(id, config = {}) {
      const btnData = { btnKey: id, ...config };
      this.$emit("top-btn-click", btnData);
    },
    /**
   * @description: 账套 记账主体改变
   * @param {type}
   */
    selectChange() {
      this.$emit("change-param", this.value);
    },
    /**
   * @description: 获取当前选项
   * @param {type}
   */
    getCurValue() {
      return this.value;
    },
    /**
   * @description: 获取自定义按钮配置图标对应的name
   * @param icon
   */
    getIconName(icon) {
      return `bus-btn${icon}_1`;
    }
  }
};
</script>

<style lang="scss" scoped>
.top-nav {
  .en-title-card {
    overflow: hidden;
  }
  .left-area {
    .en-select {
      margin-right: 10px;
    }
  }
}
</style>
