<!--
 * @Author: pengyu
 * @Date: 2020-03-16 16:40:33
 * @LastEditTime: 2021-01-21 15:57:07
 * @LastEditors: pengyu
 * @Description:
 * @FilePath: \user-intelligent-accounting\src\components\rangeInput\index.vue
 -->
<template>
  <div class="range-input">
    <el-input
      v-model="range[0]"
      type="text"
      class="s-input"
      :placeholder="firstHolder"
      @input.native="inputChange($event)"
    >
    </el-input>
    <span class="sep">——</span>
    <el-input
      v-model="range[1]"
      type="text"
      class="e-input"
      :placeholder="secondHolder"
      @input.native="inputChange($event)"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: "RangeInput",
  props: {
    inputType: {
      type: Number,
      default: 1
    },
    value: {
      type: String,
      default: "0,100"
    },
    firstHolder: {
      type: String,
      default: "请输入"
    },
    secondHolder: {
      type: String,
      default: "请输入"
    }
  },
  data() {
    return {
      range: []
    };
  },
  watch: {
    value(newval) {
      this.range = newval ? newval.split(",") : [];
    }
  },
  methods: {
    /**
     * @description: input事件
     */
    inputChange(event) {
      const target = event.target;
      const value = target.value;

      if (this.inputType === 3 && isNaN(value)) {
        target.value = "";
      }
      this.$emit("rangeInputChange", this.range);
    }
  }
};
</script>

 <style lang="scss" scoped>
.range-input{
  width: 100%;
  display: flex;
  border:1px solid $field-border-color;
  height: 32px;
  overflow: hidden;
  align-items: center;
  border-radius: 4px;
  &:hover{
    border-color: $field-border-color-hover;
  }
  & /deep/ .el-input__inner{
    border:none;
  }
  .sep{
    color: #DCDFE6;
  }
  ::-webkit-input-placeholder{
    font-size: 12px;
  }
 }
 </style>
