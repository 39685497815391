<!--
 * @Author: Zhouql
 * @Date: 2020-03-10 17:43:11
 * @LastEditTime: 2021-08-05 11:51:06
 * @LastEditors: pengyu
 * @Description: 账簿-辅助核算明细账
 * @FilePath: \user\src\views\intelligentAccounting\accountBook\assistSpecofocation\index.vue
 -->
<template>
  <div class="assistSpecofocationList">
    <div class="content" :class="isActive ? 'left-container expand' : 'left-container'">
      <en-title-card name="辅助核算明细账">
        <div slot="right">
          <searchList @clickData="getVal" :searchDataList="searchDataList" :defaultSearchData="defaultSearchData"></searchList>
          <div class="btn-export" @click="handleExport">
            <en-icon name="bus-btn5_1" size="small" style="color:#26c393"></en-icon>
            <div class="label">导出</div>
          </div>
          <div class="btn-print" @click="printTable('print-template-assist-detail')">
            <en-icon name="bus-btn6_1" size="small" style="color:#3e90fe"></en-icon>
            <div class="label">打印</div>
          </div>
        </div>
      </en-title-card>
      <en-tabs v-model="activeTab" :list="tabList" type="white" :fixedWidth="120" @change="changeTabs">
        <div slot="right">
          <en-select-data v-model="assistValue" :config="assistConfig" ref="clearValue"></en-select-data>
          <subjectSel :subjectList="subjectList" :defaultId="defaultSubjectId" @subjectVal="subjectValue"></subjectSel>
        </div>
      </en-tabs>
      <!--表格部分-->
      <div class="table-main" v-en-loading="enLoading">
        <en-table ref="dataTable" :data="list" height="100%" rowId="onlyId" @row-click="handleRowClick">
          <en-table-column type="index" width="60" align="center"></en-table-column>
          <en-table-column title="日期" prop="produceDate" header-align="center" width="90"></en-table-column>
          <en-table-column title="凭证号" prop="voucherNo" header-align="center" width="80"></en-table-column>
          <en-table-column title="辅助核算" prop="dataName" header-align="center"></en-table-column>
          <en-table-column title="摘要" prop="remark" header-align="center" width="280"></en-table-column>
          <en-table-column title="科目" field="subjectName" header-align="center" width="250">
            <template v-slot="{ row }">
              {{ `${row.subjectCode||""} ${row.subjectName||""}` }}
            </template>
          </en-table-column>
          <en-table-column title="借方金额" prop="bor" header-align="center" align="right">
            <template slot-scope="scope">
              {{ scope.row.bor | thousand(2) }}
            </template>
          </en-table-column>
          <en-table-column title="贷方金额" prop="loan" header-align="center" align="right">
            <template slot-scope="scope">
              {{ scope.row.loan | thousand(2) }}
            </template>
          </en-table-column>
          <en-table-column title="方向" prop="direction" align="center" width="50"></en-table-column>
          <en-table-column title="余额" prop="balance" header-align="center" align="right">
            <template slot-scope="scope">
              {{ scope.row.balance | thousand(2) }}
            </template>
          </en-table-column>
        </en-table>
      </div>
    </div>
    <!-- '会计期间' -->
    <dateInterval :type="1" :hideHead="false" :list="dateLineList" @collapseBtn="isActive = !isActive" @handleAccChange="accChange" class="date-interval"></dateInterval>
     <!-- 打印模板 -->
    <print-template-assist-detail :dataList="list" :search-data="printSearchData"></print-template-assist-detail>
    <!-- 凭证详情 -->
    <vocDetailAlert
      ref="vocDialog"
      :voucherId="voucherId"
      :actionType="2"
      :formal="{}"
      openFlag="quotes"
    ></vocDetailAlert>
  </div>
</template>

<script>
import { request } from "en-js";
import { Message } from "element-ui";
import { commonService, balanceService } from "@/api/intelligentAccounting";
import accountBookComm from "@/mixins/intelligentAccounting/accountBookComm.js"; // 公用的JS方法
import enSelectData from "@/components/en-select-data";
import searchList from "../../components/searchList"; // 顶部搜索
import dateInterval from "../../components/dateInterval"; // 会计期间控件
import subjectSel from "../../components/subjectSel"; // 科目
import PrintTemplateAssistDetail from "./components/PrintTemplateAssistDetail"; // 打印模板
import vocDetailAlert from "../../components/vocDetailAlert";

export default {
  name: "AssistBalanceList",
  components: {
    searchList, dateInterval, subjectSel, enSelectData, PrintTemplateAssistDetail, vocDetailAlert
  },
  mixins: [accountBookComm],
  data() {
    return {
      enLoading: null,
      isActive: false,
      searchDataList: {}, // 搜索的下拉数据
      list: [],
      tabList: [],
      activeTab: undefined,
      dateLineList: [], // 会计期间数据
      defaultYearNum: "", // 会计区间选中年份
      accountId: "", // 所属账套id
      accountMainId: [], // 记账主体
      // 凭证范围下拉数据
      vocrangeList: [
        {
          id: 1,
          name: "全部凭证"
        },
        {
          id: 2,
          name: "仅已审核"
        }
      ],
      accountList: [], // 账套下拉数据
      accountMainList: [], // 记账主体下拉数据
      accountDefault: {}, // 默认账套
      // 默认范围
      vocrangeDefault: 1,
      subjectList: [{ value: "0", text: "全部" }],
      accountMainDefault: [], // 默认记账主体
      defaultSearchData: {}, // 搜索默认数据
      accountingPeriod: [], // 会计期间的id
      accountingPeriodName: [],
      subjectCode: "",
      assistTreeList: [], // 辅助核算选择数据
      assistValue: {}, // 辅助核算内容
      assistConfig: {
        isMulti: false, // 是否多选
        lazy: true, // 获取静态数据  请设置false
        selectMode: "tree",
        placeholder: "请选择辅助核算",
        defaultProps: {
          value: "id",
          label: "name",
          isLeaf: (data) => data.hasChildren === "001"
        },
        dependData: {}
      },
      endDate: "",
      startDate: "",
      defaultSubjectId: "", // 默认选中的末级科目
      voucherId: "" // 穿透凭证id
    };
  },
  computed: {
    printSearchData() {
      let unit = "";
      const findNode = (list, id) => {
        if (!id) return;
        list.forEach((item) => {
          if (item.id === id) {
            unit = item.code + item.text;
          } else if (item.nodes) {
            findNode(item.nodes, id);
          }
        });
      };
      const title = `${this.tabList.find((item) => item.id === this.activeTab)?.name + (this.assistValue?.name || "")}明细账`;
      findNode(this.accountMainList, this.accountMainDefault[0]);
      const { startDate, endDate } = this;
      const period = startDate && endDate && startDate !== endDate ? `${startDate}-${endDate}` : endDate || startDate;
      return {
        unit,
        title,
        period
      };
    }
  },
  watch: {
    assistValue() {
      this.queryAbAssitAccDetail();
    },
    isActive() {
      this.$refs.dataTable.resetResizable();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * @description: 初始化
     */
    async init() {
      await this.queryAccList(); // 请求账套
      await this.queryAccTime(); // 会计期间
      await this.queryAccountEntity(); // 请求记账主体
      await this.queryAllAssit(); // 辅助核算项
      await this.querySubject(); // 末级科目
      await this.queryAbAssitAccDetail(); // 表格数据
    },
    // 请求日期区间数据接口
    async queryAccTime() {
      const res = await commonService.queryAccTime({
        accountId: this.accountDefault,
        hasChildren: "000"
      });
      this.dateLineList = res;
      this.accPeriod();
    },
    /**
     * @deprecate 查询会计期间默认项
     */
    accPeriod() {
      // 默认会计区间获取会计区间选中ID
      function findDefaultPeriod(arr) {
        const nodesCur = [];
        const nodesCurName = [];
        const yearNum = [];
        const dateNameList = [];
        arr.forEach((v) => {
          if (v.isDefault === 0) {
            nodesCur.push(v.id);
            nodesCurName.push(v.name);
            yearNum.push(v.yearNum);
            dateNameList.push(v.name);
          }
        });
        return {
          nodesCur, nodesCurName, yearNum, dateNameList
        };
      }
      const _this = this;
      this.dateLineList.forEach((item) => {
        if (item.isDefault === 0) {
          if (item.nodes) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            _this.accountingPeriod = findDefaultData.nodesCur;
            _this.accountingPeriodName = findDefaultData.nodesCurName;
            _this.defaultYearNum = findDefaultData.yearNum.toString();
            _this.startDate = findDefaultData.dateNameList[0];
            _this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          }
        }
      });
    },
    /**
     * @description: 查询辅助核算项 tab数据
     */
    async queryAllAssit() {
      const res = await commonService.querySubjectAllAssit({
        accountId: this.accountDefault,
        yearNum: this.defaultYearNum
      });
      this.tabList = res;
      // 如果选过辅助核算则保留
      if (this.activeTab) {
        const as = res.filter((itm) => itm.id === this.activeTab)[0];
        if (!as) {
          this.activeTab = res[0].id;
        }
      } else {
        this.activeTab = res[0].id;
      }

      const type = res[0].type;
      const url = type === "002" ? "getDeptUserTree" : "queryObjectTreeExt";
      const parmas = type === "002" ? { id: 0 } : {
        objectTypeId: res.id,
        objectType: type,
        dataSource: 1
      };
      this.assistConfig.dependData = {
        requestService: commonService, // 引入服务名称
        requestFn: url, // 服务中方法
        requestParams: parmas // 请求参数
      };
      if (type === "002") {
        this.$set(this.assistConfig.defaultProps, "disabled", (data) => data.type !== "003");
      } else {
        this.$set(this.assistConfig.defaultProps, "disabled", null);
      }
    },
    // 请求科目
    async querySubject() {
      const res = await commonService.queryAllSubject({
        accountId: this.accountDefault,
        yearNum: this.defaultYearNum,
        assitId: this.activeTab
      });
      this.subjectList = res;
      if (res && res.length) {
        this.defaultSubjectId = res[0].id;
        this.subjectCode = res[0].code;
      }
      this.subjectList.unshift({ id: "0", name: "全部", code: "" });
    },
    // 请求辅助核算明细表数据
    @request(true, "enLoading")
    async queryAbAssitAccDetail() {
      const pa = this.getCommParam();
      const res = await balanceService.queryAbAssitAccDetail(pa);
      res.dataList.forEach((itm, idx) => { itm.onlyId = `${itm.dataId || idx}-${idx}`; });
      res.dataList.forEach((itm) => {
        itm.vocId = itm.id;
      });
      this.list = res.dataList || [];
    },
    /**
     * @description: 取搜索组件带出数据
     */
    async getVal(data) {
      const originAccount = this.accountDefault;
      if (data.accountMain.length === 0) {
        Message("请选择记账主体");
        return;
      }

      this.accountDefault = data.account;
      this.accountMainDefault = data.accountMain;
      this.vocrangeDefault = data.vocrange;
      if (data.account !== originAccount) {
        // 账套发生改变 重新查询会计期间  辅助核算项
        await this.queryAccTime();
        await this.queryAllAssit();
        await this.querySubject();
      }
      this.queryAbAssitAccDetail();
    },
    /**
     * tab切换
     */
    async changeTabs(res) {
      this.activeTab = res.id;
      this.$refs.clearValue.clearMultiSelect();
      const type = res.type;
      const url = type === "002" ? "getDeptuserTree" : "queryObjectTreeExt";
      const parmas = type === "002" ? { id: 0 } : {
        objectTypeId: res.id,
        objectType: type,
        dataSource: 1
      };
      this.assistValue.id = null;
      this.assistConfig.dependData = {
        requestService: commonService, // 引入服务名称
        requestFn: url, // 服务中方法
        requestParams: parmas
      };
      if (type === "002") {
        this.$set(this.assistConfig.defaultProps, "isLeaf", (data) => data.hasChildren === "000");
        this.$set(this.assistConfig.defaultProps, "disabled", (data) => data.type !== "003");
      } else {
        this.$set(this.assistConfig.defaultProps, "disabled", null);
      }
      await this.querySubject();
      this.queryAbAssitAccDetail();
    },
    /**
     * @description: 日期区间选中数据
     */
    async accChange(data) {
      this.accountingPeriod = data.ids;
      this.accountingPeriodName = data.names;
      this.defaultYearNum = data.yearNum;
      let dateNames = [];
      dateNames = data.names.split(",");
      this.startDate = dateNames[0];
      this.endDate = dateNames[dateNames.length - 1];
      await this.queryAllAssit();
      await this.querySubject();
      this.queryAbAssitAccDetail();
    },
    /**
     * @description: 取科目下拉选中数据
     */
    subjectValue(param) {
      this.subjectCode = param.code;
      if (this.subjectCode) {
        this.queryAbAssitAccDetail();
      }
    },
    /**
     * @description: 获取公共传参
     */
    getCommParam() {
      const _this = this;
      const params = {
        accountId: this.accountDefault,
        accountName: (() => {
          const o = this.accountList.filter((itm) => itm.id === _this.accountDefault);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        accountingEntity: this.accountMainDefault.toString(),
        accountingEntityName: (() => {
          const re = [];
          _this.accountMainDefault.forEach((itm) => {
            _this.accountMainList.forEach((itm2) => {
              if (itm2.id === itm) {
                re.push(`${itm2.code} ${itm2.name}`);
              }
              itm2.nodes?.forEach((itm3) => {
                if (itm3.id === itm) {
                  re.push(`${itm3.code} ${itm3.name}`);
                }
              });
            });
          });
          return re.join(",");
        })(),
        status: this.vocrangeDefault,
        accountingPeriod: this.accountingPeriod.toString(),
        accountingPeriodName: this.accountingPeriodName.toString(),
        yearNum: this.defaultYearNum,
        subjectCode: this.subjectCode,
        assitAccId: this.activeTab,
        assitAccName: (() => {
          const o = this.tabList.filter((itm) => itm.id === _this.activeTab);
          return o.length ? o[0].name : "";
        })(),
        dataId: this.assistValue.id || "",
        dataName: this.assistValue.name || ""
      };

      return params;
    },
    /**
     * @description: 导出
     */
    handleExport() {
      const pa = this.getCommParam();
      if (!pa.accountingEntity) {
        Message("请选择记账主体");
      }

      const params = { typeId: "fzmx", ...pa };
      // 传辅助核算+辅助核算子项
      params.subjectName = params.dataId ? `${params.assitAccName}-${params.dataName}` : params.assitAccName;
      this.handleExportExcel(params);
    },
    /**
     * @description: 行点击
     */
    handleRowClick({ row }) {
      const { vocId } = row;
      if (vocId) {
        this.voucherId = vocId;
        this.$refs.vocDialog.visible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.assistSpecofocationList {
  position: relative;
  background-color: #1d2532;
  .en-title-card {
    overflow: hidden;
  }
  .choice-subject {
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  /deep/ .en-tabs-item {
    justify-content: center;
    font-weight: 600;
  }
  .btn-export,
  .btn-print {
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      color: #636c78;
    }
  }
  .btn-print {
    margin-right: 0;
  }
  .table-main {
    height: calc(100% - 100px);
  }
  /deep/ .en-tabs .en-tabs-list .en-tabs-item {
    width: 120px;
    text-align: center;
  }
}
.left-container {
  height: 100%;
  width: calc(100% - 72px);
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.expand {
    width: 100%;
  }
}
.model-select{
  display: inline-block;
  vertical-align: middle;
}
.date-interval {
  position: absolute;
  right: 4px;
  top: 0;
}
</style>
