<!--
 * @Author: zoujp
 * @Date: 2020-06-28 14:36:36
 * @LastEditTime: 2021-07-21 14:56:47
 * @LastEditors: Please set LastEditors
 * @Description: 结账/财务报表入口-顶部bar
 * @FilePath: \user\src\views\intelligentAccounting\components\topBar\index.vue
-->
<template>
  <div class="top-bar">
    <div class="title-sel" v-show="isShowBar">
        <div style="padding-left:10px;">账套名称</div>
        <en-select
            v-model="value.accountId"
            :data="accAry"
            :props="{ value: 'id', label: 'name'}"
            style="width:200px;"
            @change="selectChange"
        ></en-select>
        <div>记账主体</div>
        <en-select
          id="selentity"
          mode="tree"
          v-model="value.accountingEntity"
          placeholder="请选择"
          :data="entityAry"
          :props="{ label: 'text', value: 'id', children: 'nodes' }"
          filterable
          show-assist
          style="width:200px;"
          @change="selectChange"
        >
        </en-select>
        <en-button @click="search">查询</en-button>
    </div>
    <div class="title-btn">
        <div class="search-wrap" @click="showSearchBar">
            <en-icon
                name="sousuo-danchuang"
                size="small"
                color="rgb(169, 181, 198)"
            ></en-icon>
        </div>
        <div class=search-split v-show="isShowRecord">
            <div class="split"></div>
        </div>
        <en-button icon="rizhi" v-show="isShowRecord"
            class="record-wrap"
            text
            icon-color="#636C78"
            color="#636C78"
            @click="seeRecord"
        >
            日志
        </en-button>
    </div>
</div>
</template>

<script>
export default {
  name: "TopBar",
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    entityList: { // 记账主体
      type: Array,
      default() {
        return [];
      }
    },
    accList: { // 账套列表
      type: Array,
      default() {
        return [];
      }
    },
    showRecord: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route(to) {
      console.log(to);
    },
    accList: {
      immediate: true,
      handler(nVal) {
        this.accAry = nVal;
      }
    },
    entityList: {
      immediate: true,
      handler(nVal) {
        this.entityAry = JSON.parse(JSON.stringify(nVal));
        console.log(nVal);
      }
    },
    params: {
      immediate: true,
      handler(nVal) {
        this.value = nVal;
      }
    }
  },
  data() {
    return {
      isShowBar: true, // 是否显示搜索栏
      value: this.params, // 搜索数据集合
      entityAry: JSON.parse(JSON.stringify(this.entityList)), // 记账主体
      accAry: this.accList, // 账套集合
      isShowRecord: this.showRecord // 是否显示查看日志按钮
    };
  },
  methods: {
    /**
   * @description: 账套 记账主体改变
   * @param {type}
   */
    selectChange() {
      this.$emit("change-param", this.params);
    },
    /**
     * @description: 顶部搜索
     */
    search() {
      this.$emit("search-call-back", this.value);
    },
    /**
     * @description: 隐藏显示搜索栏，默认显示
     */
    showSearchBar() {
      this.isShowBar = !this.isShowBar;
    },
    /**
     * @description: 查看日志
     */
    seeRecord() {
      this.$emit("pop-record");
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar{
  display: flex;
  .title-sel{
    width: 650px;
    background-color:rgba(236,241,247,1);
    box-shadow:0px 3px 3px 0 rgba(236,236,236,0.75);
    border-radius:4px;
    color: #333333;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    & /deep/ .en-tree-select{
      .inline{
        font-size: 12px !important;
      }
    }
  }
  .title-btn{
    display: flex;
    justify-content: space-around;
    justify-items: center;
    .search-wrap{
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .search-split{
        width: 3px;
        display: flex;
        align-items: center;
        .split{
            width: 1px;
            height: 20px;
            line-height: 20px;
            background-color: #E8ECF2;
        }
    }
    .record-wrap{
        width:70px;
    }
  }
}
</style>
<style lang="scss">
  #selentity{
    & /deep/ .el-tree-node__label{
      font-size: 12px !important;
    }
  }
</style>
