var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "unusual-voucher",
      attrs: {
        visible: _vm.visible,
        title: "凭证列表",
        width: "1100px",
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "en-table",
            {
              attrs: {
                data: _vm.tableData,
                height: "500px",
                loading: _vm.loading,
                "page-config": {
                  pageModel: _vm.pageModel,
                  changeMethod: _vm.handlePageChanged,
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "hoverRow",
                  fn: function ({ row }) {
                    return [
                      _c("en-icon", {
                        attrs: {
                          name: "bianji-liebiao",
                          size: "small",
                          color: "#27C393",
                          title: "编辑",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.editVoucher(row)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [
              _c("en-table-column", {
                attrs: { type: "index", width: "60", align: "center" },
              }),
              _c("en-table-column", {
                attrs: { prop: "refCode", label: "凭证来源", width: "200" },
              }),
              _c("en-table-column", {
                attrs: {
                  prop: "totalD",
                  label: "借方合计",
                  "header-align": "left",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("$thousand")(row.totalD)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: {
                  prop: "totalC",
                  label: "贷方合计",
                  "header-align": "left",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("$thousand")(row.totalC)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "produceDate", label: "记账日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(row.produceDate.substr(0, 10)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "status", label: "凭证状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("statusModel", { attrs: { status: row.status } }),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "remark", label: "备注" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }