<!--
 * @Author: zoujp
 * @Date: 2020-07-01 18:13:06
 * @LastEditTime: 2021-05-21 15:08:29
 * @LastEditors: Please set LastEditors
 * @Description: 财务报表-过滤-单选字段
 * @FilePath: \user\src\views\intelligentAccounting\financialReport\filterSelect.vue
-->
<template>
  <div class="field-rows">
    <div class="row-name"><span :title="curCon.name">{{curCon.name }}</span></div>
    <en-select
        v-model="showValue"
        :data="subData"
        filterable
        multiple="true"
        data-mode="data"
        :props="{ value: 'id', label: 'text'}"
        style="width:100%;"
        @change="selectChange"
    >
    </en-select>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "FilterSelect",
  props: {
    con: {
      type: Object,
      default() {
        return {};
      }
    },
    subjectData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      curCon: this.con,
      showValue: [],
      subData: this.getSubData()
    };
  },
  watch: {
    con() {
      this.curCon = this.con;
    },
    subjectData() {
      this.subData = this.getSubData();
    }
  },

  methods: {
    getSubData() {
      return cloneDeep(this.subjectData);
    },
    /**
     * @description: 选中回调方法
     */
    selectChange(res) {
      const objInfo = cloneDeep(this.curCon);
      let value = []; let valueText = [];
      res?.forEach((b) => {
        value.push(b.id);
        valueText.push(b.text);
      });
      value = value.join(",");
      valueText = valueText.join(",");
      if (value) {
        objInfo.value = value;
        objInfo.valueText = valueText;
        objInfo.showValue = valueText;
      } else {
        objInfo.value = objInfo.initValue;
        objInfo.valueText = objInfo.initValueText;
        objInfo.showValue = objInfo.initValueText;
      }
      this.$emit("changeSelectData", objInfo);
    }
  }
};
</script>

<style lang="scss" scoped>
.field-rows {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 12px;
  color: #333333;
  margin-bottom: 10px;
  .row-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
    max-width: 100px;
    text-align: right;
    margin-right: 20px;
  }
}
</style>
