<!--
 * @Author: pengyu
 * @Date: 2020-11-20 09:47:15
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-17 15:11:39
 * @Description: 凭证打印模板
-->
<template>
  <div class="print-wrap" :class="wrapClass" id="print-wrap">
      <div class="voucher-user-mod"
      v-for="(item, index) in handledList"
      :key="'voucher' + index"
      :ref="'voucher' + index"
      :class="{otherPage:item.businessData&&printBussiness===2}"
      >
        <div class="col-tits">记账凭证</div>
        <div class="col-infos">
          <div class="item">凭证号: {{ item | fullVoucherNo }}</div>
          <div class="item">核算单位: {{item.accountingEntityName}}</div>
          <div class="item">记账日期: {{item.createDate | formatDate}}</div>
        </div>
        <div class="uigrid-table">
          <table cellspacing="0" cellpadding="0" border="0">
            <thead>
              <tr>
                <th colspan="1" rowspan="1">摘要</th>
                <th colspan="1" rowspan="1">科目</th>
                <th colspan="1" rowspan="1" width="15%">借方金额</th>
                <th colspan="1" rowspan="1" width="15%">贷方金额</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(a, b) in item.itemList" :key="b">
                <td colspan="1" rowspan="1">{{ getRemark(a) }}</td>
                <td colspan="1" rowspan="1">{{a | getSubject}}</td>
                <td colspan="1" rowspan="1" style="text-align: right;" width="15%">{{ a.amountD ? (Number(a.amountD)).toFixed(2) : ""}}</td>
                <td colspan="1" rowspan="1" style="text-align: right;" width="15%">{{ a.amountC ? (Number(a.amountC)).toFixed(2) : ""}}</td>
              </tr>
              <tr>
                <td colspan="2" rowspan="1">总计：{{index === handledList.length - 1 ? covertCurrency(item.totalD) : ""}}</td>
                <td colspan="1" rowspan="1" style="text-align: right;" width="15%">{{item.totalD ? Number(item.totalD).toFixed(2) : ""}}</td>
                <td colspan="1" rowspan="1" style="text-align: right;" width="15%">{{item.totalC ?  Number(item.totalC).toFixed(2) : ""}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-infos">
          <div class="item" v-if="isMakeName===0">制单人: {{item.makeName}}</div>
          <div class="item" v-if="isApproveName===0">审核人: {{item.approveName}}</div>
          <div class="item">附单据: {{item.attachmentNum}}张</div>
        </div>
      </div>
      <div
      v-show="item.businessData"
      v-for="(item, index) in handledList"
      :key="index"
      :ref="'bussiness' + index"
      style="page-break-after:always"
      >
        <template-html
        :renderInfo="item.businessData"
        ref="printTemlate"
        :businessData='item.businessData'
        :checkTempalte='item.checkTempalte'
        :detailId='item.refId'
        >
        </template-html>
      </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import { currency } from "en-js";
import _ from "lodash";
import printJs from "print-js";
import templateHtml from "@/components/en-print/templateHtml/temporary";

export default {
  name: "voucherPrint",
  components: { templateHtml },
  computed: {
    handledList() {
      const _this = this;
      const arr = [];
      this.voucherList.forEach((item) => {
        if (item.businessData && this.printBussiness === 2) {
          this.$nextTick(() => {
            this.$refs.printTemlate.forEach((item) => {
              item.print();
            });
            this.sort();
          });
        }
        if (item.itemList && item.itemList.length > 5) {
          const res = _this.splitItemList(item);
          arr.push(...res);
        } else {
          item.totalPage = 1;
          item.page = 1;
          item.totalC = item.itemList.reduce((prev, cur) => prev + Number(cur.amountC), 0);
          item.totalD = item.itemList.reduce((prev, cur) => prev + Number(cur.amountD), 0);
          for (let i = item.itemList.length; i < 5; i++) {
            item.itemList.push({});
          }
          arr.push(item);
        }
      });
      return arr;
    },
    wrapClass() {
      const lt = this.layout;
      switch (lt) {
        case 1:
          return "one-print-voucher";
        case 2:
          return "two-print-voucher";
        case 3:
          return "three-print-voucher";
        default:
          return "two-print-voucher";
      }
    }
  },
  filters: {
    // 凭证字号拼接
    fullVoucherNo(item) {
      return `${`${item.voucherDataTypeName || ""}-${item.voucherNo.padStart(4, "0")}`}-${item.page}/${item.totalPage}`;
    },
    // 日期格式化
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    // 科目拼接
    getSubject(item) {
      if (!item || Object.keys(item).length === 0) {
        return "";
      }
      let txt = `${item.subjectCode || ""} ${(item.subjectAllName || "").replace(",", "_")}`;
      if (item.fzhs && item.fzhs.length) {
        let fz = "";
        item.fzhs.forEach((mod) => {
          fz += mod.valueName;
        });
        txt += (` | ${fz}`);
      }

      return txt.length > 40 ? `${txt.subStr(0, 40)}...` : txt;
    }
  },
  data() {
    return {
      voucherList: [], // 分录集合
      isMakeName: 0, // 打印制单人
      isApproveName: 0, // 打印审批人
      layout: 1 // 版面  1发票版 2A4两版  3A4三版
    };
  },
  mounted() {
    this.$eventHub.$on("beginPrint", this.beginPrint);
  },
  beforeDestroy() {
    this.$eventHub.$off("beginPrint");
  },
  methods: {
    // 打印页 排序
    sort() {
      const This = this;
      for (let index = 0; index < this.handledList.length; index++) {
        This.insertAfter(This.$refs[`bussiness${index}`][0], This.$refs[`voucher${index}`][0]);
      }
    },
    // newElement是要追加的元素 targetElement 是指定元素的位置
    insertAfter(newElement, targetElement) {
      // 找到指定元素的父节点
      const parent = targetElement.parentNode;
      // 判断指定元素的是否是节点中的最后一个位置 如果是的话就直接使用appendChild方法
      if (parent.lastChild === targetElement) {
        parent.appendChild(newElement, targetElement);
      } else {
        parent.insertBefore(newElement, targetElement.nextSibling);
      }
    },
    /**
     * @description: 拆分分录  当分录行大于 5个时 打印显示块 >=2
     * @param {*} item
     * @return {*}
     */
    splitItemList(item) {
      const res = [];
      const { itemList } = item;
      const len = itemList.length;
      const times = Math.ceil(len / 5);
      item.totalPage = times;
      for (let i = 0; i < times; i++) {
        const citem = _.cloneDeep(item);
        citem.page = i + 1;
        citem.itemList = citem.itemList.splice(i * 5, 5);
        citem.totalC = citem.itemList.reduce((prev, cur) => prev + Number(cur.amountC), 0);
        citem.totalD = citem.itemList.reduce((prev, cur) => prev + Number(cur.amountD), 0);
        for (let i = citem.itemList.length; i < 5; i++) {
          citem.itemList.push({});
        }
        res.push(citem);
      }

      res[times - 1].totalC = res.reduce((pre, cur) => pre + cur.totalC, 0);
      res[times - 1].totalD = res.reduce((pre, cur) => pre + cur.totalD, 0);

      return res;
    },
    /**
     * @description: 摘要拼接
     */
    getRemark(item) {
      const _this = this;
      let txt = item.remark || "";
      const {
        itemType, quantityAccD, quantityAccC, quantityAccunitName, foroCurrSysName, foroCurrSys, price, foroCurrAmountD, foroCurrAmountC, rate
      } = item;
      const wbje = itemType === 0 ? foroCurrAmountD : foroCurrAmountC; // 外币金额
      const quantity = itemType === 0 ? quantityAccD : quantityAccC;
      if (quantity && !foroCurrSys) { // 数量核算
        txt += `（数量:${quantity}${quantityAccunitName || ""},单价: ${_this.numToFixed(price, 4)}）`;
      } else if (foroCurrSys && !quantity) { // 外币核算
        txt += `（${foroCurrSysName}：${_this.numToFixed(wbje, 6)}，汇率：${_this.numToFixed(rate, 6)}）`;
      } else if (foroCurrSys && quantity) { // 都有
        txt += `（数量：${quantity}${quantityAccunitName || ""}，单价：${_this.numToFixed(price, 4)}，${foroCurrSysName}：${_this.numToFixed(wbje, 6)}，汇率：${_this.numToFixed(rate, 6)}）`;
      }
      return txt;
    },
    /**
     * @description: 返回指定位数的数值
     * @param {*} num
     * @param {*} w
     * @return {*}
     */
    numToFixed(num, w) {
      const n = Number(num).toFixed(w);
      return parseFloat(n);
    },
    /**
     * @description: 将数字金额转换成大写金额
     * @param {*} num
     * @return {*}
     */
    covertCurrency(num) {
      return currency(num);
    },
    /**
     * @description: 开始打印
     * @param {*}
     * @return {*}
     */
    beginPrint(obj) {
      this.voucherList = obj.voucherList;
      this.isMakeName = obj.isMakeName;
      this.isApproveName = obj.isApproveName;
      this.layout = obj.layout;
      this.printBussiness = obj.printBussiness;
      const _this = this;
      setTimeout(() => {
        _this.print();
      }, 1000);
    },
    /**
     * @description: 打印
     */
    print() {
      // 发票版的 时候 横屏打印
      // return;
      /* eslint-disable */
      const style = this.layout === 1 ? "@media print{@page {size:landscape}}" : "@media print{@page {A4 portrait}}";
      printJs({
        printable: "print-wrap",
        type: "html",
        style,
        // css: "../../../css/voucher-print.css",
        // header: "打印测试",
        // documentTitle: "水印|保存名称",
        maxWidth: "100%",
        scanStyles: true,
        // honorColor: true, // 彩打
        targetStyles: ["*"],
        onPrintDialogClose: (err) => {
          console.log("取消打印", err);
        },
        error: (err) => {
          console.log("打印errrrr", err);
        },
        onLoadingStart: () => {
          console.log("onLoadingStart");
        },
        onLoadingEnd: () => {
          console.log("onLoadingStart");
        }
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.otherPage{
  page-break-after:always
}
.print-wrap{
  background: #fff;
  z-index: 9999;
  .col-tits{
    text-align:center;
    font-weight:bold;
    line-height:20px;
    color: #000;
  }
  .col-infos{
    display: flex;
    font-size:12px;
    line-height:22px;
    padding-bottom:10px;
    color: #000;
    .item{
      flex: 1;
    }
  }
  .uigrid-table{
    margin-bottom: 10px;
    table{
      border-collapse:collapse;
      width:100%;
      table-layout:fixed;
      color:#606060;
      background-color:#fff;
      font-size:12px;
    }
    th{
      text-align:left;
      padding: 10px;
      line-height:20px;
      border:1px solid #111;
      color:#000;
      border-bottom: 0;
      border-right: 0;
      &:last-child{
        border-right: 1px solid #111;
      }
    }
    td{
      text-align:left;
      padding: 10px;
      line-height:20px;
      border:1px solid #111;
      height: 50px;
      color:#000;
      border-bottom: 0;
      border-right: 0;
      &:last-child{
        border-right: 1px solid #111;
      }
    }
    tr:last-child{
      td{
        border-bottom: 1px solid #111;
      }
    }
  }
  .print-html{
    width:100%;
  }
  .voucher-user-mod{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &.one-print-voucher{
    width: 1020px;
    .voucher-user-mod{
      padding-top: 100px;
      page-break-after: always;
    }
    .col-infos{
      padding-top: 20px;
    }
  }
  &.two-print-voucher{
    width: 780px;
    .voucher-user-mod{
      padding-top: 30px;
      padding-bottom: 40px;
      border-bottom: 1px solid #e8e8ee;
      &:nth-child(2n){
        border: 0;
        margin-top: 40px;
        page-break-after: always;
      }
    }
    td{
      padding: 2px 10px;
      height: 50px;
      line-height: 18px;
      overflow: hidden;
      text-overflow:ellipsis;
    }
  }
  &.three-print-voucher{
    width: 780px;
    .voucher-user-mod{
      border-bottom: 1px dashed #e8e8e8;
      &:nth-child(2n+2){
        margin-top: 10px;
      }
      &:nth-child(3n+3){
        border: 0;
        margin-top: 10px;
        page-break-after: always;
      }
    }
    th{
      padding: 5px;
    }
    td{
      padding: 2px 5px;
      height: 40px;
      line-height: 18px;
      overflow: hidden;
      text-overflow:ellipsis;
    }
  }
}

</style>
