<!--
 * @Author: zoujp
 * @Date: 2020-06-24 11:22:39
 * @LastEditTime: 2021-07-21 15:21:51
 * @LastEditors: Please set LastEditors
 * @Description: 结账--操作日志
 * @FilePath: \user-intelligent-accounting\src\components\logAlert\index.vue
 -->
<template>
  <en-dialog
    :visible.sync="visiable"
    title="结账日志"
    class="record-alert"
    width="900px"
    :stripe="false"
    :close-on-click-modal="false"
    @close="visiable = false"
  >
    <en-table :data="tableData" v-en-loading="loading" :page-config="{ pageModel, changeMethod: handlePageChanged }">
      <en-table-column type="index" width="50"></en-table-column>
      <en-table-column prop="accountingPeriodName" label="结账期间" width="180">
      </en-table-column>
      <en-table-column prop="operateTypeName" label="操作类型">
      </en-table-column>
      <en-table-column prop="operateStatusName" label="操作结果">
        <template v-slot="{ row, column }">
          <el-tag type="success" v-if="row[column.field] === '成功'">
            {{ row[column.field] }}
          </el-tag>
          <el-tag type="danger" v-else>
            {{ row[column.field] }}
          </el-tag>
        </template>
      </en-table-column>
      <en-table-column prop="operateRemark" label="备注"> </en-table-column>
      <en-table-column prop="operatorName" label="操作人"> </en-table-column>
      <en-table-column prop="operateTime" label="操作时间"> </en-table-column>
    </en-table>
    <div slot="footer">
    </div>
  </en-dialog>
</template>

<script>
import { settleAccounts as SA } from "@/api";

export default {
  name: "Record",
  props: {
    accountId: {
      type: String,
      default: ""
    },
    accountingEntity: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visiable: false,
      loading: false,
      tableData: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0
      }
    };
  },
  watch: {
    visiable(newval) {
      if (newval) {
        this.queryLogList();
      }
    }
  },
  methods: {
    /**
     * @description: 查询列表数据
     * @param {type}
     */
    async queryLogList() {
      try {
        this.loading = true;
        const p = {
          accountId: this.accountId,
          accountingEntity: this.accountingEntity,
          ...this.pageModel
        };
        const rsp = await SA.queryVcCloseLogList(p);
        this.loading = false;
        this.tableData = rsp.records;
        this.pageModel.total = rsp.total;
        this.pageModel.totalPages = rsp.totalPages;
      } catch (error) {
        this.loading = false;
      }
    },
    /**
     * @description: 分页器 事件
     */
    handlePageChanged(rsp) {
      this.pageModel.pageNo = rsp.pageNo;
      this.pageModel.pageSize = rsp.pageSize;
      this.pageModel.total = rsp.total;
      this.pageModel.totalPages = rsp.totalPages;
      this.queryLogList();
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog .el-dialog__body {
    padding: 0 0;
}
/deep/ .el-dialog .el-dialog__footer {
    padding: 0 0 0 20px;
}
.record-alert {
  & /deep/ .el-dialog__header {
    text-align: left;
  }
  & /deep/ .pagination {
    padding-right: 10px;
  }
}
</style>
