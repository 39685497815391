var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "range-input" },
    [
      _c("el-input", {
        staticClass: "s-input",
        attrs: { type: "text", placeholder: _vm.firstHolder },
        nativeOn: {
          input: function ($event) {
            return _vm.inputChange($event)
          },
        },
        model: {
          value: _vm.range[0],
          callback: function ($$v) {
            _vm.$set(_vm.range, 0, $$v)
          },
          expression: "range[0]",
        },
      }),
      _c("span", { staticClass: "sep" }, [_vm._v("——")]),
      _c("el-input", {
        staticClass: "e-input",
        attrs: { type: "text", placeholder: _vm.secondHolder },
        nativeOn: {
          input: function ($event) {
            return _vm.inputChange($event)
          },
        },
        model: {
          value: _vm.range[1],
          callback: function ($$v) {
            _vm.$set(_vm.range, 1, $$v)
          },
          expression: "range[1]",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }