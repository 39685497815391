var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "specification" },
    [
      _c(
        "div",
        {
          staticClass: "content",
          class: _vm.isActive ? "left-container expand" : "left-container",
        },
        [
          _c("en-title-card", { attrs: { name: "明细账" } }, [
            _c(
              "div",
              { attrs: { slot: "right" }, slot: "right" },
              [
                _c("searchList", {
                  attrs: {
                    searchDataList: _vm.searchDataList,
                    defaultSearchData: _vm.defaultSearchData,
                    isDisabled: _vm.disableSearch,
                  },
                  on: { clickData: _vm.getVal },
                }),
                _c(
                  "div",
                  {
                    staticClass: "btn-export",
                    on: { click: _vm.handleExport },
                  },
                  [
                    _c("en-icon", {
                      staticStyle: { color: "#26c393" },
                      attrs: { name: "bus-btn5_1", size: "small" },
                    }),
                    _c("div", { staticClass: "label" }, [_vm._v("导出")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn-print",
                    on: {
                      click: function ($event) {
                        return _vm.printTable("print-template-specification")
                      },
                    },
                  },
                  [
                    _c("en-icon", {
                      staticStyle: { color: "#3e90fe" },
                      attrs: { name: "bus-btn6_1", size: "small" },
                    }),
                    _c("div", { staticClass: "label" }, [_vm._v("打印")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "top-head" },
            [
              _c("subjectSel", {
                attrs: {
                  subjectList: _vm.subjectList,
                  defaultId: _vm.defaultSubId,
                },
                on: { subjectVal: _vm.subjectValue },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.enLoading,
                  expression: "enLoading",
                },
              ],
              staticClass: "table-main",
            },
            [
              _c(
                "en-table",
                {
                  ref: "dataTable",
                  attrs: { data: _vm.list, height: "100%" },
                  on: { "row-click": _vm.handleRowClick },
                },
                [
                  _c("en-table-column", {
                    attrs: { type: "index", width: "60", align: "center" },
                  }),
                  _c("en-table-column", {
                    attrs: {
                      title: "日期",
                      "header-align": "center",
                      prop: "produceDate",
                      width: "90",
                    },
                  }),
                  _c("en-table-column", {
                    attrs: {
                      title: "凭证号",
                      "header-align": "center",
                      prop: "voucherNo",
                      width: "80",
                    },
                  }),
                  _c("en-table-column", {
                    attrs: {
                      title: "摘要",
                      "header-align": "center",
                      prop: "remark",
                      width: "330",
                    },
                  }),
                  _c("en-table-column", {
                    attrs: {
                      title: "科目",
                      "header-align": "center",
                      prop: "subjectName",
                      width: "304",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${row.subjectCode} ${row.subjectName}`
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("en-table-column", {
                    attrs: {
                      title: "借方金额",
                      prop: "bor",
                      "header-align": "center",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm._f("thousand")(row.bor, 2)) + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("en-table-column", {
                    attrs: {
                      title: "贷方金额",
                      prop: "loan",
                      "header-align": "center",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("thousand")(row.loan, 2)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("en-table-column", {
                    attrs: {
                      title: "方向",
                      prop: "direction",
                      align: "center",
                      width: "50",
                    },
                  }),
                  _c("en-table-column", {
                    attrs: {
                      title: "余额",
                      prop: "balance",
                      "header-align": "center",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("thousand")(row.balance, 2)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("dateInterval", {
        ref: "dateInterval",
        staticClass: "date-interval",
        attrs: { type: 1, hideHead: false, list: _vm.dateLineList },
        on: {
          collapseBtn: function ($event) {
            _vm.isActive = !_vm.isActive
          },
          handleAccChange: _vm.accChange,
        },
      }),
      _c("print-template-specification", {
        attrs: { dataList: _vm.list, "search-data": _vm.printSearchData },
      }),
      _c("vocDetailAlert", {
        ref: "vocDialog",
        attrs: {
          voucherId: _vm.voucherId,
          actionType: 2,
          formal: {},
          openFlag: "quotes",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }