<template>
  <div class="print-template" id="print-template">
    <div class="title">余额表</div>
    <div class="table-info">
      <span>编制单位:{{searchData.unit}}</span> &nbsp; &nbsp;
      <span>会计期间:{{searchData.period}}</span> &nbsp; &nbsp;
      <span>单位:元</span>
    </div>
    <table cellspacing="0" cellpadding="0" border="0">
      <thead v-html="theadHtml"></thead>
      <tbody>
        <tr v-for="(rowData, index) in dataList" :key="index">
          <td colspan="1" rowspan="1">{{rowData.text}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.balanceBor|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.balanceLoan|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.curBor|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.curLoan|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right" v-if="isYear === 0">{{rowData.yearBor|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right" v-if="isYear === 0">{{rowData.yearLoan|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.endBor|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.endLoan|thousand(2)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { thousand } from "en-js";
// 余额表主表
export default {
  name: "PrintTemplate",
  components: {
  },
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  props: {
    isYear: {
      type: [Number, Boolean]
    },
    dataList: {
      type: Array,
      default() {
        return [];
      }
    },
    searchData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
    };
  },
  computed: {
    theadHtml() {
      const that = this;
      return `${"<tr>"
        + "<th colspan=\"1\" rowspan=\"2\" style=\"text-align: center;\">"
            + "科目"
        + "</th>"
        + "<th colspan=\"2\" rowspan=\"1\" style=\"text-align: center;\">"
            + "期初余额"
        + "</th>"
        + "<th colspan=\"2\" rowspan=\"1\" style=\"text-align: center;\">"
            + "本期发生"
        + "</th>"}${
        // eslint-disable-next-line func-names
        (function() {
          if (that.isYear === 0) { // 本年累计
            return "<th colspan=\"2\" rowspan=\"1\" style=\"text-align: center;\">"
                        + "本年累计"
                    + "</th>";
          }
          return "";
        }())
      }<th colspan="2" rowspan="1" style="text-align: center;">`
              + "期末余额"
          + "</th>"
      + "</tr>"
      + `<tr>${
        // eslint-disable-next-line func-names
        (function() {
          let str = "";
          const ary = ["借方金额", "贷方金额", "借方金额", "贷方金额", "借方金额", "贷方金额"];
          if (that.isYear === 0) {
            ary.push("借方金额", "贷方金额");
          }

          for (let i = 0; i < ary.length; i++) {
            str += `<th colspan="1" rowspan="1" style="text-align: center;">${ary[i]}</th>`;
          }
          return str;
        }())
      }</tr>`;
    }
  }
};
</script>

<style lang="scss">
.print-template {
  position: fixed;
  z-index: -12;
  width: 1000px;
  height: auto;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: #fff;
  .title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  .table-info {
    font-size: 12px;
    margin: 10px 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    color: #000;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    th {
      text-align: left;
      padding: 6px 10px;
      line-height: 20px;
      border: 1px solid #333;
      word-break: break-all;
      font-weight: normal;
    }
  }
  th{text-align:left;padding:6px 10px;line-height:20px;border:1px solid #333;word-break:break-all;font-weight:normal;}
  td{padding:2px 10px;line-height:20px;border:1px solid #333;word-break:break-all;height:24px !important;}
  table{border-collapse:collapse;width:100%;table-layout:fixed;color:#000;background-color:#fff;font-size:12px;}
}
</style>
