<!--
 * @Author: pengyu
 * @Date: 2020-03-16 10:57:04
 * @LastEditTime: 2021-05-13 16:59:35
 * @LastEditors: pengyu
 * @Description: 分录视图-按钮列表
 * @FilePath: \user-intelligent-accounting\src\views\voucherHandle\components\operMenu.vue
 -->
<template>
  <div class="oper-menu">
    <div
      v-for="(item,index) in btnList"
      :key="index"
      :title="item.btnName"
      @click.stop="clickLi(item.btnKey)"
    >
      {{ item.btnName }}
    </div>
  </div>
</template>

<script>
import getSingleBtnList from "@/mixins//intelligentAccounting/getSingleBtnList";

export default {
  name: "OperMenu",
  mixins: [getSingleBtnList],
  inject: ["$voucherEntry"],
  props: {
    rowData: { // 凭证状态
      type: Object,
      default() {
        return {};
      }
    },
    isCheckPage: { // 是否是凭证审核页面
      type: Boolean,
      default: false
    },
    commParams: { // 管理端配置项
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    rowData: {
      immediate: true,
      deep: true,
      handler(nVal) {
        const list = this.getSingleBtnList(nVal, this.commParams, this.isCheckPage);
        list.forEach((itm) => {
          itm.btnKey = itm.id;
          itm.btnName = itm.name;
        });
        // 加上自定义按钮
        this.btnList = [...list, ...(nVal.customBtnList || [])];
      }
    }
  },
  data() {
    return { btnList: [] };
  },
  methods: {
    clickLi(id) {
      this.$emit("entryClick", {
        id, data: this.rowData, isCheckPage: this.isCheckPage
      });
    }
  }
};
</script>

 <style lang="scss" scoped>
.oper-menu {
  width: 120px;
  border: 1px solid #dce5ec;
  border-radius: 5px;
  background: #ffffff;
  > div {
    padding: 10px;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      background: #3e90fe;
      color: #ffffff;
    }
    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
</style>
