<!--
 * @Author: Zhouql
 * @Date: 2020-02-19 10:39:01
 * @LastEditTime: 2021-07-15 15:47:31
 * @LastEditors: pengyu
 * @Description: 账簿-余额表
 * @FilePath: \user\src\views\intelligentAccounting\accountBook\balanceList.vue
 -->
<template>
  <div class="balance-main" id="balance-main">
    <div class="content" :class="isActive ? 'left-container expand' : 'left-container'">
      <en-title-card name="余额表">
        <div slot="right">
          <searchList
            @clickData="getVal"
            :searchDataList="searchDataList"
            :defaultSearchData="defaultSearchData"
            :isDisabled="disableSearch"
          ></searchList>
          <div class="btn-more">
            <en-icon name="gengduo" size="small" style="color:#a9b5c6"></en-icon>
            <div class="label">更多</div>
            <div class="menu">
              <div v-for="item in menuList" :key="item.name" class="item" @click="open(item.page)">{{ item.name }}</div>
            </div>
          </div>
          <div class="btn-export" @click="handleExport">
            <en-icon name="bus-btn5_1" size="small" style="color:#26c393"></en-icon>
            <div class="label">导出</div>
          </div>
          <div class="btn-print" @click="printTable('print-template')">
            <en-icon name="bus-btn6_1" size="small" style="color:#3e90fe"></en-icon>
            <div class="label">打印</div>
          </div>
        </div>
      </en-title-card>
      <en-tabs v-model="activeTab" :fixedWidth="100" :list="tabList" type="white" @change="changeTabs">
        <div slot="right">
          <el-checkbox v-model="checked" @change="checkedbox">显示本年累计</el-checkbox>
        </div>
      </en-tabs>
      <div class="balance-table" v-en-loading="enLoading">
        <!--表格部分-->
        <en-table
          :tree-config="{ children: 'nodes', hasChildren: 'hasChildren', trigger: 'default' }"
          :data="list"
          height="100%"
          ref="dataTable"
          :resizable="true"
          @row-click="handleRowClick"
        >
          <en-table-column type="index" width="60" align="center"></en-table-column>
          <en-table-column prop="text" label="科目" width="430"></en-table-column>
          <en-table-column title="期初余额" header-align="center">
            <en-table-column title="借方金额" prop="balanceBor" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.balanceBor | thousand(2) }}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="balanceLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.balanceLoan | thousand(2) }}
              </template>
            </en-table-column>
          </en-table-column>
          <en-table-column title="本期发生" header-align="center">
            <en-table-column title="借方金额" prop="curBor" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.curBor | thousand(2) }}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="curLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.curLoan | thousand(2) }}
              </template>
            </en-table-column>
          </en-table-column>
          <en-table-column title="本年累计" header-align="center" v-if="checked === true">
            <en-table-column title="借方金额" prop="yearBor" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.yearBor | thousand(2) }}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="yearLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.yearLoan | thousand(2) }}
              </template>
            </en-table-column>
          </en-table-column>
          <en-table-column title="期末余额" header-align="center">
            <en-table-column title="借方金额" prop="endBor" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.endBor | thousand(2) }}
              </template>
            </en-table-column>
            <en-table-column title="贷方金额" prop="endLoan" header-align="center" align="right">
              <template slot-scope="scope">
                {{ scope.row.endLoan | thousand(2) }}
              </template>
            </en-table-column>
          </en-table-column>
        </en-table>
      </div>
    </div>
    <!--'详情'-->
    <ledgerModel ref="ledgerModel" typeId="bl" :afferentData="afferentItem" @printDetail="printDetail"></ledgerModel>
    <!--'科目辅助余额表'-->
    <balanceAlert ref="balanceAlert" :afferentData="afferentItem" @printSubject="printSubject"></balanceAlert>
    <!--'数量金额余额表'-->
    <numBalanceAlert ref="numBalanceAlert" :afferentData="afferentItem" @printNum="printNum"></numBalanceAlert>
    <!--'外币金额余额表'-->
    <foreignCurrencyAlert ref="foreignCurrencyAlert" :afferentData="afferentItem" @printForeign="printForeign"></foreignCurrencyAlert>

    <!-- '会计期间' -->
    <dateInterval :type="1" :hideHead="false" :list="dateLineList" @collapseBtn="isActive = !isActive" @handleAccChange="accChange" class="date-interval"></dateInterval>
    <!-- 打印模板列表 -->
    <print-template :dataList="flatList" :is-year="isYear" :search-data="printSearchData"></print-template>
    <!-- 打印模板详情 -->
    <print-template-detail :dataList="detailList" :afferentData="afferentItem" :search-data="printSearchData"></print-template-detail>
    <!-- 打印模板科目 -->
    <print-template-subject :dataList="subjectList" :afferentData="afferentItem" :search-data="printSearchData"></print-template-subject>
    <!-- 打印模板数量金额 -->
    <print-template-num :dataList="numList" :afferentData="afferentItem" :search-data="printSearchData"></print-template-num>
    <!-- 打印模板数量金额 -->
    <print-template-foreign :dataList="foreignList" :afferentData="afferentItem" :search-data="printSearchData"></print-template-foreign>
    <!-- 凭证详情 -->
    <vocDetailAlert
      ref="vocDialog"
      :voucherId="voucherId"
      :actionType="2"
      :formal="{}"
      openFlag="quotes"
    ></vocDetailAlert>
  </div>
</template>

<script>
import { request } from "en-js";
import { cloneDeep } from "lodash";
import { Message } from "element-ui";
import { commonService, balanceService } from "@/api/intelligentAccounting";
import accountBookComm from "@/mixins/intelligentAccounting/accountBookComm.js";
import searchList from "../../components/searchList"; // 顶部搜索
import dateInterval from "../../components/dateInterval"; // 会计期间控件
import ledgerModel from "../alert/subsidiaryLedger.vue";
import balanceAlert from "../alert/balanceAlert.vue";
import numBalanceAlert from "../alert/numBalanceAlert.vue";
import foreignCurrencyAlert from "../alert/foreignCurrencyAlert.vue";
import PrintTemplate from "./components/PrintTemplate";
import PrintTemplateDetail from "../printTemplate/PrintTemplateDetail";
import PrintTemplateSubject from "./components/PrintTemplateSubject";
import PrintTemplateNum from "./components/PrintTemplateNum";
import PrintTemplateForeign from "./components/PrintTemplateForeign";
import vocDetailAlert from "../../components/vocDetailAlert";

export default {
  name: "BalanceList",
  mixins: [accountBookComm],
  components: {
    ledgerModel,
    balanceAlert,
    numBalanceAlert,
    foreignCurrencyAlert,
    dateInterval,
    searchList,
    PrintTemplate,
    PrintTemplateDetail,
    PrintTemplateSubject,
    PrintTemplateNum,
    PrintTemplateForeign,
    vocDetailAlert
  },
  data() {
    return {
      enLoading: null,
      dateLineList: [], // 会计期间数据
      startDate: "", // 会计期间开始时间
      endDate: "", // 会计期间结束时间
      defaultYearNum: "", // 会计区间选中年份
      isActive: false,
      tabList: [], // tab切换
      checked: false, // 是否显示本年累计
      isYear: 1, // 默认1为不选中
      subjectCategory: "", // 默认为1 tab切换传值
      accountingPeriod: [], // 会计期间的id
      accountingPeriodName: [],
      activeTab: "", // tab切换默认
      // 凭证范围下拉数据
      vocrangeList: [
        {
          id: 1,
          name: "全部凭证"
        },
        {
          id: 2,
          name: "仅已审核"
        }
      ],
      accountList: [], // 账套下拉数据
      accountMainList: [], // 记账主体下拉数据
      accountDefault: {}, // 默认账套
      // 默认范围
      vocrangeDefault: 1,
      accountMainDefault: [], // 默认记账主体
      searchDataList: {}, // 搜索的下拉数据
      defaultSearchData: {}, // 搜索默认数据
      list: [], // 表格数据
      flatList: [], // 扁平化的表格数据
      menuList: [
        { name: "科目辅助余额表", page: "balanceAlert" },
        { name: "数量金额余额表", page: "numBalanceAlert" },
        { name: "外币金额余额表", page: "foreignCurrencyAlert" }
      ],
      detailList: [], // 打印详情list
      subjectList: [], // 打印科目list
      numList: [], // 打印数量金额list
      foreignList: [], // 外币金额list
      afferentItem: {}, // 科目辅助余额表传入数据
      disableSearch: false, // 是否禁用searchList 下拉
      voucherId: ""
    };
  },
  computed: {
    printSearchData() {
      let unit = "";
      const findNode = (list, id) => {
        if (!id) return;
        list.forEach((item) => {
          if (item.id === id) {
            unit = item.code + item.text;
          } else if (item.nodes) {
            findNode(item.nodes, id);
          }
        });
      };
      const { startDate, endDate } = this;
      const period = startDate && endDate && startDate !== endDate ? `${startDate}-${endDate}` : endDate || startDate;
      findNode(this.accountMainList, this.accountMainDefault[0]);
      return {
        unit,
        period
      };
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    isActive() {
      this.$refs.dataTable.resetResizable();
    }
  },
  methods: {
    /**
     * @description: 初始化
     */
    async init() {
      await this.queryAccList(); // 请求账套和日期区间
      await this.queryAccountEntity(); // 请求记账主体
      await this.queryTabList();
      await this.queryAccTime();
      this.getRouterParam();
      this.balanceTable();
    },
    /**
     * @description 获取路由参数
     */
    getRouterParam() {
      const { query } = this.$route;
      if (query && query.flg === "1") {
        // 从 结账 穿透过来的 初始参数 需要取带过来的值
        this.vocrangeDefault = query.status || 1;
        this.accountDefault = query.accountId;
        this.accountMainDefault = [query.accountingEntity];
        this.accountingPeriod = query.accountingPeriod;
        this.activeTab = query.subjectCategory;
        this.defaultYearNum = query.yearNum;
        this.defaultSearchData = {
          accountDefault: this.accountDefault,
          vocrangeDefault: this.vocrangeDefault,
          accountMainDefault: this.accountMainDefault
        };
        this.disableSearch = true;
      }
    },
    // 请求日期区间数据接口
    // @request(true, "enLoading")
    async queryAccTime() {
      const _this = this;
      const res = await commonService.queryAccTime({
        accountId: _this.accountDefault,
        hasChildren: "000"
      });
      this.dateLineList = res;
      this.accPeriod();
    },
    /**
     * @description
     */
    accPeriod() {
      // 默认会计区间获取会计区间选中ID
      const _this = this;
      const { flg, accountingPeriod } = this.$route.query;
      function findDefaultPeriod(arr) {
        const nodesCur = [];
        const nodesCurName = [];
        const yearNum = [];
        const dateNameList = [];
        arr.forEach((v) => {
          if (v.isDefault === 0) {
            nodesCur.push(v.id);
            nodesCurName.push(v.name);
            yearNum.push(v.yearNum);
            dateNameList.push(v.name);
          }
        });
        return {
          nodesCur, nodesCurName, yearNum, dateNameList
        };
      }
      // 结账调过来的
      if (flg === "1" && accountingPeriod) {
        this.dateLineList.forEach((item) => {
          const defNode = item.nodes?.filter((nd) => nd.id === accountingPeriod)[0];
          if (defNode) {
            item.isDefault = 0;
            item.nodes.forEach((nd) => { nd.isDefault = nd.id === defNode.id ? 0 : 1; });
          } else {
            item.isDefault = 1;
            item.nodes.forEach((nd) => { nd.isDefault = 1; });
          }
          if (defNode) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            _this.accountingPeriod = findDefaultData.nodesCur;
            _this.accountingPeriodName = findDefaultData.nodesCurName;
            _this.defaultYearNum = findDefaultData.yearNum.toString();
            _this.startDate = findDefaultData.dateNameList[0];
            _this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          }
        });
        return;
      }

      this.dateLineList.forEach((item) => {
        if (item.isDefault === 0) {
          if (item.nodes) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            _this.accountingPeriod = findDefaultData.nodesCur;
            _this.accountingPeriodName = findDefaultData.nodesCurName;
            _this.defaultYearNum = findDefaultData.yearNum.toString();
            _this.startDate = findDefaultData.dateNameList[0];
            _this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          } else {
            this.defaultYearNum = item.name;
          }
        }
      });
    },
    // 请求tab数据
    // @request(true, "enLoading")
    async queryTabList() {
      const res = await commonService.queryObjectTreeExt({
        objectTypeId: "KMLB_SJZD",
        id: 0
      });
      this.tabList = res;
      this.activeTab = this.tabList[0].id;
      this.subjectCategory = this.tabList[0].id;
    },
    // 查询余额表数据
    @request(true, "enLoading")
    async balanceTable() {
      const pa = this.getCommParam();
      const res = await balanceService.queryAbBalance(pa);
      console.log("riqi", res);
      const copyList = cloneDeep(res?.dataList || []);
      if (res && res.dataList) {
        this.formatKemu(res.dataList);
      }
      this.list = res?.dataList || [];
      this.flatList = [];
      this.getFlatList(copyList);
    },
    formatKemu(list = []) {
      list.forEach((item) => {
        item.text = item.text !== "合计" ? `${item.code} ${item.name}` : item.text;
        if (item.nodes && item.nodes.length) {
          this.formatKemu(item.nodes);
        }
        // if (item.parentCode !== "0" && !item.nodes) {
        //   item.nodes = [];
        // }
      });
    },
    /**
     * @description:扁平化list
     */
    getFlatList(list) {
      list.forEach((item) => {
        item.text = item.text !== "合计" ? `${item.code} ${item.name}` : item.text;
        this.flatList.push(item);
        if (item.nodes) {
          this.getFlatList(item.nodes);
        }
      });
    },
    /**
     * @description:本年累计是否显示
     */
    checkedbox(data) {
      if (data === true) {
        this.isYear = 0;
      } else {
        this.isYear = 1;
      }
      this.balanceTable();
    },
    /**
     * @description: tab切换
     */
    changeTabs(res) {
      this.subjectCategory = res.id;
      this.balanceTable();
    },
    /**
     * @description: 取搜索组件带出数据
     */
    async getVal(data) {
      const originAccount = this.accountDefault;
      if (data.accountMain.length === 0) {
        Message("请选择记账主体");
        return;
      }
      this.accountDefault = data.account;
      this.accountMainDefault = data.accountMain;
      this.vocrangeDefault = data.vocrange;
      if (data.account !== originAccount) {
        // 账套发生变化 重查会计期间
        await this.queryAccTime();
      }

      this.balanceTable();
    },
    /**
     * @description: 日期区间选中数据
     */
    accChange(data) {
      this.accountingPeriod = data.ids;
      this.accountingPeriodName = data.names;
      this.defaultYearNum = data.yearNum;
      let dateNames = [];
      dateNames = data.names.split(",");
      this.startDate = dateNames[0];
      this.endDate = dateNames[dateNames.length - 1];
      this.balanceTable();
    },
    /**
     * @description: 弹窗
     */
    open(methodsNums) {
      this[methodsNums]();
    },
    /**
     * @description: 点击行-详情
     */
    handleRowClick(item) {
      const { row } = item;
      if (!row.code) {
        return;
      }

      this.afferentItem = {
        subjectCode: item.row.code, // 科目code
        subjectName: item.row.name, // 科目名称
        ...this.getCommParam()
      };
      this.$refs.ledgerModel.visible = true;
    },
    /**
     * @description: 科目辅助余额表
     */
    balanceAlert() {
      this.afferentItem = { ...this.getCommParam() };
      this.$refs.balanceAlert.visible = true;
    },
    /**
     * @description: 数量金额余额表
     */
    numBalanceAlert() {
      this.afferentItem = { ...this.getCommParam() };
      this.$refs.numBalanceAlert.visible = true;
    },
    /**
     * @description: 外币金额余额表
     */
    foreignCurrencyAlert() {
      this.afferentItem = { ...this.getCommParam() };
      this.$refs.foreignCurrencyAlert.visible = true;
    },
    /**
     * @description: 导出
     */
    handleExport() {
      if (!this.accountMainDefault.length) {
        Message("请选择记账主体");
        return;
      }
      const pa = { typeId: "ye", ...this.getCommParam() };
      this.handleExportExcel(pa);
    },
    /**
     * @description: 打印详情
     */
    printDetail(list) {
      this.detailList = list;
      this.$nextTick(() => {
        this.printTable("print-template-detail");
      });
    },
    /**
     * @description: 打印科目
     */
    printSubject(list) {
      console.log(list);
      this.subjectList = list;
      this.$nextTick(() => {
        this.printTable("print-template-subject");
      });
    },
    /**
     * @description: 打印数量金额
     */
    printNum(list) {
      console.log(list);
      this.numList = list;
      this.$nextTick(() => {
        this.printTable("print-template-num");
      });
    },
    /**
     * @description: 打印外币金额
     */
    printForeign(list) {
      console.log(list);
      this.foreignList = list;
      this.$nextTick(() => {
        this.printTable("print-template-foreign");
      });
    },
    /**
     * @description: 获取公共传参
     */
    getCommParam() {
      const _this = this;
      return {
        accountId: this.accountDefault,
        accountName: (() => {
          const o = this.accountList.filter((itm) => itm.id === _this.accountDefault);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        accountingEntity: this.accountMainDefault.toString(),
        accountingEntityName: (() => {
          const re = [];
          _this.accountMainDefault.forEach((itm) => {
            _this.accountMainList.forEach((itm2) => {
              if (itm2.id === itm) {
                re.push(`${itm2.code} ${itm2.name}`);
              }
              itm2.nodes?.forEach((itm3) => {
                if (itm3.id === itm) {
                  re.push(`${itm3.code} ${itm3.name}`);
                }
              });
            });
          });
          return re.join(",");
        })(),
        accountingPeriod: this.accountingPeriod.toString(), // 日期区间id
        accountingPeriodName: this.accountingPeriodName.toString(),
        status: this.vocrangeDefault,
        yearNum: this.defaultYearNum,
        isYear: this.isYear,
        subjectCategory: this.subjectCategory,
        startDate: this.startDate,
        endDate: this.endDate
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.balance-main {
  position: relative;
  background-color: #1d2532;
  & /deep/ .en-tabs-item {
    justify-content: center;
    font-weight: 600;
  }
  .btn-more,
  .btn-export,
  .btn-print {
    position: relative;
    cursor: pointer;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      color: #636c78;
    }
  }
  .btn-export,.btn-print{
    margin-left: 15px;
  }
  .btn-more {
    &:hover {
      .menu {
        display: block;
      }
    }
  }
  .menu {
    position: absolute;
    top: 32px;
    right: 0;
    border: 1px solid #dce5ec;
    border-radius: 5px;
    background: #fff;
    width: 120px;
    z-index: 3;
    display: none;
    .item {
      text-align: center;
      line-height: 32px;
      &:hover {
        background: #3e90fe;
        color: #fff;
      }
    }
  }
  .balance-table {
    height: calc(100% - 100px);
  }
}
.left-container {
  height: 100%;
  width: calc(100% - 72px);
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.expand {
    width: 100%;
  }
}
.date-interval {
  position: absolute;
  right: 4px;
  top: 0;
}
</style>
