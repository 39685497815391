<!--
 * @Author: pengyu
 * @Date: 2021-05-12 17:59:31
 * @LastEditors: pengyu
 * @LastEditTime: 2021-05-13 11:44:14
 * @Description: 结转块块
-->
<template>
 <div :class="['temp-mod', mod.isGenerateVoucher===0 ? 'disabled' : '']">
   <div class="top">
      <div class="name" :title="mod.name">{{mod.name || ""}}</div>
      <div class="voucher-no"
        v-if="!periodIsClose&&prevIsClose&&mod.voucher"
        @click="toDetail(mod.voucher.id)"
      >{{mod.voucher.voucherNo}}</div>
      <div class="handle-btn" v-if="!periodIsClose&&prevIsClose&&!mod.voucher">
        <en-icon
          name="shengchengpingzheng-zhinenghesuanyonghu"
          size="16px"
          color="#a9b5c6"
          title="生成凭证"
          v-show="mod.showVoc"
          @click.native="generateVoucher(mod.id)"
        ></en-icon>
        <en-icon
          name="ceshi-zhinenghesuanyonghu"
          size="16px"
          color="#a9b5c6"
          title="测算金额"
          @click.native="testMoney(mod.id)"
        ></en-icon>
      </div>
      <div class="voucher-no"
        v-if="periodIsClose && mod.voucher"
        @click="toDetail(mod.voucher.id)"
      >{{mod.voucher.voucherNo}}</div>
    </div>
    <div class="bottom">
      <div class="temp-money">
        <en-icon name="jiezhuanjinetongji" size="20px"></en-icon>
        <span class="money">{{ getVmoney }}</span>
      </div>
    </div>
 </div>
</template>

<script>
export default {
  name: "CarryOverMod",
  props: {
    mod: {
      type: Object,
      default: () => {}
    },
    periodIsClose: { // 当前块级期间是否已结账
      type: Boolean,
      default: false
    },
    prevIsClose: { // 上一块级期间是否已结账
      type: Boolean,
      default: false
    }
  },
  watch: {
    mod: {
      deep: true,
      immediate: true,
      handler(mod) {
        console.log(mod);
        this.$forceUpdate();
      }
    }
  },
  computed: {
    getVmoney() {
      const { voucher, amount } = this.mod;
      if (voucher) {
        return voucher.totalAmountD;
      }
      if (amount) {
        return amount;
      }
      if (this.periodIsClose) {
        return "0";
      }
      return "?";
    }
  },
  data() {
    return {
    };
  },
  methods: {
    // 生成凭证
    generateVoucher(id) {
      this.$emit("generateVoucher", id);
    },
    // 测算金额
    testMoney(id) {
      this.$emit("testMoney", id);
    },
    // 凭证详情
    toDetail(id) {
      this.$emit("toDetail", id);
    }
  }
};
</script>

<style lang='scss' scoped>
.temp-mod{
  width: 316px;
  height: 100px;
  border: 1px solid #e8ecf2;
  border-radius: 5px;
  background: #ffffff;
  padding: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
  display: inline-block;
  &:hover{
    background: #f5f8fc;
  }
  &.disabled{
    background: #f5f8fc;
  }
}
.top{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 25px;
  .name{
    font-size: 12px;
    font-weight: 600;
  }
  .en-icon{
    margin-left: 20px;
    cursor: pointer;
  }
  .voucher-no{
    font-size: 12px;
    color: #636c78;
    text-decoration: underline;
    cursor: pointer;
    &:hover{
      color: #3e90fe;
    }
  }
}
.bottom{
  color: #3e90fe;
  margin-top: 5px;
  .en-icon{
    vertical-align: middle;
  }
  span{
    font-size: 20px;
    margin-left: 10px;
    vertical-align: middle;
  }
}
</style>
