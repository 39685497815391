<!--
 * @Author: zoujp
 * @Date: 2020-03-10 11:27:09
 * @LastEditTime: 2021-10-25 16:22:21
 * @LastEditors: wuqi
 * @Description: 结账
 -->
<template>
  <div class="container">
        <div :class="isActive ? 'left-container expand' : 'left-container'">
            <div class="list-title">
                <div class="title-desc">
                    <span class="title">结账</span>结账至：{{ initInfo.closeAcPeriod }}，凭证记录至：{{ initInfo.voucherAcPeriod }}
                </div>
                <!-- 头部toolbar -->
                <topBar
                  :params="params"
                  :entityList="entityList"
                  :accList="accList"
                  :showRecord="true"
                  @search-call-back="search"
                  @change-param="changeParam"
                  @pop-record="seeRecord"
                ></topBar>
            </div>
            <div class="list-content">
                <div class="operate-head">
                    <img src="@/assets/images/cartoon-1.png" alt="">
                    <span class="col-tits">{{ checkMsg }}</span>
                    <div class="progress-all col-progress" v-show="isShowProgress">
                        <div class="progress-cur" :style="{ width: progress + '%' }"></div>
                    </div>
                    <div class="col-btn">
                        <en-button  :disabled="isDesiableBatchCheck" @click="batchCheck">批量结账</en-button>
                        <en-button v-show="isShowCheckoutUp" :disabled="isDesiableCheckoutUp" @click="checkoutUp">结账检查</en-button>
                        <en-button v-show="isShowCheckoutAgain" @click="checkoutAgain">重新检查</en-button>
                        <en-button v-show="isShowReverseCheckout" @click="reverseCheckout">反结账</en-button>
                        <en-button v-show="isShowCheckout" @click="checkout">结账</en-button>
                        <en-button v-show="isShowNoCheckout" type="green" @click="noCheckout">不结账</en-button>
                    </div>
                </div>
                <div class="accounts-operate-cents">
                    <div class="mods" v-for="item in initList" :key="item.id">
                        <div class="header"   :style="getExpandCss(item.id)">
                            <div class="icon" :style="{ background:item.bg }">
                                <en-icon
                                    :name="item.icon"
                                    size="18px">
                                </en-icon>
                            </div>

                            <div class="tname">
                                <div class="iname">{{ item.name }}</div>
                                <div class="infos">{{ item.desc }}</div>
                            </div>
                            <div class="expand" @click="switchList(item.id)">
                                <en-icon
                                    :name="item.status"
                                    size="small"
                                    color="#A9B5C6"
                                >
                                </en-icon>
                            </div>
                        </div>
                        <div class="list" v-show="showHide(item.id)">
                            <div class="list-row"
                                v-for="obj in item.items"
                                :key="obj.id"
                            >
                                <div class="row-cicle"></div>
                                <div class="row-name">{{obj.name}}</div>
                                <div class="row-desc" @click.stop="toDetail(obj)">{{obj.msg}}</div>
                                <div class="row-status" v-show="obj.name">
                                    <en-icon
                                        :name="statusMap[obj.isSuccess]?statusMap[obj.isSuccess].icon:''"
                                        :color="statusMap[obj.isSuccess]?statusMap[obj.isSuccess].color:''"
                                        size="small"
                                    >
                                </en-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 会计期间 -->
        <dateInterval
          class="check-vtime"
          :type="1"
          :list="dateLineListCle"
          :hideHead="true"
          :selected="{isCloseAccount: 0}"
          :cur="curPeriod.id"
          @collapseBtn="isActive=!isActive"
          @handleAccChange="accChange"
        ></dateInterval>

        <!-- 操作日志 -->
        <recordAlert
          ref="popCheckoutRecord"
          :accountId="params.accountId"
          :accountingEntity="params.accountingEntity"
        ></recordAlert>
        <!-- 批量结账 -->
        <batch-check ref='batchCheckDialog' :interval='dateLineListCle' :prezjrq='initInfo.closeAcPeriod' :params='params' @batchCheck='batchCheckCallback'></batch-check>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { Message } from "element-ui";
import voucherComm from "@/mixins/intelligentAccounting/voucherComm";
import { settleAccounts as SA } from "@/api";
import dateInterval from "../components/dateInterval"; // 会计期间控件
import recordAlert from "./record"; // 操作日志'
// 顶部栏
import topBar from "../components/topBar";
import batchCheck from "../components/batchCheck";
/* eslint-disable */
// 查询公共参数
export default {
  name: "CheckList",
  components: { dateInterval, recordAlert, topBar,batchCheck },
  mixins: [voucherComm],
  data() {
    return {
      // 是否关闭会计期间
      isActive: false,
      // 是否显示进度条
      isShowProgress: false,
      // 进度
      progress: 0,
      // 是否显示结账检查
      isShowCheckoutUp: true,
      // 是否置灰结账检查
      isDesiableCheckoutUp: true,
      // 是否显示重新检查
      isShowCheckoutAgain: false,
      // 是否显示结账
      isShowCheckout: false,
      // 是否显示反结账
      isShowReverseCheckout: false,
      // 是否显示不结账
      isShowNoCheckout: false,
      // 是否置灰批量结账
      isDesiableBatchCheck:false,
      // 结账检查信息
      checkMsg: "",
      // 加载数据条件（包含搜素栏数据）
      params: {
        accountId: "",
        accountingEntity: "",
        accountingPeriod: "",
        accountingPeriodMainId: ""
      },
      // 结账信息
      initInfo: {},
      // 静态结账数据
      initList: [
        {
          id: 1,
          name: "资产类科目余额",
          desc: "此条目尚未检查",
          status: "shouqi",
          icon: "huijiqijian-zhinenghesuan",
          bg: "#26C393",
          items: []
        },
        {
          id: 2,
          name: "期末结转",
          desc: "此条目尚未检查",
          status: "shouqi",
          icon: "huijikemu-zhinenghesuan",
          bg: "#FFAD2C",
          items: []
        },
        {
          id: 3,
          name: "其他异常",
          desc: "此条目尚未检查",
          status: "shouqi",
          icon: "qitayichang-zhinenghesuanyonghu",
          bg: "#49BEF2",
          items: []
        }
      ],
      statusMap: {
        1: { icon: "tishi", color: "#F7BF27" },
        2: { icon: "wancheng", color: "#58CB7E" },
        3: { icon: "tishi", color: "#F76B6B" }
      },
      // 账套数据集合
      accList: [],
      // 记账主体数据集合
      entityList: [],
      // 会计期间数据集合
      dateLineList: [],
      dateLineListCle: [],
      // 检查权限数据
      powerData: {},
      // 当前默认会计期间
      curPeriod: {},
      // 上一个会计期间
      prePeriod: {},
      // 下一个会计期间
      nextPeriod: {}
    };
  },
  watch: {
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations("intelligentAccounting", [
      "saveSaMxSubjectlist",
      "saveSaPzclConditionlist"
    ]),
    /**
     * @description: 保存用户习惯
     * @value :选中的数据集合
     */
    async changeParam(value) {
      this.params.accountId = value.accountId;
      this.params.accountingEntity = value.accountingEntity;
      await this.saveAccHabit();
    },
    /**
     * @description: 会计期间选中回调
     * @curId :选中的会计期间id
     */
    accChange(cur) {
      const This = this;
      this.dateLineList.forEach((m) => {
        m.nodes.forEach((n, index) => {
          if (n.id === cur.ids) {
            console.log(JSON.stringify(n, null, 2));
            This.curPeriod = JSON.parse(JSON.stringify(n));
            This.prePeriod = m.nodes[index - 1] || {};
            This.nextPeriod = m.nodes[index + 1] || {};
          }
        });
      });
      let msg = "";
      if((this.curPeriod.isCloseAccount == "0") && ( (this.nextPeriod.isCloseAccount == "1") || (Object.keys(this.nextPeriod).length === 0) )){
        msg = `上次结账到${this.curPeriod.name}会计期间`;
        this.setBtnStatus(5, msg);
      } else if (this.curPeriod.isCloseAccount== "1" && this.curPeriod.isDefault== "0") { // 默认 且 未结账
        if (this.prePeriod.isCloseAccount== "0") {
          msg = `上次结账到${this.prePeriod.name}会计期间`;
        }
        this.setBtnStatus(1, msg);
      } else {
        this.setBtnStatus(2);
      }
    },
    initTimes() {
      let msg = "";
      const This = this;
      // 默认的肯定为未结账的
      if (Object.keys(This.curPeriod).length === 0) { // 无默认
        This.setBtnStatus(2);
      } else if (This.curPeriod.isCloseAccount== "1") { // 当前未结账 上一个已经结账
        if (This.prePeriod.isCloseAccount== "0") {
          msg = `上次结账到${This.prePeriod.name}会计期间`;
        }
        This.setBtnStatus(1, msg);
      } else if (Object.keys(This.nextPeriod).length === 0 || This.nextPeriod.isCloseAccount== "1") {
        This.setBtnStatus(5);
      } else {
        This.setBtnStatus(2);
      }
    },
    /**
     * @description: 初始化
     */
    async init() {
      await this.queryAccountList();
      await this.queryCloseDateLine({ accountId: this.params.accountId, accountingEntity: this.params.accountingEntity, hasChildren: "000" });
      const This = this;
      this.dateLineList.forEach((m,pIndex) => {
        m.nodes.forEach((n, index) => {
          if (n.isDefault== "0") {
            console.log(JSON.stringify(n, null, 2));
            This.curPeriod = n;
             if(index==0){
              let preNodes=this.dateLineList[pIndex-1].nodes
              This.prePeriod = preNodes[preNodes.length - 1] || {};
            }else{
              This.prePeriod = m.nodes[index - 1] || {};
            }
            if(index==m.nodes.length){
              This.nextPeriod = this.dateLineList[pIndex+1].nodes[0] || {};
            }else{
              This.nextPeriod = m.nodes[index + 1] || {};
            }
            
          }
        });
      });
      this.search();
    },

    //批量结账
    batchCheck(){
      this.$refs.batchCheckDialog.show()
    },
    getMonthBetween(start, end) {
        const result = [];
        const starts = start.split("-");
        const ends = end.split("-");
        let staYear = parseInt(starts[0], 10);
        let staMon = parseInt(starts[1], 10);
        const endYear = parseInt(ends[0], 10);
        const endMon = parseInt(ends[1], 10);
        while (staYear <= endYear) {
            if (staYear === endYear) {
                while (staMon <= endMon) {
                    const m = (`00${ staMon}`);
                    const ym = staYear + m.substr(m.length - 2);
                    result.push({ value: ym, text: ym });
                    staMon++;
                }
                staYear++;
            } else {
                if (staMon > 12) {
                    staMon = 1;
                    staYear++;
                }
                const m = (`00${ staMon}`);
                const ym = staYear + m.substr(m.length - 2);
                result.push({ value: ym, text: ym });
                staMon++;
            }
        }
        return result;
    },
    getIdByname(name) {
      var id = "", has = false, objectId = "";
      this.dateLineListCle.forEach((b,a)=>{
          b.nodes.forEach((d,c)=>{
              if (d.name == name) {
                  id = d.id;
                  objectId = d.objectId;
                  has = true;
                  return false;
              }
          });
          if (has) {
              return false;
          }
      });
      return {
          id: id,
          objectId: objectId
      };
    },
    //批量结账回调
    async batchCheckCallback(sd,ed,reDate){
      if (!this.params.accountingEntity) {
        this.$message.warning("请选择记账主体");
        return false;
      } 
      this.$refs.batchCheckDialog.close()
      var sd0 = sd.substr(0, 4);
      var sd1 = sd.substr(4);
      var ed0 = ed.substr(0, 4);
      var ed1 = ed.substr(4);
      var reDate = this.getMonthBetween(sd0 + "-" + sd1, ed0 + "-" + ed1);
      if (reDate.length > 12) {
          this.$message.warning("批量结账不能超过12个月");
          return;
      }
      this.isShowProgress=true
      var finish = 0, needFinish = reDate.length;
      const csaRes = await this.checkIsSettleAccount({accountId:this.params.accountId,accountingEntity:this.params.accountingEntity}) || {};
      if (csaRes.isSettleAccount === 1) {
        Message({
          showClose: true,
          message: "当前无权限",
          type: "error"
        });
        return;
      }
      this.resetItems();
      await (async ()=>{
        for (var a=0; a < reDate.length; a++) {
          var b = reDate[a];
          var v = b.value;
          var obj = this.getIdByname(v);
          var id = obj.id;
          var objectId = obj.objectId;
          if (!id) {
              this.$message.error("未找到【" + v + "】对应数据ID，无法结账!");
              return false;
          }
          var isError = await this.action(id,objectId, a, v,reDate, ()=> {
              this.checkout(id,objectId,a==reDate.length-1)
          });
          console.log(isError)
          if (isError) {
              await this.queryCloseAccount(true);
              await this.queryCloseDateLine({ accountId: this.params.accountId, accountingEntity: this.params.accountingEntity, hasChildren: "000" });
              return false;
          }
        } 
      })()
      
     
      return undefined;
    },
    async action(id, objectId, a, v, reDate,fn) {
        var isError = false;
        let vm=this;
        async function query (code) {
          const p = {
            checkCode: code,
            accountId: vm.params.accountId,
            accountingEntity: vm.params.accountingEntity,
            accountingPeriod: id,
            accountingPeriodMainId: objectId
          };
          let data= await vm.checkCloseAccount(p);
          var error = vm.initTheData(code, data);
          if (error > 0) {//有异常
              var msg = "";
              if (a == 0) {
                  msg = v + "存在异常，已终止";
              } else {
                  msg = "结账至" + reDate[a-1].value + "," + v + "存在异常，已终止";
              }
              vm.$alert(msg, '提示', {
                confirmButtonText: '查看异常',
                callback: action => {
                  
                }
              });
              isError = true;
              return false;
            }
            const map = { 1: 33, 2: 66, 3: 100 };
            vm.progress = map[code];
            if (code < 3) {
                code += 1;
               await query(code);
            } else {
                fn && fn();
            }
        }
        await query.bind(this)(1);
        console.log(isError)
        return isError;
    },
    /**
     * @description: 校验记账主题，账套是否选择了
     */
    verify() {
      if (!this.params.accountId) {
        Message({
          showClose: true,
          message: "请选择账套",
          type: "error"
        });
        return false;
      }
      if (!this.params.accountingEntity) {
        Message({
          showClose: true,
          message: "请选择记账主体",
          type: "error"
        });
        return false;
      }
      return true;
    },
    /**
     * @description: 搜索
     */
    async search() {
      if (!this.verify()) return;
      await this.queryCloseAccount();
      await this.queryCloseDateLine({ accountId: this.params.accountId, accountingEntity: this.params.accountingEntity, hasChildren: "000" });
      //获取当前选中的会计期间
      let cur;
      this.dateLineListCle.forEach((m) => {
        m.nodes.forEach((n, index) => {
          if (n.isDefault == 0) {
            cur = JSON.parse(JSON.stringify(n));
          }
        });
      });
      if(!cur)return;
      this.accChange(cur);
    },
    /**
     * @description: 查看日志
     */
    seeRecord() {
      if (!this.verify()) return;
      this.$refs.popCheckoutRecord.visiable = true;
    },

    /**
     * @description: 重置
     */
    resetItems() {
      this.progress = 0;
      this.initList.forEach((v) => {
        v.desc = "此条目尚未检查";
        v.status = "shouqi";
        v.items = [];
      });
    },
    /**
     * @description: 设置按钮状态
     */
    setBtnStatus(type, msg) {
      this.checkMsg = msg || "";
      // 1，结账检查 2，结账检查置灰 3，重新检查 4，结账|不结账 5，反结账 ,6 批量结账 7批量结账置灰
      switch (type) {
        case 1:
          this.isShowCheckoutUp = true;
          this.isDesiableCheckoutUp = false;
          this.isShowCheckoutAgain = false;
          this.isShowCheckout = false;
          this.isShowReverseCheckout = false;
          this.isShowNoCheckout = false;
          break;
        case 2:
          this.isShowCheckoutUp = true;
          this.isDesiableCheckoutUp = true;
          this.isShowCheckoutAgain = false;
          this.isShowCheckout = false;
          this.isShowReverseCheckout = false;
          this.isShowNoCheckout = false;
          break;
        case 3:
          this.isShowCheckoutAgain = true;
          this.isShowCheckoutUp = false;
          this.isShowCheckout = false;
          this.isShowReverseCheckout = false;
          this.isShowNoCheckout = false;
          break;
        case 4:
          this.isShowCheckout = true;
          this.isShowNoCheckout = true;
          this.isShowCheckoutAgain = false;
          this.isShowCheckoutUp = false;
          this.isShowReverseCheckout = false;
          break;
        case 5:
          this.isShowReverseCheckout = true;
          this.isShowCheckout = false;
          this.isShowNoCheckout = false;
          this.isShowCheckoutAgain = false;
          this.isShowCheckoutUp = false;
          break;
        default:
          break;
      }
    },
    /**
     * @description: 结账检查
     */
    async checkoutUp() {
      if (!this.verify()) return;
      const res = await this.checkIsSettleAccount({accountId:this.params.accountId,accountingEntity:this.params.accountingEntity}) || {};
      if (res.isSettleAccount === 1) {
        Message({
          showClose: true,
          message: "当前无权限",
          type: "error"
        });
        return;
      }
      this.resetItems();
      this.setBtnStatus(2, `${this.curPeriod.name}会计期间结账检查中`);
      this.checkoutUpComm(1);
    },
    /**
     * @description: 结账检查公共方法
     * @code: 编码1，2，3
     */
    async checkoutUpComm(code) {
      console.log(this.curPeriod);
      const p = {
        checkCode: code,
        accountId: this.params.accountId,
        accountingEntity: this.params.accountingEntity,
        accountingPeriod: this.curPeriod.id,
        accountingPeriodMainId: this.curPeriod.objectId
      };
      const res = await this.checkCloseAccount(p) || {};
      let hasError= this.initTheData(code, res);

      const map = { 1: 33, 2: 66, 3: 100 };
      this.progress = map[code];

      if (code < 3) {
        code += 1;
        this.checkoutUpComm(code);
      }
      let len = 0;
      this.initList.forEach((m) => {
        m.items.forEach((n) => {
          if (n.isSuccess === "3") {
            len += 1;
          }
        });
      });
      if (len) {
        this.setBtnStatus(3, "");
      }
      return hasError
    },
    /**
     * @description: 结账检查公共方法
     * @code: 编码1，2，3
     * @res: 明细列表
     */
    initTheData(code, res) {
      const This = this;
      let totalRiskNum=0;
      this.initList.forEach((m) => {
        const arr = []; let isCur = false;
        if (m.id === code) {
          isCur = true;
          let riskNum = 0;
          const total = res.length || 0;
          let riskMsg = "";
          res.forEach((n) => {
            arr.push(n);
            if (n.isSuccess === "3") {
              riskNum += 1;
              if (!riskMsg) {
                riskMsg = n.msg; // 取异常第一条;
              }
            }
          });
          // 有明细数据需要展开
          if (res.length) {
            m.status = "zhankai";
          }

          if (code === 3) {
            This.isShowProgress = false;
          } else {
            This.isShowProgress = true;
          }
          let msg = "";
          if (riskNum > 0) {
            msg = `${this.curPeriod.name}会计期间${riskMsg},不能结账`;
            This.setBtnStatus(3, msg);
          } else {
            msg = `${this.curPeriod.name}会计期间完成检查`;
            This.setBtnStatus(4, msg);
          }
          m.desc = `有${total}项类目，其中${riskNum}项类目有风险`;
          totalRiskNum+=riskNum
        }
        if (isCur) {
          m.items = arr;
        }
        
      });
      return totalRiskNum
    },
    /**
     * @description: 重新检查
     */
    checkoutAgain() {
      this.checkoutUp();
    },
    /**
     * @description: 反结账
     */
    async reverseCheckout() {
      if (!this.verify()) return;
      const p = {
        accountId: this.params.accountId,
        accountingEntity: this.params.accountingEntity,
        accountingPeriod: this.curPeriod.id,
        accountingPeriodMainId: this.curPeriod.objectId
      };
      const res = await this.reCloseAccount(p) || {};
      console.log(`--------${JSON.stringify(res)}`);
      location.reload();
    },
    /**
     * @description: 结账
     */
    async checkout(id=this.curPeriod.id,objectId=this.curPeriod.objectId,reload=true) {
      if (!this.verify()) return;
      const p = {
        accountId: this.params.accountId,
        accountingEntity: this.params.accountingEntity,
        accountingPeriod: id,
        accountingPeriodMainId: objectId
      };
      const res = await this.closeAccount(p) || {};
      console.log(`--------${JSON.stringify(res)}`);
      reload?location.reload():"";
    },
    /**
     * @description: 不结账
     */
    noCheckout() {
      location.reload();
    },
    /**
     * @description: 收起，折叠
     * @id: 对应的id
     */
    switchList(id) {
      this.initList.forEach((v) => {
        if (v.id === id) {
          v.status = v.status === "shouqi" ? "zhankai" : "shouqi";
        }
      });
    },
    /**
     * @description: 是否显示明细
     * @id: 对应的id
     */
    showHide(id) {
      const obj = this.initList.filter((item) => item.id === id);
      return obj[0]?.status !== "shouqi";
    },
    /**
     * @description: 展开后添加下方边框
     * @id: 对应的id
     */
    getExpandCss(id) {
      const obj = this.initList.filter((item) => item.id === id);
      if (obj[0]?.status === "shouqi") {
        return {
          borderBottom: 0
        };
      }
      return {
        borderBottom: "1px solid #f3f3f3"
      };
    },
    /**
     * @description: 跳转到明细
     * @item: 对应的数据集
     */
    toDetail(atd) {
      /* {11:'库存现金',12:'银行存款',13:'原材料',14:'库存商品',15:'固定资产',16:'无形资产',17:'长期待摊费用',
        21:'计提折旧',22:'结转销售成本',23:'计提税金',31:'科目期初',32:'凭证审核',33:'损益类科目',34:'断号'} */
      const code = Number(atd.code); const con = atd.condition;
      const mx = [11, 12, 13, 14, 17, 21, 22, 23];
      const yeb = [15, 16, 33]; const qcye = [31];
      const pzcl = [32, 34]; let url = "";
      const p = {
        accountId: con.accountId,
        accountingEntity: con.accountingEntity,
        accountingPeriod: con.accountingPeriod,
        flg: 1
      };

      if (mx.includes(code)) {
        url = "/intelligent-accounting/specification";
        p.status = con.status;
        p.yearNum = con.yearNum;
        p.subjectCode = con.subjectCode;
        p.flg = "1";
        // 科目会有多条，用存储
        this.saveSaMxSubjectlist(JSON.stringify(con.subjectList || []));
      } else if (yeb.includes(code)) {
        url = "/intelligent-accounting/balanceList";
        p.status = con.status;
        p.yearNum = con.yearNum;
        p.subjectCategory = con.subjectCategory;
        p.flg = "1";
      } else if (qcye.includes(code)) {
        return;
        // url = "/bdAccInitSetUp.do?user=user&";
        // p.yearNum = con.yearNum;
        // p.user = "user";
      } else if (pzcl.includes(code)) {
        url = "/intelligent-accounting/voucherEntry";
        // 凭证审核  带初始条件查询
        this.saveSaPzclConditionlist(JSON.stringify(con.subjectList || []));
      }
      let routeUrl = this.$router.resolve({
        path: url,
        query: p
      });
      window.open(routeUrl.href, '_blank');
    },
    /**
    * @description:  查询结账会计期间
    * @param {type}
    * @return:
    */
    async queryCloseDateLine(params) {
      const rsp = await SA.queryCloseAccountAcQueryAccTime(params);
      // 查找默认值
      let def;
      for (let i = 0; i < rsp.length; i++) {
        def = (rsp[i].nodes || []).filter((item) => item.isDefault === 0);
        if (def.length) {
          break;
        }
      }
      this.dateLineList = rsp;
      this.dateLineListCle = JSON.parse(JSON.stringify(rsp));
      this.params.accountingPeriod = def[0]?.id;
    },
    /**
    * @description:  结账检查权限
    * @param {type}
    * @return:
    */
    async checkIsSettleAccount(params) {
      const rsp = await SA.checkIsSettleAccount(params);
      return rsp;
    },
    /**
    * @description:  结账检查
    * @param {type}
    * @return:
    */
    async checkCloseAccount(params) {
      const rsp = await SA.checkCloseAccount(params);
      return rsp;
    },
    /**
    * @description:  结账
    * @param {type}
    * @return:
    */
    async closeAccount(params) {
      const rsp = await SA.closeAccount(params);
      return rsp;
    },
    /**
    * @description:  反结账
    * @param {type}
    * @return:
    */
    async reCloseAccount(params) {
      const rsp = await SA.reCloseAccount(params);
      return rsp;
    },
    setBatchCheckInfo(){
      if(this.initInfo.curYearNum !== -1){
        this.isDesiableBatchCheck=false
      }
    },
    /**
    * @description:  查询结账信息
    * @param {type}
    * @return:
    */
    async queryCloseAccount(flag) {
      const rsp = await SA.queryCloseAccount(this.params);
      this.initInfo = rsp;
      this.initTimes();
      this.setBatchCheckInfo();
      console.log(this.initInfo,'this.initInfo')
      !flag?this.resetItems():"";
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: #1d2532 !important;
  position: relative;
  .left-container{
    height: 100%;
    width: calc(100% - 72px);
    background: #ffffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &.expand {
      width: 100%;
    }
    .list-title{
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid rgba(232,236,242,1);
        text-align: left;
        display: flex;
        justify-content: space-between;
        .title-desc{
            width: 300px;
            color: #91A1B7;
            font-size: 12px;
            .title{
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                display: inline-block;
                padding: 0 20px;
            }
        }

    }
    .list-content{
        height: calc(100% - 50px);
        .operate-head{
            background-color: #F5F7FA;
            padding: 10px 20px;
            display: flex;
            .col-tits {
                line-height: 24px;
                margin-top: 18px;
                margin-left: 12px;
                font-size: 12px;
            }
            .col-progress {
                display: inline-block;
                margin: 26px 14px 0;
                flex: 1;
            }
            .progress-all {
                width: 522px;
                border-radius: 10px;
                height: 12px;
                background-color: #f5f5f5;
                overflow: visible;
                display: inline-block;
                .progress-cur {
                    border-radius: 10px;
                    height: 12px;
                    background-color: #189edb;
                }
            }
            .col-btn {
                margin-left: auto;
                margin-top: 14px;
                & .en-button{
                    width:160px;
                }
            }
        }
        .accounts-operate-cents {
            height: calc(100% - 20px);
            overflow-y: auto;
            padding-bottom: 20px;
            .mods {
                padding: 0 20px 0;
                .header{
                    height: 80px;
                    border-top: 1px solid #f3f3f3;
                    display: flex;
                    align-items: center;
                    .tname{
                        flex: 1;
                        text-align: left;
                        .iname{
                            color:#606060;
                            font-size: 12px;
                            margin-bottom: 8px;
                        }
                    .infos{
                        color:#aeaeae;
                        font-size: 12px;
                    }
                    }
                    .icon{
                        margin-right: 10px;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        & .en-icon{
                            color: #fff;
                        }
                    }
                }
                .list{
                    padding: 0 20px 0 40px;
                    margin-bottom: 0;
                    .list-row{
                        height: 50px;
                        display: flex;
                        align-items: center;
                        .row-cicle{
                            width: 6px;
                            height: 6px;
                            background-color: #3e90fe;
                            border-radius: 50%;
                            margin: 0 10px 0 10px;
                        }
                        .row-name{
                            font-size: 12px;
                            flex: 1;
                            text-align: left;
                        }
                        .row-desc{
                            font-size: 12px;
                            text-decoration: underline;
                            cursor: pointer;
                            flex: 1;
                            text-align: right;
                        }
                        .row-status{
                            width: 30px;
                            padding-left: 20px;
                        }
                    }
                    .list-row:not(:first-child) {
                        border-top: 1px solid #f1f5fc;
                    }
                }
            }
        }
        img {
            vertical-align: middle;
            border: 0;
        }
    }
  }
  .check-vtime{
    position: absolute;
    right: 4px;
    top: 0;
  }
}
</style>
