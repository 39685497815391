var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "oper-menu" },
    _vm._l(_vm.btnList, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          attrs: { title: item.btnName },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.clickLi(item.btnKey)
            },
          },
        },
        [_vm._v(" " + _vm._s(item.btnName) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }