<!--
 * @Author: pengyu
 * @Date: 2020-03-13 11:45:51
 * @LastEditTime: 2021-06-23 15:39:47
 * @LastEditors: pengyu
 * @Description: 分录视图--分录行
 * @FilePath: \user-intelligent-accounting\src\views\voucherHandle\components\entryModel.vue
 -->
<template>
  <div class="wrap-row">
    <table class="grid">
      <!-- 首条需要头部 -->
      <thead v-show="index === 0">
        <tr>
          <th class="check-th">
            <el-checkbox
              v-model="chkAll"
              :indeterminate="isIndeterminate"
              @change="hasCheckAll"
            ></el-checkbox>
          </th>
          <th class="zhaiyao-th">
            摘要
          </th>
          <th class="kemu-th">
            科目
          </th>
          <th>借方金额</th>
          <th>贷方金额</th>
        </tr>
      </thead>
      <tbody @click.stop="entryRowClick">
        <tr>
          <td class="check-td">
            <el-checkbox
              v-model="rowData.checked"
              @click.native.stop
              @change="hasCheck"
            ></el-checkbox>
          </td>
          <td class="zhaiyao-td">
            凭证字号: {{ rowData | formatVoucherNo }}
          </td>
          <td class="kemu-td">
            <span>业务编号: {{ rowData.refCode || "无" }}</span>
            <span>业务模板: {{ rowData.busTemplateName || "无"}}</span>
          </td>
          <td>记账日期: {{ rowData.produceDate | formatDate }}</td>
          <td class="oper-td">
            <status-model :status="rowData.status"></status-model>
            <en-icon name="bianji1" size="small" class="oper-btn"></en-icon>
            <oper-menu
              :is-check-page="isCheckPage"
              :comm-params="commParams"
              :row-data="rowData"
              @entryClick="entryClick"
            ></oper-menu>
          </td>
        </tr>
        <tr v-for="item in rowData.itemList" :key="item.id" :title="cashItemName(item)">
          <td class="check-td"></td>
          <td class="zhaiyao-td">
            {{ getRemarkAllName(item) }}
          </td>
          <td class="kemu-td">
            {{ getSubjectAllName(item) }}
          </td>
          <td class="amount-td">
            <template v-if="item.amountD">
              {{ item.amountD | thousand }}
            </template>
          </td>
          <td class="amount-td">
            <template v-if="item.amountC">
              {{ item.amountC | thousand }}
            </template>
          </td>
        </tr>
        <tr>
          <td class="check-td"></td>
          <td colspan="2" class="total-td">
            总计: <b>{{ rowData.totalDCapi || "" }}</b>
          </td>
          <td class="amount-td">
            <b>{{ rowData.totalD | thousand }}</b>
          </td>
          <td class="amount-td">
            <b>{{ rowData.totalC | thousand }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { thousand } from "en-js";
import dayjs from "dayjs";
import statusModel from "../../components/statusModel";
import operMenu from "./operMenu";

export default {
  name: "EntryModel",
  inject: ["$voucherEntry"],
  components: { statusModel, operMenu },
  filters: {
    thousand,
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    formatVoucherNo(data) {
      const _no = data.voucherNo.padStart(4, "0");
      return `${data.voucherDataTypeName || ""}-${_no}`;
    }
  },
  computed: {
    cashItemName() {
      return (item) => (item.cashFlowItemName ? `现金流量项目：${item.cashFlowItemName}` : "");
    },
    isIndeterminate() {
      const { voucherData } = this.$voucherEntry;
      return voucherData.some((item) => item.checked) && !voucherData.every((item) => item.checked);
    }
  },
  props: {
    isCheckPage: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    checkedAll: {
      type: Boolean,
      default: false
    },
    commParams: {
      // 管理端配置项
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    checkedAll(val) {
      this.chkAll = val;
    }
  },
  data() {
    return {
      chkAll: this.checkedAll
    };
  },
  methods: {
    /**
     * @description:  获取标记组合名称
     * @param {type}
     * @return:
     */
    getRemarkAllName(b) {
      let txt = "";
      const {
        itemType, foroCurrSys, quantityAccD, quantityAccC, foroCurrAmountD, foroCurrAmountC
      } = b;
      const quantity = itemType === 0 ? quantityAccD : quantityAccC; // 数量
      const wbje = itemType === 0 ? foroCurrAmountD : foroCurrAmountC; // 外币金额

      txt += b.remark;
      if (quantity && !foroCurrSys) {
        // 有数量核算
        txt += `（数量：${quantity}${b.quantityAccunitName || ""}，单价：${this.numToFixed(b.price, 4)}）`;
      } else if (foroCurrSys && !quantity) {
        // 外币核算
        txt += `（${b.foroCurrSysName}：${this.numToFixed(wbje, 2)}，汇率：${this.numToFixed(b.rate, 6)}）`;
      } else if (foroCurrSys && quantity) {
        txt += `（数量：${quantity}${b.quantityAccunitName || ""}，
                  单价：${this.numToFixed(b.price, 4)}，${b.foroCurrSysName}：${this.numToFixed(wbje, 2)}，
                  汇率：${this.numToFixed(b.rate, 6)}）`;
      }
      return txt;
    },
    numToFixed(num, w) {
      const n = Number(num).toFixed(w);
      return parseFloat(n);
    },
    /**
     * @description:  获取科目+辅助核算 名称
     * @param {type}
     * @return:
     */
    getSubjectAllName(b) {
      let kemu = `${b.subjectCode} ${b.subjectAllName.replace(/,/g, "_")}`;
      if (b.fzhs && b.fzhs.length) {
        // 辅助核算
        let fz = "";
        b.fzhs.forEach((b2) => {
          fz += b2.valueName;
        });
        kemu += ` | ${fz}`;
      }
      return kemu;
    },
    /**
     * @/mixins/getSingleBtnList
     * @description:  单个checkbox
     * @param {type}
     */
    hasCheck(value) {
      this.$emit("init-batch", { value, id: this.rowData.id });
    },
    /**
     * @description:  头部全选checkbox
     * @param {type}
     */
    hasCheckAll(value) {
      this.$emit("init-batch", { value, id: "all" });
    },
    /**
     * @description: 分录行按钮事件
     * @param {type}
     * @return:
     */
    entryClick(obj) {
      this.$emit("single-click", obj);
    },
    /**
     * @description: 分录行点击
     * @param {type}
     */
    entryRowClick() {
      this.$parent.actionType = 2;
      this.$parent.voucherId = this.rowData.id;
      this.$parent.setProps();
      this.$parent.$refs.VDA.visible = true;

      // this.$router.push({
      //   path: "/intelligent-accounting/voucherDetail",
      //   query: {
      //     actionType: 2,
      //     id: this.rowData.id,
      //     isCheckPage: this.isCheckPage
      //   }
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
$ash: #dce5ec;
.wrap-row {
  width: 100%;
  margin-bottom: 14px;
  border: 1px solid $ash;
  box-sizing: border-box;
  &:hover {
    border-color:#4591dc;
    .oper-btn {
      display: block !important;
    }
  }
  .grid {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    thead {
      background-color: #f5f9fc;
      tr {
        display: flex;
      }
      th {
        padding: 0 10px;
        border: 1px solid $ash;
        border-left: 0;
        border-top: 0;
        text-align: left;
        flex: 1;
        font-weight: 500;
        box-sizing: border-box;
        height: 50px;
        line-height: 50px;
        &:last-child {
          border-right: 0;
        }
      }
      .check-th {
        width: 60px;
        flex: none;
        text-align: center;
      }
      .zhaiyao-th {
        width: 30%;
        flex: none;
      }
      .kemu-th {
        width: 30%;
        flex: none;
      }
    }
    tbody {
      tr {
        display: flex;
        border-bottom: 1px solid $ash;
        &:last-child {
          border-bottom: 0;
        }
        &:nth-child(even) {
          background-color: #f8faf9;
        }
      }
      td {
        padding: 0 10px;
        border-right: 1px solid $ash;
        text-align: left;
        flex: 1;
        min-height: 46px;
        box-sizing: border-box;
        line-height: 46px;
        &:last-child {
          border-right: 0;
        }
      }
      .check-td {
        width: 60px;
        flex: none;
        text-align: center;
      }
      .zhaiyao-td {
        width: 30%;
        flex: none;
      }
      .kemu-td {
        width: 30%;
        flex: none;
        display: flex;
        >span{
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:first-child{
            width: 40%;
          }
          &:last-child{
            width: 60%;
          }
        }
      }
      .total-td {
        width: 60%;
        flex: none;
      }
      .amount-td {
        text-align: right;
      }
      .oper-td {
        position: relative;
        .status-model {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }
        .en-icon {
          float: right;
          transform: translateY(80%);
          color: #4591dc;
          display: none;
          &:hover {
            & + .oper-menu {
              display: block;
            }
          }
        }
        .oper-menu {
          display: none;
          position: absolute;
          z-index: 1;
          top: 33px;
          right: 10px;
          &:hover {
            display: block;
          }
        }
      }
    }
  }
}
</style>
