<!--
 * @Author: zoujp
 * @Date: 2020-07-01 17:30:32
 * @LastEditTime: 2021-07-01 17:05:01
 * @LastEditors: Please set LastEditors
 * @Description: 财务报表 - 过滤弹窗
 * @FilePath: \user\src\views\intelligentAccounting\financialReport\reportFilter.vue
-->
<template>
  <en-dialog v-if="visiable" :visible.sync="visiable" title="筛选" class="fin-record-filter" width="900px" :close-on-click-modal="false" @close="closeFn">
    <div class="form-wrap">
      <div v-for="item in cons" :key="item.id" class="form-content">
        <filterSelect v-if="item.type == 5" :con="item" :subjectData="subjectData" @changeSelectData="changeSelectData"> </filterSelect>
        <div class="field-rows" v-else-if="item.type == 7 && item.dateAccuracy == 3">
          <div class="row-name">
            <span>{{ item.name }}</span>
          </div>
          <div class="row-content">
            <div class="date-line-ipt">
               <en-date-picker
                  v-model="item.showValue"
                  type="monthrange"
                  range-separator="至"
                  value-format="yyyy-MM"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  :picker-options="{ disabledDate }"
              >
              </en-date-picker>
            </div>
          </div>
        </div>
        <div class="field-rows" v-else-if="item.type == 7 && item.dateAccuracy == 4">
          <div class="row-name">
            <span>{{ item.name }}</span>
          </div>
          <div class="row-content">
            <div class="date-line-ipt date-quarter-row">
              <en-date-picker
                  v-model="qsd"
                  type="year"
                  class="mod"
                  :picker-options="{ disabledDate: disabledDateQuarter }"
                  placeholder="选择年"
                  value-format="yyyy"
                  @change="changeQuarDateSd"
              >
              </en-date-picker>
              <en-select
                  v-model="qsq"
                  class="mod"
                  :data="quarDataStart"
                  :props="{ value: 'value', label: 'text'}"
              >
              </en-select>
              <el-date-picker
                v-model="qed"
                type="year"
                class="mod"
                :picker-options="{ disabledDate: disabledDateQuarter }"
                placeholder="选择年"
                value-format="yyyy"
                @change="changeQuarDateEd"
              >
              </el-date-picker>
               <en-select
                  v-model="qeq"
                  :data="quarDataEnd"
                  :props="{ value: 'value', label: 'text'}"
              >
              </en-select>
            </div>
          </div>
        </div>
        <div class="field-rows" v-else-if="item.type == 94 || (item.type == 7 && item.dateAccuracy == 5)">
          <div class="row-name">
            <span>{{ item.name }}</span>
          </div>
          <div class="row-content">
            <div class="date-line-ipt" @click="showListFn">
              <el-input v-model="item.showValue" @mouseover.native="mover" @mouseout.native="mout" placeholder="请选择" readonly="readonly" suffix-icon="el-icon-date"></el-input>
              <div @mouseover.stop="mover" @mouseout.stop="mout">
                <en-icon v-show="showClose" name="guanbi-danchuang" size="10px" color="#C0C4CC" @click.native.stop="closePicker"> </en-icon>
              </div>
            </div>
            <datePanel ref="dateTimePicker" :ftype="item.type" :list="listAcc" :type="typeAcc" :startDate="startDate" :endDate="endDate" @panelConfirm="submit"></datePanel>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { financialReport as FR } from "@/api";
import filterSelect from "./filterSelect";
import datePanel from "../components/dateInterval/components/datePanel"; // 会计期间控件
// 过滤
/* eslint-disable */
export default {
  name: "FinRecordFilter",
  components: { datePanel, filterSelect },
  props: {
    conditions: {
      type: Array,
      default() {
        return [];
      }
    },
    accountId: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: Number,
      default: 1
    },
    startD: {
      type: String,
      default: ""
    },
    endD: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visiable: false,
      cons: this.formatCondition(), // 搜索条件
      subjectData: [], // 科目备选数据
      accId: this.accountId, // 账套
      listAcc: this.list, // 会计区间
      typeAcc: this.type, // 类型
      startDate: "", // 开始时间
      endDate: "", // 结束时间
      selectedValue: {}, // 选中的数据Map
      showClose: false,
      SYD: "-01-01",
      EYD: "-12-31",
      quarDataStart: [],
      qsd: "",//记账日期季度开始时间
      qed: "",//记账日期季度开始季度
      qsq: "",//记账日期季度结束时间
      qeq: "",//记账日期季度结束季度
      quarDataStart: [],
      quarDataEnd: [],
      quarter: [
        { value: "1", text: "第一季度" },
        { value: "2", text: "第二季度" },
        { value: "3", text: "第三季度" },
        { value: "4", text: "第四季度" }
      ]
    };
  },
  watch: {
    conditions() {
      this.cons = this.formatCondition();
    },
    accountId() {
      this.accId = this.accountId;
    },
    list() {
      this.listAcc = this.list;
    },

    type() {
      this.typeAcc = this.type;
    },
    startD() {
      this.startDate = this.startD;
    },
    endD() {
      this.endDate = this.endD;
    },
    visiable() {
      if (this.visiable) {
        this.cons = this.formatCondition();
        this.getSubjectData();
      }
    }
  },
  mounted() {},
  methods: {
    /**
     * @description: 设置季度年份选中后季度开始下拉选数据
     */
    changeQuarDateSd(year) {
      let jzrq = this.getQuarterConObj();
      if(jzrq){
        if(!jzrq.rangeValue){ //如果没有设置取前10年时间
          this.quarDataStart = JSON.parse(JSON.stringify(this.quarter));
          this.qsq = "1";
        }else{
          let arr = jzrq.rangeValue.split(",");
          let year1 = arr[0].substr(0, 4);
          let quarter1 = arr[0].substr(4, 1);
          let year2 = arr[1].substr(0, 4);
          let quarter2 = arr[1].substr(4, 1);
          let startMonth = 1;
          let endMonth = 4;
          let qlist = [];
          if (year == year1) {
              startMonth = Number(quarter1);
              endMonth = 4;
          } else if (year == year2) {
              endMonth = Number(quarter2);
              startMonth = 1;
          }
          while (startMonth <= endMonth) {
              qlist.push({
                  value: startMonth.toString(),
                  text: this.getQuerterName(startMonth)
              });
              startMonth++;
          }
          this.quarDataStart = qlist;
          this.qsq = qlist[0].value;
        }
      }
    },
     /**
     * @description: 设置季度年份选中后季度结束下拉选数据
     */
    changeQuarDateEd(year) {
      let jzrq = this.getQuarterConObj();
      if(jzrq){
        if(!jzrq.rangeValue){ //如果没有设置取前10年时间
          this.quarDataEnd = JSON.parse(JSON.stringify(this.quarter));
          this.qeq = "1";
        }else{
          let arr = jzrq.rangeValue.split(",");
          let year1 = arr[0].substr(0, 4);
          let quarter1 = arr[0].substr(4, 1);
          let year2 = arr[1].substr(0, 4);
          let quarter2 = arr[1].substr(4, 1);
          let startMonth = 1;
          let endMonth = 4;
          let qlist = [];
          if (year == year1) {
              startMonth = Number(quarter1);
              endMonth = 4;
          } else if (year == year2) {
              endMonth = Number(quarter2);
              startMonth = 1;
          }
          while (startMonth <= endMonth) {
              qlist.push({
                  value: startMonth.toString(),
                  text: this.getQuerterName(startMonth)
              });
              startMonth++;
          }
          this.quarDataEnd = qlist;
          this.qeq = qlist[0].value;
        }
      }
    },
     /**
     * @description: 获取季度中文名
     */
    getQuerterName(val) {
      let n = "";
      for (let i = 0; i < this.quarter.length; i++) {
        if (this.quarter[i].value == val) {
          n = this.quarter[i].text;
          break;
        }
      }
      return n;
    },
    getToday(dd) {
      const d = dd || new Date();
      const m = (`00${d.getMonth() + 1}`);
      const r = (`00${d.getDate()}`);
      return `${d.getFullYear()}-${m.substr(m.length - 2)}-${r.substr(r.length - 2)}`;
    },
    /**
     * @description: 设置季度年份的可选范围
     */
    disabledDateQuarter(date) {
      date = new Date(this.getToday(date));
      let jzrq = this.getQuarterConObj();
      if(jzrq){
        let startDate, endDate;
        if (!jzrq.rangeValue) {
          //如果没有设置取前10年时间
          let year10 = new Date().getFullYear() - 10;
          startDate = year10 + this.SYD;
          endDate = new Date().getFullYear() + this.EYD;
          if (date < new Date(startDate) || date > new Date(endDate)) {
            return true;
          }
          return false;
        }else{
            return false;
        }
      }
      return false;
    },
     /**
     * @description: 获取筛选条件的记账日期的季度数据对象
     */
    getQuarterConObj(){
      let jzrq;
      this.cons.forEach((b) => {
        if (b.type == 7 && b.dateAccuracy == 4) {
          jzrq = b;
        } 
      });
      return jzrq;
    },
    /**
     * @description: 设置季度年份的可选范围
     */
    disabledDate(date) {
      date = new Date(this.getToday(date));
      if (date < new Date(this.startD) || date > new Date(this.endD)) {
        return true;
      }
        return false;
    },
    // 格式化处理筛选条件
    formatCondition() {
      const co = JSON.parse(JSON.stringify(this.conditions));
      co.forEach((b) => {
        if (b.type.toString() === "5") {
          b.showValue = b.value || ""; // 用于显示到v-model的值
          if (b.value) {
            b.showValue = b.value.split(",");
          } else {
            b.showValue = [];
          }
        } else if (b.type.toString() === "94") {
          // 会计期间
          if (b._isSave) {
            b.showValue = b.valueText || "";
          } else if (b.initValue) {
            b.showValue = `${b.initValueText}至${b.initValueText}`;
          } else {
            b.showValue = "";
          }
        } else if (b.type.toString() === "7") {
          // 记账日期
          if (b._isSave) {
            if (b.dateAccuracy.toString() === "3") { //月
              b.showValue = this.setShowValue(b.value || "");
            } else if(b.dateAccuracy.toString() === "4"){//季度
              //20202,20212
              if(b.value){
                let s = b.value.split(",");
                if(!s[1]){
                  s[1] = s[0];
                }
                this.changeQuarDateSd(s[0].substr(0,4));
                this.changeQuarDateEd(s[1].substr(0,4));
                this.qsd = s[0].substr(0,4);
                this.qsq = s[0].substr(4,1); 
                this.qed = s[1].substr(0,4);
                this.qeq = s[1].substr(4,1); 
              }else{
                this.qsd="";
                this.qsq="";
                this.qed="";
                this.qeq="";
              }
            } else {
              b.showValue = b.value || "";
            }
          } else if (b.initValue) {
            if (b.dateAccuracy.toString() === "4") { // 季度
              let str = b.initValue.split(",");
              if(!str[1]){
                str[1] = str[0];
              }
              this.changeQuarDateSd(str[0].substr(0,4));
              this.changeQuarDateEd(str[1].substr(0,4));
              this.qsd = str[0].substr(0,4);
              this.qsq = str[0].substr(4,1); 
              this.qed = str[1].substr(0,4);
              this.qeq = str[1].substr(4,1); 
            } else if (b.dateAccuracy.toString() === "3") { // 月
              b.showValue = this.setShowValue(b.initValue);
            } else { // 年
              b.showValue = `${b.initValueText}至${b.initValueText}`;
            }
          } else {
            b.showValue = "";
          }
        }
      });
      return co;
    },
    setShowValue(val) {
      if (!val) {
        return [];
      }
      let re = [];
      const arr = val.split(",");
      if (arr[1]) {
        re = arr;
      } else {
        re = [arr[0], arr[0]];
      }
      return re;
    },
    async getSubjectData() {
      const res = await FR.querySubjectAll({ accountId: this.accId });
      const dd = [];
      res?.forEach((b) => {
        dd.push({
          id: b.id,
          text: `${b.id} ${b.name}`
        });
      });
      this.subjectData = dd;
    },
    /**
     * @description: 选中回调方法
     */
    selectChange(res) {
      const value = [];
      const valueText = [];
      res?.forEach((b) => {
        value.push(b.id);
        valueText.push(b.text);
      });
    },
    /**
     * @description: 关闭弹窗
     */
    closeFn() {
      this.visiable = false;
      this.$refs.dateTimePicker[0].showPanel = false;
    },
    /**
     * @description: 保存
     */
    save() {
      this.visiable = false;
      //如果是记账日期，这里需要从shouValue里面拿值赋给value,valueText,valueText2
      let isMonth=false,isQuarter = false;
      let monthValue = "";
      let quarterObj;
      this.cons.forEach((b) => {
        if(b.type == "7"){
          if(b.dateAccuracy.toString() === "3"){//月
            if(b.showValue){
              b.value = b.showValue.join(",");
              b.valueText = b.showValue.join("至");
              b.valueText2 = b.showValue.join(",");
            }else{
              b.value = b.initValue;
              b.valueText = b.initValueText;
              b.valueText2 = b.initValueText2;
            }
            isMonth = true;
            monthValue = b.value;
          }else if(b.dateAccuracy.toString() === "4"){//季
            quarterObj = b;
            isQuarter = true;
          }else if(b.dateAccuracy.toString() === "5"){//年
          }
        }
        b._isSave = true;
      });
      if(isMonth){
        //这里需要修改listAcc选中的值isDefault为0
        this.listAcc.forEach((a)=>{
          a.isDefault = 1;
          let cnt = 0;
          a.nodes.forEach((b)=>{
            let mv0 = Number(monthValue.split(",")[0].replace("-","")),
                mv1 = Number(monthValue.split(",")[1].replace("-","")),
                nv = Number(b.id);
            if(nv >= mv0 && nv <= mv1){
              b.isDefault = 0;
              cnt+=1;
            }else{
              b.isDefault = 1;
            }
          });
          if(cnt > 0){
            a.isDefault = 0;
          }
        });
      }
      if(isQuarter){
        if(this.qsd && this.qed && this.qsq && this.qeq){
          let v1 = this.qsd + "" + this.qsq;
          let v2 = this.qed + "" + this.qeq;
          if (Number(this.qsd + "." + this.qsq) > Number(this.qed + "." + this.qeq)) {
              layer.msg("季度区间错误！");
              return false;
          }
          let v1_text = this.qsd + "年" + this.getQuerterName(this.qsq);
          let v2_text = this.qed + "年" + this.getQuerterName(this.qeq);
          quarterObj.value = [v1, v2].join(",");
          quarterObj.valueText = [v1_text, v2_text].join("至");
          quarterObj.valueText2 = [v1, v2].join("至");
          quarterObj._isSave = true;
        }else{
          quarterObj.value = quarterObj.initValue;
          quarterObj.valueText = quarterObj.initValueText;
          quarterObj.valueText2 = quarterObj.initValueText2;
          quarterObj._isSave = false;
        }

        //这里需要修改listAcc选中的值isDefault为0
        this.listAcc.forEach((a)=>{
          a.isDefault = 1;
          let cnt = 0;
          a.nodes.forEach((b)=>{
            let mv0 = Number(quarterObj.value.split(",")[0]),
                mv1 = Number(quarterObj.value.split(",")[1]),
                nv = Number(b.id);
            if(nv >= mv0 && nv <= mv1){
              b.isDefault = 0;
              cnt+=1;
            }else{
              b.isDefault = 1;
            }
          });
          if(cnt > 0){
            a.isDefault = 0;
          }
        });
      }
      this.$emit("saveFilter", this.cons, this.listAcc);
    },

    /**
     * @description: 显示会计期间面板
     */
    showListFn() {
      this.$refs.dateTimePicker[0].showPanel = !this.$refs.dateTimePicker[0].showPanel;
    },
    /**
     * @description: 悬停显示清空按钮
     */
    mover() {
      this.showClose = true;
    },
    /**
     * @description: 离开悬停隐藏清空按钮
     */
    mout() {
      this.showClose = false;
    },
    /**
     * @description: 清空选中的数据
     */
    closePicker() {
      this.$refs.dateTimePicker[0].showPanel = false;
    },
    /**
     * @description: 获取会计期间的条件
     */
    getSubjectInfo(ftype) {
      return this.cons.filter((v) => v.type.toString() === ftype.toString());
    },
    /**
     * @description: 获取会计期间的条件
     * @obj : 选中的数据
     */
    changeSelectData(obj) {
      console.log(obj);
      // for (let i = 0, len = this.cons.length; i < len; i++) {
      //   if (obj.id === this.cons[i].id) {
      //     this.cons[i].value = obj.value;
      //     this.cons[i].valueText = obj.valueText;
      //   }
      // }
    },
    /**
     * @description: 保存
     */
    submit(res, ftype) {
      this.listAcc = res; // 改变选中后，需要改变list
      const objInfo = this.getSubjectInfo(ftype)[0];
      let values = [];
      let valueText = [];
      let valueText2 = [];
      let defIds = [];
      if (this.typeAcc === 2) {
        // 无层级
        defIds = res.filter((v) => v.isDefault === 0);
        defIds.forEach((b) => {
          values.push(b.name);
          valueText2.push(b.name);
        });
        if (defIds.length) {
          valueText.push(defIds[0].name);
          valueText.push(defIds[defIds.length - 1].name);
        }
        values = values.join(",");
        valueText = valueText.join("至");
        valueText2 = valueText2.join(",");
        if (values) {
          // 有值
          objInfo._isSave = true;
          objInfo.value = values;
          objInfo.valueText = valueText;
          objInfo.valueText2 = valueText2;
          objInfo.showValue = valueText;
        } else {
          // 为空时，用initValue覆盖value
          objInfo._isSave = false;
          objInfo.showValue = "";
          objInfo.value = objInfo.initValue;
          objInfo.valueText = objInfo.initValueText;
          objInfo.valueText2 = objInfo.initValueText2;
        }
      } else {
        res.forEach((b) => {
          b.nodes?.forEach((d) => {
            if (d.isDefault === 0) {
              values.push(d.id);
              valueText.push(d.name);
            }
          });
        });
        if (values.length) {
          // 有值
          objInfo._isSave = true;
          objInfo.value = values.join(",");
          objInfo.valueText = `${valueText[0]}至${valueText[valueText.length - 1]}`;
          objInfo.valueText2 = valueText.join(",");
          objInfo.showValue = objInfo.valueText;
        } else {
          // 为空时，用initValue覆盖value
          objInfo._isSave = false;
          objInfo.showValue = "";
          objInfo.value = objInfo.initValue;
          objInfo.valueText = objInfo.initValueText;
          objInfo.valueText2 = objInfo.initValueText2;
        }
      }
      this.$refs.dateTimePicker[0].showPanel = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.fin-record-filter {
  & /deep/ .el-dialog__header {
    text-align: left;
  }
  & /deep/ .el-dialog__body{
    height: 400px;
  }
  & /deep/ .el-range-editor{
    width: 100%;
  }
  .form-wrap {
    padding: 10px;
    .field-rows {
      display: flex;
      align-items: center;
      text-align: left;
      font-size: 12px;
      color: #333333;
      margin-bottom: 10px;
      .row-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
        max-width: 100px;
        text-align: right;
        margin-right: 20px;
      }
      .row-content {
        position: relative;
        width: 100%;
        & /deep/ .date-panel {
          top: 33px;
          left: 0;
          .date-title {
            text-align: center;
          }
        }
        .date-line-ipt {
          position: relative;
          & .en-icon {
            position: absolute;
            top: 10px;
            right: 30px;
            cursor: pointer;
          }
        }
        .date-quarter-row{
          display: flex;
          .mod{
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
