<!--
 * @Author: hcl
 * @Date: 2020-06-16 11:39:08
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-12 10:48:15
 * @Description: 凭证生成
-->
<template>
  <div class="credential">
    <div class="header-contain">
      <div class="header-left">
        <div class="header-title">
          凭证生成
        </div>
        <div class="header-filter">
          <en-select
            class="filter-select"
            v-model="params.accountId"
            data-mode="value"
            :data="accAry"
            :props="{ value: 'id', label: 'name'}"
            @change="selectChange"
          ></en-select>
        </div>
      </div>
      <div class="header-right" v-show="batchBtn">
        <en-button
          icon="pingzhengshengcheng-xianxing"
          type="text"
          :icon-size="16"
          icon-color="#26C393"
          class="button"
          @click="createVoucher"
        >
          生成凭证
        </en-button>
        <en-button
          icon="shanchu-kuaijiecaozuo"
          type="text"
          :icon-size="16"
          icon-color="#F76B6B"
          class="button"
          @click="deleteVoucher"
        >
          删除
        </en-button>
      </div>
    </div>
    <div class="content" v-en-loading="enLoading">
      <div class="table-content">
        <en-table
          ref="voucherTable"
          :data="tableData"
          class="table"
          :page-config="{ pageModel, changeMethod: handlePageChanged }"
          @row-click="toBusinessDetail"
          @filter-sort-change="handleFilterSortChange"
          @select-change="selectionChange"
          :select-config="{showHeader: true, checkDisabledMethod}"
          :height-config="{ bottomHeight: 10 }"
        >
          <en-table-column
            type="index-selection"
            width="60"
          >
            <template #header>
              序号
            </template>
          </en-table-column>
          <table-column
            ref="tableColumn"
            v-for="(itm, idx) in columnData"
            :data="itm"
            :key="idx"
            :filter-sort-config="itm"
            :is-custom-content="itm.field==='status'"
          >
            <template #content="{data}">
              <div :class="['status-mod', `status${data.status}`]">{{getStatusName(data.status)}}</div>
            </template>
          </table-column>
          <template #hoverRow="scope">
            <en-expand-buttons
              v-if="scope.row.status !=='003'"
              :data="buttonData"
              @button-click="buttonClick(scope.row, arguments[1])"
            />
          </template>
        </en-table>
      </div>
      <!-- <div class="content-bottom">
        <en-pagination
          :page-model="pageModel"
          @change="handlePageChanged"
        ></en-pagination>
      </div> -->
    </div>
    <!-- 记账日期 -->
    <bookDateDialog
      ref="dateMod"
      @getComfirmValue="getConfirmDateValue"
    ></bookDateDialog>
    <!-- 异常凭证 -->
    <popUnusualVoucher
      ref="unusualMod"
      :ids="unusualVoucherIds"
    ></popUnusualVoucher>
    <!-- 编辑凭证 -->
    <vocDetailAlert
      ref="vocdialog"
      :voucherId="voucherId"
      :actionType="3"
      :fromCredential="true"
      :formal="params"
      refreshFunc="callback"
    ></vocDetailAlert>
    <!-- 业务详情 -->
    <businessDialog
      :fullscreen="true"
      :dialogVisible.sync="dialogVisible"
      :businessParams="businessParams"
    ></businessDialog>
  </div>
</template>
<script>
import { Message, MessageBox } from "element-ui";
import { clone } from "lodash";
import { mapMutations } from "vuex";
import { credentialService, intelligentService } from "@/api";
import businessDialog from "@/components/businessDetail/businessDialog.vue";
import tableColumn from "@/components/en-table-extra/tableColumn";
import { queryGlobalVarible } from "@/tools/util";
import { BusinessDataOpenType } from "@/components/businessDetail/data/BusinessCommonHeader";
import bookDateDialog from "./components/bookDateDialog";
import vocDetailAlert from "../components/vocDetailAlert";
import popUnusualVoucher from "./components/popUnusualVoucher";

export default {
  name: "Credential",
  components: {
    bookDateDialog, popUnusualVoucher, vocDetailAlert, tableColumn, businessDialog
  },
  data() {
    return {
      enLoading: null,
      batchBtn: false,
      vocDetail: {},
      params: {
        accountId: "",
        pageNo: 1,
        pageSize: 10,
        filterFields: [
          {
            field: "status",
            prop: "status",
            property: "status",
            name: "生成状态",
            fieldType: 5,
            type: "filter",
            dataSource: 3,
            nameVariable: "status",
            valueText: "待生成,生成异常",
            value: "待生成,生成异常",
            column: {
              title: "生成状态",
              field: "status",
              prop: "status",
              property: "status",
              nameVariable: "status"
            }
        }],
        sortFields: ""
      },
      accAry: [], // 账套列表
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: "",
        totalPages: ""
      },
      column: [
        {
          field: "code", name: "单据编号", title: "单据编号", fieldType: 1
        },
        {
          field: "templateName", name: "单据名称", title: "单据名称", fieldType: 1
        },
        {
          field: "createName", name: "创建人", title: "创建人", fieldType: 1
        },
        {
          field: "createDepName", name: "创建人部门", title: "创建人部门", fieldType: 1
        },
        {
          field: "createTime", name: "创建时间", title: "创建时间", fieldType: 7, timeFormat: 9, dateRange: 0
        },
        {
          field: "status",
          name: "生成状态",
          title: "生成状态",
          fieldType: 5,
          sortable: false,
          filterable: true,
          dataSource: 3,
          dataArea: [
            { id: "001", name: "待生成" },
            { id: "002", name: "生成异常" },
            { id: "003", name: "已生成" }
          ]
        }
      ],
      columnData: [], // 列头数据
      tableData: [],
      buttonData: [
        {
          id: "generate",
          name: "生成凭证",
          icon: "shengchengpingzheng-liebiao",
          iconColor: "#26C393"
        }, {
          id: "delete",
          name: "删除",
          icon: "shanchu-liebiao",
          iconColor: "#F76B6B"
        }
      ],
      generateParam: {},
      unusualVoucherIds: "", // 异常凭证ids
      voucherId: "", // 异常凭证编辑 id
      dialogVisible: false,
      businessParams: {
        id: "",
        templateId: "",
        openType: BusinessDataOpenType.view,
        pageType: "",
        businessType: 2
      }
    };
  },
  computed: {
    tableHeight() {
      const content = document.querySelector(".table-content");
      const isFilter = this.params.sortFields || this.params.filterFields;
      if (content) {
        return `${content.clientHeight - (isFilter ? 53 : 0)}px`;
      }
      return "";
    }
  },
  filters: {
    statusName(st) {
      return st === "001" ? "待生成" : st === "002" ? "生成异常" : st === "003" ? "已生成" : "";
    }
  },
  mounted() {
    this.init();
    const _this = this;
    this.$eventHub.$on("toDetail", (id) => {
      _this.popDetail(id);
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("toDetail");
  },
  methods: {
     ...mapMutations("businessModel", ["saveDateVariables"]),
    /**
     * @description: 初始化
     */
    async init() {
      try {
        const _this = this;
        _this.enLoading = true;
        // 全局变量
        const variable = await queryGlobalVarible(7, 3);
        this.saveDateVariables(variable);
        this.columnData = this.column;
        this.accAry = await intelligentService.queryAccList();
        // 查询默认值
        this.accAry.forEach((itm) => {
          if (itm.isDefault === 0) {
            _this.params.accountId = itm.id;
          }
        });
        await this.queryVoucherList();
         _this.enLoading = false;
        // 默认筛选 待生成和生成异常的
        this.$nextTick(() => {
          this.$refs.voucherTable.filterStore.dataList = this.params.filterFields;
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    /**
     * @description: 切换账套
     * @param val  账套id
     */
    async selectChange(accountId) {
      const params = { accountId };
      console.log(this.$options.data().params);
      this.params = { ...this.$options.data().params, ...params };
      this.$nextTick(() => {
        this.$refs.tableColumn.forEach((col) => {
          col.$children.forEach((child) => {
            child.filterSortStore.valueText = "";
            child.filterSortStore.value = "";
          });
        });
        this.$refs.voucherTable.filterStore.dataList = this.params.filterFields;
      });
      // this.params.accountId = val;
      await this.saveAccHabit(accountId);
      this.resetPageModel();
      this.queryVoucherList();
    },
    async queryVoucherList() {
      const pa = clone(this.params);
      pa.filterFields = (pa.filterFields || []).filter((itm) => itm.type === "filter");
      pa.filterFields = (pa.filterFields || []).map((itm) => ({
          // column: itm.column,
          dataSource: itm.dataSource,
          field: itm.field,
          fieldType: itm.fieldType,
          name: itm.name,
          nameVariable: itm.nameVariable,
          prop: itm.prop,
          property: itm.property,
          type: itm.type,
          value: itm.value,
          valueText: itm.valueText
        }));
      pa.filterFields = typeof (pa.filterFields) === "object" ? JSON.stringify(pa.filterFields) : pa.filterFields;
      const rsp = await credentialService.queryVoucherGeneration(pa);
      rsp.records.forEach((itm) => {
        itm.statusName = itm.status === "001" ? "待生成" : itm.status === "002" ? "生成异常" : itm.status === "003" ? "已生成" : "";
      });
      this.tableData = rsp.records;
      this.pageModel.total = rsp.total;
      this.pageModel.totalPages = rsp.totalPages;
      this.$refs.voucherTable.recalculate();
      console.log(this.params);
    },
    /**
     * @description:  分页控件事件
     * @param pageNo
     * @param pageSize
     */
    handlePageChanged({ pageNo, pageSize }) {
      this.params.pageNo = pageNo;
      this.params.pageSize = pageSize;
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.queryVoucherList();
    },
    /**
     * @description: 保存账套习惯
     * @param id
     */

    async saveAccHabit(accountId) {
      await intelligentService.saveAccHabit({ accountId });
    },
    /**
     * @description: 表格行勾选处理
     * @param val
     */
    selectionChange(val) {
      const { selection: ary } = val;
      this.tableData.forEach((itm) => {
        const { id } = itm;
        let flg = false;
        ary.forEach((citm) => {
          if (citm.id === id) {
            flg = true;
          }
        });
        itm.checked = flg;
      });
      this.batchBtn = !!ary.length;
    },
    /**
     * @description: 生成凭证
     * @param data 单条凭证数据
     */
    async createVoucher(data) {
      let list = [];
      const pa = {
        sourceType: "002",
        accountId: this.params.accountId
      };
      if (data) {
        list.push(data);
      } else {
        list = this.tableData.filter((itm) => itm.checked === true);
      }
      if (list.length === 1) {
        pa.ids = list[0].id;
        if (list[0].status === "002") { // 生成异常
          const { refId } = list[0];
          if (refId && refId.includes(",")) {
            // 来源多条凭证，先弹窗选择
            this.popUnusualVoucher(refId);
          } else {
            this.actionType = 3;
            this.voucherId = refId;
            this.$refs.vocdialog.visible = true;
            // this.$router.push({
            //   path: "/intelligent-accounting/voucherEdit",
            //   query: {
            //     actionType: 3,
            //     id: refId,
            //     isCheckPage: false,
            //     accountId: pa.accountId
            //   }
            // });
            // 跳转 编辑凭证页面
          }
        } else {
          await credentialService.generateVoucher(pa);
          this.queryVoucherList();
        }
      } else {
        // 判断是不是不同状态的数据
        // 判断单据创建日期是否属于同一天
        const sts = [];
        const idAry = [];
        list.forEach((itm) => {
          if (!sts.includes(itm.status)) {
            sts.push(itm.status);
          }
          idAry.push(itm.id);
        });

        if (sts.length > 1) {
          Message("生成状态不同的数据不可同时生成凭证");
        } else if (sts.length === 1 && sts[0] === "002") {
          const rds = [];
          list.forEach((itm) => {
            rds.push(itm.refId);
          });
          this.popUnusualVoucher(rds.join(","));
        } else {
          pa.ids = idAry.join(",");
          this.generateParam = pa;
          this.$refs.dateMod.visible = true;
        }
      }
    },
    /**
     * @description: 生成异常凭证弹窗
     * @param ids
     * @return:
     */
    popUnusualVoucher(ids) {
      this.unusualVoucherIds = ids;
      this.$refs.unusualMod.visible = true;
    },
    /**
     * @description: 删除凭证
     * @param data 单条操作的数据
     */
    deleteVoucher(data) {
      const _this = this;
      let list = [];
      const ids = [];
      if (data) {
        list.push(data);
      } else {
        list = this.tableData.filter((itm) => itm.checked === true);
      }
      list.forEach((itm) => {
        ids.push(itm.id);
      });
      const pa = { accountId: this.params.accountId, ids: ids.join(",") };

      MessageBox.confirm("将勾选的单据删除后，需要重新提交单据才能生成凭证，确定删除？", "", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "warning"
      }).then(async() => {
        await credentialService.deleteVoucher(pa);
        _this.queryVoucherList();
      });
    },
    // 获取确定记账日期
    async getConfirmDateValue(value) {
      this.generateParam.produceDate = value;
      await credentialService.generateVoucher(this.generateParam);
      this.queryVoucherList();
    },
    // 表格行按钮事件监听
    buttonClick(row, btnData) {
      const { id } = btnData;
      if (id === "generate") { // 生成凭证
        this.createVoucher(row);
      } else {
        this.deleteVoucher(row);
      }
    },
    /**
     * @description: 表格筛选排序
     * @param data
     */
    handleFilterSortChange(data) {
      console.log(this.$refs.tableColumn);
      this.resetPageModel();
      if ((!data.filterData || data.filterData === "[]") && (!data.sortData || data.sortData === "{}")) { // 清空
        this.params.filterFields = [];
        this.params.sortFields = {};
        this.queryVoucherList();

        this.$nextTick(() => {
          const columns = this.$refs.tableColumn;
          columns.forEach((col) => {
            this.$set(col, "filterData", []);
          });
        });

        return;
      }
      const { type, filterData } = data;
      const fd = JSON.parse(filterData);
      (fd || []).forEach((itm) => {
        if (itm.nameVariable === "status") {
          itm.value = itm.valueText;
        }
      });

      if (type === "sort") { // 排序
        this.params.sortFields = data.sortData;
      } else if (type === "filter") {
        this.params.filterFields = fd;
      } else {
        this.params.sortFields = "";
        this.params.filterFields = [];
      }
      this.queryVoucherList();
    },
    resetPageModel() {
      this.pageModel.pageNo = 1;
      this.params.pageNo = 1;
      this.pageModel.pageSize = 10;
      this.params.pageSize = 10;
    },
    /**
     * @description: 编辑凭证 弹窗
     * @param data
     */
    popDetail(id) {
      this.voucherId = id;
      this.$refs.vocdialog.visible = true;
    },
    /**
     * @description: 异常凭证编辑提交后 触发异常凭证列表更新
     * @param data
     */
    callback() {
      this.$refs.unusualMod.queryData();
      this.queryVoucherList();
    },
    /**
     * @description: 行点击穿透业务建模
     * @param row
     */
    toBusinessDetail({ row }) {
      this.$set(this.businessParams, "templateId", row.templateId);
      this.$set(this.businessParams, "id", row.id);
      this.dialogVisible = true;
    },
    checkDisabledMethod({ row }) {
      const { status } = row;
      return status === "003";
    },
    getStatusName(status) {
      const map = {
        "001": "待生成",
        "002": "生成异常",
        "003": "已生成"
      };
      return map[status];
    }
  }
};
</script>
<style lang='scss' scoped>
.credential {
  height: 100%;
  background: #fff;
  .header-contain {
    height: 49px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8ee;
    .header-left {
      margin-left: 19px;
      display: flex;
      align-items: center;

      .header-title {
        color: #1A1C1E;
        font-size:14px;
        margin-right: 11px;
        width: 60px;
        height: 15px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
      }
      .header-filter {
        display: flex;
        .filter-select {
          margin-right: 6px;
        }
      }
    }
    .header-right {
      margin-right: 19px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .button {
        margin-left: 20px;
      }
    }
  }
  .content {
    position: relative;
    height: calc(100% - 50px);
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 0;
    .table-content {
      height: calc(100% - 56px);
      .table{
        height: 100%;
      }
      & /deep/ .vxe-hover-row-right-wrapper{
        padding-right: 10px;
      }
      & /deep/ .vxe-table:after{
        display: none;
      }
      & /deep/ .transfer-data{
        height: 100px;
      }
      & /deep/ .vxe-table--repair{
        height: 0;
      }
      & /deep/ .pagination {
        border-top: 1px solid #e8e8ee;
      }
    }
    .status-mod{
      display: inline-block;
      padding: 5px 10px;
      border-radius: 3px;
      &.status001{
        background: #E8F2FACC;
        color: #3e90fe;
      }
      &.status002{
        background: #F6EBEA;
        color: #F66A69;
      }
      &.status003{
        background: #26C3931A;
        color: #26C393;
      }
    }
    .content-bottom {
      border-top: 1px solid #e8e8ee;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
