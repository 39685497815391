<!--
 * @Author: pengyu
 * @Date: 2020-03-10 11:27:09
 * @LastEditTime: 2021-08-19 09:45:47
 * @LastEditors: wuqi
 * @Description: 凭证处理-分录视图
 * @FilePath: \user-intelligent-accounting\src\views\voucherHandle\voucherEntry.vue
 -->
<template>
  <div class="container" style="background:#1d2532">
    <div :class="isActive ? 'left-container expand' : 'left-container'">
      <top-nav
        ref="topNav"
        :is-check-page="isCheckPage"
        cur-view="1"
        :acc-list="accList"
        :entity-list="entityList"
        :batch-btn="batchBtn"
        :self-btn="selfBtn"
        :params="params"
        :selectDisable="selectDisable"
        @change-param="handleChangeParmas"
        @top-btn-click="topBtnClick"
      ></top-nav>
      <div class="content" v-en-loading="enLoading">
        <div
          class="condition"
          :class="this.params.conditions.length ? 'expand' : ''"
        >
          <screenModel
            v-for="(item,index) in params.conditions"
            :key="index"
            :item="item"
            :index="index"
            @deleteCondition="deleteCondition"
          ></screenModel>
        </div>
        <div class="nodata" v-if="!voucherData.length">
          <en-result type="noData"></en-result>
        </div>
        <div class="entry-row" v-if="voucherData.length">
          <entry-model
            v-for="(item, index) in voucherData"
            :key="index"
            :comm-params="commParams"
            :is-check-page="isCheckPage"
            :index="index"
            :row-data="item"
            :checked-all="checkedAll"
            @init-batch="changeBatchBtn"
            @single-click="singleClick"
          ></entry-model>
        </div>
        <div class="pagi" v-if="voucherData.length">
          <en-pagination
            :page-model="pageModel"
            @change="handlePageChanged"
          ></en-pagination>
        </div>
      </div>
    </div>

    <!-- 筛选 -->
    <screenAlert
      ref="popScreen"
      :dateList="dateLineList"
      :accountId="params.accountId"
      :initData="params.conditions"
      @submitScreen="handleSubmitScreen"
    ></screenAlert>
    <!-- 打印 -->
    <printAlert ref="popPrint"></printAlert>
    <!-- 操作日志 -->
    <logAlert ref="popLog" :logParam="logParam"></logAlert>
    <!-- 会计期间 -->
    <dateInterval
      :hideHead="false"
      class="date-interval"
      :type="1"
      :list="dateLineList"
      @collapseBtn="isActive=!isActive"
      @handleAccChange="accChange"
    ></dateInterval>
    <!-- 打印模板 -->
    <vocPrintTemplate></vocPrintTemplate>
    <!-- 凭证详情 -->
    <vocDetailAlert
      ref="VDA"
      :actionType="actionType"
      :voucherId="voucherId"
      :voucherIdAry="voucherIdAry"
      :isCheckPage="isCheckPage"
      :formal="detailProp"
      refreshFunc="queryVoucherList"
    ></vocDetailAlert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { intelligentService } from "@/api";
import voucherButtonEvent from "@/mixins/intelligentAccounting/voucherButtonEvent"; // 按钮公共方法
import voucherComm from "@/mixins/intelligentAccounting/voucherComm"; // 查询公共参数
import topNav from "../components/topNav";// 顶部操作栏
import screenModel from "../components/screenModel";// 筛选块块";
import screenAlert from "../components/screenAlert";// 筛选弹窗
import printAlert from "../components/printSetting"; // 打印设置
import logAlert from "../components/logAlert"; // 操作日志
import dateInterval from "../components/dateInterval"; // 会计期间控件
import entryModel from "./components/entryModel"; // 分录行
import vocPrintTemplate from "../components/vocPrintTemplate"; // 打印模板
import vocDetailAlert from "../components/vocDetailAlert"; // 凭证详情弹窗

export default {
  name: "VoucherEntry",
  components: {
    topNav, screenModel, entryModel, screenAlert, printAlert, logAlert, dateInterval, vocPrintTemplate, vocDetailAlert
  },
  mixins: [voucherButtonEvent, voucherComm],
  data() {
    return {
      enLoading: null,
      // 查询凭证数据参数
      params: {
        conditions: [],
        filterFields: "",
        sortFields: "",
        accountingPeriod: "",
        accountingPeriodMainId: "",
        accountId: "",
        accountingEntity: "",
        itemFlag: 0,
        pageSize: 10,
        pageNo: 1,
        queryType: 0
      },
      dateLineParam: {
        accountingPeriod: "",
        accountingPeriodName: "",
        yearNum: "",
        accountingPeriodMainId: ""
      },
      // 分页参数
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 2,
        totalPages: 1
      },
      accList: [], // 账套列表
      entityList: [], // 记账主体
      dateLineList: [], // 会计期间数据
      voucherData: [], // 分录行数据
      batchBtn: false, // 批量按钮是否显示
      checkedAll: false,
      isActive: false,
      isCheckPage: false, // 区分是否是 凭证审核页面
      commParams: {}, // 凭证管理端配置项
      settleParam: {}, // 结账页面穿透带过来的参数
      selectDisable: false,
      detailProp: {
        accountId: "",
        accountName: "",
        accountingEntity: "",
        accountingEntityName: ""
      },
      actionType: 1,
      voucherId: "",
      voucherIdAry: [], // 当前页凭证ids
      selfBtn: [], // 自定义按钮
      logParam: {}
    };
  },
  watch: {
    $route(to, from) {
      // 凭证处理 和 凭证审核公用一个组件 两个路由切换时  需要重新加载
      if ((to.path === "/intelligent-accounting/voucherExamine" && from.path === "/intelligent-accounting/voucherEntry")
      || (from.path === "/intelligent-accounting/voucherExamine" && to.path === "/intelligent-accounting/voucherEntry")
      ) {
        this.init();
      }
    }
  },
  provide() {
    return {
      $voucherEntry: this
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("intelligentAccounting", [
      "getSaPzclConditionlist"
    ])
  },
  methods: {
    /**
     * @description: 初始化
     */
    async init() {
      this.resetData();
      this.getRouteParam();
      // 查询自定义按钮
      const rsp = await intelligentService.queryBtnFunc();
      if (rsp) {
        this.selfBtn = [...rsp];
      }
      await this.queryAccountList();
      // 结账穿透过来的，会有默认账套，默认记账主体， 默认条件等
      if (this.settleParam.flg === "1") {
        this.selectDisable = true;
        this.params.accountId = this.settleParam.accountId;
        this.params.accountingEntity = this.settleParam.accountingEntity;
        this.params.conditions = this.getSaPzclConditionlist || [];
      } else {
        this.selectDisable = false;
      }
      await this.queryDateLine({ accountId: this.params.accountId, hasChildren: "000" });
      // 结账穿透过来的，会有默认会计期间
      if (this.settleParam.flg === "1") {
        this.params.accountingPeriod = this.settleParam.accountingPeriod;
        this.dateLineList.forEach((itm) => {
          (itm.nodes || []).forEach((citm) => {
            if (citm.id === this.settleParam.accountingPeriod) {
              citm.isDefault = 0;
              this.dateLineParam = {
                accountingPeriod: citm.id,
                accountingPeriodName: citm.name,
                yearNum: citm.yearNum,
                accountingPeriodMainId: citm.mId
              };
            } else {
              citm.isDefault = 1;
            }
          });
        });
      }

      await this.queryVoucherList();
    },
    /**
     * @description: 判断当前路由是凭证审核 还是 凭证处理
     * @return:
     */
    getRouteParam() {
      const { path, query } = this.$route;
      if (query && query.flg === "1") {
        // 从结账页面穿透过来的
        this.settleParam = { ...query };
      } else {
        this.settleParam = {};
      }
      this.isCheckPage = path.includes("voucherExamine");
      this.params.queryType = this.isCheckPage ? 1 : 0;
    },
    /**
     * @description: 凭证审核和凭证处理切换时 用来重置data
     */
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
    setProps() {
      const val = this.$refs.topNav.getCurValue();
      const { accountId, accountingEntity } = val;
      this.detailProp = {
        accountId,
        accountName: (() => {
          let n = "";
          this.accList.forEach((itm) => {
            if (itm.id === accountId) {
              n = itm.name;
            }
          });
          return n;
        })(),
        accountingEntity,
        accountingEntityName: (() => {
          let n = "";
          this.entityList.forEach((itm) => {
            if (itm.id === accountingEntity) {
              n = `${itm.code} ${itm.name}`;
            }
            (itm.nodes || []).forEach((citm) => {
              if (citm.id === accountingEntity) {
                n = `${citm.code} ${citm.name}`;
              }
            });
          });
          return n;
        })()
      };
    },
    /**
     * @description: 手动筛选回调
     */
    handleSubmitScreen(list) {
      this.params.conditions = list;
      this.queryVoucherList();
    },
    /**
     * @description:  筛选块块删除
     * @param index
     */
    deleteCondition(index) {
      this.params.conditions.splice(index, 1);
      // 更新条件 查询一遍数据
      this.queryVoucherList();
    }
  }
};
</script>

<style lang="scss" scoped>
$ash: #dce5ec;
.container {
  width: 100%;
  position: relative;
  .left-container {
    height: 100%;
    width: calc(100% - 72px);
    background: #ffffff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    &.expand {
      width: 100%;
    }
  }
  .content {
    height: calc(100% - 50px);
    background: #ffffff;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    .condition {
      width: 100%;
      display: flex;
      align-items: center;
      // min-height: 10px;
      // border-bottom: 1px solid #dce5ec;
      &.expand {
        height: 60px;
      }
    }
    .entry-row {
      height: calc(100% - 64px);
      overflow: hidden;
      overflow-y: auto;
      border-bottom: 1px solid $ash;
    }
    .pagi {
      height: 64px;
      .pagination{
        height: 100%;
      }
    }
  }
  .date-interval {
    position: absolute;
    right: 4px;
    top: 0;
  }
}
</style>
<style lang="scss">
.dh-confirm .el-icon-info{
  color: #3e90fe;
}
</style>
