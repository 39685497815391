<!--
 * @Author: pengyu
 * @Date: 2020-07-02 18:51:06
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-02 15:21:53
 * @Description: 生成异常凭证列表-弹窗
-->
<template>
  <en-dialog
    :visible="visible"
    title="凭证列表"
    class="unusual-voucher"
    width="1100px"
    :close-on-click-modal="false"
    append-to-body
    @close="visible = false"
  >
    <div class="table-wrap">
      <en-table
        :data="tableData"
        height="500px"
        :loading="loading"
        :page-config="{pageModel: pageModel, changeMethod: handlePageChanged}"
      >
        <en-table-column type="index" width="60" align="center"></en-table-column>
        <!-- <en-table-column prop="voucherNo" label="凭证号" width="180"></en-table-column> -->
        <en-table-column prop="refCode" label="凭证来源" width="200"></en-table-column>
        <en-table-column prop="totalD" label="借方合计" header-align="left" align="right">
          <template v-slot="{row}">
            {{row.totalD | $thousand}}
          </template>
        </en-table-column>
        <en-table-column prop="totalC" label="贷方合计" header-align="left" align="right">
          <template v-slot="{row}">
            {{row.totalC | $thousand}}
          </template>
        </en-table-column>
        <en-table-column prop="produceDate" label="记账日期">
          <template v-slot="{row}">
            {{row.produceDate.substr(0, 10)}}
          </template>
        </en-table-column>
        <en-table-column prop="status" label="凭证状态">
          <template v-slot="{row}">
            <statusModel :status="row.status"></statusModel>
          </template>
        </en-table-column>
        <en-table-column prop="remark" label="备注"></en-table-column>
        <template #hoverRow="{row}">
          <en-icon
            name="bianji-liebiao"
            size="small"
            color="#27C393"
            title="编辑"
            @click.native="editVoucher(row)"
          ></en-icon>
        </template>
      </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { intelligentService } from "@/api";
import statusModel from "../../components/statusModel";

export default {
  name: "UnusualVoucher",
  components: { statusModel },
  props: {
    ids: { // 异常凭证id
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: false,
      tableData: [],
      loading: false,
      pageModel: {
        pageSize: 10,
        pageNo: 1,
        total: 0,
        totalPages: 0
      }
    };
  },
  watch: {
    visible(nVal) {
      nVal && this.queryData();
    }
  },
  methods: {
    /**
     * @description: 查询异常凭证数据
     */
    async queryData() {
      try {
        this.loading = true;
        const pa = {
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize,
          filterFields: `[{field: "idList",name:"",fieldType:"1",sort:true,filter:true,show:true,nameVariable:"idList",width:213,"value": "${this.ids.toString()}"}]`
        };
        const rsp = await intelligentService.queryVoucherList(pa);
        this.pageModel.total = rsp.total;
        this.pageModel.totalPages = rsp.totalPages;
        this.loading = false;
        this.tableData = rsp.records;
      } catch (error) {
        this.loading = false;
      }
    },
    /**
     * @description:  分页控件事件
     * @param pageNo
     * @param pageSize
     */
    handlePageChanged({ pageNo, pageSize }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.queryData();
    },
    /**
     * @description:  编辑凭证
     * @param row
     */
    editVoucher(row) {
      // 跳转 编辑凭证详情
      this.$eventHub.$emit("toDetail", row.id);
    }
  }
};
</script>

<style lang='scss' scoped>
.unusual-voucher{
  & /deep/ .el-dialog__header{
    text-align: left;
  }
  & /deep/ .vxe-hover-row-right-wrapper{
    padding-right: 10px;
  }
  .en-table{
    height: 100%;
  }
  & /deep/ .en-icon{
    margin-top: 15px;
    cursor: pointer;
  }
  & /deep/ .vxe-table--repair{
    height: 0;
  }
}
</style>
