<!--
 * @Author: pengyu
 * @Date: 2020-11-19 10:47:20
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-15 15:51:34
 * @Description: 结转
-->
<template>
  <div class="carry-over">
    <div class="content" :class="isActive ? 'left-container expand' : 'left-container'">
      <en-title-card name="结转">
        <div slot="right">
          <searchList
            :searchDataList="searchDataList"
            :defaultSearchData="defaultSearchData"
            :hideVocRange="true"
            :accountMainMulti="false"
            @clickData="getVal"
          ></searchList>
          <div class="btn-export" @click="handleLog">
            <en-icon name="pingzhengrizhi" size="small" style="color:#a9b5c6"></en-icon>
            <div class="label">日志</div>
          </div>
          <div class="btn-print" v-show="showCalBtn" @click="testAllMoney">
            <en-icon name="yijiancesuan-zhinenghesuanyonghu" size="small" style="color:#3e90fe"></en-icon>
            <div class="label">一键测算</div>
          </div>
        </div>
      </en-title-card>
      <!--列表部分-->
      <div class="table-main" v-en-loading="enLoading">
        <en-result v-if="!list.length" type="NoData"></en-result>
        <carry-over-mod
          v-for="(mod) in list"
          :key="Math.random()"
          :mod="mod"
          :periodIsClose="periodIsClose"
          :prevIsClose="prevIsClose"
          @generateVoucher="generateVoucher"
          @testMoney="testMoney"
          @toDetail="toDetail"
        ></carry-over-mod>
      </div>
    </div>

    <!-- '会计期间' -->
    <dateInterval
      ref="dateline"
      :type="1"
      :hideHead="true"
      :list="dateLineList"
      :selected="{isCloseAccount: 0}"
      @collapseBtn="isActive = !isActive"
      @handleAccChange="accChange"
      class="date-interval"
    ></dateInterval>
    <!-- 日志 -->
    <carryoverLog ref="carryLog" :refId="refId"></carryoverLog>
    <!-- 打印模板 -->
    <vocPrintTemplate></vocPrintTemplate>
    <!-- 凭证详情 -->
    <vocDetailAlert
      ref="vocDialog"
      :voucherId="voucherId"
      :actionType="actionType"
      :fromCarryOver="true"
      :formal="formal"
      refreshFunc="queryData"
      :cache="isCache"
    ></vocDetailAlert>
  </div>
</template>

<script>
import { request } from "en-js";
import { Message } from "element-ui";
import { mapMutations } from "vuex";
import dayjs from "dayjs";
import { fmoney } from "@/tools/util.js";
import { carryOverService } from "@/api/intelligentAccounting";
import accountBookComm from "@/mixins/intelligentAccounting/accountBookComm.js"; // 公用的JS方法
import searchList from "../components/searchList"; // 顶部搜索
import dateInterval from "../components/dateInterval"; // 会计期间控件
import carryoverLog from "../components/carryoverLog";
import vocDetailAlert from "../components/vocDetailAlert";
import vocPrintTemplate from "../components/vocPrintTemplate"; // 打印模板
import carryOverMod from "./components/carryOverMod.vue";

export default {
  name: "FinalCarryOver",
  components: {
    searchList,
    dateInterval,
    carryoverLog,
    vocDetailAlert,
    vocPrintTemplate,
    carryOverMod
  },
  provide() {
    return {
      CarryOver: this
    };
  },
  mixins: [accountBookComm],
  data() {
    return {
      enLoading: null,
      isActive: false,
      multiColtype: true,
      searchDataList: {}, // 搜索的下拉数据
      list: [],
      dateLineList: [], // 会计期间数据
      defaultYearNum: "", // 会计区间选中年份
      accountId: "", // 所属账套id
      accountList: [], // 账套下拉数据
      accountMainList: [], // 记账主体下拉数据
      accountDefault: "", // 默认账套
      accountMainDefault: [], // 默认记账主体
      defaultSearchData: {}, // 搜索默认数据
      accountingPeriod: [], // 会计期间的id
      actionType: 1,
      voucherId: "",
      isCache: false,
      formal: {}
    };
  },
  computed: {
    showCalBtn() { // 是否显示一键测算按钮
    // 当前会计期间为本账套的第一个会计期间
    // 记账主体对应会计期间前一个会计期间已经结账
      return !(this.periodIsClose || !this.prevIsClose) && this.list.length;
    },
    periodIsClose() { // 当前已结账
      const _this = this;
      let flg = false;
      this.dateLineList.forEach((itm) => {
        itm.nodes.forEach((citm) => {
          if (_this.accountingPeriod.toString() === citm.id && citm.isCloseAccount === 0) {
            flg = true;
          }
        });
      });
      return flg;
    },
    prevIsClose() { // 上一期间已结账
      const _this = this;
      let flg = false;
      this.dateLineList.forEach((itm, index) => {
        itm.nodes.forEach((citm, cindex) => {
          if (_this.accountingPeriod.toString() === citm.id
              && ((cindex !== 0 && itm.nodes[cindex - 1].isCloseAccount === 0) || (index === 0 && cindex === 0))) {
            flg = true;
          }
        });
      });
      return flg;
    },
    refId() { // 日志查询参数
      return `${this.accountDefault},${this.accountMainDefault.toString()},${this.accountingPeriod.toString()}`;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations("intelligentAccounting", [
      "setCacheVoucherData"
    ]),
    /**
     * @description 初始化
    */
    async init() {
      await this.queryAccList(); // 请求账套和日期区间
      await this.queryAccountEntity(true); // 记账主体
      await this.queryAccTime();
      this.queryData();
    },
    // 请求日期区间数据接口
    async queryAccTime() {
      const res = await carryOverService.queryCloseAccountAccTime({
        accountId: this.accountDefault,
        accountingEntity: this.accountMainDefault.toString(),
        hasChildren: "000"
      });
      this.dateLineList = res;
      this.accPeriod();
    },
    /**
     * @description 处理会计期间数据  查询默认值
     */
    accPeriod() {
      // 默认会计区间获取会计区间选中ID
      function findDefaultPeriod(arr) {
        const nodesCur = [];
        const nodesCurName = [];
        const yearNum = [];
        const dateNameList = [];
        arr.forEach((v) => {
          if (v.isDefault === 0) {
            nodesCur.push(v.id);
            nodesCurName.push(v.name);
            yearNum.push(v.yearNum);
            dateNameList.push(v.name);
          }
        });
        return {
          nodesCur, nodesCurName, yearNum, dateNameList
        };
      }

      this.dateLineList.forEach((item) => {
        if (item.isDefault === 0) {
          if (item.nodes) {
            const findDefaultData = findDefaultPeriod(item.nodes);
            this.accountingPeriod = findDefaultData.nodesCur;
            this.accountingPeriodName = findDefaultData.nodesCurName;
            this.defaultYearNum = findDefaultData.yearNum.toString();
            this.startDate = findDefaultData.dateNameList[0];
            this.endDate = findDefaultData.dateNameList[findDefaultData.dateNameList.length - 1];
          } else {
            this.defaultYearNum = item.name;
          }
        }
      });
    },
    /**
     * @description: 日期区间选中数据
     */
    async accChange(data) {
      this.accountingPeriod = data.ids;
      this.accountingPeriodName = data.names;
      this.defaultYearNum = data.yearNum;
      let dateNames = [];
      dateNames = data.names.split(",");
      this.startDate = dateNames[0];
      this.endDate = dateNames[dateNames.length - 1];
      this.queryData();
    },
    /**
     * @description: 取搜索组件带出数据
     */
    async getVal(data) {
      const originAccount = this.accountDefault;
      const originEntity = this.accountMainDefault;
      if (data.accountMain.length === 0) {
        Message("请选择记账主体");
        return;
      }

      this.accountDefault = data.account;
      this.accountMainDefault = data.accountMain;
      if (data.account !== originAccount || data.accountMain !== originEntity) {
        // 账套或者记账主体发生改变   重新查询会计期间
        await this.queryAccTime();
      }
      this.queryData();
    },
    /**
     * @description 查询结转数据
    */
    @request(true, "enLoading")
    async queryData() {
      const pa = this.getCommParam();
      delete pa.accountName;
      delete pa.accountingEntityName;
      delete pa.accountingPeriodName;
      pa.pageSize = 99;
      pa.pageNo = 1;
      const rsp = await carryOverService.queryFinalCarryOverList(pa);
      this.list = rsp.records || [];
    },
    /**
     * @description 公共参数
    */
    getCommParam() {
      const _this = this;
      const params = {
        accountId: this.accountDefault,
        accountName: (() => {
          const o = this.accountList.filter((itm) => itm.id === _this.accountDefault);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        accountingEntity: this.accountMainDefault.toString(),
        accountingEntityName: (() => {
          const re = [];
          _this.accountMainList.forEach((itm2) => {
            if (itm2.id === this.accountMainDefault.toString()) {
              re.push(`${itm2.code} ${itm2.name}`);
            }
            itm2.nodes?.forEach((itm3) => {
              if (itm3.id === this.accountMainDefault.toString()) {
                re.push(`${itm3.code} ${itm3.name}`);
              }
            });
          });
          return re.join(",");
        })(),
        accountingPeriod: this.accountingPeriod.toString(),
        accountingPeriodName: this.accountingPeriodName.toString()
      };

      return params;
    },
    getVmoney(data) {
      if (data.voucher) {
        return data.voucher.totalAmountD;
      }
      if (data.amount) {
        return data.amount;
      }
      if (this.periodIsClose) {
        return "0";
      }
      return "?";
    },
    /**
     * @description 测算金额
    */
    async testMoney(id) {
      if (!id) {
        return;
      }
      try {
        this.$showLoading();
        const param = this.getCommParam();
        param.ids = id;
        const rsp = await carryOverService.evaluateFinalCarryOver(param);
        this.$hideLoading();
        rsp.forEach((itm) => {
          const { id, amount } = itm;
          const index = this.list.findIndex((mod) => mod.id === id);
          const mod = this.list[index];
          mod.showVoc = true; // 显示生成凭证按钮
          mod.amount = fmoney(Number(amount));
          this.list[index] = mod;
          // this.$set(this.list, index, mod);
          this.list.splice(0, 0);
        });
      } catch (error) {
        this.$hideLoading();
      }
    },
    /**
     * @description 一键测算全部金额
    */
    testAllMoney() {
      const ids = [];
      this.list.forEach((itm) => {
        ids.push(itm.id);
      });
      this.testMoney(ids.join(","));
    },
    /**
     * @description 结转日志
    */
    handleLog() {
      this.$refs.carryLog.visible = true;
    },
    /**
     * @description 查看凭证详情
    */
    toDetail(id) {
      this.isCache = false;
      this.voucherId = id;
      this.actionType = 2;
      this.$refs.vocDialog.visible = true;
    },
    /**
     * @description 编辑凭证
    */
    async generateVoucher(id) {
      this.$showLoading();
      try {
        const pa = this.getCommParam();
        const params = { ...pa };
        delete params.accountName;
        delete params.accountingEntityName;
        delete params.accountingPeriodName;
        params.id = id;
        const rsp = await carryOverService.generateVoucherFinalCarryOver(params);
        this.$hideLoading();
        const curDate = this.$refs.dateline.getCurDate()[0] || {};

        const vd = {
          accountId: pa.accountId,
          accountName: pa.accountName,
          accountingEntity: pa.accountingEntity,
          accountingEntityName: pa.accountingEntityName,
          accountingPeriod: pa.accountingPeriod,
          accountingPeriodMainId: curDate.objectId || "",
          accountingPeriodName: pa.accountingPeriodName,
          vcTemplateId: id,
          refType: "003",
          produceDate: dayjs(curDate.endDate).format("YYYY-MM-DD HH:mm:ss")
        };
        const vh = [
          {
            fieldName: "记账日期",
            fieldNameVariable: "produceDate",
            fieldType: "001",
            timeFormat: "8",
            type: 7,
            value: vd.produceDate
          }, {
            fieldName: "会计期间",
            fieldNameVariable: "accountingPeriod",
            fieldType: "001",
            type: 7,
            value: {
              accountingPeriodMainId: vd.accountingPeriodMainId,
              id: vd.accountingPeriod,
              name: vd.accountingPeriodName,
              yearNum: curDate.yearNum
            }
          }, {
            fieldName: "记账主体",
            fieldNameVariable: "accountingEntity",
            fieldType: "001",
            type: 1,
            value: {
              id: vd.accountingEntity,
              name: vd.accountingEntityName
            }
          }, {
            fieldName: "账套名称",
            fieldNameVariable: "accountId",
            fieldType: "001",
            type: 1,
            value: vd.accountName
          }
        ];
        const vg = {
          itemList: [...rsp.itemList],
          voucherData: { ...vd, status: "004" },
          voucherHeader: vh
        };
        this.voucherId;
        this.cache = true;
        this.actionType = 3;
        this.setCacheVoucherData(vg);
        this.formal = { ...vd };
        this.$refs.vocDialog.visible = true;
      } catch (error) {
        this.$hideLoading();
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.carry-over{
  position: relative;
  background-color: #1d2532;
  .en-title-card {
    overflow: hidden;
  }
  .search{
    margin-right: 0;
  }
  .btn-export,
  .btn-print {
    position: relative;
    cursor: pointer;
    margin-left: 15px;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      color: #636c78;
    }
  }
}
.left-container {
  height: 100%;
  width: calc(100% - 72px);
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.expand {
    width: 100%;
  }
}
.date-interval {
  position: absolute;
  right: 4px;
  top: 0;
}
.table-main{
  padding: 20px;
  height: calc(100% - 50px);
}
</style>
