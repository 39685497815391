var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "choice-subject" },
    [
      _c(
        "div",
        { staticClass: "left-arrow", on: { click: _vm.prevtSubject } },
        [
          _c("en-icon", {
            attrs: { name: "xiayiye1", size: "10px", color: "#b6c0cf" },
          }),
        ],
        1
      ),
      _c("en-select", {
        attrs: {
          placeholder: "请选择",
          "node-key": "id",
          data: _vm.subjectList,
          props: _vm.subjectProps,
          filterable: "",
          align: "left",
          "data-mode": "id-name",
        },
        on: { change: _vm.subjectClk },
        model: {
          value: _vm.subjectId,
          callback: function ($$v) {
            _vm.subjectId = $$v
          },
          expression: "subjectId",
        },
      }),
      _c(
        "div",
        { staticClass: "right-arrow", on: { click: _vm.nextSubject } },
        [
          _c("en-icon", {
            attrs: { name: "xiayiye", size: "10px", color: "#b6c0cf" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }