var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "log-alert",
      attrs: { visible: _vm.visible, title: "结转日志", width: "1100px" },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "en-loading",
              rawName: "v-en-loading",
              value: _vm.enLoading,
              expression: "enLoading",
            },
          ],
          staticClass: "table-wrap",
        },
        [
          _c(
            "en-table",
            {
              attrs: {
                data: _vm.tableData,
                "page-config": {
                  pageModel: _vm.pageModel,
                  changeMethod: _vm.handlePageChanged,
                },
              },
              on: { "filter-sort-change": _vm.handleFilterSortChange },
            },
            [
              _c("en-table-column", { attrs: { type: "index", width: "60" } }),
              _vm._l(_vm.columnData, function (item, index) {
                return _c("table-column", {
                  key: index,
                  attrs: { data: item, width: item.width },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }