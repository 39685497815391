<!--
 * @Author: zoujp
 * @Date: 2020-06-28 14:19:06
 * @LastEditTime: 2021-08-03 16:05:53
 * @LastEditors: Please set LastEditors
 * @Description: 财务报表-详情页
 * @FilePath: \user\src\views\intelligentAccounting\financialReport\reportList.vue
-->
<template>
  <div class="container">
    <div :class="isActive ? 'left-container expand' : 'left-container'">
      <div class="list-title">
        <div class="title-desc" @click="back">
          <en-icon name="iconfanhuishangcengji" size="small" color="#A9B5C6" class="icon-cls"> </en-icon>
          <span class="title">{{ title }}</span>
        </div>
        <div class="title-btn" v-show="isShowBtn">
          <en-button icon="filter" iconColor="#9367EB" iconSize="16" color="#636C78" text @click="searchFn">
            筛选
          </en-button>
          <en-button icon="daochu" iconSize="16" iconColor="#26C393" color="#636C78" text @click="exportFn">
            导出
          </en-button>
          <en-button icon="bus-btn6_1" iconSize="16" iconColor="#3e90fe" color="#636C78" text @click="printFn">
            打印
          </en-button>
        </div>
      </div>
      <div class="list-content">
        <div class="charts-wrap" v-show="isShowCharts">
          <div id="charts" class="charts-inner"></div>
        </div>
        <div class="list-wrap">
          <div class="list-tit">
            <div>编制单位：{{ accountingEntityName }}</div>
            <div>{{ accountingStand }}</div>
            <div>单位：{{ accountCurrency }}元</div>
          </div>
          <div class="list-conent">
            <div class="list-content-inner">
              <div class="table-head">
                <div v-for="(item, hindex) in headData" :key="item.col" class="head-mod" :style="getValue(item, 0) | getCellStyle">
                  <div class="cell-main">
                    <div class="tit">
                      <en-icon v-if="hindex === 0" :name="isExpandAll ? 'zhankai' : 'shouqi'" size="10px" color="#A9B5C6" @click.native="expandAllLevel()"> </en-icon>
                      <span title="">{{ getValue(item, 0).value }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-body">
                <div v-for="(row, index) in rowData" :key="index" class="row-mod">
                  <div class="row" :style="{ 'background-color': row._bgc }">
                    <div v-for="(col, cindex) in row.cols" :key="col.col" class="cell-mod" :class="{ 'cell-level': cindex === 0 }" :style="getValue(col) | getCellStyle">
                      <div class="cell-main">
                        <div class="tit">
                          <en-icon
                            v-if="cindex === 0"
                            :name="row._isExpand && row.child.length ? 'zhankai' : 'shouqi'"
                            v-show="row.child.length"
                            size="10px"
                            color="#A9B5C6"
                            @click.native="expandLevel(row)"
                          >
                          </en-icon>
                          <span title="" :class="{ 'expand-first-cell': row.child.length === 0 }">{{ getValue(col).value }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row-child" v-if="row.child.length" v-show="row._isExpand">
                    <div v-for="(rowhd, indexhd) in row.child" :key="indexhd" class="row" :style="{ 'background-color': rowhd._bgc }">
                      <div v-for="colhd in rowhd" :key="colhd.col" class="cell-mod" :style="getValue(colhd) | getCellStyle">
                        <div class="cell-main">
                          <div class="tit">
                            <span title="" class="expand-child-cell">{{ getValue(colhd).value }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 会计期间 -->
    <dateInterval
      v-if="rType === 1"
      class="check-vtime"
      :type="type"
      :hideHead="type == 2 || (type == 1 && isJZRQ)"
      :list="dateLineList"
      :cur="cur"
      @collapseBtn="isActive = !isActive"
      @handleAccChange="accChange"
    ></dateInterval>
    <quarterInterval v-else class="check-vtime" :type="type" :list="dateLineList" :cur="cur" @collapseBtn="isActive = !isActive" @handleAccChange="accChange"> </quarterInterval>
    <recordFilter ref="popReportFilter" :conditions="cond" :accountId="params.accountId" :list="dateLineList" :type="type" :startD="startDate" :endD="endDate" @saveFilter="saveFilter"></recordFilter>
    <print-template-financial :dataList="rowData" :headData="headData" :search-data="printSearchData"></print-template-financial>
  </div>
</template>

<script>
import { financialReport as FR } from "@/api";
import financialComm from "@/mixins/intelligentAccounting/financialComm";
import { base64Encode } from "@/tools/util";
import dateInterval from "../components/dateInterval"; // 会计期间控件
import quarterInterval from "../components/quarterInterval"; // 会计期间季度控件
import recordFilter from "./reportFilter"; // 过滤
import PrintTemplateFinancial from "./components/PrintTemplateFinancial";
/* eslint-disable */
// 查询公共参数
export default {
  name: "ReportList",
  components: {
    dateInterval,
    quarterInterval,
    recordFilter,
    PrintTemplateFinancial
  },
  mixins: [financialComm],
  data() {
    return {
      quarter: [
        { value: "1", text: "第一季度" },
        { value: "2", text: "第二季度" },
        { value: "3", text: "第三季度" },
        { value: "4", text: "第四季度" }
      ],
      rType: 1, // 1默认右侧会计期间，2季度期间
      cond: [], // 筛选条件
      dateLineList: [], // 会计期间数据
      SYD: "-01-01",
	    EYD: "-12-31",
      startDate: "", // 会计期间开始时间
      endDate: "" // 会计期间结束时间
    };
  },
  computed: {
    printSearchData() {
      const { accountingEntityName, accountCurrency, title } = this;
      return {
        accountingEntityName,
        accountCurrency,
        title,
        period: this.cond[0]?.valueText2
      };
    },
    isJZRQ() {
      let re = false;
      this.cond.forEach((itm) => {
        if (itm.type.toString() === "7") {
          // 记账日期的月，需要隐藏头部
          re = true;
        }
      });
      return re;
    }
  },
  watch: {
    dateLineList() {
      this.getStartEndDate();
      this.getStartEndDateJZRQ();
    }
  },
  mounted() {
    this.init();
  },
  filters: {},
  methods: {
    getDateAccuracy() {
      let re = { dateAccuracy: "" };
      this.cond.forEach((b) => {
        if (b.type.toString() === "7") {
          re = b;
        }
      });
      return re;
    },
    back(){
       this.$router.go(-1);
    },
    /**
     * @description: 初始化加载
     */
    init() {
      // 获取query参数
      const p = this.$route.query;
      this.params.accountId = p.accountId;
      this.params.accountingEntity = p.accountingEntity;
      this.params.templateId = p.templateId;
      this.getReportData();
    },
    /**
     * @description: 获取报表数据
     * @flag :true首次加载，false非首次加载
     */
    async getReportData() {
      this.$showLoading();
      const par = {
        templateId: this.params.templateId,
        isPreview: this.isPreview,
        conditions: JSON.stringify(this.cond || [])
      };
      this.reportData = await FR.queryFinanceReportData(par);
      if (!this.reportData.totalPic || Object.keys(this.reportData.totalPic).length === 0 || this.reportData.isPic === 1) {
        this.isShowCharts = false;
      } else {
        this.isShowCharts = true;
        this.$nextTick(() => {
          this.drawCharts();
          // setTimeout(() => { }, 500);
        });
      }
      this.initTitleInfo();
      this.isFirst && this.initConditions();
      this.drawTable();
      this.isShowBtn = true;
      this.$hideLoading();
    },
    /**
     * @description: 初始化头部信息
     */
    initTitleInfo() {
      this.title = this.reportData.templateName;
      this.accountingEntityName = this.reportData.accountingEntityName;
      this.accountingStand = this.reportData.accountingStand;
      this.accountCurrency = this.reportData.accountCurrency;
    },
    /**
     * @description: 如果是首次加载报表，将条件保存到查询条件中
     */
    initConditions() {
      let obj = {};
      let vtype;
      this.reportData.conditions?.forEach((v) => {
        if (v.type.toString() === "94") {
          v.rangeValue = v.value;
          v.rangeValueText = v.valueText;
          obj = v;
          vtype = v.type;
        } else if (v.type.toString() === "5") {
          v.value = v.initValue;
        } else if (v.type.toString() === "7") {
          v.rangeValue = v.value;
          v.rangeValueText = v.valueText;
          obj = v;
          vtype = v.type;
        }
      });
      this.cond = this.reportData.conditions || [];
      this.initDateLine(obj, vtype);
    },
    /**
     * @description: 初始化会计区间
     */
    async initDateLine(obj, vtype) {
      if (vtype.toString() === "94") {
        const par = {
          accountId: this.params.accountId,
          hasChildren: obj.dateAccuracy.toString() === "1" ? "000" : "001"
        };
        this.dlData = await FR.queryAccTimes(par);
        this.type = obj.dateAccuracy.toString() === "1" ? 1 : 2;
        this.dateLineList = this.type === 1 ? this.getAvaibleTime(obj) : this.getAvaibleYear(obj);
        this.cur = obj.initValue || "";
        this.getStartEndDate();
      } else if (vtype.toString() === "7") {
        this.initQuaterDateLine(obj);
        this.getStartEndDateJZRQ();
      }
    },
    /**
     * @description: 导出
     */
    async exportFn() {
      const params = {
        templateId:this.$route.query?.templateId,
        isPreview:"001",
        conditions:base64Encode(this.isFirst?"[]":JSON.stringify(this.cond || [])),
        schemeVersionId:"",
        requestType : "stream"
      };
      const res = await FR.exportFinanceReportData(params);
      const { headers } = res;
      const cd = headers["content-disposition"];
      const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/vnd.ms-excel" }));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = decodeURIComponent(cd.split("=")[1]);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    initQuaterDateLine(obj) {
      const _this = this;
      if (obj.dateAccuracy.toString() === "3" || obj.dateAccuracy.toString() === "5") {
        // 月，年
        var ltype = obj.dateAccuracy == 3 ? 1 : 2;
        var cd = [];
        if (obj.dateAccuracy == 5) {
          if (obj.rangeValue) {
            //管理端有设置范围
            var arr = obj.rangeValue.split(",");
            var sYear = Number(arr[0]);
            var eYear = Number(arr[1]);
            while (sYear <= eYear) {
              cd.push({
                id: sYear.toString(),
                name: sYear.toString(),
                isDefault: (function() {
                  if (sYear == obj.initValue) {
                    return 0;
                  } else {
                    return 1;
                  }
                })()
              });
              sYear++;
            }
          } else {
            //没有取前十年
            var year = new Date().getFullYear(),
              per10 = year - 10;
            while (per10 <= year) {
              cd.push({
                id: per10.toString(),
                name: per10.toString(),
                isDefault: (function() {
                  if (per10 == obj.initValue) {
                    return 0;
                  } else {
                    return 1;
                  }
                })()
              });
              per10++;
            }
          }
        } else if (obj.dateAccuracy == 3) {
          var initYear = "";
          if (obj.initValue) {
            initYear = obj.initValue.split("-")[0];
          }
          if (obj.rangeValue) {
            //管理端有设置范围
            var arr = obj.rangeValue.split(",");
            var sYear = arr[0].split("-")[0];
            sYear = Number(sYear);
            var eYear = arr[1].split("-")[0];
            eYear = Number(eYear);
            while (sYear <= eYear) {
              cd.push({
                id: sYear.toString(),
                name: sYear.toString(),
                isDefault: (function() {
                  if (sYear == initYear) {
                    return 0;
                  } else {
                    return 1;
                  }
                })(),
                nodes: (function() {
                  var ns = [];
                  var __sYear = arr[0].split("-")[0];
                  var __sMonth = arr[0].split("-")[1];
                  var __eYear = arr[1].split("-")[0];
                  var __eMonth = arr[1].split("-")[1];
                  var startMonth = 1;
                  var endMonth = 12;
                  if (sYear == __sYear) {
                    startMonth = Number(__sMonth);
                    if (sYear == eYear) {
                      //只有一年
                      endMonth = __eMonth;
                    } else {
                      endMonth = 12; //跨年
                    }
                  } else if (sYear == __eYear) {
                    endMonth = Number(__eMonth);
                    startMonth = 1;
                  } else {
                  }
                  while (startMonth <= endMonth) {
                    var m = "00" + startMonth;
                    m = m.substr(m.length - 2);
                    ns.push({
                      id: sYear.toString() + m,
                      name: sYear.toString() + m,
                      yearNum: sYear.toString(),
                      num: startMonth,
                      isDefault: (function() {
                        if (sYear.toString() + "-" + m == obj.initValue) {
                          return 0;
                        } else {
                          return 1;
                        }
                      })()
                    });
                    startMonth++;
                  }
                  return ns;
                })()
              });
              sYear++;
            }
          } else {
            //没有取前十年
            var year = new Date().getFullYear(),
              per10 = year - 10;
            while (per10 <= year) {
              cd.push({
                id: per10.toString(),
                name: per10.toString(),
                isDefault: (function() {
                  if (per10 == initYear) {
                    return 0;
                  } else {
                    return 1;
                  }
                })(),
                nodes: (function() {
                  var ns = [],
                    iMonth = 1;
                  while (iMonth <= 12) {
                    var m = "00" + iMonth;
                    m = m.substr(m.length - 2);
                    ns.push({
                      id: per10.toString() + m,
                      name: per10.toString() + m,
                      yearNum: per10.toString(),
                      num: iMonth,
                      isDefault: (function() {
                        if (per10.toString() + "-" + m == obj.initValue) {
                          return 0;
                        } else {
                          return 1;
                        }
                      })()
                    });
                    iMonth++;
                  }
                  return ns;
                })()
              });
              per10++;
            }
          }
        }
        this.type = ltype;
        this.cur = (() => {
          var cur = "";
          if (obj.initValue) {
            cur = obj.dateAccuracy == 3 ? obj.initValue.replace("-", "") : obj.initValue;
          }
          return cur;
        })();
        this.dateLineList = cd || [];
        this.rType = 1;
      } else if (obj.dateAccuracy.toString() === "4") {
        // 季度
        var cd = [],
          initYear = "";
        if (obj.initValue) {
          initYear = obj.initValue.substr(0, 4);
        }
        if (obj.rangeValue) {
          var arr = obj.rangeValue.split(",");
          var sYear = arr[0].substr(0, 4);
          sYear = Number(sYear);
          var eYear = arr[1].substr(0, 4);
          eYear = Number(eYear);
          while (sYear <= eYear) {
            cd.push({
              id: sYear.toString(),
              name: sYear.toString(),
              isDefault: (function() {
                if (sYear == initYear) {
                  return 0;
                } else {
                  return 1;
                }
              })(),
              nodes: (function() {
                //20204,20232
                var ns = [];
                var __sYear = arr[0].substr(0, 4);
                var __sMonth = arr[0].substr(4, 1);
                var __eYear = arr[1].substr(0, 4);
                var __eMonth = arr[1].substr(4, 1);
                var startMonth = 1;
                var endMonth = 4;
                if (sYear == __sYear) {
                  startMonth = Number(__sMonth);
                  endMonth = 4;
                } else if (sYear == __eYear) {
                  endMonth = Number(__eMonth);
                  startMonth = 1;
                } else {
                }
                while (startMonth <= endMonth) {
                  ns.push({
                    id: sYear.toString() + startMonth,
                    name: _this.getQuerterName(startMonth),
                    isDefault: (function() {
                      if (sYear.toString() + "" + startMonth == obj.initValue) {
                        return 0;
                      } else {
                        return 1;
                      }
                    })()
                  });
                  startMonth++;
                }
                return ns;
              })()
            });
            sYear++;
          }
        } else {
          var year = new Date().getFullYear(),
            per10 = year - 10;
          while (per10 <= year) {
            cd.push({
              id: per10.toString(),
              name: per10.toString(),
              isDefault: (function() {
                if (per10 == initYear) {
                  return 0;
                } else {
                  return 1;
                }
              })(),
              nodes: (function() {
                var ns = [];
                _this.quarter.forEach((b)=>{
                  ns.push({
                    id: per10.toString() + b.value,
                    name: b.text,
                    isDefault: (function() {
                      if (per10.toString() + "" + b.value == obj.initValue) {
                        return 0;
                      } else {
                        return 1;
                      }
                    })()
                  });
                });
                return ns;
              })()
            });
            per10++;
          }
        }
        this.type = 1;
        this.rType = 2;
        this.dateLineList = cd;
        this.cur = (() => {
          var cur = "";
          if (obj.initValue) {
            cur = obj.initValue;
          }
          return cur;
        })();
      }
    },
    /**
     * @description: 获取开始和结束日期
     */
    getStartEndDate() {
      let defIds = [];
      if (this.type === 2) {
        // 无层级
        defIds = this.dateLineList.filter((v) => v.isDefault === 0);
        if (defIds.length) {
          this.startDate = defIds[0].name;
          this.endDate = defIds[defIds.length - 1].name;
        } else {
          this.startDate = this.dateLineList[0].name;
          this.endDate = this.startDate;
        }
      } else {
        // 数据有层级
        for (let i = 0; i < this.dateLineList.length; i++) {
          (this.dateLineList[i].nodes || []).forEach((v) => {
            if (v.isDefault === 0 || v.isSelect) {
              defIds.push(v);
            }
          });
        }
        if (defIds.length) {
          this.startDate = `${defIds[0].yearNum}.${defIds[0].num}`;
          this.endDate = `${defIds[defIds.length - 1].yearNum}.${defIds[defIds.length - 1].num}`;
        } else {
          this.startDate = this.dateLineList[0]?.name || "";
          this.endDate = this.startDate;
        }
      }
    },
    /**
     * @description: 获取开始和结束日期 - 记账日期
     */
    getStartEndDateJZRQ() {
      const accObj = this.getDateAccuracy();
      const acc = accObj.dateAccuracy.toString() ;
      if(acc === "3" || acc === "5"){
        if (!accObj.rangeValue) {
            //如果没有设置取前10年时间
            let year10 = new Date().getFullYear() - 10;
            this.startDate = year10 + this.SYD;
            this.endDate = new Date().getFullYear() + this.EYD;
        } else {
            let arr = accObj.rangeValue.split(",");
            this.startDate = arr[0] + "-01";
            this.endDate = arr[1] + "-01";
        }
      } else if(acc === "4"){
        if (!accObj.rangeValue) {
          //如果没有设置取前10年时间
          let year10 = new Date().getFullYear() - 10;
          this.startDate = year10 + this.SYD;
          this.endDate = new Date().getFullYear() + this.EYD;
        }else{
          this.startDate = "";
          this.endDate = "";
        }
      }
    },
    getQuerterName(val) {
      let n = "";
      for (let i = 0; i < this.quarter.length; i++) {
        if (this.quarter[i].value == val) {
          n = this.quarter[i].text;
          break;
        }
      }
      return n;
    },
    /**
     * @description:
     * 开始画列表
     */
    drawTable() {
      const This = this;
      // 让数据的宽度设置需要重置跟列头（第一列）一致
      function setStyle(cell, idx) {
        This.reportData?.values?.forEach((b, a) => {
          if (a === 0) {
            const widthType = b[idx].controlInfo.showFormat?.widthType;
            const width = b[idx].controlInfo.showFormat?.width;
            if (widthType) {
              b.forEach((d, c) => {
                if (c === idx) {
                  cell.controlInfo.showFormat.widthType = widthType;
                  cell.controlInfo.showFormat.width = width;
                }
              });
            }
          }
        });
      }
      const first = [];
      this.reportData?.values?.forEach((b, a) => {
        if (a > 0) {
          b.forEach((d, idx) => {
            setStyle(d, idx);
          });
        }
        // 第一行为列头
        let _bgc = "#FFFFFF";
        if (a % 2 === 0) {
          _bgc = "#F7F9F9";
        }
        b._bgc = _bgc;
        if (a === 0) {
          this.headData = b;
        } else if (!b[0].controlInfo.pControlNameVariable) {
          // 没有父级，说明是第一层
          first.push({
            cols: b,
            _bgc: b._bgc,
            _isExpand: true // 默认展开
          });
        }
      });
      // 处理成带层级的数据
      for (let i = 0, len = first.length; i < len; i++) {
        const chd = [];
        for (let j = 0, len2 = this.reportData.values.length; j < len2; j++) {
          if (j > 0) {
            const field = first[i].cols[0].controlInfo.controlNameVariable;
            const pField = this.reportData.values[j][0].controlInfo.pControlNameVariable;
            if (field === pField) {
              chd.push(this.reportData.values[j]);
            }
          }
        }
        first[i].child = chd;
      }
      this.rowData = first;
    },
    /**
     * @description: 选中会计期间回调函数
     * @res : 回调返回会计区间数据
     */
    accChange(res) {
      const _this = this;
      let ids = res.ids.split(",");
      let names = res.names.split(",");
      let pnames = [];
      const dacc = this.getDateAccuracy().dateAccuracy.toString();
      if (this.type === 1 && this.isJZRQ &&  dacc === "3") {
        // 记账日期月的期间
        let _ids = [];
        let _names = [];
        ids.forEach((b) => {
          _ids.push(b.substr(0, 4) + "-" + b.substr(4));
        });
        names.forEach((b) => {
          _names.push(b.substr(0, 4) + "-" + b.substr(4));
        });
        ids = _ids;
        names = _names;
      } else if (dacc === "4") {
        pnames = res.pnames.split(",");
      }
      const cleCon = JSON.parse(JSON.stringify(this.cond));
      cleCon.forEach((b) => {
        if (b.type.toString() === "94") {
          b.value = (() => {
            if (ids.length) {
              b._isSave = true;
              return ids.join(",");
            }
            b._isSave = false;
            return b.initValue;
          })();
          b.valueText = (() => {
            if (names.length) {
              b._isSave = true;
              return [names[0], [names[names.length - 1]]].join("至");
            }
            b._isSave = false;
            return b.initValueText;
          })();
          b.valueText2 = (() => {
            if (names.length) {
              b._isSave = true;
              return names.join(",");
            }
            b._isSave = false;
            return b.initValueText2;
          })();
        } else if (b.type.toString() === "7") {
          // 记账日期
          if (dacc === "4") {
            //记账日期季度期间
            b.value = (function() {
              if (ids.length) {
                b._isSave = true;
                if (ids.length == 1) {
                  return [ids[0], ids[0]].join(",");
                } else {
                  return ids.join(",");
                }
              } else {
                b._isSave = false;
                return b.initValue;
              }
            })();
            b.valueText = (function() {
              if (names.length) {
                b._isSave = true;
                return [pnames[0] + "年" + names[0], [pnames[pnames.length - 1]] + "年" + [names[names.length - 1]]].join("至");
              } else {
                b._isSave = false;
                return b.initValueText;
              }
            })();
            b.valueText2 = (function() {
              if (names.length) {
                b._isSave = true;
                return names.join(",");
              } else {
                b._isSave = false;
                return b.initValueText2;
              }
            })();
          } else {
            //记账日期年月期间
            b.value = (function() {
              if (ids.length) {
                b._isSave = true;
                return ids.join(",");
              } else {
                b._isSave = false;
                return b.initValue;
              }
            })();
            b.valueText = (function() {
              if (names.length) {
                b._isSave = true;
                return [names[0], [names[names.length - 1]]].join("至");
              } else {
                b._isSave = false;
                return b.initValueText;
              }
            })();
            b.valueText2 = (function() {
              if (names.length) {
                b._isSave = true;
                return names.join(",");
              } else {
                b._isSave = false;
                return b.initValueText2;
              }
            })();
          }
        }
      });
      this.isFirst = false;
      this.cond = cleCon;
      this.getReportData();
    },
    /**
     * @description: 保存过滤回调
     * @con : 过滤条件
     */
    saveFilter(res, resList) {
      this.cond = res;
      this.dateLineList = resList;
      this.isFirst = false;
      this.getReportData();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: #1d2532 !important;
  position: relative;
  .left-container {
    height: 100%;
    width: calc(100% - 72px);
    background: #ffffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &.expand {
      width: 100%;
    }
    .list-title {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid rgba(232, 236, 242, 1);
      text-align: left;
      display: flex;
      justify-content: space-between;
      .title-desc {
        width: 300px;
        padding: 0 20px;
        color: #91a1b7;
        font-size: 12px;
        .icon-cls{
          vertical-align: middle;
        }
        .title {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .title-btn {
        text-align: right;
        margin-right: 20px;
        & .en-button {
          margin-right: 5px;
        }
      }
    }
    .list-content {
      height: calc(100% - 50px);
      overflow-y: auto;
      .charts-wrap {
        height: 570px;
        .charts-inner {
          width: 100%;
          height: 100%;
        }
      }
      .list-wrap {
        .list-tit {
          height: 50px;
          line-height: 50px;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          & div {
            font-size: 12px;
            color: #333333;
          }
        }
        .list-conent {
          .list-content-inner {
            overflow-x: auto;
            width: 100%;
            min-height: 300px;
            margin-bottom: 20px;
            .table-head {
              display: flex;
              .head-mod {
                flex: 1;
                border-top: 1px solid #dce5ec;
                border-right: 1px solid #dce5ec;
                border-bottom: 1px solid #dce5ec;
                background-color: #f6fafd;
                text-align: left;
                width: 0;
                .cell-main .tit {
                  color: #333333 !important;
                }
              }
            }
            .table-body {
              .row {
                display: flex;
                .cell-mod {
                  flex: 1;
                  border-right: 1px solid #dce5ec;
                  border-bottom: 1px solid #dce5ec;
                  text-align: left;
                  width: 0;
                }
              }
              .row-child {
                & .cell-mod:first-child {
                  .tit {
                    padding-left: 20px;
                  }
                }
              }
            }
            .cell-main {
              padding: 0 10px;
              font-size: 0;
              cursor: pointer;
              .tit {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                height: 46px;
                line-height: 46px;
                font-size: 12px;
                color: #636c78;
                & .en-icon {
                  margin-right: 5px;
                }
              }
            }
            span.expand-child-cell {
              margin-left: 10px;
            }
            span.expand-first-cell {
              margin-left: 18px;
            }
          }
        }
      }
    }
  }
  .check-vtime {
    position: absolute;
    right: 4px;
    top: 0;
  }
}
</style>
