<!--
 * @Author: pengyu
 * @Date: 2020-07-08 14:52:17
 * @LastEditors: pengyu
 * @LastEditTime: 2020-12-30 16:23:39
 * @Description: 凭证详情
-->
<template>
  <div class="container" v-en-loading="enLoading">
    <en-top-menu
      type="white"
      menu-name="凭证详情"
      :need-back="true"
      :back-url="bUrl"
    >
      <div slot="right">
        <status-model :status="vcdata.voucherData.status"></status-model>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <en-button
              type="text"
              icon="shoucang-kuaijiecaozuo"
              :icon-size="16"
              icon-color="#F8C641"
            > 收藏 </en-button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="cj">
              创建
            </el-dropdown-item>
            <el-dropdown-item command="yy">
              引用
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </en-top-menu>

    <div class="content" >
      <el-row class="top-row">
        <el-col :span="4" v-for="(item,index) in vcdata.voucherHeader" :key="index">
          <span v-if="item.fieldNameVariable==='voucherNo'">
            <!-- 凭证号 -->
            <span>{{item.fieldName}}：</span><span>{{item.value | $padStart(4)}}</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='refId'">
            <!-- 凭证来源 -->
            <span>{{item.fieldName}}：</span>
            <span :class="getRefIdName(item.value) && getRefIdName(item.value)!=='无' ? 'through' : ''"
                  @click="throughVoucher(item)"
            >{{ getRefIdName(item.value) }}</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='accountingEntity'">
            <!-- 记账主体 -->
            <span>{{item.fieldName}}：</span><span>{{ item.value.name }}</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='accountingPeriod'">
            <!-- 会计期间 -->
            <span>{{item.fieldName}}：</span><span>{{ item.value.name }}</span>
          </span>
          <span v-else-if="item.type===7 && item.fieldNameVariable!=='accountingPeriod'">
            <span>{{item.fieldName}}：</span><span>{{ item | formaDate }}</span>
          </span>
          <span v-else-if="item.type===5">
            <span>{{item.fieldName}}：</span><span>{{ item.value&&item.value[0]&&item.value[0].name||"" }}</span>
          </span>
          <span v-else-if="[1,3,4].includes(item.type)">
            <span>{{item.fieldName}}：</span><span>{{ item.value }}</span>
          </span>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <div class="grid-wrap">
        <voucherTable
          :isFor="vcdata.voucherData.isFor"
          :accountId="vcHeader.accountId"
          :list="vcdata.itemList"
          :subjectList="subjectList"
          :actionType="actionType"
          :voucherType="vcdata.voucherData.voucherType"
        ></voucherTable>
      </div>
      <div class="middle-row">
        <div>制单人: {{ vcdata.voucherData.makeName || "" }}</div>
        <div>审核人: {{ vcdata.voucherData.approveName || ""}}</div>
        <div>
          <span>附单据</span>
          <el-input
            v-model="vcdata.voucherData.attachmentNum"
            type="number"
            :disabled="actionType===2"
            placeholder="请输入"
          ></el-input>
          <span class="suffix">张</span>
        </div>
      </div>
      <div class="bottom-row">
        <span>附件与图片</span>
      </div>
      <div class="file-row">
        <div class="img-lis">
          <imgLi
            v-for="(item, index) in imageList"
            :key="index"
            :attr="item"
            :index="index"
            :disable="actionType===2"
          ></imgLi>
        </div>
        <div class="file-lis">
          <fileLi
            v-for="(item, index) in fileList"
            :key="index"
            :attr="item"
            :index="index"
            :disable="actionType===2"
          ></fileLi>
        </div>
      </div>
      <div class="btn-row">
        <en-button
          v-for="(item,idx) in btnList"
          :key="idx"
          :type="item.type"
          @click="voucherBtnClick(item)"
        >
          {{ item.name }}
        </en-button>
      </div>
    </div>
    <!-- 创建模板 -->
    <createModel
      ref="popCreateModel"
      :itemList="modelItemList"
      :yearNum="vcHeader.yearNum"
      :accountId="vcHeader.accountId"
    ></createModel>
    <!-- 引用模板 -->
    <quoteModel
      ref="popQuoteModel"
      :actionType="actionType"
      :accountId="vcHeader.accountId"
      :yearNum="vcHeader.yearNum"
    ></quoteModel>
    <!-- 打印 -->
    <printAlert ref="popPrint"></printAlert>
    <!-- 操作日志 -->
    <logAlert ref="popLog"></logAlert>
    <!-- 打印模板 -->
    <vocPrintTemplate></vocPrintTemplate>
    <!-- 业务单据列表 -->
    <businessGrid ref="popBusGrid" :busTemplateId="busTemplateId" :sourceId="sourceId"></businessGrid>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "en-js";
import dayjs from "dayjs";
import _ from "lodash";
import { voucherDetail } from "@/api";
import voucherButtonEvent from "@/mixins/intelligentAccounting/voucherButtonEvent";
// 按钮公共方法
import getSingleBtnList from "@/mixins/intelligentAccounting/getSingleBtnList"; // 获取按钮
import voucherComm from "@/mixins/intelligentAccounting/voucherComm";
import { getFileUrl } from "@/tools/getFileUrl";
import voucherTable from "../components/voucherTable";
import statusModel from "../components/statusModel";
// 状态块块
import createModel from "../components/createModel"; // 创建模板弹窗
import quoteModel from "../components/quoteModel"; // 引用模板弹窗
import printAlert from "../components/printSetting"; // 打印设置
import logAlert from "../components/logAlert"; // 操作日志'
import imgLi from "../components/imgLi/imgLi"; // 图片块块
import fileLi from "../components/fileLi/fileLi"; // 文件块块
import vocPrintTemplate from "../components/vocPrintTemplate"; // 打印dom
import businessGrid from "../components/businessGrid";

export default {
  name: "VoucherDetail",
  components: {
    voucherTable,
    imgLi,
    fileLi,
    createModel,
    quoteModel,
    printAlert,
    logAlert,
    statusModel,
    vocPrintTemplate,
    businessGrid
  },
  mixins: [voucherButtonEvent, getSingleBtnList, voucherComm],
  data() {
    return {
      enLoading: null,
      voucherId: "", // 凭证id
      actionType: 2, // 1新增2查看3编辑4复制5冲销
      vcHeader: {
        accountId: "", // 账套id
        accountName: "",
        accountingEntity: "", // 记账主体id
        accountingEntityName: "",
        produceDate: "", // 记账日期
        yearNum: "",
        accountingPeriod: "", // 会计期间
        accountingPeriodName: "",
        accountingPeriodMainId: "",
        objectId: ""
      },
      pickerOptions: {}, // 设置记账日期控件的选项设置
      vcdata: {
        itemList: [], // 分录行数据
        voucherHeader: [],
        voucherData: {}
      },
      commonData: {
        remark: "", // 摘要
        subjectId: "", // 科目id
        amountD: "", // 借方本位币
        amountC: "", // 贷方本位币
        foroCurrAmountD: "", // 借方原币金额
        foroCurrAmountC: "", // 贷方原币金额
        amount: "", // 原币金额
        quantityAcc: "", // 数量
        foroCurrSys: "", // 币种
        foroCurrSysName: "", // 币种name
        price: "", // 单价
        rate: "", // 汇率
        fzhs: [] // 辅助核算
      }, // 单条分录
      subjectList: [], // 科目list
      subjectMap: {}, // 科目map
      subjectProps: { value: "id", label: "formatText" },
      fzhsBaseData: {}, // 辅助核算对应的基础数据
      imageList: [], // 上传图片集合
      fileList: [], // 上传文件集合
      btnList: [], // 按钮集合
      bUrl: "", // 返回路径
      isCheckPage: false, // 是否是凭证审核
      modelItemList: [], // 创建模板使用 的分录行数据
      busTemplateId: "",
      sourceId: ""
    };
  },
  filters: {
    formaDate(item) { // 时间格式化
      const ft = [{ value: "1", text: "YYYY-MM" },
        { value: "2", text: "YYYY/MM" },
        { value: "3", text: "YYYYMM" },
        { value: "4", text: "YYYY/MM/DD" },
        { value: "5", text: "YYYY/MM/DD HH:mm" },
        { value: "6", text: "YYYYMMDD" },
        { value: "7", text: "YYYYMMDD HH:mm" },
        { value: "8", text: "YYYY-MM-DD" },
        { value: "9", text: "YYYY-MM-DD HH:mm" },
        { value: "10", text: "YYYY-MM-DD HH:mm:ss" },
        { value: "11", text: "YYYY-MM-DD HH" },
        { value: "12", text: "YYYY" }];
      const { value, timeFormat } = item;
      const ob = ft.filter((itm) => itm.value === timeFormat.toString());
      return dayjs(new Date(value)).format(ob[0].text);
    }
  },
  computed: {
    ...mapGetters(["userInfo", "urlParam"])
  },
  beforeRouterUpdate(to, from, next) {
    this.bUrl = from.path; // 获取返回路径
    next();
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * @description: 请求凭证详情
     */
    async init() {
      // 重置数据
      Object.assign(this.$data, this.$options.data());
      this.initRouteParam();
      await this.queryVoucherDetail();
      this.renderBtn();
    },
    /**
     * @description: 查询凭证详情
     */
    @request(true, "enLoading", {
      subTitle: "数据加载中"
    })
    async queryVoucherDetail() {
      const pa = { vcType: 1, voucherId: this.voucherId };
      const rsp = await voucherDetail.queryVoucherDetail(pa);
      this.vcdata = { ...rsp };
      this.vcdata.itemList.push({});
      // 附件与图片
      this.vcdata.voucherData?.themeFileList?.forEach((itm) => {
        itm.src = getFileUrl(itm.code, itm.type, itm.name);
        if (itm.type === "000" || itm.type === 0) {
          this.imageList.push(itm);
        } else {
          this.fileList.push(itm);
        }
      });
      Object.keys(this.vcHeader).forEach((itm) => {
        this.vcHeader[itm] = this.vcdata.voucherData[itm] || "";
      });

      const kjqj = rsp.voucherHeader.filter((itm) => itm.fieldNameVariable === "accountingPeriod")[0];
      const yearNum = kjqj?.value?.yearNum;
      this.$set(this.vcHeader, "yearNum", yearNum);
    },
    /**
     * @description: 获取路由数据
     */
    initRouteParam() {
      const { query } = this.$route;
      const { id, isCheckPage } = query;
      this.voucherId = id;
      this.isCheckPage = !!(isCheckPage && (isCheckPage === true || isCheckPage === "true"));
      Object.keys(this.vcHeader).forEach((itm) => {
        this.vcHeader[itm] = query[itm] || "";
      });
    },
    /**
     * @description: 查询完凭证详情后获取按钮
     */
    renderBtn() {
      this.btnList = this.getDetailBtnList(this.actionType, this.vcdata.voucherData, this.isCheckPage);
    },
    /**
     * @description: 获取凭证来源名称
     * @param value
     */
    getRefIdName(value) {
      let txt = "";
      const item = value[0];
      if (!item) {
        txt = "无";
      } else if (item.busTemplateId) {
        if (value.length > 1) {
          txt = `${item.name}（${value.length}）`;
        } else {
          txt = item.name;
        }
      } else {
        txt = item.name;
      }
      txt = txt || "无";
      return txt;
    },
    /**
     * @description: 收藏按钮
     * @param command 创建cj  引用yy
     */
    handleCommand(command) {
      if (command === "cj") {
        const re = this.vcdata.itemList;
        if (re) {
          this.modelItemList = JSON.parse(JSON.stringify(re));
          this.$refs.popCreateModel.visible = true;
        }
      } else if (command === "yy") {
        this.$refs.popQuoteModel.visible = true;
      }
    },
    /**
     * @description: 底部按钮点击事件
     * @param item 按钮详情
     */
    voucherBtnClick(item) {
      const { id } = item;
      if (id === "log") {
        this.singleClick({
          id: "log",
          data: this.vcdata.voucherData,
          isCheckPage: this.isCheckPage
        });
      } else if (id === "delete") {
        this.singleClick({
          id: "delete",
          data: this.vcdata.voucherData,
          callback() {

          }
        });
      } else if (id === "edit") {
        this.$router.push({
          path: "voucherEdit",
          query: {
            accountId: this.vcdata.voucherData.accountId,
            actionType: 3,
            id: this.voucherId,
            isCheckPage: this.isCheckPage
          }
        });
      } else if (id === "print") {
        const list = _.cloneDeep(this.vcdata.itemList);
        // 最后1条数据是 用于表格补位的填充数据 去掉
        list.splice(list.length - 1, 1);

        this.singleClick({
          id,
          data: {
            ...this.vcdata.voucherData,
            itemList: list
          }
        });
      }
    },
    /**
     * @description: 凭证来源穿透
     * @param data
     */
    throughVoucher(data) {
      if (data.value && data.value.length && data.value[0].id) {
        this.showVoucherRelation(data.value, this.vcdata.voucherData.voucherType);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  font-size: 12px;
  .en-top-menu {
    border-bottom: 1px solid #dce5ec;
    .en-top-menu-right > div {
      display: flex;
      justify-content: flex-end;
      .status-model {
        margin-top: 18px;
        margin-right: 10px;
      }
    }
    .el-dropdown{
      height: 30px;
    }
  }
  .content {
    padding: 0 20px;
    height: calc(100% - 60px);
    overflow: hidden;
    overflow-y: auto;
    .top-row {
      height: 70px;
      line-height: 70px;
      overflow: hidden;
      .through{
        color:#179eda;
        text-decoration:underline;
        cursor: pointer;
      }
    }
    .el-col {
      overflow: hidden;
      text-align: left;
      display: flex;
      & /deep/ .el-date-editor {
        width: 180px;
      }
      .el-input {
        width: 180px;
      }
    }
  }
  .grid-wrap {
    width: 100%;
    height: calc(100% - 390px);
    overflow-y: auto;
  }
  .middle-row {
    height: 70px;
    line-height: 70px;
    border-bottom: 1px solid #dcdfe6;
    display: flex;
    > div {
      text-align: left;
      width: 20%;
      &:last-child {
        text-align: right;
        width: 60%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        .el-input {
          width: 280px;
          margin-left: 10px;
        }
        .suffix {
          position: absolute;
          font-size: 14px;
          color: #dcdfe6;
          right: 10px;
        }
      }
    }
  }
  .bottom-row {
    height: 50px;
    line-height: 50px;
    text-align: left;
    .foot-upload {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .file-row {
    min-height: 120px;
    .img-lis {
      text-align: left;
      margin-bottom: 20px;
      .img-li {
        display: inline-block;
        margin-right: 10px;
      }
    }
    .file-lis {
      text-align: left;
      .file-li {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .btn-row {
    height: 60px;
    line-height: 60px;
    text-align: right;
    border-top: 1px solid #dce5ec;
    .en-button {
      width: 75px;
    }
  }
}
</style>
