/*
 * @Author: zoujp
 * @Date: 2020-07-01 16:57:13
 * @LastEditTime: 2021-08-03 15:23:45
 * @LastEditors: Please set LastEditors
 * @Description: 财务报表-公共方法
 * @FilePath: \user\src\mixins\intelligentAccounting\financialComm.js
 */
import { thousand } from "en-js";
import printJS from "print-js";

export default {
  data() {
    return {
      // 是否关闭会计期间
      isActive: false,
      params: {
        accountId: "",
        accountingEntity: "",
        templateId: ""
      },
      // 会计期间数据集合
      dateLineList: [],
      // 会计期间类型
      type: 1,
      // 会计区间当前默认
      cur: "",
      // 末级科目数据集
      subjectData: [],
      // 当前默认会计期间
      curPeriod: {},
      // 查询数据集合
      searchData: {},
      // 是否预览
      isPreview: "001",
      // 报表数据集合
      reportData: {},
      // 会计区间数据集合
      dlData: [],
      // 是否显示图表
      isShowCharts: false,
      // 是否显示按钮
      isShowBtn: false,
      // 标题
      title: "",
      // 编制单位
      accountingEntityName: "",
      // 标准
      accountingStand: "",
      // 单位
      accountCurrency: "",
      // 首次加载
      isFirst: true,
      // 报表列头数据
      headData: [],
      // 报表行数据
      rowData: [],
      // 是否展开全部
      isExpandAll: true,
      slineColor: "#eee",
      alineColor: "#999",
      echart: null
    };
  },
  filters: {
    // 获取单元格宽度样式
    getCellStyle(item) {
      let css = `text-align:${item.align}`;
      if (item.widthType.toString() === "2" || item.widthType.toString() === "3") {
        css = `flex:0;min-width:${item.width}{SUFFIX};width:${item.width}{SUFFIX};text-align:{ALIGN}`;
        if (item.widthType.toString() === "2") {
          css = css.replace(/{SUFFIX}/g, "px").replace(/{ALIGN}/g, item.align);
        } else {
          css = css.replace(/{SUFFIX}/g, "%").replace(/{ALIGN}/g, item.align);
        }
      }
      return css;
    }
  },
  methods: {
    /**
     * @description: 开始画图表
     */
    drawCharts() {
      const map = this.reportData.totalPic;
      const type = map.type; // 图表类型001竖向柱状图,010堆积柱形图,014百分比堆积柱形图,002横向柱状图,015堆积条形图,016百分比堆积条形图,003曲线图,004饼图,009环状图
      const isShow = map.isShow; // 显示数值
      const isReverse = map.isReverse; // 是否反转 (正常x轴维度y轴数据)
      const sortField = map.sortField; // 排序字段
      let sortld = "";
      const sortType = map.sortType; // 排序方式 003不排序；000升序；001倒序,
      let x = map.x; // 显示的x轴字段
      let y = map.y; // 显示的y轴字段
      const values = this.reportData.values || []; // 数据合集
      let xdata = [];
      let ydata = {};
      const ldata = [];
      const xarr = [];

      if (["002", "015", "016"].includes(type)) { // 条形图（反过来取）
        x = map.y;
        y = map.x;
      }

      x?.forEach((b) => {
        xarr.push(b.controlNameVariable);
      });

      let res = [];
      y?.forEach((b) => {
        const re = this.getPicData(b.controlNameVariable, isReverse, xarr, type, values);
        res = res.concat(re);
      });

      // 百分比图  先把数据处理成百分数  因为排序要根据百分比排
      if (type === "014" || type === "016") {
        const y1 = []; const
          y2 = [];
          res?.forEach((b) => {
            y1.push(b.yd);
          });

          for (let i = 0; i < y1[0].length; i++) {
            let a = 0;
            for (let j = 0; j < y1.length; j++) {
              a += Math.abs(Number(y1[j][i]));
            }
            y2.push(a);
          }
          res?.forEach((b) => {
            const nyd = (() => {
              const arr = [];
              b.yd?.forEach((b2, a2) => {
                arr.push(this.per(b2, y2[a2]));
              });
              return arr;
            })();
            b.yd = nyd;
          });
      }
      // 有排序字段
      function sort(dd) {
        const obj = {};
        dd.xd?.forEach((b, a) => {
          dd.yd?.forEach((b2, a2) => {
            if (a === a2) {
              obj[b] = b2;
            }
          });
        });
        return obj;
      }

      if (sortField && sortType !== "003") {
        const yObj = {};
        res?.forEach((b) => {
          yObj[b.ld] = sort(b);
          if (b.yk === sortField) {
            if (!sortld) sortld = b.ld;
          }
        });

        const sArr = this.sortObject(yObj[sortld], sortType);
        // 排序之后, 再还原维度跟数值数组
        const xdd = [];
        for (let i = 0; i < sArr.length; i++) {
          xdd.push(sArr[i][1]);
        }
        // 其余非排序的字段根据x的变化 顺序也得发生变化
        res?.forEach((b) => {
          b.xd = xdd;
          b.yd = (() => {
            const f = [];
            xdd?.forEach((b2) => {
              f.push(yObj[b.ld][b2]);
            });
            return f;
          })();
        });
      }
      xdata = res[0].xd;
      ydata = (() => {
        const ar = [];
        res?.forEach((b) => {
          ar.push(b.yd);
          ldata.push(b.ld);
        });
        return ar;
      })();

      // 条形图 非排序状态
      if (["002", "015", "016"].includes(type) && sortField && sortType !== "003") {
        xdata = xdata.reverse();
        if (ydata[0] && ydata[0] instanceof Array) {
          ydata.forEach((b) => {
            b = b.reverse();
            console.log(b);
          });
        } else {
          ydata = ydata.reverse();
        }
      }
      const params = {
        name: map.name, isShow, xdata, ydata, ldata
      };
      this.echart = this.$charts.init(document.getElementById("charts"));
      if (type === "001") {
        this.chart001(params);
      } else if (type === "002") {
        this.chart002(params);
      } else if (type === "003") {
        this.chart003(params);
      } else if (type === "004") {
        this.chart004(params);
      } else if (type === "009") {
        this.chart009(params);
      } else if (type === "010") {
        this.chart010(params);
      } else if (type === "014") {
        this.chart014(params);
      } else if (type === "015") {
        this.chart015(params);
      } else if (type === "016") {
        this.chart016(params);
      }
    },
    /**
     * @description: 格式化百分比
     * @itm 单元格数据
     * @itms 单元格数据
     */
    per(itm, itms) {
      if (Number(itms) !== 0) {
        return ((itm / itms) * 100);
      }
      return 0;
    },
    /**
     * @description: 对象排序
     * @obj      排序对象
     * @sortType 排序方式
     */
    sortObject(obj, sortType) {
      const arr = [];
      Reflect.ownKeys(obj).forEach((key) => {
        arr.push([obj[key], key]);
      });
      arr.sort((a, b) => a[0] - b[0]);

      if (sortType === "001") { // 倒序
        arr.reverse();
      }
      return arr;
    },
    /**
     * @description: 饼图 环图百分比计算
     * @arr 数据数值
     * @val 当前值
     */
    getPiePercent(arr, val) {
      let total = 0;
      arr.forEach((item) => {
        total += Number(item || 0);
      });
      if (total === 0) {
        return 0;
      }
      return parseFloat((val / total) * 100).toFixed(2);
    },
    /**
     * @description: 获取图表数据
     * @key 字段名
     * @isReverse 是否反转
     * @xarr 字段名集合
     * @type 图表类型
     * @values 行数据集合
     */
    getPicData(key, isReverse, xarr, type, values) {
      // 获取格式化数据
      function getData(dd, xarr) {
        const xd = []; const
          yd = [];
        for (let i = 1; i < values.length; i++) {
          if (xarr.includes(values[i][0].controlInfo.controlNameVariable)) {
            xd.push(values[i][0].value);
            let va;
            if (isNaN(values[i][dd.index].value)) {
              va = 0;
            }
            if (["004", "009"].includes(type)) { // 饼 环 百分比图
              if (Number(values[i][dd.index].value) > 0) {
                va = Number(values[i][dd.index].value);
              }
              va = 0;
            }
            if (["014", "016"].includes(type)) { // 百分比图 取正
              va = Number(values[i][dd.index].value);
            }
            va = Number(values[i][dd.index].value);
            yd.push(va);
          }
        }
        return {
          xd, yd, ld: dd.ldname, yk: dd.y
        };
      }
      // 获取格式化数据（根据下标）
      function getData2(index) {
        const rd = values[index]; const xd = []; const
          yd = [];
        const obj = { ld: rd[0].value };
        xarr.forEach((b) => {
          values[0]?.forEach((b2, a2) => {
            if (b2.controlInfo.controlNameVariable === b) {
              xd.push(b2.value);
              yd.push((() => {
                if (isNaN(rd[a2].value)) {
                  return 0;
                }
                if (["004", "009"].includes(type)) {
                  if (Number(rd[a2].value) > 0) {
                    return Number(rd[a2].value);
                  }
                  return 0;
                }
                if (["014", "016"].includes(type)) { // 百分比图 取正
                  return Number(rd[a2].value);
                }
                return Number(rd[a2].value);
              })());
            }
          });
        });
        obj.xd = xd;
        obj.yd = yd;
        obj.yk = rd[0].controlInfo.controlNameVariable;
        return obj;
      }

      const hd = values[0]; // 第一行 头部数据
      const fd = [];
      const onewd = ["004", "009"].includes(type); // 饼 环
      const re = [];

      if (isReverse.toString() === "1") {
        // 找出下标值
        for (let i = 0; i < hd.length; i++) {
          if (hd[i].controlInfo.controlNameVariable === key) {
            const obj = {
              y: key,
              index: i,
              ldname: hd[i].value
            };
            fd.push(obj);

            if (onewd) {
              break;
            }
          } else {
            // continue;
          }
        }

        fd?.forEach((b) => {
          const r = getData(b, xarr);
          re.push(r);
        });
      } else {
        for (let i = 1; i < values.length; i++) {
          if (values[i][0].controlInfo.controlNameVariable === key) {
            re.push(getData2(i));
          }
        }
      }
      return re;
    },
    /**
     * @description: 竖向柱状图
     * @par 参数
     */
    chart001(pa) {
      this.echart.clear();
      const xdata = pa.xdata || []; const ydata = pa.ydata || []; const ldata = pa.ldata || []; const
        series = [];
      ydata.forEach((b, a) => {
        series.push({
          name: ldata[a],
          type: "bar",
          barMaxWidth: 28,
          barMinHeight: 5,
          label: {
            normal: {
              show: pa.isShow.toString() === "0"
            }
          },
          data: b
        });
      });
      const option = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            res.push(dd[0].name);
            dd?.forEach((item) => {
              res.push(`${item.seriesName} : ${item.value}`);
            });
            return res.join("<br/>");
          }
        },
        calculable: true,
        legend: {
          x: "center",
          bottom: "10px",
          itemGap: 10,
          selectedMode: true,
          data: ldata
        },
        grid: {
          left: "5%",
          right: "5%",
          // bottom: "80px",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            },
            data: xdata
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#606060"
            },
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            }
          }
        ],
        series
      };
      this.echart.setOption(option);
      this.echart.resize();
    },
    /**
     * @description: 横向柱状图
     * @par 参数
     */
    chart002(pa) {
      this.echart.clear();
      const xdata = pa.xdata || []; const ydata = pa.ydata || []; const ldata = pa.ldata || []; const
        series = [];
      ydata.forEach((b, a) => {
        series.push({
          name: ldata[a],
          type: "bar",
          barMinHeight: 5,
          barMaxWidth: 28,
          // barGap:5,
          label: {
            normal: {
              show: pa.isShow.toString() === "0"
            }
          },
          data: b
        });
      });
      const option = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            res.push(dd[0].name);
            dd?.forEach((item) => {
              res.push(`${item.seriesName} : ${item.value}`);
            });
            return res.join("<br/>");
          }
        },
        legend: {
          x: "center",
          bottom: "10px",
          itemGap: 10,
          selectedMode: true,
          data: ldata
        },
        grid: {
          left: "5%",
          right: "5%",
          // bottom: '80%',
          containLabel: true
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#606060"
          },
          splitLine: {
            lineStyle: {
              color: this.slineColor
            }
          },
          axisLine: {
            lineStyle: {
              color: this.alineColor
            }
          }
        },
        yAxis: {
          type: "category",
          splitLine: {
            lineStyle: {
              color: this.slineColor
            }
          },
          axisLine: {
            lineStyle: {
              color: this.alineColor
            }
          },
          data: xdata
        },
        series
      };
      this.echart.setOption(option);
    },
    /**
     * @description: 曲线图
     * @par 参数
     */
    chart003(pa) {
      this.echart.clear();
      const xdata = pa.xdata || []; const ydata = pa.ydata || []; const ldata = pa.ldata || []; const
        series = [];
      ydata.forEach((b, a) => {
        series.push({
          name: ldata[a],
          type: "line",
          // barGap:5,
          label: {
            normal: {
              show: pa.isShow.toString() === "0",
              position: "top"
            }
          },
          data: b
        });
      });

      const option = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            res.push(dd[0].name);
                    dd?.forEach((item) => {
                      res.push(`${item.seriesName} : ${item.value}`);
                    });
                    return res.join("<br/>");
          }
        },
        legend: {
          x: "center",
          bottom: "10px",
          icon: "roundRect",
          itemGap: 10,
          selectedMode: true,
          data: ldata
        },
        grid: {
          left: "5%",
          right: "5%",
          // bottom: '80px',
          containLabel: true
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#606060"
          },
          boundaryGap: true,
          splitLine: {
            lineStyle: {
              color: this.slineColor
            }
          },
          axisLine: {
            lineStyle: {
              color: this.alineColor
            }
          },
          data: xdata
        },
        yAxis: {
          axisLabel: {
            color: "#606060"
          },
          splitLine: {
            lineStyle: {
              color: this.slineColor
            }
          },
          axisLine: {
            lineStyle: {
              color: this.alineColor
            }
          },
          type: "value"
        },
        series
      };
      this.echart.setOption(option);
    },
    /**
     * @description: 饼图
     * @par 参数
     */
    chart004(pa) {
      const This = this;
      this.echart.clear();
      const xdata = pa.xdata; const ydata = pa.ydata;
      // const ldata = pa.ldata;
      const nydata = [];

      xdata.forEach((b, a) => {
        ydata[0]?.forEach((b2, a2) => {
          if (a === a2) {
            nydata.push({ name: b, value: b2 });
          }
        });
      });
      const format = {
        textStyle: {
          fontSize: 14
        }
      };
      if (pa.isShow.toString() === "0") {
        // eslint-disable-next-line
        format.formatter = function(a) {
          const res = [];
          const percent = This.getPiePercent(ydata[0], a.value);
          res.push(`${a.value} (${percent}%)`);
          return res.join("");
        };
      } else { // 不显示数值  不包括百分比
        // format.show = false;
        // eslint-disable-next-line
        format.formatter = function(a) {
          const res = [];
          const percent = This.getPiePercent(ydata[0], a.value);
          res.push(` (${percent}%)`);
          return res.join("");
        };
      }

      const series = [
        {
          name: pa.name || "",
          type: "pie",
          label: {
            normal: format
          },
          radius: [0, "58%"],
          center: ["50%", "46%"],
          data: nydata,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ];
      const option = {
        tooltip: {
          trigger: "item",
          formatter(dd) {
            const res = [`${dd.seriesName}<br/>`, `${dd.name} : `];
            const percent = this.getPiePercent(ydata[0], dd.value);
            res.push(`${dd.value} (${percent}%)`);
            return res.join("");
          }
        },
        legend: {
          x: "center",
          bottom: "20px",
          itemGap: 10,
          selectedMode: true,
          data: xdata
        },
        series
      };
      this.echart.setOption(option);
    },
    /**
     * @description: 环状图
     * @par 参数
     */
    chart009(pa) {
      const This = this;
      this.echart.clear();
      const xdata = pa.xdata; const ydata = pa.ydata;
      const nydata = [];
      xdata.forEach((b, a) => {
        ydata.forEach((b2, a2) => {
          if (a === a2) {
            nydata.push({ name: b, value: b2 });
          }
        });
      });
      const format = {
        textStyle: {
          fontSize: 14
        }
      };
      if (pa.isShow.toString() === "0") {
        // eslint-disable-next-line
        format.formatter = function(a) {
          const res = [];
          const percent = This.getPiePercent(ydata[0], a.value);
          res.push(`${a.value} (${percent}%)`);
          return res.join("");
        };
      } else {
        // format.show = false;
        // eslint-disable-next-line
        format.formatter = function(a) {
          const res = [];
          const percent = This.getPiePercent(ydata[0], a.value);
          res.push(` (${percent}%)`);
          return res.join("");
        };
      }
      const option = {
        tooltip: {
          trigger: "item",
          formatter(dd) {
            const res = [`${dd.seriesName}<br/>`, `${dd.name} : `];
            const percent = This.getPiePercent(ydata[0], dd.value);
            res.push(`${dd.value} (${percent}%)`);
            return res.join("");
          }
        },
        legend: {
          x: "center",
          bottom: "10px",
          itemGap: 10,
          selectedMode: true,
          data: xdata
        },
        series: [
          {
            name: "环形图统计表",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: format
            },
            labelLine: {
              normal: {
                show: true,
                length: 15,
                length2: 10,
                lineStyle: {
                }
              }
            },
            data: nydata
          }
        ]
      };
      this.echart.setOption(option);
    },
    /**
     * @description: 堆积柱形图
     * @par 参数
     */
    chart010(pa) {
      this.echart.clear();
      const xdata = pa.xdata; const ydata = pa.ydata; const ldata = pa.ldata; const
        series = [];
      ydata.forEach((b, a) => {
        series.push({
          name: ldata[a],
          type: "bar",
          stack: "总量",
          barMinHeight: 5,
          barMaxWidth: 28,
          // barGap:5,
          label: {
            normal: {
              show: pa.isShow.toString() === "0",
              position: "inside"
            }
          },
          data: b
        });
      });
      const option = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            res.push(dd[0].name);
            dd?.forEach((item) => {
              res.push(`${item.seriesName} : ${item.value}`);
            });
            return res.join("<br/>");
          }
        },
        calculable: true,
        grid: {
          left: "5%",
          right: "5%",
          // bottom: '80px',
          containLabel: true
        },
        legend: {
          x: "center",
          bottom: "20px",
          itemGap: 10,
          selectedMode: true,
          data: ldata
        },
        xAxis: [
          {
            type: "category",
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            },
            axisLabel: {
              textStyle: {
                color: "#aeaeae" // x轴字颜色
              }
            },
            data: xdata
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              // formatter: '{value}',
              color: "#606060"
            },
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            }
          }
        ],
        series
      };
      this.echart.setOption(option);
    },
    /**
     * @description: 百分比堆积柱形图
     * @par 参数
     */
    chart014(pa) {
      this.echart.clear();
      const xdata = pa.xdata; const ydata = pa.ydata; const ldata = pa.ldata; const
        series = [];
      ydata.forEach((b, a) => {
        series.push({
          name: ldata[a],
          type: "bar",
          barMinHeight: 5,
          barMaxWidth: 28,
          stack: "总量",
          label: {
            normal: {
              show: pa.isShow.toString() === "0",
              position: "inside",
              formatter(dd) {
                return `${Number(dd.value || 0).toFixed(2)}%`;
              }
            }
          },
          data: b
        });
      });
      const option = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            res.push(dd[0].name);
                  dd?.forEach((item) => {
                    res.push(`${item.seriesName} : ${parseFloat(item.value).toFixed(2)}%`);
                  });
                  return res.join("<br/>");
          }
        },
        calculable: true,
        grid: {
          left: "5%",
          right: "5%",
          // bottom: '80px',
          containLabel: true
        },
        legend: {
          x: "center",
          bottom: "20px",
          itemGap: 10,
          data: ldata
        },
        xAxis: [
          {
            type: "category",
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            },
            axisLabel: {
              textStyle: {
                color: "#aeaeae" // x轴字颜色
              }
            },
            data: xdata
          }
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            },
            axisLabel: {
              textStyle: {
                color: "#aeaeae" // x轴字颜色
              },
              show: true,
              interval: "auto",
              formatter: "{value} %"
            }
          }

        ],
        series
      };
      this.echart.setOption(option);
    },
    /**
     * @description: 堆积条形图
     * @par 参数
     */
    chart015(pa) {
      this.echart.clear();
      const xdata = pa.xdata; const ydata = pa.ydata; const ldata = pa.ldata; const
        series = [];
      ydata.forEach((b, a) => {
        series.push({
          name: ldata[a],
          type: "bar",
          stack: "总量",
          barMinHeight: 5,
          barMaxWidth: 28,
          // barGap:5,
          label: {
            normal: {
              show: pa.isShow.toString() === "0",
              position: "top"
            }
          },
          data: b
        });
      });
      const option = {
        tooltip: {
          trigger: "axis",
          formatter() {
          }
        },
        calculable: true,
        grid: {
          left: "5%",
          right: "5%",
          // bottom: '80%',
          containLabel: true
        },
        legend: {
          x: "center",
          bottom: "20px",
          itemGap: 10,
          selectedMode: true,
          data: ldata
        },
        yAxis: [
          {
            type: "category",
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            },
            axisLabel: {
              textStyle: {
                color: "#aeaeae" // x轴字颜色
              }
            },
            data: xdata
          }
        ],
        xAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
              color: "#606060"
            },
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            }
          }

        ],
        series
      };
      this.echart.setOption(option);
    },
    /**
     * @description: 百分比堆积条形图
     * @par 参数
     */
    chart016(pa) {
      this.echart.clear();
      const xdata = pa.xdata; const ydata = pa.ydata; const ldata = pa.ldata; const
        series = [];
      ydata.forEach((b, a) => {
        series.push({
          name: ldata[a],
          type: "bar",
          stack: "总量",
          barMinHeight: 5,
          barMaxWidth: 28,
          label: {
            normal: {
              show: pa.isShow.toString() === "0",
              position: "inside",
              formatter(dd) {
                return `${Number(dd.value || 0).toFixed(2)}%`;
              }
            }
          },
          data: b
        });
      });
      const option = {
        tooltip: {
          trigger: "axis",
          formatter(dd) {
            const res = [];
            res.push(dd[0].name);
                dd?.forEach((item) => {
                  res.push(`${item.seriesName} : ${parseFloat(item.value).toFixed(2)}%`);
                });
                return res.join("<br/>");
          }
        },
        calculable: true,
        grid: {
          left: "5%",
          right: "5%",
          // top: '0%',
          // bottom: '10%',
          containLabel: true
        },
        legend: {
          x: "center",
          y: "bottom",
          bottom: "20px",
          icon: "roundRect",
          itemGap: 10,
          data: ldata
        },
        yAxis: [
          {
            type: "category",
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            },
            axisLabel: {
              textStyle: {
                color: "#aeaeae" // x轴字颜色
              }
            },
            data: xdata
          }
        ],
        xAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {
                color: this.slineColor
              }
            },
            axisLine: {
              lineStyle: {
                color: this.alineColor
              }
            },
            axisLabel: {
              textStyle: {
                color: "#aeaeae" // x轴字颜色
              },
              show: true,
              interval: "auto",
              formatter: "{value} %"
            }
          }

        ],
        series
      };
      this.echart.setOption(option);
    },
    /**
     * @description: 过滤搜索
     */
    searchFn() {
      this.$refs.popReportFilter.visiable = true;
    },
    /**
     * @description: 打印
     */
    printFn() {
      printJS({
        printable: "print-template-financial",
        type: "html",
        css: "../../../css/print-table.css",
        // header: "打印测试",
        // documentTitle: "水印|保存名称",
        maxWidth: 900,
        scanStyles: false,
        // honorColor: true, // 彩打
        targetStyles: ["*"],
        onPrintDialogClose: (err) => {
          console.log("取消打印", err);
        },
        error: (err) => {
          console.log("打印errrrr", err);
        },
        onLoadingStart: (err) => {
          console.log("onLoadingStart", err);
        },
        onLoadingEnd: (err) => {
          console.log("onLoadingEnd", err);
        }
      });
    },
    /**
     * @description: 展开收起
     * @row : 当前行数据
     */
    expandLevel(row) {
      row._isExpand = !row._isExpand;
    },
    /**
     * @description: 展开收起全部
     * @row : 当前行数据
     */
    expandAllLevel() {
      this.isExpandAll = !this.isExpandAll;
      const This = this;
      this.rowData.forEach((b) => {
        b._isExpand = This.isExpandAll;
      });
    },
    /**
     * @description: 获取第一个会计区间的开始时间和结束时间
     */
    getStartEndTime() {
      let startDate = "";
      let endDate = "";
      for (let i = 0, len = this.dlData.length; i < len; i++) {
        if (i === 0) { // 第一个的startDate
          startDate = this.dlData[i].startDate;
        } else if (i === len - 1) { // 最后一个的endDate
          endDate = this.dlData[i].endDate;
        }
      }
      return {
        startDate,
        endDate
      };
    },
    /**
     * @description: 获取会计区间可用时间
     * @obj :会计期间科目数据
     */
    getAvaibleTime(b) {
      let dd = JSON.parse(JSON.stringify(this.dlData));
      const res = [];
      const re = [];
      if (b.rangeValue) {
        this.dlData.forEach((d) => {
          const bb = JSON.parse(JSON.stringify(d));
          const ns = [];
          (d.nodes || []).forEach((n) => {
            if (b.rangeValue.indexOf(n.id) > -1) {
              ns.push(n);
            }
          });
          bb.nodes = ns;
          res.push(bb);
        });

        res.forEach((n) => {
          const ns = n.nodes || [];
          if (ns.length) {
            re.push(n);
          }
        });
        dd = re;
      }
      return dd;
    },
    /**
     * @description: 获取会计区间可用年份
     * @obj :会计期间科目数据
     */
    getAvaibleYear(b) {
      let dd = JSON.parse(JSON.stringify(this.dlData));
      const res = [];
      if (b.rangeValue) {
        const arr = b.rangeValue.split(",");
        this.dlData.forEach((d) => {
          if (Number(d.id) >= Number(arr[0]) && Number(d.id) <= Number(arr[1])) {
            res.push(d);
          }
        });
        dd = res;
      }
      return dd;
    },
    /**
     * @description: 获取当前单元格格式化数据
     * @item : 当前单元格源数据
     * @rindex : 当前行下标
     */
    getValue(n, rindex) {
      let value = n.value;
      let align = "left";
      let width = "";
      let widthType = 1;
      let dataFormat; let controlType; let num0Format; let numberFormat;
      // const n0 = { 1: "", 2: "0", 3: "--" };
      const cu = {
        1: "¥", 2: "$", 3: "€", 4: "£"
      };
      let currency; let permillage;
      // 获取格式化数据
      function format(value) {
        let prefix = ""; let suffix = "";
        if (currency) {
          prefix = cu[currency];
        }
        if (numberFormat.toString() === "2") { // 百分比 * 100，去掉前缀
          prefix = "";
          value *= 100;
          suffix = "%";
        }
        if (permillage.toString() === "0") {
          value = thousand(Number(value), 2);
        } else {
          value = Number(value).toFixed(2);
        }
        value = `${prefix} ${value}${suffix}`;
        return value;
      }
      if (Object.keys(n.controlInfo).length) {
        dataFormat = n.controlInfo.showFormat.dataFormat;
        controlType = n.controlInfo.controlType;
        widthType = n.controlInfo.showFormat.widthType || 1;
        width = n.controlInfo.showFormat.width || "";
        if (dataFormat.toString() === "2") {
          align = "center";
        } else if (dataFormat.toString() === "3") {
          align = "right";
        }
        if (rindex !== 0 && controlType.toString() === "4") {
          const valueType = n.controlInfo.valueType || "";
          if (valueType.toString() === "7") {
            permillage = n.controlInfo.showFormat.permillage || "";
            num0Format = n.controlInfo.showFormat.num0Format || "";
            numberFormat = n.controlInfo.showFormat.numberFormat || 1; // 1数值2百分比
            currency = n.controlInfo.showFormat.currency;
            if (value.toString() === "0") {
              if (num0Format.toString() === "1") { // 数据为0时显示 "" 或 "--"
                value = "";
              } else if (num0Format.toString() === "3") {
                value = "--";
              } else {
                value = format(value);
              }
            } else {
              value = format(value);
            }
          }
        }
      }
      return {
        value, align, widthType, width
      };
    }
  }
};
