<!--
 * @Author: zhouql
 * @Date: 2020-03-06 11:28:54
 * @LastEditTime: 2021-07-12 10:55:44
 * @LastEditors: pengyu
 * @Description: 外币金额余额表
 * @FilePath: \user-intelligent-accounting\src\views\accountBook\alert\foreignCurrencyAlert.vue
 -->
<template>
  <en-dialog
    :visible="visible"
    :title="alertName"
    class="foreignBalance"
    enFullscreen
    en-body-scroll
    @close="visible=false"
  >
    <div slot="title" class="dialog-title">
      <div>{{alertName}}</div>
      <div class="btn-group">
        <div class="btn-export" @click="handleExport">
          <en-icon
            name="bus-btn5_1"
            size="small"
            style="color:#26c393"
          ></en-icon>
          <div class="label">
            导出
          </div>
        </div>
        <div class="btn-print" @click="handlePrint">
          <en-icon
            name="bus-btn6_1"
            size="small"
            style="color:#3e90fe"
          ></en-icon>
          <div class="label">
            打印
          </div>
        </div>
      </div>
    </div>
    <div class="main" v-en-loading="enLoading">
      <!--表格部分-->
      <en-table :tree-config="{ children: 'nodes', hasChildren: 'hasNode', trigger: 'row' }" :data="list"  height="100%">
        <en-table-column type="index" width="60" align="center"></en-table-column>
        <en-table-column prop="name" label="科目" width="220"></en-table-column>
        <en-table-column prop="foroCurrSysName" label="币种" width="130"></en-table-column>
        <en-table-column title="期初余额" header-align="center">
          <en-table-column title="方向" prop="balanceDir" header-align="center" width="130"></en-table-column>
          <en-table-column title="原币" prop="balanceOri" header-align="center" align="right" width="130">
            <template slot-scope="scope">
              {{scope.row.balanceOri | thousand(2)}}
            </template>
          </en-table-column>
          <en-table-column title="外币" prop="balanceFor" header-align="center" align="right" width="130">
            <template slot-scope="scope">
              {{scope.row.balanceFor | thousand(2)}}
            </template>
          </en-table-column>
        </en-table-column>
        <en-table-column title="本期发生" header-align="center">
          <en-table-column title="借方原币" prop="curBorOri" header-align="center" align="right" width="130">
            <template slot-scope="scope">
              {{scope.row.curBorOri | thousand(2)}}
            </template>
          </en-table-column>
          <en-table-column title="借方外币" prop="curBorFor" header-align="center" align="right" width="130">
            <template slot-scope="scope">
              {{scope.row.curBorFor | thousand(2)}}
            </template>
          </en-table-column>
          <en-table-column title="贷方原币" prop="curLoanOri" header-align="center" align="right" width="130">
            <template slot-scope="scope">
              {{scope.row.curLoanOri | thousand(2)}}
            </template>
          </en-table-column>
          <en-table-column title="贷方外币" prop="curLoanFor" header-align="center" align="right" width="130">
            <template slot-scope="scope">
              {{scope.row.curLoanFor | thousand(2)}}
            </template>
          </en-table-column>
        </en-table-column>
        <en-table-column title="期末余额" header-align="center">
          <en-table-column title="方向" prop="endDir" header-align="center" width="130"></en-table-column>
          <en-table-column title="原币" prop="endOri" header-align="center" align="right" width="130">
            <template slot-scope="scope">
              {{scope.row.endOri | thousand(2)}}
            </template>
          </en-table-column>
          <en-table-column title="外币" prop="endFor" header-align="center" align="right" width="130">
            <template slot-scope="scope">
              {{scope.row.endFor | thousand(2)}}
            </template>
          </en-table-column>
        </en-table-column>
      </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { request, thousand } from "en-js";
import { balanceService } from "@/api/intelligentAccounting";

export default {
  props: {
    afferentData: {}// 查询的一些参数
  },
  data() {
    return {
      enLoading: false,
      visible: false,
      alertName: "",
      list: []
    };
  },
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  watch: {
    visible(data) {
      if (this.afferentData) {
        this.alertName = `外币金额余额表（${this.afferentData.startDate}—${this.afferentData.endDate}）`;
      }
      if (data === true) {
        this.queryAbForAmount();
      }
    }
  },
  methods: {
    // 请求数量金额余额
    @request(true, "enLoading")
    async queryAbForAmount() {
      const res = await balanceService.queryAbForAmount({
        accountId: this.afferentData.accountId,
        accountingEntity: this.afferentData.accountingEntity,
        status: this.afferentData.status,
        accountingPeriod: this.afferentData.accountingPeriod
      });
      this.list = res;
    },
    // 导出
    handleExport() {
      const pa = { typeId: "wb", ...this.afferentData };
      this.$parent.handleExportExcel(pa);
    },
    // 打印
    handlePrint() {
      this.$emit("printForeign", this.list);
    }
  }
};
</script>

<style lang="scss" scoped>
.foreignBalance {
  .dialog-title{
    display: flex;
    justify-content: space-between;
    .btn-group{
      margin-right: 20px;
      display: flex;
    }
    .btn-export,
    .btn-print {
      cursor: pointer;
      margin-right: 20px;
      display: flex;
      align-items: center;
      .en-icon {
        margin-right: 5px;
      }
      .label {
        color: #636c78;
        font-weight: 500;
      }
    }
  }
  .main{
    min-height:100%;
  }
}
</style>
