<template>
  <div class="print-template" id="print-template-financial">
    <div class="title">{{searchData.title}}</div>
    <div class="table-info">
      <span>编制单位:&nbsp; {{searchData.accountingEntityName}}</span> &nbsp; &nbsp;
      <span>会计期间:&nbsp; {{period}}</span> &nbsp; &nbsp;
      <span>单位:&nbsp; {{searchData.accountCurrency}}元</span>
    </div>
    <table cellspacing="0" cellpadding="0" border="0">
      <thead>
        <tr>
          <th colspan="1" rowspan="1" style="text-align: center;" v-for="(col, index) in headData" :key="index">{{col.value}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(rowData, index) in flatList" :key="index">
          <td colspan="1" rowspan="1" v-for="(row, idx) in rowData.cols" :key="idx" :style="getValue(row, 0) | getCellStyle">{{getValue(row).value}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { thousand } from "en-js";
import financialComm from "@/mixins/intelligentAccounting/financialComm";

// 外币金额余额表
export default {
  name: "PrintTemplateFinancial",
  mixins: [financialComm],
  components: {
  },
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  props: {
    headData: {
      type: Array,
      default() {
        return [];
      }
    },
    dataList: {
      type: Array,
      default() {
        return [];
      }
    },
    searchData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      flatList: []
    };
  },
  watch: {
    dataList: {
      immediate: true,
      handler(list) {
        this.flatList = [];
        this.getFlatList(list);
      }
    }
  },
  methods: {
    /**
     * @description:扁平化list
     */
    getFlatList(list, isChild) {
      list.forEach((item) => {
        this.flatList.push(isChild ? { cols: item } : item);
        if (item.child) {
          this.getFlatList(item.child, true);
        }
      });
    }
  },
  computed: {
    period() {
      let arr = this.searchData.period || "";
      if (arr.indexOf(",") > -1) {
        arr = arr.split(",");
        return `${arr[0]}-${arr[arr.length - 1]}`;
      }
      return arr;
    }
  }
};
</script>

<style lang="scss">
.print-template {
  position: fixed;
  z-index: -12;
  width: 1000px;
  height: auto;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: #fff;
  .title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  .table-info {
    font-size: 12px;
    margin: 10px 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    color: #000;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    th {
      text-align: left;
      padding: 6px 10px;
      line-height: 20px;
      border: 1px solid #333;
      word-break: break-all;
      font-weight: normal;
    }
  }
  th{text-align:left;padding:6px 10px;line-height:20px;border:1px solid #333;word-break:break-all;font-weight:normal;}
  td{padding:2px 10px;line-height:20px;border:1px solid #333;word-break:break-all;height:24px !important;}
  table{border-collapse:collapse;width:100%;table-layout:fixed;color:#000;background-color:#fff;font-size:12px;}
}
</style>
