<!--
 * @Author: zoujp
 * @Date: 2020-06-28 14:18:51
 * @LastEditTime: 2021-07-08 16:16:36
 * @LastEditors: Please set LastEditors
 * @Description: 财务报表-入口
 * @FilePath: \user\src\views\intelligentAccounting\financialReport\reportIndex.vue
-->
<template>
  <div class="container">
        <div :class="isActive ? 'left-container expand' : 'left-container'">
            <div class="list-title">
                <div class="title-desc">
                    <span class="title">财务报表</span>
                </div>
                <!-- 头部toolbar -->
                <topBar
                  :params="params"
                  :entityList="entityList"
                  :accList="accList"
                  :showRecord="false"
                  @search-call-back="search"
                  @change-param="changeParam"
                ></topBar>
            </div>
            <div class="list-content">
                <div
                  class="mod"
                  v-for="item in dataList" :key="item.id"
                  :v-show="item.enableFlag==0"
                  >
                  <div class="mod-title">{{item.name}}</div>
                  <div class="mod-list">
                      <en-card
                        v-for="v in item.templateList||[]"
                        :v-show="item.enableFlag==0"
                        :key="v.id"
                        type='normal'
                        :title='v.name'
                        icon-name='mingxizhang-xianxing'
                        icon-color='#ffffff'
                        icon-bg='#3e90fe'
                        @click.native="toReport(v.id)"
                      >
                      </en-card>
                  </div>
                </div>
            </div>
        </div>
        <!-- 测试 -->
  </div>
</template>

<script>
import { Message } from "element-ui";
import voucherComm from "@/mixins/intelligentAccounting/voucherComm";
import { financialReport as FR } from "@/api";
import topBar from "../components/topBar"; // 搜索顶部栏

// 查询公共参数
export default {
  name: "ReportIndex",
  components: { topBar },
  mixins: [voucherComm],
  data() {
    return {
      isActive: true,
      // 加载数据条件（包含搜素栏数据）
      params: {
        accountId: "",
        accountingEntity: ""
      },
      // 账套数据集合
      accList: [],
      // 记账主体数据集合
      entityList: [],
      dataList: [],
      iconMap: {

      }
    };
  },
  watch: {
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * @description: 保存用户习惯
     * @value :选中的数据集合
     */
    async changeParam(value) {
      this.params.accountId = value.accountId;
      this.params.accountingEntity = value.accountingEntity;
      await this.saveAccHabit();
    },
    /**
     * @description: 初始化
     */
    async init() {
      await this.queryAccountList();
      this.search();
    },
    /**
     * @description: 校验记账主题，账套是否选择了
     */
    verify() {
      if (!this.params.accountId) {
        Message({
          showClose: true,
          message: "请选择账套",
          type: "error"
        });
        return false;
      }
      if (!this.params.accountingEntity) {
        Message({
          showClose: true,
          message: "请选择记账主体",
          type: "error"
        });
        return false;
      }
      return true;
    },
    /**
     * @description: 搜索
     */
    async search() {
      if (!this.verify()) return;
      this.$showLoading();
      this.dataList = await FR.queryFiTemplateClassify({ withTemplate: "000", accountId: this.params.accountId, accountingEntity: this.params.accountingEntity });
      this.$hideLoading();
    },
    /**
     * @description: 跳转到报表详情
     * @id 报表id
     */
    toReport(id) {
      this.$router.push({
        path: "/intelligent-accounting/reportList",
        query: {
          templateId: id, accountId: this.params.accountId, accountingEntity: this.params.accountingEntity
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: #1d2532 !important;
  position: relative;
  .left-container{
    height: 100%;
    width: calc(100% - 72px);
    background: #ffffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &.expand {
      width: 100%;
    }
    .list-title{
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid rgba(232,236,242,1);
        text-align: left;
        display: flex;
        justify-content: space-between;
        .title-desc{
            width: 300px;
            color: #91A1B7;
            font-size: 12px;
            .title{
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                display: inline-block;
                padding: 0 20px;
            }
        }

    }
    .list-content{
      height: calc(100% - 50px);
      padding: 0 20px;
      overflow-y: auto;
      .mod{
        .mod-title{
          padding:  20px 0;
          font-size: 12px;
          color: #636C78;
          font-weight: bold;
          text-align: left;
        }
        .mod-list{
          display: flex;
          flex-wrap: wrap;
          min-height: 60px;
          & .en-card{
            margin-right: 20px;
            margin-bottom: 20px;
            & /deep/ .en-card-normal-main-title{
              margin-top:12px;
            }
          }
        }
      }
      & :not(:first-child){
        .mod-title{
          padding-top: 0;
        }
      }
    }
  }
}
</style>
