<!--
 * @Author: your name
 * @Date: 2020-03-18 16:02:38
 * @LastEditTime: 2021-08-19 09:46:34
 * @LastEditors: wuqi
 * @Description: 凭证处理--编辑凭证|复制凭证
 * @FilePath: \user-intelligent-accounting\src\views\voucherEdit\index.vue
 -->
<template>
  <div class="container" v-en-loading="enLoading">
    <en-top-menu
      type="white"
      :menu-name="actionType===3 ? '编辑凭证' : '复制凭证'"
      :need-back="true"
    >
      <div slot="right">
        <status-model :status="vcdata.voucherData.status"></status-model>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <en-button
              type="text"
              icon="shoucang-kuaijiecaozuo"
              :icon-size="16"
              icon-color="#F8C641"
            > 收藏 </en-button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="cj">
              创建
            </el-dropdown-item>
            <el-dropdown-item command="yy">
              引用
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </en-top-menu>

    <div class="content">
      <el-row class="top-row">
        <el-col :span="4" v-for="(item,index) in vcdata.voucherHeader" :key="index">
          <span v-if="item.fieldNameVariable==='voucherNo'">
            <!-- 凭证号 -->
            <span>{{item.fieldName}}：</span>
            <el-input
              v-if="item.value"
              type="number"
              v-model="item.value"
            ></el-input>
            <span v-else>自动生成</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='refId'">
            <!-- 凭证来源 -->
            <span>{{item.fieldName}}：</span>
            <span :class="getRefIdName(item.value) && getRefIdName(item.value)!=='无' ? 'through' : ''"
                  @click="throughVoucher(item)">{{ getRefIdName(item.value) }}</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='accountingEntity'">
            <!-- 记账主体 -->
            <span>{{item.fieldName}}：</span><span>{{ item.value.name }}</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='accountingPeriod'">
            <!-- 会计期间 -->
            <span>{{item.fieldName}}：</span><span>{{ item.value.name }}</span>
          </span>
          <span v-else-if="item.type===7 && item.fieldNameVariable!=='accountingPeriod'">
            <span>{{item.fieldName}}：</span>
            <el-date-picker
              type="date"
              v-model="item.value"
              placeholder="选择日期时间"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="produceDateChange"
            >
            </el-date-picker>
          </span>
          <span v-else-if="item.type===5">
            <span>{{item.fieldName}}：</span><span>{{ item.value&&item.value[0]&&item.value[0].name||"" }}</span>
          </span>
          <span v-else-if="[1,3,4].includes(item.type)">
            <span>{{item.fieldName}}：</span><span>{{ item.value }}</span>
          </span>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <div class="grid-wrap">
        <voucherTable
          :accountId="vcHeader.accountId"
          :list="vcdata.itemList"
          :subjectList="subjectList"
          :actionType="actionType"
          @updateItemList="handleUpdateItemList"
        ></voucherTable>
      </div>
      <div class="middle-row">
        <div>制单人: {{ vcdata.voucherData.makeName || "" }}</div>
        <div>审核人: {{ vcdata.voucherData.approveName || ""}}</div>
        <div>
          <span>附单据</span>
          <el-input
            v-model="attachmentNum"
            type="number"
            :disabled="actionType===2"
            placeholder="请输入"
          ></el-input>
          <span class="suffix">张</span>
        </div>
      </div>
      <div class="bottom-row">
        <span>附件与图片</span>
        <el-upload
          v-if="actionType!==2"
          multiple
          class="foot-upload"
          :accept="upextStr"
          :data="uploadParams"
          name="storage_file"
          :action="urlParam.server"
          :on-success="handleUpload"
          :show-file-list="false"
        >
          <en-icon
            name="tianjia-danchuang"
            color="#4894DF"
            size="12"
          ></en-icon>
        </el-upload>
        <!-- <en-upload
          class="foot-upload"
          :options="uploadParams"
        >
          <en-icon
            name="tianjia-danchuang"
            color="#4894DF"
            size="small"
          ></en-icon>
        </en-upload> -->
      </div>
      <div class="file-row">
        <div class="img-lis">
          <imgLi
            v-for="(item, index) in imageList"
            :key="index"
            :attr="item"
            :index="index"
            :disable="actionType===2"
            @img-close="imageClose"
          ></imgLi>
        </div>
        <div class="file-lis">
          <fileLi
            v-for="(item, index) in fileList"
            :key="index"
            :attr="item"
            :index="index"
            :disable="actionType===2"
            @file-close="fileClose"
          ></fileLi>
        </div>
      </div>
      <div class="btn-row">
        <en-button
          v-for="(item,idx) in btnList"
          :key="idx"
          :type="item.type"
          @click="voucherBtnClick(item)"
        >
          {{ item.name }}
        </en-button>
      </div>
    </div>
    <!-- 创建模板 -->
    <createModel
      ref="popCreateModel"
      :itemList="modelItemList"
      :yearNum="vcHeader.yearNum"
      :accountId="vcHeader.accountId"
    ></createModel>
    <!-- 引用模板 -->
    <quoteModel
      ref="popQuoteModel"
      :accountId="vcHeader.accountId"
      :yearNum="vcHeader.yearNum"
      @handleChooseModel="handleQuoteModel"
    ></quoteModel>
    <!-- 打印 -->
    <printAlert ref="popPrint"></printAlert>
    <!-- 现金流量项目 -->
    <checkCashFlow
      ref="cashFlowMod"
      :itemList="submitParam.itemList"
      @handleCashFlowSubmit="cashFlowSubmit"
    ></checkCashFlow>
    <!-- 操作日志 -->
    <logAlert ref="popLog"></logAlert>
    <!-- 打印模板 -->
    <vocPrintTemplate></vocPrintTemplate>
    <!-- 业务单据列表 -->
    <businessGrid ref="popBusGrid" :busTemplateId="busTemplateId" :sourceId="sourceId"></businessGrid>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MessageBox, Message } from "element-ui";
import _ from "lodash";
import { executeNum, request } from "en-js";
import dayjs from "dayjs";
import voucherComm from "@/mixins/intelligentAccounting/voucherComm";
import getSingleBtnList from "@/mixins/intelligentAccounting/getSingleBtnList"; // 获取按钮
import voucherButtonEvent from "@/mixins/intelligentAccounting/voucherButtonEvent";// 按钮公共方法
import { voucherDetail } from "@/api";
import { getFileUrl } from "@/tools/getFileUrl";
import VoucherTable from "../components/voucherTable";
import StatusModel from "../components/statusModel";// 状态块块
import CreateModel from "../components/createModel"; // 创建模板弹窗
import QuoteModel from "../components/quoteModel"; // 引用模板弹窗
import PrintAlert from "../components/printSetting"; // 打印设置
import logAlert from "../components/logAlert"; // 操作日志'
import imgLi from "../components/imgLi/imgLi"; // 图片块块
import fileLi from "../components/fileLi/fileLi"; // 文件块块
import checkCashFlow from "../components/checkCashFlow"; // 现金流量项目
import vocPrintTemplate from "../components/vocPrintTemplate";
import businessGrid from "../components/businessGrid";

export default {
  name: "VoucherEdit",
  components: {
    VoucherTable,
    imgLi,
    fileLi,
    CreateModel,
    QuoteModel,
    PrintAlert,
    StatusModel,
    checkCashFlow,
    logAlert,
    vocPrintTemplate,
    businessGrid
  },
  mixins: [voucherButtonEvent, getSingleBtnList, voucherComm],
  watch: {
  },
  data() {
    return {
      enLoading: null,
      voucherId: "", // 凭证id
      actionType: 1, // 1新增2查看3编辑4复制5冲销
      vcHeader: {
        accountId: "", // 账套id
        accountName: "",
        accountingEntity: "", // 记账主体id
        accountingEntityName: "",
        produceDate: "", // 记账日期
        yearNum: "",
        accountingPeriod: "", // 会计期间
        accountingPeriodName: "",
        accountingPeriodMainId: "",
        objectId: ""
      },
      pickerOptions: {}, // 设置记账日期控件的选项设置
      vcdata: {
        itemList: [], // 分录行数据
        voucherHeader: [],
        voucherData: {}
      },
      commonData: {
        remark: "", // 摘要
        subjectId: "", // 科目id
        amountD: "", // 借方本位币
        amountC: "", // 贷方本位币
        foroCurrAmountD: "", // 借方原币金额
        foroCurrAmountC: "", // 贷方原币金额
        amount: "", // 原币金额
        quantityAcc: "", // 数量
        foroCurrSys: "", // 币种
        foroCurrSysName: "", // 币种name
        price: "", // 单价
        rate: "", // 汇率
        fzhs: [] // 辅助核算
      }, // 单条分录
      subjectList: [], // 科目list
      subjectMap: {}, // 科目map
      subjectProps: { value: "id", label: "formatText" },
      fzhsBaseData: {}, // 辅助核算对应的基础数据
      uploadParams: {}, // 上传参数
      imageList: [], // 上传图片集合
      fileList: [], // 上传文件集合
      btnList: [], // 按钮集合
      bUrl: "", // 返回路径
      isCheckPage: false, // 是否是凭证审核
      VOUCHER_RULE_LENDING_BALANCE: "1", // 是否需要借贷平衡
      modelItemList: [], // 创建模板使用 的分录行数据
      upextArr: [".gif", ".jpg", ".jpeg", ".bmp", ".png", ".pdf", ".doc", ".docx", ".txt", ".xls", ".xlsx", ".ppt", ".pptx", ".zip", ".rar"], // 上传文件类型限制
      imgArr: ["gif", "jpg", "jpeg", "bmp", "png"], // 图片类型
      fileArr: ["pdf", "doc", "docx", "txt", "xls", "xlsx", "ppt", "pptx", "rar", "zip"], // 文件类型
      attachmentNum: 0, // 单据数量
      submitParam: {}, // 提交参数
      busTemplateId: "",
      sourceId: ""
    };
  },
  filters: {
    formaDate(item) { // 时间格式化
      const ft = [{ value: "1", text: "YYYY-MM" },
        { value: "2", text: "YYYY/MM" },
        { value: "3", text: "YYYYMM" },
        { value: "4", text: "YYYY/MM/DD" },
        { value: "5", text: "YYYY/MM/DD HH:mm" },
        { value: "6", text: "YYYYMMDD" },
        { value: "7", text: "YYYYMMDD HH:mm" },
        { value: "8", text: "YYYY-MM-DD" },
        { value: "9", text: "YYYY-MM-DD HH:mm" },
        { value: "10", text: "YYYY-MM-DD HH:mm:ss" },
        { value: "11", text: "YYYY-MM-DD HH" },
        { value: "12", text: "YYYY" }];
      const { value, timeFormat } = item;
      const ob = ft.filter((itm) => itm.value === timeFormat.toString());
      return dayjs(new Date(value)).format(ob[0].text);
    }
  },
  computed: {
    ...mapGetters(["userInfo", "urlParam"]),
    ...mapGetters("intelligentAccounting", {
      cacheData: "getCacheVoucherData"
    }),
    upextStr() {
      return this.upextArr.join(",");
    }
  },
  beforeRouterUpdate(to, from, next) {
    this.bUrl = from.path; // 获取返回路径
    next();
  },
  mounted() {
    this.initPage();
  },
  methods: {
    /**
     * @description: 请求凭证详情
     */
    async initPage() {
      this.resetData();
      this.initRouteParam();
      await this.queryComConfig();
      await this.initProduceDate();
      await this.querySubject();
      await this.queryVoucherDetail();
      this.renderBtn();
      this.uploadParams = {
        appKey: this.urlParam.appKey,
        token: this.urlParam.token,
        ts: this.urlParam.ts
      };
    },
    /**
     * @description: 查询凭证详情
     */
    @request(true, "enLoading")
    async queryVoucherDetail() {
      let rsp;
      if (this.voucherId) {
        const pa = { vcType: 1, voucherId: this.voucherId };
        rsp = await voucherDetail.queryVoucherDetail(pa);
      } else {
        rsp = _.clone(this.cacheData);
        this.$store.commit("intelligentAccounting/_MxChangeVuexState", { cacheVoucherData: null });
      }

      // 编辑 复制凭证的情况下 此时如果 分录行中有选了  含辅助核算和外币的科目  需要处理一下数据
      const _this = this;
      if (this.actionType === 3 || this.actionType === 4) {
        rsp.itemList?.forEach((itm) => {
          if (itm.fzhs) {
            const acc = [];
            const fzhsbd = [];
            itm.fzhs?.forEach(async(fh) => {
              acc.push(fh.key);
              let dd;
              if (_this.fzhsBaseData[fh.key]) {
                dd = _this.fzhsBaseData[fh.key];
              } else {
                const re = await voucherDetail.queryObjectTypeByIds({ ids: fh.key });
                re[0].value = "";
                dd = re[0];
                _this.fzhsBaseData[fh.key] = dd;
              }
              fzhsbd.push(dd);
            });
            itm.assitAcc = acc.toString();
            itm.fzhsbd = fzhsbd;
          }
          itm.forcoinAcc = itm.foroCurrSys;
          itm.forcoinAccName = itm.foroCurrSysName;
          itm.quantityUnit = itm.quantityAccunit;
          itm.quantityUnitName = itm.quantityAccunitName;
          itm.amount = itm.amount || itm.foroCurrAmountD || itm.foroCurrAmountC;
        });
      }

      this.vcdata = { ...rsp };
      this.vcdata.itemList.push({});
      this.attachmentNum = rsp.voucherData.attachmentNum;

      // 附件与图片
      this.vcdata.voucherData?.themeFileList?.forEach((itm) => {
        itm.src = getFileUrl(itm.code, itm.type, itm.name);
        if (itm.type === "000" || itm.type === 0) {
          this.imageList.push(itm);
        } else {
          this.fileList.push(itm);
        }
      });
      Object.keys(this.vcHeader).forEach((itm) => {
        this.vcHeader[itm] = this.vcdata.voucherData[itm] || "";
      });
    },
    /**
     * @description: 获取路由数据
     */
    initRouteParam() {
      const { query } = this.$route;
      const { actionType, isCheckPage, id } = query;
      this.voucherId = id;
      this.actionType = Number(actionType);
      this.isCheckPage = !!(isCheckPage && (isCheckPage === true || isCheckPage === "true"));
      Object.keys(this.vcHeader).forEach((itm) => {
        this.vcHeader[itm] = query[itm] || "";
      });
    },
    /**
     * @description: 查询是否需要借贷平衡
     */
    // @request(true, "enLoading")
    async queryComConfig() {
      const rsp = await voucherDetail.queryComConfig();
      this.VOUCHER_RULE_LENDING_BALANCE = rsp ? rsp.VOUCHER_RULE_LENDING_BALANCE : "1";
    },
    /**
     * @description:  选择记账日期的时候，回填会计期间
     *                查询会计期间
     */
    // @request(true, "enLoading")
    async initProduceDate() {
      const pa = { accountId: this.vcHeader.accountId, accountingEntity: this.vcHeader.accountingEntity };
      const rsp = await voucherDetail.queryProduceDate(pa);
      this.pickerOptions.disabledDate = (time) => time.getTime() < new Date(rsp.startTime).getTime() || time.getTime() >= new Date(rsp.endTime).getTime();
      // this.produceDateChange(this.vcHeader.produceDate);
    },
    /**
     * @description: 记账日期改变 回调
     * @param value
     */
    async produceDateChange(value) {
      // 选择记账日期的时候，回填会计期间
      // 查询会计期间
      if (!value) return;
      const { vcHeader } = this;
      const dd = dayjs(value).format("YYYY-MM-DD");
      vcHeader.produceDate = dd;

      const pa = { appointTime: `${dd} 00:00:00` };
      const rsp = await voucherDetail.queryAccTimeDetByTime(pa);
      if (vcHeader.yearNum !== rsp.yearNum) {
        if (!vcHeader.yearNum) {
          vcHeader.accountingPeriod = rsp.id;
          vcHeader.accountingPeriodName = rsp.name;
          vcHeader.yearNum = rsp.yearNum;
          vcHeader.objectId = rsp.objectId;
          await this.querySubject();
        } else {
          // 更改会计年度，需要清除分录行
          this.changeYearNumConfirm(rsp);
        }
      } else {
        // 没有更改会计年度
        // eslint-disable-next-line no-lonely-if
        if (vcHeader.accountingPeriodName) { // 改了会计期间
          vcHeader.accountingPeriod = rsp.id;
          vcHeader.accountingPeriodName = rsp.name;
          vcHeader.objectId = rsp.objectId;
        }
      }
    },
    /**
     * @description: 重置data数据
     */
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
    /**
     * @description: 改了会计年度，需要清除分录行 确认
     */
    changeYearNumConfirm(rsp) {
      const _this = this;
      MessageBox.confirm("修改记账日期导致所属会计年度不同，将自动删除分录区科目", "提示信息", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "warning"
      }).then(async() => {
        _this.vcdata.itemList = [];
        _this.vcdata.itemList.push(JSON.parse(JSON.stringify(this.commonData)), {}, {});
        _this.vcHeader.accountingPeriodName = rsp.name;
        _this.vcHeader.yearNum = rsp.yearNum;
        await this.querySubject();
      });
    },
    /**
     * @description: 查询完凭证详情后获取按钮
     */
    renderBtn() {
      this.btnList = this.getDetailBtnList(this.actionType, this.vcdata.voucherData, this.isCheckPage);
    },
    /**
     * @description: 获取凭证来源名称
     * @param value
     */
    getRefIdName(value) {
      let txt = "";
      const item = value[0];
      if (!item) {
        txt = "无";
      } else if (item.busTemplateId) {
        if (value.length > 1) {
          txt = `${item.name}（${value.length}）`;
        } else {
          txt = item.name;
        }
      }
      txt = txt || "无";
      return txt;
    },
    /**
     * @description: 获取凭证头部值
     * @param item
     * @return:
     */
    getHeader(item) {
      const { value } = item;
      let re = "";
      if (_.isString(value)) {
        if (item.fieldNameVariable === "voucherNo") {
          // if(this.actionType === 3){ //编辑
          //   re = value ? "" : "自动生成";
          // }else{
          //   re = value ? value : "自动生成";
          // }
          re = value ? "" : "自动生成";
        }
      } else if (_.isObject(value)) {
        re = value.name;
      } else if (_.isArray(value)) {
        return value.forEach((a) => {
          re += a.name;
        });
      }
      return re;
    },
    /**
     * @description: 获取title
     */
    menuName() {
      const tp = this.actionType;
      switch (tp) {
        case 1:
          return "添加凭证";
        case 2:
          return "凭证详情";
        case 3:
          return "修改凭证详情";
        case 4:
          return "凭证复制";
        case 5:
          return "凭证冲销";
        default:
          return "";
      }
    },
    /**
     * @description: 收藏按钮
     * @param command 创建cj  引用yy
     */
    handleCommand(command) {
      if (command === "cj") {
        const re = this.getRowData();
        if (re) {
          this.modelItemList = JSON.parse(JSON.stringify(re));
          this.$refs.popCreateModel.visible = true;
        }
      } else if (command === "yy") {
        this.$refs.popQuoteModel.visible = true;
      }
    },
    /**
     * @description: 查询年度下的所有科目
     */
    async querySubject() {
      const pa = { accountId: this.vcHeader.accountId, yearNum: this.vcHeader.yearNum };
      if (!this.subjectMap[this.vcHeader.yearNum]) {
        const rsp = await voucherDetail.querySubjectNoParent(pa);
        rsp.forEach((item) => {
          item.formatText = `${item.code} ${item.name}`;
        });

        this.subjectMap[this.vcHeader.yearNum] = rsp;
        this.subjectList = rsp;
      }
    },
    /**
     * @description: 凭证分录数据更新
     * @param list
     */
    handleUpdateItemList(list) {
      this.$set(this.vcdata, "itemList", list);
    },
    /* eslint-enable */
    /**
     * @description: 删除图片
     * @param index
     */
    imageClose(index) {
      this.imageList.splice(index, 1);
      this.attachmentNum = Number(this.attachmentNum) - 1;
    },
    /**
     * @description: 删除图片
     * @param index
     */
    fileClose(index) {
      this.fileList.splice(index, 1);
      this.attachmentNum = Number(this.attachmentNum) - 1;
    },
    /**
     * @description: 保存
     * @param type 1暂存 2提交 3继续添加
     */
    save(type) {
      const rd = this.getRowData();
      if (!rd) {
        return;
      }
      const { vcHeader } = this;
      if (!vcHeader.accountingPeriod) {
        Message("记账日期未维护会计期间");
        return;
      }
      if (!vcHeader.accountingEntity) {
        Message("请选择记账主体");
        return;
      }
      const param = {
        operateType: type,
        itemList: rd,
        themeFileList: JSON.stringify([...this.fileList, ...this.imageList]),
        voucherMain: {
          id: "", //   string 凭证ID
          voucherNo: "", // string 凭证号
          refId: "", // string 凭证来源ID—查询和冲销用
          refCode: "", // string 凭证来源编码—查询和冲销用
          accountId: vcHeader.accountId,
          accountName: vcHeader.accountName,
          produceDate: vcHeader.produceDate,
          accountingPeriod: vcHeader.accountingPeriod,
          accountingPeriodName: vcHeader.accountingPeriodName,
          accountingPeriodMainId: vcHeader.objectId,
          attachmentNum: this.attachmentNum,
          accountingEntity: vcHeader.accountingEntity,
          accountingEntityName: vcHeader.accountingEntityName
        }
      };

      let sumD = 0; let sumC = 0;
      rd.forEach((itm) => {
        sumD += Number(itm.amountD);
        sumC += Number(itm.amountC);
      });
      console.log(executeNum(sumD, sumC, 1, 2));
      console.log(typeof (executeNum(sumD, sumC, 1, 2)));
      if (this.VOUCHER_RULE_LENDING_BALANCE === "0" && (executeNum(sumD, sumC, 1, 2) !== 0 || !sumC)) {
        Message("借贷合计金额不相等");
        return;
      }
      // 校验现金流科目借贷平衡
      this.checkLending(param, type);
    },
    /**
     * @description: 校验现金流科目 借贷平衡
     * @param param  提交数据
     * @param type  保存类型
     * @return:
     */
    checkLending(param, type) {
      let totalC = 0;
      let totalD = 0;
      let has = false;
      const list = param.itemList;
      list?.forEach((itm) => {
        if (itm.cashSubject === "1") { // 现金流科目
          totalC += itm.amountC ? Number(itm.amountC) : 0;
          totalD += itm.amountD ? Number(itm.amountD) : 0;
        } else { // 非现金流科目
          has = true;
        }
      });

      if (has && totalC !== totalD) { // 现金流量科目借贷不平衡
        this.submitParam = param;
        this.$refs.cashFlowMod.visible = true;
      } else {
        this.submitVoucher(param, type);
      }
    },
    /**
     * @description: 提交凭证数据
     * @param param 提交数据
     * @param type 保存类型 1暂存 2提交 3继续添加
     * @return:
     */
    async submitVoucher(param, type) {
      param.itemList = JSON.stringify(param.itemList);
      param.voucherMain = JSON.stringify(param.voucherMain);
      try {
        await voucherDetail.saveOrUpdateVoucher(param);
        Message({
          type: "success",
          message: type === 1 ? "暂存成功" : "提交成功",
          duration: 2000,
          onClose: () => {
            if (type === 3) { // 继续添加
              this.$router.go(0);
            } else {
              this.$router.go(-1);
            }
          }
        });
      } catch (error) {
        console.log(`%c${error}`, "color:red");
      }
    },
    /**
     * @description: 附件上传成功处理
     * @param response
     * @param file
     */
    handleUpload(response, file) {
      try {
        const { data } = response;
        const suffix = file.name.replace(/.+\./, "");
        const attr = {
          src: data.fileUrl,
          code: data.fileCode,
          extension: suffix,
          name: file.name,
          size: file.size
        };

        if (this.imgArr.includes(suffix)) { // 图片类型
          attr.type = "000";
          this.imageList.push(attr);
        } else if (this.fileArr.includes(suffix)) { // 文件类型
          attr.type = "001";
          this.fileList.push(attr);
        }
        this.attachmentNum = Number(this.attachmentNum) + 1;
      } catch (error) {
        Message(error);
        console.log(`%c${error}`, "color:red");
      }
    },
    /**
     * @description: 底部按钮点击事件
     * @param item 按钮详情
     */
    voucherBtnClick(item) {
      const { id } = item;
      const _this = this;
      switch (id) {
        case "save":
          this.save(1);
          break;
        case "submit":
          this.save(2);
          break;
        case "continueAdd":
          this.save(3);
          break;
        case "delete":
          this.singleClick({
            id,
            data: this.vcdata.voucherData,
            callback() {
              if (_this.bUrl) {
                _this.$router.push(_this.bUrl);
              } else {
                _this.$router.go(-1);
              }
            }
          });
          break;
        case "log":
          this.singleClick({
            id,
            data: this.vcdata.voucherData,
            isCheckPage: this.isCheckPage
          });
          break;
        case "print":
        {
          const list = _.cloneDeep(this.vcdata.itemList);
          // 最后1条数据是 用于表格补位的填充数据 去掉
          list.splice(list.length - 1, 1);
          this.singleClick({
            id,
            data: {
              ...this.vcdata.voucherData,
              itemList: list
            }
          });
          break;
        }
        default:
          break;
      }
    },
    /**
     * @description: 校验itemList 合法性,组装分录数据
     */
    getRowData() {
      const { itemList } = this.vcdata;
      let flg = false;
      const result = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const itm of itemList) {
        if (itm.remark && itm.subjectId && (itm.amountD || itm.amountC)) {
          // 编辑  查询接口 下发字段 跟 新增不一样  需做转换 (具体是借|贷 后台根据itemType判断)
          if (itm.cashFlowItem && !(itm.cCashPro && itm.dCashPro)) {
            itm.cCashPro = itm.cashFlowItem;
            itm.dCashPro = itm.cashFlowItem;
          }

          const rd = {
            remark: itm.remark,
            subjectId: itm.subjectId,
            subjectCode: itm.subjectCode,
            subjectName: itm.subjectName,
            amountD: itm.amountD,
            amountC: itm.amountC,
            quantityAcc: itm.quantityAcc,
            price: itm.price,
            foroCurrSys: itm.foroCurrSys,
            foroCurrSysName: itm.foroCurrSysName,
            rate: itm.rate,
            cashSubject: itm.cashSubject,
            cCashPro: itm.cCashPro,
            dCashPro: itm.dCashPro,
            fzhs: itm.fzhs || []
          };
          rd.foroCurrAmountD = itm.amountD ? itm.amount : "";
          rd.foroCurrAmountC = itm.amountC ? itm.amount : "";

          if (itm.fzhs) {
            itm.fzhs?.forEach((fd) => {
              rd[fd.key] = fd.value;
            });
          }

          result.push(rd);
        } else if (!itm.remark && !itm.subjectId && !(itm.amountD && itm.amountC)) {
          // eslint-disable-next-line no-continue
          continue;
        } else {
          flg = true;
          break;
        }
      }
      if (flg) {
        Message("请输入完整的分录行");
        return false;
      }
      return result;
    },
    /**
     * @description: 选择引用模板回调
     * @param list 选择 的分录数据
     */
    handleQuoteModel(list) {
      const itemList = this.vcdata.itemList;
      itemList.splice(itemList.length - 2, 0, ...list);
    },
    /**
     * @description: 现金流量科目 设置确认
     * @param list  被设置非现金流量科目
     * @return:
     */
    cashFlowSubmit(list) {
      const _this = this;
      const { itemList, operateType } = this.submitParam;
      itemList.forEach((itm, idx) => {
        if (itm.cashSubject !== 1) {
          const item = _this.getChangedItem(list, itm.subjectId);
          if (item) {
            itemList.splice(idx, 1, item);
          }
        }
      });
      this.submitVoucher(this.submitParam, operateType);
    },
    getChangedItem(list, id) {
      const rsp = list.filter((itm) => itm.subjectId === id);
      return rsp[0] || "";
    },
    /**
     * @description: 凭证来源穿透
     * @param data
     */
    throughVoucher(data) {
      if (data.value && data.value.length && data.value[0].id) {
        this.showVoucherRelation(data.value, this.vcdata.voucherData.voucherType);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  font-size: 12px;
  .en-top-menu {
    border-bottom: 1px solid #dce5ec;
    .en-top-menu-right > div {
      display: flex;
      justify-content: flex-end;
      .status-model {
        margin-top: 18px;
        margin-right: 10px;
      }
    }
    .el-dropdown{
      height: 30px;
    }
  }
  .content {
    padding: 0 20px;
    height: calc(100% - 60px);
    overflow: hidden;
    overflow-y: auto;
    .top-row {
      height: 70px;
      line-height: 70px;
      overflow: hidden;
      .through{
        color:#179eda;
        text-decoration:underline;
        cursor: pointer;
      }
    }
    .el-col {
      overflow: hidden;
      text-align: left;
      display: flex;
      & /deep/ .el-date-editor {
        width: 180px;
      }
      .el-input {
        width: 180px;
      }
    }
  }
  .grid-wrap {
    width: 100%;
    height: calc(100% - 390px);
    overflow-y: hidden;
  }
  .middle-row {
    height: 70px;
    line-height: 70px;
    border-bottom: 1px solid #dcdfe6;
    display: flex;
    > div {
      text-align: left;
      width: 20%;
      &:last-child {
        text-align: right;
        width: 60%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        .el-input {
          width: 280px;
          margin-left: 10px;
        }
        .suffix {
          position: absolute;
          font-size: 14px;
          color: #dcdfe6;
          right: 10px;
        }
      }
    }
  }
  .bottom-row {
    height: 50px;
    line-height: 50px;
    text-align: left;
    .foot-upload {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .file-row {
    min-height: 120px;
    .img-lis {
      text-align: left;
      .img-li {
        display: inline-block;
        margin-right: 10px;
      }
    }
    .file-lis {
      margin-top: 20px;
      text-align: left;
      .file-li {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .btn-row {
    height: 60px;
    line-height: 60px;
    text-align: right;
    border-top: 1px solid #dce5ec;
    .en-button {
      width: 75px;
    }
  }
}
</style>
