<!--
 * @Author: pengyu
 * @Date: 2020-06-16 11:39:08
 * @LastEditors: pengyu
 * @LastEditTime: 2020-07-02 09:56:35
 * @Description: file content
-->
<template>
  <en-dialog
    :visible="visible"
    title="记账日期"
    width="700px"
    @close="visible = false"
  >
    <div class="body-contain">
      <div class="flex-item">
        <div class="tips-text">
          默认日期
        </div>
        <el-date-picker
          v-model="dateValue"
          type="date"
          placeholder="选择日期"
          class="date-picker"
          clearable
        >
        </el-date-picker>
      </div>
    </div>
    <div slot="footer">
      <div class="bottom-tips-text">
        注：单据日期不同，如存在合并凭证，需选择默认记账日期
      </div>
      <en-button
        class="button"
        @click="confirmClick"
      >
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { Message } from "element-ui";
import dayjs from "dayjs";

export default {
  name: "IpDialog",
  props: {

  },
  data() {
    return {
      dateValue: "",
      visible: false
    };
  },
  watch: {
    visible() {
      this.dateValue = "";
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗
     */
    closeClick() {
      this.visible = false;
    },
    // 确定记账日期
    confirmClick() {
      if (this.dateValue !== "") {
        this.closeClick();
        const dd = dayjs(this.dateValue).format("YYYY-MM-DD");
        this.$emit("getComfirmValue", dd);
      } else {
        Message({
          showClose: false,
          message: "请选择一个记账日期",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header{
  text-align: left;
}
/deep/ .el-dialog__footer>div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .bottom-tips-text{
    font-size: 12px;
    color: #999;
  }
}
.body-contain{
  height: 200px;
  .flex-item{
    display: flex;
    font-size: 12px;
    align-items: center;
    .date-picker{
      margin-left: 10px;
      flex: 1;
    }
  }
  /deep/.el-input__inner{
    width: 100%;
  }
}
</style>
