<template>
  <div class="print-template" id="print-template-foreign">
    <div class="title">{{title}}</div>
    <div class="table-info">
      <span>编制单位:{{searchData.unit}}</span> &nbsp; &nbsp;
      <span>会计期间:{{searchData.period}}</span> &nbsp; &nbsp;
      <span>单位:元</span>
    </div>
    <table cellspacing="0" cellpadding="0" border="0">
      <thead>
        <tr>
          <th colspan="1" rowspan="2" width="100" style="text-align: center;">科目</th>
          <th colspan="1" rowspan="2" style="text-align: center;">币种</th>
          <th colspan="3" rowspan="1" style="text-align: center;">期初余额</th>
          <th colspan="4" rowspan="1" style="text-align: center;">本期发生</th>
          <th colspan="3" rowspan="1" style="text-align: center;">期末余额</th>
        </tr>
        <tr>
          <th colspan="1" rowspan="1">方向</th>
          <th colspan="1" rowspan="1">原币</th>
          <th colspan="1" rowspan="1">外币</th>
          <th colspan="1" rowspan="1">借方原币</th>
          <th colspan="1" rowspan="1">借方外币</th>
          <th colspan="1" rowspan="1">贷方原币</th>
          <th colspan="1" rowspan="1">贷方外币</th>
          <th colspan="1" rowspan="1">方向</th>
          <th colspan="1" rowspan="1">原币</th>
          <th colspan="1" rowspan="1">外币</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(rowData, index) in dataList" :key="index">
          <td colspan="1" rowspan="1">{{rowData.name}}</td>
          <td colspan="1" rowspan="1">{{rowData.foroCurrSysName}}</td>
          <td colspan="1" rowspan="1">{{rowData.balanceDir}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.balanceOri|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.balanceFor|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.curBorOri|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.curBorFor|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.curLoanOri|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.curLoanFor|thousand(2)}}</td>
          <td colspan="1" rowspan="1">{{rowData.endDir}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.endOri|thousand(2)}}</td>
          <td colspan="1" rowspan="1" align="right">{{rowData.endFor|thousand(2)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { thousand } from "en-js";

// 外币金额余额表
export default {
  name: "PrintTemplateForeign",
  components: {
  },
  filters: {
    thousand(value, decimal) {
      if (value === 0 || value === "") {
        return "";
      }
      return thousand(value, decimal);
    }
  },
  props: {
    isYear: {
      type: [Number, Boolean]
    },
    dataList: {
      type: Array,
      default() {
        return [];
      }
    },
    searchData: {
      type: Object,
      default() {
        return {};
      }
    },
    afferentData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    title() {
      const { startDate, endDate } = this.afferentData;
      if (startDate && endDate && startDate !== endDate) {
        return `外币金额余额表（${startDate}—${endDate}）`;
      }
      return "外币金额余额表";
    }
  }
};
</script>

<style lang="scss">
.print-template {
  position: fixed;
  z-index: -12;
  width: 1000px;
  height: auto;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: #fff;
  .title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  .table-info {
    font-size: 12px;
    margin: 10px 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    color: #000;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    th {
      text-align: left;
      padding: 6px 10px;
      line-height: 20px;
      border: 1px solid #333;
      word-break: break-all;
      font-weight: normal;
    }
  }
  th{text-align:left;padding:6px 10px;line-height:20px;border:1px solid #333;word-break:break-all;font-weight:normal;}
  td{padding:2px 10px;line-height:20px;border:1px solid #333;word-break:break-all;height:24px !important;}
  table{border-collapse:collapse;width:100%;table-layout:fixed;color:#000;background-color:#fff;font-size:12px;}
}
</style>
