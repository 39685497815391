<!--
 * @Author: 周晴龙
 * @Date: 2020-07-28 09:45:56
 * @LastEditTime: 2021-05-12 16:43:40
 * @LastEditors: pengyu
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\intelligentAccounting\components\subjectSel\index.vue
-->
<template>
   <div class="choice-subject">
    <div class="left-arrow" @click="prevtSubject">
      <en-icon
        name="xiayiye1"
        size="10px"
        color="#b6c0cf"
      ></en-icon>
    </div>
    <en-select
      placeholder="请选择"
      v-model="subjectId"
      node-key="id"
      :data="subjectList"
      :props="subjectProps"
      filterable
      align="left"
      @change="subjectClk"
      data-mode="id-name"
    >
    </en-select>
    <div class="right-arrow" @click="nextSubject">
      <en-icon
        name="xiayiye"
        size="10px"
        color="#b6c0cf"
      ></en-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subjectList: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      subjectProps: {
        label: this.contentFormat,
        value: "id"
        // assist: this.contentFormat
      },
      subjectId: ""
    };
  },
  computed: {
    /**
     * @description: 找当前下标
     */
    subjectIndex() {
      return this.subjectList.findIndex((item) => item.id === this.subjectId);
    },
    subjectItem() {
      return this.subjectList[this.subjectIndex];
    }
  },
  watch: {
    subjectList() {
      this.setDefaultValue();
    }
  },
  methods: {
    /**
     * @description: 自定义label
     */
    contentFormat(data) {
      if (data) {
        return `${data.code || ""} ${data.name || ""}`;
      }
      return "";
    },
    /**
     * @description: 设置默认科目
     */
    setDefaultValue() {
      if (this.subjectList.length) {
        if (this.defaultId) {
          this.subjectId = this.defaultId;
        } else {
          this.subjectId = this.subjectList[0].id;
        }
      } else {
        this.subjectId = "";
      }
    },
    /**
     * @description: 选中ID
     */
    subjectClk(val) {
      this.subjectId = val.id;
      this.handleQuery();
    },
    /**
     * @description: 上一个
     */
    prevtSubject() {
      if (this.subjectIndex > 0 && this.subjectIndex < this.subjectList.length - 1 && this.subjectList[this.subjectIndex - 1]) {
        this.subjectId = this.subjectList[this.subjectIndex - 1].id;
        this.handleQuery();
      }
    },
    /**
     * @description: 下一个
     */
    nextSubject() {
      if (this.subjectIndex >= 0 && this.subjectIndex < this.subjectList.length - 1 && this.subjectList[this.subjectIndex + 1]) {
        this.subjectId = this.subjectList[this.subjectIndex + 1].id;
        this.handleQuery();
      }
    },
    /**
     * @description: 触发查询
     */
    handleQuery() {
      this.$emit("subjectVal", this.subjectItem);
    }

  }
};
</script>

<style lang="scss" scoped>
.choice-subject {
  display: table;
  .left-arrow,
  .right-arrow {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    line-height: 30px;
    width: 22px;
    text-align: center;
    border: 1px solid #e8ecf2;
    border-radius: 3px;
    cursor: pointer;
    background: #fff;
  }
  .en-select {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
  }
}
</style>
