<!--
 * @Author: pengyu
 * @Date: 2020-03-10 11:27:40
 * @LastEditTime: 2021-06-25 15:20:21
 * @LastEditors: pengyu
 * @Description: 凭证处理-列表视图
 * @FilePath: \user-intelligent-accounting\src\views\voucherHandle\voucherList.vue
 -->
<template>
  <div class="container" style="background:#1d2532">
    <div :class="isActive ? 'left-container expand' : 'left-container'">
      <top-nav
        ref="topNav"
        :is-check-page="isCheckPage"
        cur-view="2"
        :acc-list="accList"
        :entity-list="entityList"
        :batch-btn="batchBtn"
        :self-btn="selfBtn"
        :params="params"
        :selectDisable="selectDisable"
        @change-param="handleChangeParmas"
        @top-btn-click="topBtnClick"
      ></top-nav>
      <div class="content" v-en-loading="enLoading">
        <div
          v-if="params.conditions.length"
          class="condition"
          :class="params.conditions.length ? 'expand' : ''"
        >
          <screenModel
            v-for="(item,index) in params.conditions"
            :key="index"
            :item="item"
            :index="index"
            @deleteCondition="deleteCondition"
          ></screenModel>
          <en-button class="clear-btn" type="text" @click="clearAllCondition">清空</en-button>
        </div>
        <div class="grid-warp">
          <en-table
            ref="table"
            :data="voucherData"
            :height="tableHeight"
            :head-end-config="{type:'more',moreChangeMethod: handleMoreChange}"
            :hide-columns="hideColumn"
            @filter-sort-change="handleFilterSortChange"
            @select-change="selectionChange"
            @row-click="rowClickEvent"
            v-if="columnData.length"
          >
            <en-table-column type="index-selection"></en-table-column>
            <en-table-column
              v-for="(headData) in columnData"
              :key="headData.field"
              :data="headData"
              :field="headData.field"
              :title="headData.title"
              :filter-sort-config="headData"
              :align="['totalD','totalC'].includes(headData.field)?'right':'left'"
            >
              <template v-slot="{row}">
                <span v-if="headData.field==='voucherNo'">
                  {{ row.voucherNo | padStart(4) }}
                </span>
                <span v-else-if="textField.includes(headData.field)">
                  {{ row[headData.field] || "" }}
                </span>
                <span v-else-if="headData.field==='refCode'">
                  {{ row.refCode ? row.refCode : "无" }}
                </span>
                <span v-else-if="headData.field==='totalD'">
                  {{ row.totalD | thousand }}
                </span>
                <span v-else-if="headData.field==='totalC'">
                  {{ row.totalC | thousand }}
                </span>
                <span v-else-if="headData.field==='produceDate'">
                  {{ row.produceDate.slice(0,11) }}
                </span>
                <span v-else-if="headData.field==='busTemplateName'">
                   {{ row.busTemplateName || "无" }}
                </span>
                <status-model
                  v-else-if="headData.field==='status'"
                  :status="row.status"
                ></status-model>
              </template>
            </en-table-column>
            <template #hoverRow="{row, rowIndex}">
              <en-expand-buttons
                :data="getRowBtns(row)"
                @button-click="handleButtonClick(row, rowIndex, arguments[1], arguments[0])"
              />
            </template>
          </en-table>
        </div>
        <div class="pagi">
          <en-pagination
            :page-model="pageModel"
            @change="handlePageChanged"
          ></en-pagination>
        </div>
      </div>
    </div>
    <!-- 筛选 -->
    <screenAlert
      ref="popScreen"
      :dateList="dateLineList"
      :accountId="params.accountId"
      :initData="params.conditions"
      @submitScreen="handleCondtion"
    ></screenAlert>
    <!-- 打印 -->
    <printAlert ref="popPrint"></printAlert>
    <!-- 操作日志 -->
    <logAlert ref="popLog" :logParam="logParam"></logAlert>
    <!-- 会计期间 -->
    <dateInterval
      class="date-interval"
      :type="1"
      :list="dateLineList"
      @collapseBtn="isActive=!isActive"
      @handleAccChange="accChange"
    ></dateInterval>
    <!-- 打印模板 -->
    <vocPrintTemplate></vocPrintTemplate>
    <!-- 凭证详情 -->
    <vocDetailAlert
      ref="VDA"
      :actionType="actionType"
      :voucherId="voucherId"
      :voucherIdAry="voucherIdAry"
      :isCheckPage="isCheckPage"
      :formal="detailProp"
      refreshFunc="queryVoucherList"
    ></vocDetailAlert>
  </div>
</template>

<script>
import { thousand, padStart } from "en-js";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import { intelligentService } from "@/api";
import voucherButtonEvent from "@/mixins/intelligentAccounting/voucherButtonEvent";// 按钮公共方法
import voucherComm from "@/mixins/intelligentAccounting/voucherComm"; // 查询公共参数
import getSingleBtnList from "@/mixins/intelligentAccounting/getSingleBtnList"; // 获取单行按钮
import topNav from "../components/topNav";// 顶部操作栏
import screenAlert from "../components/screenAlert";// 筛选弹窗
import printAlert from "../components/printSetting"; // 打印设置
import logAlert from "../components/logAlert"; // 操作日志'
import statusModel from "../components/statusModel";
import dateInterval from "../components/dateInterval"; // 会计期间控件\
import vocPrintTemplate from "../components/vocPrintTemplate";
import vocDetailAlert from "../components/vocDetailAlert"; // 凭证详情弹窗
import screenModel from "../components/screenModel"; // 自定义筛选块块

export default {
  name: "VoucherList",
  components: {
    topNav,
    screenAlert,
    printAlert,
    logAlert,
    statusModel,
    dateInterval,
    vocPrintTemplate,
    vocDetailAlert,
    screenModel
  },
  mixins: [
    voucherButtonEvent,
    voucherComm,
    getSingleBtnList
  ],
  filters: { thousand, padStart },
  data() {
    return {
      enLoading: null,
      isActive: false,
      params: { // 查询数据 参数
        conditions: [],
        filterFields: "",
        sortFields: "",
        accountingPeriod: "",
        accountId: "",
        accountingEntity: "",
        itemFlag: 0,
        pageSize: 10,
        pageNo: 1,
        queryType: 0
      },
      dateLineParam: {
        accountingPeriod: "",
        accountingPeriodName: "",
        yearNum: "",
        accountingPeriodMainId: ""
      },
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 2,
        totalPages: 1
      },
      voucherData: [], // 列表数据
      accList: [], // 账套列表
      entityList: [], // 记账主体
      batchBtn: false, // 批量按钮是否显示
      dateLineList: [], // 会计期间shuju
      defaultColumn: [
        {
          field: "voucherNo",
          nameVariable: "voucherNo",
          fieldType: 1,
          title: "凭证号",
          name: "凭证号",
          fieldName: "凭证号",
          filterable: true,
          sortable: true, // 1排序 0不排序
          type: "001"// 文本
        },
        {
          field: "voucherDataTypeName",
          nameVariable: "voucherDataTypeName",
          fieldType: 1,
          title: "凭证类别",
          name: "凭证类别",
          fieldName: "凭证类别",
          filterable: true,
          sortable: true,
          type: "001"// 文本
        },
        {
          field: "refCode",
          nameVariable: "refCode",
          fieldType: 1,
          title: "业务编号",
          name: "业务编号",
          fieldName: "业务编号",
          sortable: true,
          filterable: true,
          type: "001"
        },
        {
          field: "busTemplateName",
          nameVariable: "busTemplateName",
          fieldType: 1,
          title: "业务模板",
          name: "业务模板",
          fieldName: "业务模板",
          sortable: true,
          filterable: true,
          type: "001"
        },
        {
          field: "totalD",
          nameVariable: "totalD",
          fieldType: 4,
          title: "借方合计",
          name: "借方合计",
          fieldName: "借方合计",
          sortable: true,
          filterable: true,
          type: "004"// 金额
        },
        {
          field: "totalC",
          nameVariable: "totalC",
          fieldType: 4,
          title: "贷方合计",
          name: "贷方合计",
          fieldName: "贷方合计",
          sortable: true,
          filterable: true,
          type: "004"
        },
        {
          field: "produceDate",
          nameVariable: "produceDate",
          fieldType: 7,
          title: "记账日期",
          name: "记账日期",
          fieldName: "记账日期",
          sortable: true,
          filterable: true,
          type: "007"// 日期
        },
        {
          field: "status",
          nameVariable: "status",
          fieldType: 1,
          title: "凭证状态",
          name: "凭证状态",
          fieldName: "凭证状态",
          sortable: true,
          filterable: true,
          type: "001"
        },
        {
          field: "interfaceStatusName",
          nameVariable: "interfaceStatusName",
          fieldType: 1,
          title: "是否传输",
          name: "是否传输",
          fieldName: "是否传输",
          sortable: true,
          filterable: true,
          type: "001"
        },
        {
          field: "makeName",
          nameVariable: "makeName",
          fieldType: 1,
          title: "制单人",
          name: "制单人",
          fieldName: "制单人",
          sortable: true,
          filterable: true,
          type: "001"
        },
        {
          field: "approveName",
          nameVariable: "approveName",
          fieldType: 1,
          title: "审核人",
          name: "审核人",
          fieldName: "审核人",
          sortable: true,
          filterable: true,
          type: "001"
        },
        {
          field: "attachmentNum",
          nameVariable: "attachmentNum",
          fieldType: 3,
          title: "附单据数",
          name: "附单据数",
          fieldName: "附单据数",
          sortable: true,
          filterable: true,
          type: "001"
        }
      ],
      columnData: [],
      hideColumn: [], // 隐藏列头
      statusName: {
        "000": "待审核",
        "001": "已审核",
        "002": "生成异常",
        "004": "未提交"
      },
      isCheckPage: false, // 是否是凭证审核
      commParams: {}, // 凭证管理端配置项
      settleParam: {}, // 结账页面穿透带过来的参数
      selectDisable: false,
      detailProp: { // 凭证详情用props
        accountId: "",
        accountName: "",
        accountingEntity: "",
        accountingEntityName: ""
      },
      actionType: 1,
      voucherId: "",
      voucherIdAry: [], // 当前页凭证ids
      selfBtn: [], // 自定义按钮
      selfCondition: [],
      filterStore: {
        dataList: [
          {
            type: "filter", nameVariable: "goubi", fieldType: 1, value: "小狗", valueText: "小狗", column: { title: "狗比" }
          }
        ]
      },
      logParam: {},
      textField: ["voucherDataTypeName", "interfaceStatusName", "makeName", "approveName", "attachmentNum"]
    };
  },
  watch: {
    isActive() {
      // 重置列头的宽度
      this.$refs.table.resetResizable();
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("intelligentAccounting", ["getSaPzclConditionlist"]),
    tableHeight() {
      const content = document.querySelector(".grid-warp");
      const isFilter = this.params.sortFields || this.params.filterFields || this.params.conditions.length;
      if (content) {
        return `${content.clientHeight - (isFilter ? 53 : 0)}px`;
      }
      return "";
    }
  },
  methods: {
    /**
     * @description:  初始化
     * @param {type}
     */
    async init() {
      // 重置数据
      Object.assign(this.$data, this.$options.data());
      const { query } = this.$route;
      this.isCheckPage = query.isCheckPage === "true" ? true : query.isCheckPage === "false" ? false : query.isCheckPage;
      this.params.queryType = this.isCheckPage ? 1 : 0;
      if (query && query.flg === "1") {
        // 从结账页面穿透过来的
        this.settleParam = { ...query };
      } else {
        this.settleParam = {};
      }
      // 查询自定义按钮
      const rsp = await intelligentService.queryBtnFunc();
      if (rsp) {
        this.selfBtn = [...rsp];
      }
      await this.queryAccountList();

      // 查询自定义列头
      await this.queryBehaviorCol();

      // 结账穿透过来的，会有默认账套，默认记账主体， 默认条件等
      if (this.settleParam.flg === "1") {
        this.selectDisable = true;
        this.params.accountId = this.settleParam.accountId;
        this.params.accountingEntity = this.settleParam.accountingEntity;
        this.params.conditions = this.getSaPzclConditionlist || [];
      } else {
        this.selectDisable = false;
      }
      await this.queryDateLine({ accountId: this.params.accountId, hasChildren: "000" });
      // 结账穿透过来的，会有默认会计期间
      if (this.settleParam.flg === "1") {
        this.params.accountingPeriod = this.settleParam.accountingPeriod;
        this.dateLineList.forEach((itm) => {
          (itm.nodes || []).forEach((citm) => {
            if (citm.id === this.settleParam.accountingPeriod) {
              citm.isDefault = 0;
              this.dateLineParam = {
                accountingPeriod: citm.id,
                accountingPeriodName: citm.name,
                yearNum: citm.yearNum,
                accountingPeriodMainId: citm.mId
              };
            } else {
              citm.isDefault = 1;
            }
          });
        });
      }
      await this.queryVoucherList();
    },
    /**
     * @description:  筛选排序事件
     * @param {param}
     */
    handleFilterSortChange({ filterData, sortData }) {
      this.params.conditions = [];
      this.params.filterFields = filterData;
      this.params.sortFields = sortData;
      this.queryVoucherList();
    },
    /**
     * @description: 单行按钮点击
     * @param {type}
     * @return:
     */
    handleButtonClick(row, index, arg1) {
      const { id } = arg1;
      const data = row;
      this.singleClick({ id, data });
    },
    /**
     * @description: 表格行勾选处理
     * @param val
     */
    selectionChange(val) {
      const { selection: ary } = val;
      this.voucherData.forEach((itm) => {
        const { id } = itm;
        let flg = false;
        ary.forEach((citm) => {
          if (citm.id === id) {
            flg = true;
          }
        });
        itm.checked = flg;
      });
      this.batchBtn = !!ary.length;
    },
    /**
     * @description: 表格行点击
     * @param {type}
     */
    rowClickEvent({ row }) {
      this.actionType = 2;
      this.voucherId = row.id;
      this.setProps();
      this.$refs.VDA.visible = true;
    },
    setProps() {
      const val = this.$refs.topNav.getCurValue();
      const { accountId, accountingEntity } = val;
      this.detailProp = {
        accountId,
        accountName: (() => {
          let n = "";
          this.accList.forEach((itm) => {
            if (itm.id === accountId) {
              n = itm.name;
            }
          });
          return n;
        })(),
        accountingEntity,
        accountingEntityName: (() => {
          let n = "";
          this.entityList.forEach((itm) => {
            if (itm.id === accountingEntity) {
              n = `${itm.code} ${itm.name}`;
            }
            (itm.nodes || []).forEach((citm) => {
              if (citm.id === accountingEntity) {
                n = `${citm.code} ${citm.name}`;
              }
            });
          });
          return n;
        })()
      };
    },
    /**
   * @description:  获取列表行操作按钮
   */
    getRowBtns(row) {
      row = cloneDeep(row);
      const btns = this.getSingleBtnList(row, this.commParams, this.isCheckPage);
      // v4.8.10 增加自定义按钮
      const selfList = [];
      (row.customBtnList || []).forEach((itm) => {
        itm.id = itm.btnKey;
        itm.name = itm.btnName;
        itm.icon = `bus-btn${itm.icon}`;
        itm.iconColor = itm.color;
        selfList.push(itm);
      });

      return [...btns, ...selfList];
    },
    /**
     * @description: 自定义筛选  增加筛选块块
     */
    handleCondtion(list) {
      const obj = {
        subjectId: "科目",
        baseDataTableType: "辅助核算",
        remark: "摘要",
        voucherWay: "凭证类型",
        voucherNo: "凭证号码",
        amount: "金额",
        status: "凭证状态",
        makeId: "制单人",
        interfaceStatus: "是否传输",
        voucherDataType: "凭证类别"
      };
      const arr = [];
      list.forEach((itm) => {
        itm.type = "filter";
        itm.field = itm.nameVariable;
        itm.prop = itm.nameVariable;
        itm.property = itm.nameVariable;
        itm.title = obj[itm.nameVariable];
        itm.src = "screen";
        itm.column = {
          field: itm.nameVariable,
          prop: itm.nameVariable,
          property: itm.nameVariable,
          title: obj[itm.nameVariable],
          fieldType: itm.fieldType
        };
        arr.push(cloneDeep(itm));
      });
      this.$refs.table.filterStore.dataList = [];
      this.params.filterFields = "";
      this.params.sortFields = "";
      this.params.conditions = arr;
      this.queryVoucherList();
    },
    /**
     * @description:  筛选块块删除
     * @param index
     */
    deleteCondition(index) {
      this.params.conditions.splice(index, 1);
      // 更新条件 查询一遍数据
      this.queryVoucherList();
    },
    /**
     * @description:  清除全部自定义筛选
     * @param index
     */
    clearAllCondition() {
      this.params.conditions = [];
      this.queryVoucherList();
    },
    /**
     * @description:  查询自定义列头
     */
    async queryBehaviorCol() {
      try {
        const rsp = await intelligentService.queryUserBehavior({ templateId: "226507326664736768" });
        let res = [];
        if (rsp.fieldSort && rsp.fieldSort.length) {
          const arr = cloneDeep(this.defaultColumn);
          rsp.fieldSort.forEach((item) => {
            const index = arr.findIndex((inner) => inner.field === item.field);
            if (index > -1) {
              res.push(arr[index]);
              arr.splice(index, 1);
            }
          });
          this.hideColumn = cloneDeep(arr);
          res = res.concat(arr);
        } else {
          res = cloneDeep(this.defaultColumn);
        }
        if (rsp.fixedColumnNum > 0) {
          res.forEach((itm, idx) => {
            if (idx <= rsp.fixedColumnNum) {
              itm.fixed = "left";
            }
          });
        }

        this.columnData = res;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @description:  保存自定义列头
     * @param params  保存参数
     */
    async handleMoreChange(params) {
      try {
        this.enLoading = true;
        const fieldSort = JSON.parse(params.fieldSort);
        const fixedColumnNum = params.fixedColumnNum;
        const arr = [];
        fieldSort.forEach((itm) => {
          if (itm.field && itm.visible) {
            arr.push(itm.data);
          }
        });
        await intelligentService.saveUserBehavior({ fieldSort: JSON.stringify(arr), fixedColumnNum, templateId: "226507326664736768" });
        this.enLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  position: relative;
  .left-container {
    height: 100%;
    width: calc(100% - 72px);
    background: #ffffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &.expand {
      width: 100%;
    }
    .content{
      height: calc(100% - 50px);
      .condition {
        width: 100%;
        display: flex;
        align-items: center;
        // min-height: 10px;
        // border-bottom: 1px solid #dce5ec;
        background: #f7f9f9;
        .clear-btn{
          margin-left: 10px;
          color: #4695DF;
          &:hover{
            color: #409eff;
          }
        }
        &.expand {
          height: 54px;
        }
        &.expand + .grid-warp{
          height: calc(100% - 108px);
        }
      }
    }
    .grid-warp{
      height: calc(100% - 55px);
      & /deep/ .vxe-table-box{
        width: 100%;
      }
      & /deep/ .vxe-table--repair{
        height: 0;
      }
      & /deep/ .vxe-table:before{
        height: 0;
      }
    }
  }
  .date-interval {
    position: absolute;
    right: 4px;
    top: 0;
  }
}
</style>
<style lang="scss">
.dh-confirm .el-icon-info{
  color: #3e90fe;
}
</style>
