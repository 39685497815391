<!--
 * @Author: zoujp
 * @Date: 2021-04-28 11:35:01
 * @LastEditTime: 2021-05-20 14:49:14
 * @LastEditors: Please set LastEditors
 * @Description: 用于财务报表-记账日期-季度
 * @FilePath: \user\src\views\intelligentAccounting\components\weekInterval\index.vue
-->
<template>
  <div class="date-interval-container" :class="{ active: isActive }">
    <!-- head+body -->
    <div class="date-bar">
      <!-- body -->
      <div class="line">
        <div class="top-arrow" @click="topArrow">
          <en-icon name="zhankai" size="small" color="#A9B5C6"></en-icon>
        </div>
        <div class="line-list">
          <div v-for="(item, index) in dateRangeList" :key="index" class="date-item">
            <div class="date-year">{{ item.name }}</div>
            <div
              v-for="(month, cindex) in item.nodes"
              :key="cindex"
              :class="{ 'date-month': true, cur: month.isDefault === 0 }"
              :style="!index && cindex === 0 ? styles : ''"
              :title="getQuerterName(month.id.substr(month.id.length - 1, 1))"
              @click="itemClick(month)"
            >
              {{ month.id.substr(month.id.length - 1, 1) }}
            </div>
          </div>
        </div>
        <div class="bottom-arrow" @click="bottomArrow">
          <en-icon name="zhankai" size="small" color="#A9B5C6"></en-icon>
        </div>
      </div>
    </div>
    <!-- 切换按钮 -->
    <div class="open-icon" @click="collapseBtn">
      <en-icon name="shouqi-huijiqijian" size="12px" color="#fff"></en-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: "QuarterInterval",
  components: {},
  props: {
    // 类型： 1月季 2年
    type: {
      type: Number,
      default: 2
    },
    // 初始数据
    list: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    list: {
      deep: true,
      handler(nVal) {
        this.dateRangeList = nVal;
      }
    }
  },
  data() {
    return {
      dateRangeList: this.list || [],
      styles: {},
      rangeStep: 0,
      startDate: "", // 开始日期
      endDate: "", // 结束日期
      isActive: false,
      quarter: [
        { value: "1", text: "第一季度" },
        { value: "2", text: "第二季度" },
        { value: "3", text: "第三季度" },
        { value: "4", text: "第四季度" }
      ]
    };
  },
  mounted() {},
  methods: {
    getQuerterName(val) {
      let n = "";
      for (let i = 0; i < this.quarter.length; i++) {
        if (this.quarter[i].value.toString() === val) {
          n = this.quarter[i].text;
          break;
        }
      }
      return n;
    },
    /**
     * @description: 展开隐藏日期区间
     */
    collapseBtn() {
      this.isActive = !this.isActive;
      this.$emit("collapseBtn");
    },
    /**
     * @description: 会计区间向上滑动
     */
    topArrow() {
      if (this.rangeStep > 0) {
        this.rangeStep -= this.rangeStep >= 5 ? 5 : this.rangeStep;
        this.styles = {
          "margin-top": `${this.rangeStep * -32}px`,
          transition: "1s"
        };
      }
    },
    /**
     * @description: 会计区间向下滑动
     */
    bottomArrow() {
      let allItem = 0;
      this.dateRangeList.forEach((v) => {
        allItem += v.nodes?.length || 0;
      });
      const allItemLength = allItem - 13;
      if (this.rangeStep < allItemLength) {
        this.rangeStep += allItemLength - this.rangeStep >= 5 ? 5 : allItemLength - this.rangeStep;
        this.styles = {
          "margin-top": `${this.rangeStep * -32}px`,
          transition: "1s"
        };
      }
    },
    /**
     * @description: 会计期间点击
     */
    itemClick(data) {
      if (this.type === 1) {
        this.dateRangeList.forEach((itm) => {
          (itm.nodes || []).forEach((citm) => {
            citm.isDefault = 1;
            if (citm.id === data.id) {
              citm.isDefault = 0;
            }
          });
        });
      } else {
        this.dateRangeList.forEach((itm) => {
          itm.isDefault = 1;
          if (itm.id === data.id) {
            itm.isDefault = 0;
          }
        });
      }
      this.$forceUpdate();
      this.$emit("handleAccChange", {
        ids: data.id,
        mids: data.mId,
        names: data.name,
        pnames: data.id.substr(0, 4),
        list: this.dateRangeList,
        yearNum: data.yearNum
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.date-interval-container {
  position: relative;
  .date-bar {
    width: 62px;
    background: #fff;
    border-radius: 5px;
    font-size: 12px;
    .date-choose {
      position: relative;
      text-align: center;
      cursor: pointer;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 50px;
      border-bottom: 1px solid #f3f3f3;
      &::before {
        position: absolute;
        left: 31px;
        top: 22px;
        content: "";
        height: 6px;
        width: 0;
        border-left: 1px solid #ababab;
        line-height: 0;
      }
    }
    .date-sel {
      line-height: 25px;
      cursor: pointer;
    }
    .top-arrow {
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      background: #f2f2f6;
      border-radius: 50%;
      margin: 0 auto 10px auto;
      cursor: pointer;
      .en-icon {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        margin-top: 2px;
      }
    }
    .bottom-arrow {
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      background: #f2f2f6;
      border-radius: 50%;
      margin: 10px auto;
      cursor: pointer;
      .en-icon {
        margin-top: 4px;
      }
    }
    .line-list {
      height: 420px;
      padding-top: 10px;
      overflow: hidden;
    }
    .line {
      height: 510px;
      padding-top: 10px;
      text-align: center;
      .date-item {
        position: relative;
      }
      .date-year {
        height: 12px;
        line-height: 12px;
        background: #edf4ff;
        color: #3e90fe;
        // border-top: 1px solid #ffffff;
        // border-bottom: 1px solid #ffffff;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        z-index: 9;
      }
      .date-month {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        position: relative;
        .en-icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
        }
      }
      .date-month.cur {
        background: #edf4ff;
        color: #3e90fe;
      }
      .date-month.disabled {
        color: #b9c4d2;
      }
    }
  }
  .open-icon {
    position: absolute;
    top: 225px;
    right: -4px;
    width: 16px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: rgba(25, 48, 70, 0.5);
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    vertical-align: middle;
    z-index: 9;
  }
}
.active {
  .date-bar,
  .date-panel {
    display: none;
  }
  .open-icon {
    .en-icon {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      margin-top: 2px;
    }
  }
}
</style>
