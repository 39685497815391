<!--
 * @Author: 周晴龙
 * @Date: 2020-07-20 19:05:09
 * @LastEditTime: 2021-07-15 10:04:24
 * @LastEditors: pengyu
 * @Description: 搜索组件
 * @FilePath: \user\src\views\intelligentAccounting\components\searchList\index.vue
-->
<template>
  <div class="search">
    <div class="search-box" v-show="isShow">
      <div class="search-condition">
        <div class="label">
          <span>账套名称</span>
        </div>
        <div class="sel-box">
          <en-select
            v-model="accountDefault"
            placeholder="请选择"
            :data="accountList"
            :props="accountNameProps"
            align="left"
            :disabled="isDisabled"
            @change="accountClk"
          >
          </en-select>
        </div>
      </div>
      <div class="search-condition" v-if="!hideVocRange">
        <div class="label">
          <span>凭证范围</span>
        </div>
        <div class="sel-box">
          <en-select
            v-model="vocrangeDefault"
            placeholder="请选择"
            :data="vocrangeList"
            :props="vocrangeProps"
            align="left"
            :disabled="isDisabled"
          >
          </en-select>
       </div>
      </div>
      <div class="search-condition">
        <div class="label">
          <span>记账主体</span>
        </div>
        <div class="sel-box">
          <en-select
            v-model="accountMainDefault"
            mode="tree"
            placeholder="请选择"
           :props="accountMainProps"
            :data="accountMainList"
            filterable
            :multiple="accountMainMulti"
            :disabled="isDisabled"
            @change="mainClk"
          >
          </en-select>
        </div>
      </div>
      <en-button class="query-btn" @click="getSelectVal()">查询</en-button>
    </div>
    <en-icon
      name="sousuo-danchuang"
      size="small"
      style="color:#a9b5c6"
      @click.native="searchBox"
    ></en-icon>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { commonService } from "@/api/intelligentAccounting";

export default {
  name: "searchList",
  props: {
    searchDataList: {}, // 搜索的下拉数据
    defaultSearchData: {}, // 搜索的默认数据
    isDisabled: { // 是否禁用
      type: Boolean,
      default: false
    },
    hideVocRange: { // 无凭证范围
      type: Boolean,
      default: false
    },
    accountMainMulti: { // 记账主体 是否多选
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 搜索框的隐藏显示
      isShow: true,
      // 账套props
      accountNameProps: {
        label: "name",
        value: "id",
        assist: "name"
      },
      // 默认账套
      accountDefault: "",
      // 凭证范围props
      vocrangeProps: {
        label: "name",
        value: "id",
        assist: "name"
      },
      // 默认范围
      vocrangeDefault: 1,
      // 记账主体props
      accountMainProps: {
        label: this.contentFormat,
        value: "id",
        children: "nodes",
        assist: "name"
      },
      // 默认记账主体
      accountMainDefault: [],
      // 账套下拉数据
      accountList: [],
      vocrangeList: [],
      accountMainList: [],
      // 选择框数据
      searchData: {}
    };
  },
  computed: {
    listenChange() {
      const { searchDataList, defaultSearchData } = this;
      return { searchDataList, defaultSearchData };
    }
  },
  watch: {
    // 查询默认搜索数据
    listenChange(val) {
      if (val) {
        this.accountDefault = this.defaultSearchData.accountDefault;
        this.vocrangeDefault = Number(this.defaultSearchData.vocrangeDefault);
        this.accountMainDefault = this.defaultSearchData.accountMainDefault;
        this.accountList = this.searchDataList.accountList;
        this.vocrangeList = this.searchDataList.vocrangeList;
        this.accountMainList = this.searchDataList.accountMainList;
        // this.getSelectVal();
        // this.$emit("clickData", this.searchData);
        if (!this.accountMainMulti) {
          this.accountMainDefault = this.defaultSearchData.accountMainDefault.toString();
        }
      }
    },
    searchData(val) {
      if (val) {
        if (!val.accountMain.length) {
          Message("请选择记账主体");
          return;
        }
        this.$emit("clickData", this.searchData);
      }
    }

  },
  mounted() {
  },
  methods: {
    /**
     * @description: 获取重新选择后的值
     * @param {type}
     */
    getSelectVal() {
      this.searchData = {
        account: this.accountDefault,
        vocrange: this.vocrangeDefault,
        accountMain: this.accountMainDefault
      };
    },
    /**
     * @description: 账套名称选中
     * @param {val}
     */
    accountClk() {
      this.getSelectVal();
      this.saveAccHabit();
    },
    /**
     * @description: 记账主体
     * @param {val}
     */
    mainClk() {
      this.saveAccHabit();
      // this.getSelectVal();
    },
    /**
     * @description: 搜索框部分显示隐藏
     * @param {val}
     */
    searchBox() {
      this.isShow = !this.isShow;
    },
    contentFormat(data) {
      return `${data.code || ""}${data.name || ""}`;
    },
    /**
     * @description: 账套切换保存习惯
     * @param param
     */
    saveAccHabit() {
      const _this = this;
      const pa = {
        accountId: this.accountDefault,
        accountingEntity: (() => {
          if (_this.accountMainMulti) {
            return _this.accountMainDefault.join(",");
          }
          return _this.accountMainDefault;
        })()
      };
      commonService.saveAccHabit(pa);
    }
  }
};
</script>

<style lang="scss" scoped>
  .search {
    position: relative;
    border-right: 1px solid #e8ecf2;
    margin-right: 15px;
    cursor: pointer;
    .search-box {
      position: absolute;
      right: 50px;
      top: -8px;
      height: 48px;
      line-height: 46px;
    }
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin: 0 15px;
    }
    .search-box {
      background: #ecf1f7;
      border: 1px solid #e8ecf2;
      display: flex;
      .search-condition {
        display: flex;
        width: 240px;
        margin-right: 20px;
        &:first-child{
          margin-left: 20px;
        }
      }
      .label {
        width: 20%;
      }
      .sel-box{
        flex: 1;
        margin-left: 10px;
        .en-select{
          width: 100%;
        }
      }
      .query-btn {
        margin: 7px 20px 0 0;
        height: 30px;
        line-height: 30px;
        width: 75px;
      }
    }
  }
</style>
