var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { visible: _vm.visible, title: "记账日期", width: "700px" },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c("div", { staticClass: "body-contain" }, [
        _c(
          "div",
          { staticClass: "flex-item" },
          [
            _c("div", { staticClass: "tips-text" }, [_vm._v(" 默认日期 ")]),
            _c("el-date-picker", {
              staticClass: "date-picker",
              attrs: { type: "date", placeholder: "选择日期", clearable: "" },
              model: {
                value: _vm.dateValue,
                callback: function ($$v) {
                  _vm.dateValue = $$v
                },
                expression: "dateValue",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "bottom-tips-text" }, [
            _vm._v(" 注：单据日期不同，如存在合并凭证，需选择默认记账日期 "),
          ]),
          _c(
            "en-button",
            { staticClass: "button", on: { click: _vm.confirmClick } },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }