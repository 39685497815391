var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enLoading,
          expression: "enLoading",
        },
      ],
      staticClass: "container",
    },
    [
      _c(
        "en-top-menu",
        {
          attrs: { type: "white", "menu-name": "凭证冲销", "need-back": true },
        },
        [
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c("status-model", {
                attrs: { status: _vm.vcdata.voucherData.status },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-row",
            { staticClass: "top-row" },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [_vm._v("凭证号：自动生成")]),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [
                  _vm._v("凭证来源：" + _vm._s(_vm.vcdata.voucherData.refCode)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [
                  _vm._v(
                    "所属账套：" + _vm._s(_vm.vcdata.voucherData.accountName)
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [
                  _vm._v(
                    "记账主体：" +
                      _vm._s(_vm.vcdata.voucherData.accountingEntityName)
                  ),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("span", [_vm._v("记账日期：")]),
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "选择日期时间",
                      format: "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions,
                    },
                    on: { change: _vm.produceDateChange },
                    model: {
                      value: _vm.vcdata.voucherData.produceDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.vcdata.voucherData, "produceDate", $$v)
                      },
                      expression: "vcdata.voucherData.produceDate",
                    },
                  }),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [
                  _vm._v(
                    "会计期间：" +
                      _vm._s(_vm.vcdata.voucherData.accountingPeriodName)
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "grid-wrap" },
            [
              _c("voucherTable", {
                attrs: {
                  accountId: _vm.vcHeader.accountId,
                  list: _vm.vcdata.itemList,
                  subjectList: _vm.subjectList,
                  actionType: _vm.actionType,
                },
                on: { updateItemList: _vm.handleUpdateItemList },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "middle-row" }, [
            _c("div", [
              _vm._v("制单人: " + _vm._s(_vm.vcdata.voucherData.makeName)),
            ]),
            _c("div", [
              _vm._v("审核人: " + _vm._s(_vm.vcdata.voucherData.approveName)),
            ]),
            _c(
              "div",
              [
                _c("span", [_vm._v("附单据")]),
                _c("el-input", {
                  attrs: {
                    type: "number",
                    disabled: _vm.actionType === 2,
                    placeholder: "请输入",
                  },
                  model: {
                    value: _vm.attachmentNum,
                    callback: function ($$v) {
                      _vm.attachmentNum = $$v
                    },
                    expression: "attachmentNum",
                  },
                }),
                _c("span", { staticClass: "suffix" }, [_vm._v("张")]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "bottom-row" },
            [
              _c("span", [_vm._v("附件与图片")]),
              _vm.actionType !== 2
                ? _c(
                    "el-upload",
                    {
                      staticClass: "foot-upload",
                      attrs: {
                        multiple: "",
                        accept: _vm.upextStr,
                        data: _vm.uploadParams,
                        name: "storage_file",
                        action: _vm.urlParam.server,
                        "on-success": _vm.handleUpload,
                        "show-file-list": false,
                      },
                    },
                    [
                      _c("en-icon", {
                        attrs: {
                          name: "tianjia-danchuang",
                          color: "#4894DF",
                          size: "12",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "file-row" }, [
            _c(
              "div",
              { staticClass: "img-lis" },
              _vm._l(_vm.imageList, function (item, index) {
                return _c("imgLi", {
                  key: index,
                  attrs: {
                    attr: item,
                    index: index,
                    disable: _vm.actionType === 2,
                  },
                  on: { "img-close": _vm.imageClose },
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "file-lis" },
              _vm._l(_vm.fileList, function (item, index) {
                return _c("fileLi", {
                  key: index,
                  attrs: {
                    attr: item,
                    index: index,
                    disable: _vm.actionType === 2,
                  },
                  on: { "file-close": _vm.fileClose },
                })
              }),
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "btn-row" },
            _vm._l(_vm.btnList, function (item, idx) {
              return _c(
                "en-button",
                {
                  key: idx,
                  attrs: { type: item.type },
                  on: {
                    click: function ($event) {
                      return _vm.voucherBtnClick(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("createModel", {
        ref: "popCreateModel",
        attrs: {
          itemList: _vm.modelItemList,
          yearNum: _vm.vcHeader.yearNum,
          accountId: _vm.vcHeader.accountId,
        },
      }),
      _c("quoteModel", {
        ref: "popQuoteModel",
        attrs: {
          accountId: _vm.vcHeader.accountId,
          yearNum: _vm.vcHeader.yearNum,
        },
        on: { handleChooseModel: _vm.handleQuoteModel },
      }),
      _c("printAlert", { ref: "popPrint" }),
      _c("checkCashFlow", {
        ref: "cashFlowMod",
        attrs: { itemList: _vm.submitParam.itemList },
        on: { handleCashFlowSubmit: _vm.cashFlowSubmit },
      }),
      _c("logAlert", { ref: "popLog" }),
      _c("vocPrintTemplate"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }