<!--
 * @Author: Zhouql
 * @Date: 2020-03-06 14:29:41
 * @LastEditTime: 2021-07-12 11:01:07
 * @LastEditors: pengyu
 * @Description: 账簿-总账
 * @FilePath: \user\src\views\intelligentAccounting\accountBook\generalLedger\index.vue
 -->
<template>
  <div class="generalLedger">
    <div class="content" :class="isActive ? 'left-container expand' : 'left-container'">
      <en-title-card name="总账">
        <div slot="right">
          <searchList @clickData="getVal" :searchDataList="searchDataList" :defaultSearchData="defaultSearchData"></searchList>
          <div class="btn-export" @click="handleExport">
            <en-icon name="bus-btn5_1" size="small" style="color:#26c393"></en-icon>
            <div class="label">导出</div>
          </div>
          <div class="btn-print" @click="printTable('print-template-total')">
            <en-icon name="bus-btn6_1" size="small" style="color:#3e90fe"></en-icon>
            <div class="label">打印</div>
          </div>
        </div>
      </en-title-card>
      <div class="top-head">
        <subjectSel :subjectList="subjectList" @subjectVal="subjectValue"></subjectSel>
      </div>
      <!--表格部分-->
      <div class="table-main" v-en-loading="enLoading">
        <en-table ref="dataTable" :data="legerList" height="100%" @row-click="ledger">
          <en-table-column type="index" width="60" align="center"></en-table-column>

          <en-table-column prop="accountingPeriodName" header-align="center" label="期间" width="110"></en-table-column>
          <en-table-column prop="remark" label="摘要" width="330" header-align="center"></en-table-column>
          <en-table-column prop="subjectName" label="科目" width="280" header-align="center"></en-table-column>

          <en-table-column prop="bor" label="借方金额" width="" header-align="center" align="right">
            <template slot-scope="scope">
              {{ scope.row.bor | thousand(2) }}
            </template>
          </en-table-column>
          <en-table-column prop="loan" label="贷方金额" width="" header-align="center" align="right">
            <template slot-scope="scope">
              {{ scope.row.loan | thousand(2) }}
            </template>
          </en-table-column>
          <en-table-column prop="direction" label="方向" width="" header-align="center" align="center"></en-table-column>
          <en-table-column prop="balance" label="余额" width="" header-align="center" align="right">
            <template slot-scope="scope">
              {{ scope.row.balance | thousand(2) }}
            </template>
          </en-table-column>
        </en-table>
      </div>
    </div>
    <!-- '会计期间' -->
    <dateInterval :type="2" :hideHead="false" :list="dateLineList" @collapseBtn="isActive = !isActive" @handleAccChange="accChange" class="date-interval"></dateInterval>
    <!--'详情'-->
    <ledgerModel ref="ledgerModel" typeId="zz" :afferentData="afferentItem" @printDetail="printDetail"></ledgerModel>
    <!-- 总帐打印模板 -->
    <print-template-total :data-list="legerList" :search-data="printSearchData" ></print-template-total>
    <!-- 打印模板详情 -->
    <print-template-detail :dataList="detailList" :afferentData="afferentItem" :search-data="printSearchData"></print-template-detail>
    <!-- 凭证详情 -->
    <vocDetailAlert
      ref="vocDialog"
      :voucherId="voucherId"
      :actionType="2"
      :formal="{}"
      openFlag="quotes"
    ></vocDetailAlert>

  </div>
</template>

<script>
import { request } from "en-js";
import { Message } from "element-ui";
import { commonService, generalLedgerService } from "@/api/intelligentAccounting";
import accountBookComm from "@/mixins/intelligentAccounting/accountBookComm.js";
import ledgerModel from "../alert/subsidiaryLedger.vue";
import searchList from "../../components/searchList";
import dateInterval from "../../components/dateInterval"; // 会计期间控件
import subjectSel from "../../components/subjectSel";
import PrintTemplateTotal from "./components/PrintTemplateTotal";
import PrintTemplateDetail from "../printTemplate/PrintTemplateDetail";
import vocDetailAlert from "../../components/vocDetailAlert";

// 顶部搜索
export default {
  name: "GeneralLedger",
  mixins: [accountBookComm],
  components: {
    searchList,
    dateInterval,
    subjectSel,
    ledgerModel,
    PrintTemplateTotal,
    PrintTemplateDetail,
    vocDetailAlert
  },
  data() {
    return {
      enLoading: null,
      dateLineList: [], // 会计期间数据
      // 凭证范围下拉数据
      vocrangeList: [
        {
          id: 1,
          name: "全部凭证"
        },
        {
          id: 2,
          name: "仅已审核"
        }
      ],
      accountList: [], // 账套下拉数据
      accountMainList: [], // 记账主体下拉数据
      accountDefault: {}, // 默认账套
      // 默认范围
      vocrangeDefault: 1,
      accountMainDefault: [], // 默认记账主体
      searchDataList: {}, // 搜索的下拉数据
      defaultSearchData: {}, // 搜索默认数据
      subjectList: [], // 科目列表
      isActive: false, // 会计区间显示
      defaultYearNum: "", // 会计区间选中年份
      subjectCode: "", // 科目的code
      list: [], // 表格数据
      detailList: [],
      afferentItem: {},
      endDate: "",
      startDate: "",
      voucherId: ""
    };
  },
  computed: {
    legerList() {
      // 需要处理一下请求数据
      const arr = [];
      this.list.forEach((itm) => {
        itm.accList?.forEach((citm) => {
          citm.accountingPeriod = itm.accountingPeriod;
          citm.accountingPeriodName = itm.accountingPeriodName;
          arr.push(citm);
        });
      });
      return arr;
    },
    printSearchData() {
      let unit = "";
      const findNode = (list, id) => {
        if (!id) return;
        list.forEach((item) => {
          if (item.id === id) {
            unit = item.code + item.text;
          } else if (item.nodes) {
            findNode(item.nodes, id);
          }
        });
      };
      const legerObj = this.subjectList.find((item) => item.code === this.subjectCode);
      findNode(this.accountMainList, this.accountMainDefault[0]);
      const { startDate, endDate } = this;
      const period = startDate && endDate && startDate !== endDate ? `${startDate}-${endDate}` : endDate || startDate;
      return {
        unit,
        period,
        title: `${legerObj?.code + legerObj?.name}总帐`
      };
    }
  },
  watch: {
    isActive() {
      this.$refs.dataTable.resetResizable();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    printDetail(list) {
      this.detailList = list;
      this.$nextTick(() => {
        this.printTable("print-template-detail");
      });
    },
    /**
     * @description: 初始化
     */
    async init() {
      await this.queryAccList();
      await this.queryAccTime();
      // 获取记账主体数据列表
      await this.queryAccountEntity();
      await this.queryAllSubject();
      await this.queryAbTotal();
    },
    // 请求日期区间数据接口
    // @request(true, "enLoading")
    async queryAccTime() {
      const _this = this;
      const res = await commonService.queryAccTime({
        accountId: _this.accountDefault,
        hasChildren: "001"
      });
      this.dateLineList = res;
      this.accPeriod();
    },
    /**
     * @deprecate
     */
    accPeriod() {
      // 默认会计区间获取会计区间选中ID
      this.dateLineList.forEach((item) => {
        if (item.isDefault === 0) {
          this.defaultYearNum = item.id;
          this.startDate = item.name;
          this.endDate = item.name;
        }
      });
    },
    // 请求表格数据
    @request(true, "enLoading")
    async queryAbTotal() {
      const pa = this.getCommParam();
      const res = await generalLedgerService.queryAbTotal(pa);
      this.list = res?.dataList || [];
    },
    /**
     * @description: 日期区间选中数据
     */
    accChange(data) {
      this.defaultYearNum = data.ids;
      this.startDate = data?.names.split(",")[0];
      this.endDate = data?.names.split(",")[1];
      this.queryAbTotal();
    },
    /**
     * @description: 取科目下拉选中数据
     */
    subjectValue(param) {
      this.subjectCode = param.code;
      this.queryAbTotal();
    },
    /**
     * @description: 取搜索组件带出数据
     */
    async getVal(data) {
      const originAccount = this.accountDefault;
      if (data.accountMain.length === 0) {
        Message("请选择记账主体");
        return;
      }
      this.accountDefault = data.account;
      this.accountMainDefault = data.accountMain;
      this.vocrangeDefault = data.vocrange;
      if (data.account !== originAccount) {
        // 账套变了  需要重新查询会计期间 和 科目
        this.subjectList = [];
        await this.queryAccTime();
        await this.queryAllSubject();
      }
      this.queryAbTotal();
    },
    /**
     * @description: 详情
     */
    ledger({ row }) {
      this.afferentItem = {
        subjectCode: row.subjectCode, // 科目code
        subjectName: row.subjectName, // 科目名称
        ...this.getCommParam(),
        accountingPeriod: row.accountingPeriod,
        accountingPeriodName: row.accountingPeriodName
      };
      this.$refs.ledgerModel.visible = true;
    },
    /**
     * @description: 导出
     */
    handleExport() {
      if (!this.accountMainDefault.length) {
        Message("请选择记账主体");
        return;
      }

      const pa = { typeId: "zz", ...this.getCommParam() };
      this.handleExportExcel(pa);
    },
    // 查询参数
    getCommParam() {
      const _this = this;
      const params = {
        accountId: this.accountDefault,
        accountName: (() => {
          const o = this.accountList.filter((itm) => itm.id === _this.accountDefault);
          if (o.length) {
            return o[0].name;
          }
          return "";
        })(),
        accountingEntity: this.accountMainDefault.toString(),
        accountingEntityName: (() => {
          const re = [];
          _this.accountMainDefault.forEach((itm) => {
            _this.accountMainList.forEach((itm2) => {
              if (itm2.id === itm) {
                re.push(`${itm2.code} ${itm2.name}`);
              }
              itm2.nodes?.forEach((itm3) => {
                if (itm3.id === itm) {
                  re.push(`${itm3.code} ${itm3.name}`);
                }
              });
            });
          });
          return re.join(",");
        })(),
        accountingPeriod: "",
        status: this.vocrangeDefault,
        subjectCode: this.subjectCode,
        subjectName: (() => {
          const o = this.subjectList.filter((itm) => itm.code === _this.subjectCode);
          if (o.length) {
            return `${o[0].code} ${o[0].name}`;
          }
          return "";
        })(),
        yearNum: this.defaultYearNum,
        accountingPeriodName: this.defaultYearNum,
        isYearNum: "1",
        startDate: this.startDate,
        endDate: this.endDate
      };

      if (!params.yearNum) {
        params.isYearNum = "0";
      }

      return params;
    }
  }
};
</script>

<style lang="scss" scoped>
.generalLedger {
  position: relative;
  background-color: #1d2532;
  .en-title-card {
    overflow: hidden;
  }
  .top-head {
    height: 45px;
    line-height: 45px;
    text-align: right;
    overflow: hidden;
    .choice-subject {
      float: right;
      margin-right: 20px;
    }
  }
  .btn-export,
  .btn-print {
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
      color: #636c78;
    }
  }
  .btn-print {
    margin-right: 0;
  }
  .table-main {
    height: calc(100% - 100px);
  }
}
.left-container {
  height: 100%;
  width: calc(100% - 72px);
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &.expand {
    width: 100%;
  }
}
.date-interval {
  position: absolute;
  right: 4px;
  top: 0;
}
</style>
